import axios from 'axios';
import { queryCache } from "react-query"
import {
  UNAUTH_USER,

  FETCH_PICKUP_POINT,
  FETCH_PICKUP_POINT_BY_ID,
  ADD_PICKUP_POINT,
  EDIT_PICKUP_POINT,
  DELETE_PICKUP_POINT,
  CLEAR_PICKUP_POINT_ERRORS,

  FETCH_RETURN_POINT,
  FETCH_RETURN_POINT_BY_ID,
  ADD_RETURN_POINT,
  EDIT_RETURN_POINT,
  DELETE_RETURN_POINT,
  CLEAR_RETURN_POINT_ERRORS,

  ADDRESS_ADDED_TYPE
} from './types';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const defaultToken = localStorage.getItem('token');
if (defaultToken) {
  axios.defaults.headers.common['Authorization'] = 'Token ' + defaultToken;
}

export function fetchPickupPoint(type="pickup") {
  /**
   * param type:
   * "pickup": "return"
   */
  console.log(`fetching ${type} point...`);

  const token = localStorage.getItem('token');

  return function(dispatch) {
    const url = `/api/shipper/${type}-point/`
    axios.get(url,
      { headers: { 'Authorization': 'Token ' + token }}
    )
    .then((response) => {
      console.log(response);
      dispatch({
        type: type === "pickup" ? FETCH_PICKUP_POINT:FETCH_RETURN_POINT,
        payload: response.data
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: UNAUTH_USER });
    })
  }
}

export function fetchPickupPointById(id, type="pickup") {
  console.log(`fetching ${type} point by id ${id}`);
  const token = localStorage.getItem('token');

  return function(dispatch) {
    const url = `/api/shipper/${type}-point/${id}/`
    axios.get(url,
      { headers: { 'Authorization': 'Token ' + token }}
    )
    .then((response) => {
      console.log(response);
      dispatch({
        type: type === "pickup" ? FETCH_PICKUP_POINT_BY_ID:FETCH_RETURN_POINT_BY_ID,
        payload: response.data
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: UNAUTH_USER });
    })
  }
}

export function addPickupPoint(data, type="pickup") {
  console.log(`adding ${type} point...`);
  const token = localStorage.getItem('token');

  return function(dispatch) {
    const url = `/api/shipper/${type}-point/`
    axios.post(url,
      data,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token }}
    )
    .then((response) => {
      console.log(response);
      dispatch({
        type: type === "pickup" ? ADD_PICKUP_POINT:ADD_RETURN_POINT,
        payload: false
      });
      dispatch({
        type: ADDRESS_ADDED_TYPE,
        payload: type
      })
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: type === "pickup" ? ADD_PICKUP_POINT:ADD_RETURN_POINT,
        payload: true,
        errorData: error.data
      });
    });
  }
}

export function editPickupPoint(pickupPointId, data, type="pickup") {
  console.log(`editing ${type} point by ${pickupPointId}`);
  const token = localStorage.getItem('token');

  return function(dispatch) {
    const url = `/api/shipper/${type}-point/${pickupPointId}/`
    axios.patch(url,
      data,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token }}
    )
    .then((response) => {
      queryCache.invalidateQueries("return-points")
      dispatch({
        type: type === "pickup" ? EDIT_PICKUP_POINT:EDIT_RETURN_POINT,
        payload: false
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: type === "pickup" ? EDIT_PICKUP_POINT:EDIT_RETURN_POINT,
        payload: true,
        errorData: error.data
      });
    });
  }
}

export function deletePickupPoint(pickupPointId, type="pickup") {
  console.log(`deleting ${type} point by ${pickupPointId}`);
  const token = localStorage.getItem('token');

  return function(dispatch) {
    const url = `/api/shipper/${type}-point/${pickupPointId}/`
    axios.delete(url,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token }}
    )
    .then((response) => {
      console.log(response);
      dispatch({
        type: type === "pickup" ? DELETE_PICKUP_POINT:DELETE_RETURN_POINT,
        payload: false,
        status: response.status
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: type === "pickup" ? DELETE_PICKUP_POINT:DELETE_RETURN_POINT,
        payload: true
      });
    });
  }
}

export function clearPickupPointErrors(type="pickup") {
  console.log('clearing pickup point errors...');
  return {
    type: type === "pickup" ? CLEAR_PICKUP_POINT_ERRORS:CLEAR_RETURN_POINT_ERRORS
  }
}
