import React from 'react';
import { Empty, Button } from 'antd';
import emptyImage from '../../../images/draftOrder/no_channel.svg'
import { Link } from 'react-router-dom';

export const EmptyChannel = (props) => {
  return (
  <Empty
    image={emptyImage}
    imageStyle={{
      height: 500,
    }}
    description={
      <span>
        It seems you haven't connected our eCommerce store yet.
      </span>
    }
  >
    <Link to={"/add-channel/"}>
      <Button type="primary">Connect a store</Button>
    </Link>
  </Empty>
  )
}

