/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Jumbotron } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import { connect } from "react-redux";
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactTable from "react-table";
import "react-table/react-table.css";
import _ from 'lodash';
import axios from 'axios';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next';
import { DatePicker, Modal, message, Alert, Select, Pagination } from 'antd';
import { history } from "../../utils/historyUtils";
import { capitalizeUnderscore } from "../../utils"
import queryString from 'query-string'
import print from '../../images/print.png'

import { fetchShipperDetails } from '../../actions/shipperDetailsActions';
import {
  fetchAllCountries,
  fetchService,
  fetchStatuses,
  fetchPrevious,
  fetchNext,
  fetchOrderByFilter,
  deleteOrder,
  clearOrderErrors
} from '../../actions/orderActions';
import {ROOT_URL} from '../../actions/index';

import twoGoExpressLogo from '../../images/twoGoExpress.svg';
import stickerLogo from '../../images/janio-sticker-logo.svg';

// import DropdownField from '../common/DropdownField';
import SelectServices from "./SelectServices";
import MPSIcon from "./viewOrderDetails/components/MPSIcon";

const { Option, OptGroup } = Select

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});

const { RangePicker } = DatePicker;

const initialState = {
  error: true,
  fetched: false,
  assigned: false,
  fetchedOrders: false,

  urlParamsList: [],
  dataObj: {},
  secretKey: '',
  serviceId: 0,
  serviceValue: null,
  clearValue: null,
  fromDate: moment().subtract(90, 'days').startOf('day'),
  toDate: moment().endOf('day'),
  pickupDateFrom: null,
  pickupDateTo: null,
  pickupDate: null,
  trackingNo: '',
  shipperOrderId: '',
  statusCode: '',
  uploadBatchNo: '',
  pickupCountry: '',
  consigneeCountry: '',
  consigneeName: '',
  consigneeEmail: '',
  phoneNumber: '',

  shipperOrderIdStatus: true,
  trackingNoStatus: true,
  statusCodeStatus: true,
  createdOnStatus: true,
  consigneeNameStatus: false,
  consigneeEmailStatus: false,
  pickupCountryStatus: false,
  consigneeCountryStatus: false,
  consigneeAddressStatus: false,
  consigneeCustomsDocuments: false,
  phoneNumberStatus: true,
  uploadBatchNoStatus: false,
  isProcessing: false,
  pickupDateStatus: false,
  forwardTrackingNoStatus: false,
  orderTypeStatus: false,
  identification_document_name: false,
  consignee_identifcation_number: false,

  selectAll: false,
  page: 1,
  pageSize: 50,
  selectedDataList: [],
  selectedAllDataList: [],
  lastUpdated: moment(),
  loading: false,
  showAllPageNums: false,
  printingOrder: [],
  isPrinting: false,
  showPrintingModal: false,
  confirmingCancelOrders: [],
  cancellingOrders: [],

  // New States to eliminate filter-refresh bug
  queryId: 0,
  data: undefined,
  totalOrders: undefined,
  totalPages: undefined,
  selectedService: null,

  publicStatusCode: ""
};


class ViewOrders extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.debouncedFetch = _.debounce(this.fetchOrders, 1000);
  }

  componentDidMount() {
    const location = window.location.search
    const queries = queryString.parse(location)
    const shipperOrderIds = queries['ids[]'] || queries.ids || queries.id
    const ids = shipperOrderIds instanceof Array ? shipperOrderIds.join():shipperOrderIds

    if (ids) {
      queries.shipperOrderId = ids
    }
    if (Object.keys(queries).length) {
      this.setState(queries)
    }

    this.props.fetchShipperDetails();
    if (this.props.publicStatuses === undefined) {
      this.props.fetchStatuses();
    }
    if (this.props.countries === undefined) {
      this.props.fetchAllCountries();
    }
  }

  componentDidUpdate() {
    // if (this.state.hasReturnFeature !== this.props.hasReturnFeature) {
    //   const { hasReturnFeature } = this.props
    //   this.setState({
    //     hasReturnFeature: hasReturnFeature,
    //     orderTypeStatus: hasReturnFeature,
    //     forwardTrackingNoStatus: hasReturnFeature
    //   })
    // }

    if (this.state.secretKey && !this.state.fetchedOrders) {
      this.fetchOrders();
      this.setState({
        fetchedOrders: true
      });
    }

    // Check if the query is the latest query, then update this.state.data with the latest data from latest query, change loading to false, and take care of paginations
    if (this.props.queryId === this.state.queryId && this.props.data !== this.state.data){
      this.setState({
        data: this.props.data,
        loading: false,
        totalOrders: this.props.totalOrders,
        totalPages: this.props.totalPages
      })
    }
    //////

    if (this.props.error !== this.state.error) {
      this.setState({
        error: this.props.error
      });
    }

    if (this.props.service === undefined && this.state.secretKey.length > 0 && !this.state.fetched) {
      this.props.fetchService(this.state.secretKey);
      this.setState({
        fetched: true
      });
    }

    if (this.props.service !== undefined && !this.state.assigned) {
      if (this.state.serviceId) {
        const service = this.props.service.filter(s => parseInt(s.service_id) === parseInt(this.state.serviceId))[0]
        this.setState({
          selectedService: {
            label: service.service_name,
            value: service.service_id,
            serviceId: service.service_id
          },
          assigned: true
        });
      }
    }

    if (this.props.lastUpdated && this.state.lastUpdated) {
      if ((this.props.lastUpdated.valueOf() > this.state.lastUpdated.valueOf()) && this.state.loading) {
        this.setState({
          loading: false
        });
      }
    }

    if (this.props.lastUpdated > this.state.lastUpdated) {
      this.setState({
        lastUpdated: this.props.lastUpdated
      });
    }

    const { shipperDetails } = this.props
    if (shipperDetails && shipperDetails.hasOwnProperty('agent_application_secret_key') && this.state.secretKey.length === 0) {
      this.setState({
        secretKey: this.props.shipperDetails.agent_application_secret_key
      });
    }
  }

  getPubliStatusCodeOptions = () => {
    const { public_statuses, public_statuses_v2 } = this.props.publicStatuses || {}
    const options = [
      <Option value="" key="public-status-all">All Statuses</Option>
    ]
    if (!!public_statuses_v2) {
      const statusGroup = []
      public_statuses_v2.forEach(s => {
        statusGroup.push(
          <Option value={`${s}-v2`} key={`public-status-v2-${s}`}>{capitalizeUnderscore(s)}</Option>
        )
      })
      options.push(
        <OptGroup key="public-status-label" label={
          <p className="mb-0"><strong>Public Statuses</strong></p>
        }>
          {statusGroup}
        </OptGroup>
      )
    }
    if (!!public_statuses) {
      const statusGroup = []
      public_statuses.forEach(s => {
        statusGroup.push(
          <Option value={`${s}-v1`} key={`public-status-old-${s}`}>{s}</Option>
        )
      })
      options.push(
        <OptGroup key="public-status-old-label" label={
          <p className="mb-0 mt-2"><strong>Old Public Statuses</strong></p>
        }>
          {statusGroup}
        </OptGroup>
      )
    }
    return options
  }

  renderCountries = () => {
    let option = [<option key={-1} value={''}>{this.props.t('common.allCountries')}</option>];

    _.map(this.props.countries || [], (item, i) => {
      option.push(<option key={i} value={item}>{item}</option>);
    });

    return option;
  }

  renderFilters = () => {
    return (
      <div className="row">
        <div className="col-sm">
          <Jumbotron className="p-4 border border-secondary">
            {
              this.state.secretKey.length === 0 && this.props.data === undefined
                ?
                null
                :
                <div>
                  <div className="h5 mt-3 font-weight-bold"><Trans i18nKey='common:orders.filters' /></div>
                  <div className="form-inline">
                    <SelectServices
                      serviceValue={this.state.selectedService}
                      clearValue={this.state.clearValue}
                      onChange={(e) => this.handleDropdownChange(e, 'serviceId')} />
                  </div>
                  <div className="form-inline">
                    <div className="d-flex mt-1 align-items-center mr-2">
                      
                    <div><Trans i18nKey='common:orders.fromDate' /></div>
                      <DatePicker
                        name="fromDate"
                        className="form-control ml-2"
                        format="DD-MM-YY"
                        // selected={this.state.fromDate !== null ? moment(this.state.fromDate) : null}
                        onChange={(e) => this.handleDateChange(e, "fromDate")}
                        locale="en-gb"
                        value={this.state.fromDate !== null ? moment(this.state.fromDate) : null}
                        // readOnly={true}
                      />
                    </div>

                    <div className="d-flex mt-1 align-items-center mr-2">
                      <div><Trans i18nKey='common:orders.toDate' /></div>
                      <DatePicker
                        name="toDate"
                        className="form-control ml-2"
                        format="DD-MM-YY"
                        defaultValue={moment(this.state.toDate)}
                        // selected={this.state.toDate !== null ? moment(this.state.toDate) : null}
                        onChange={(e) => this.handleDateChange(e, "toDate")}
                        locale="en-gb"
                        todayButton={"Today"}
                        // readOnly={true}
                      />
                    </div>

                    <div className="d-flex mt-1 align-items-center mr-2">
                      <div><Trans i18nKey='common:orders.pickupDate' /></div>
                      <RangePicker
                        name="pickupDate"
                        className="form-control ml-2"
                        // defaultValue={[!this.state.pickupDateFrom || moment(this.state.pickupDateFrom), moment(this.state.pickupDateTo)]}
                        // defaultValue={[moment(this.state.pickupDateFrom), moment(this.state.pickupDateTo)]}
                        format="DD-MM-YY"
                        onChange={this.handleDateRangeChange}
                        ranges={{
                          Today: [moment(), moment()],
                          'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        value={[this.state.pickupDateFrom ? moment(this.state.pickupDateFrom) : null, this.state.pickupDateTo ? moment(this.state.pickupDateTo) : null]}
                      />
                    </div>

                    <div className="d-flex mt-1 align-items-center mr-2">
                      <div><Trans i18nKey='common:orders.trackingNos' /></div>
                      <input
                        className="form-control ml-2 mr-sm-2"
                        type="search"
                        placeholder="e.g. ABC123,DEF456"
                        aria-label="Search"
                        name="trackingNo"
                        value={this.state.trackingNo}
                        onChange={(e) => this.handleOnChange(e)}
                      />
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <div>Consignee Name</div>
                      <input
                        className="form-control ml-2 mr-sm-2"
                        type="search"
                        placeholder="Enter consignee name"
                        aria-label="Search"
                        name="consigneeName"
                        value={this.state.consigneeName}
                        onChange={(e) => this.handleOnChange(e)}
                      />
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <div>Consignee Email</div>
                      <input
                        className="form-control ml-2 mr-sm-2"
                        type="search"
                        placeholder="Enter consignee email"
                        aria-label="Search"
                        name="consigneeEmail"
                        value={this.state.consigneeEmail}
                        onChange={(e) => this.handleOnChange(e)}
                      />
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <div>Phone Number</div>
                      <input
                        className="form-control ml-2 mr-sm-2"
                        type="search"
                        placeholder="e.g. 123456"
                        aria-label="Search"
                        name="phoneNumber"
                        value={this.state.phoneNumber}
                        onChange={(e) => this.handleOnChange(e)}
                      />
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <div><Trans i18nKey='common:orders.shipperOrderIdForFilter' /></div>
                      <input
                        className="form-control ml-2 mr-sm-2"
                        type="search"
                        placeholder={this.props.t('orders.enterShipperOrderId')}
                        aria-label="Search"
                        name="shipperOrderId"
                        value={this.state.shipperOrderId}
                        onChange={(e) => this.handleOnChange(e)}
                      />
                    </div>
                    <div className="d-flex mt-1 align-items-center">
                      <div><Trans i18nKey='common:orders.uploadBatchNo' /></div>
                      <input
                        className="form-control ml-2 mr-sm-2"
                        type="search"
                        placeholder={this.props.t('orders.allUploadBatchNo')}
                        aria-label="Search"
                        name="uploadBatchNo"
                        value={this.state.uploadBatchNo}
                        onChange={(e) => this.handleUploadBatchNoChange(e)}
                      />

                      {/* <select
                        id="uploadBatchNo"
                        className="ml-2 custom-select"
                        name="uploadBatchNo"
                        value={this.state.uploadBatchNo != null ? this.state.uploadBatchNo : ''}
                        onChange={(e) => this.handleUploadBatchNoChange(e)}
                      >
                        {this.renderUploadBatchNo()}
                      </select> */}

                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <div><Trans i18nKey='common:orders.pickupCountry' /></div>
                      <select
                        id="pickupCountry"
                        className="ml-2 custom-select w-50"
                        name="pickupCountry"
                        value={this.state.pickupCountry != null ? this.state.pickupCountry : ''}
                        onChange={(e) => this.handlePickupCountryChange(e)}
                      >
                        {this.renderCountries()}
                      </select>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <div><Trans i18nKey='common:orders.consigneeCountry' /></div>
                      <select
                        id="consigneeCountry"
                        className="ml-2 custom-select w-50"
                        name="consigneeCountry"
                        value={this.state.consigneeCountry != null ? this.state.consigneeCountry : ''}
                        onChange={(e) => this.handleConsigneeCountryChange(e)}
                      >
                        {this.renderCountries()}
                      </select>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <div><Trans i18nKey='common:orders.statusCode' /></div>
                      {/* <select
                        id="statusCode"
                        className="ml-2 custom-select w-50"
                        name="statusCode"
                        value={(this.state.statusCode || '')}
                        onChange={(e) => this.handleStatusCodeChange(e)}
                      >
                        {this.renderStatuses()}
                      </select> */}
                      <div className="ml-2 w-100">
                        {/* <select
                          id="statusCode"
                          className="ml-2 custom-select"
                          name="statusCode"
                          value={this.state.statusCode != null ? this.state.statusCode : ''}
                          onChange={(e) => this.handleDropdownChange(e, 'statusCode')}
                        >
                          {this.renderStatuses()}
                        </select> */}
                        <Select
                          id="publicStatusCode"
                          value={this.state.publicStatusCode}
                          style={{ width: 300 }}
                          onChange={(e)=> this.handleDropdownChange(e, 'publicStatusCode')}
                        >
                          {this.getPubliStatusCodeOptions()}
                        </Select>
                      </div>
                    </div>
                    {/* <div className="d-flex mt-1 align-items-center mr-auto">
                      <input type="checkbox" name="isProcessing" id="isProcessing"
                        onChange={this.handleCheckboxFilter} checked={(!!this.state.isProcessing)} />
                      <label htmlFor='isProcessing' className='ml-2'>
                        <Trans i18nKey='common:orders.isProcessing' />
                      </label>
                    </div> */}
                    <div className="d-flex mt-1 align-items-center" style={{ gap: 8 }}>
                      <button onClick={this.handleApplyFilters} type="button" className="btn btn-primary">Apply</button>
                      <button
                        onClick={this.handleResetFilters}
                        type="button"
                        className="w-100 btn btn-secondary">
                        <Trans i18nKey='common:orders.resetFilters' />
                      </button>
                    </div>
                  </div>
                  <div className="h5 mt-3 font-weight-bold"><Trans i18nKey="common:stores.showOrHideColumns" /></div>
                  <div className="form-inline">
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="shipperOrderIdStatus"
                        checked={this.state.shipperOrderIdStatus}
                        onChange={(e) => this.handleOnChange(e, "shipperOrderIdStatus")}
                      />
                      <div><Trans i18nKey="common:orders.shipperOrderIdCheckbox" /></div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="trackingNoStatus"
                        checked={this.state.trackingNoStatus}
                        onChange={(e) => this.handleOnChange(e, "trackingNoStatus")}
                      />
                      <div><Trans i18nKey="common:orders.trackingNoCheckbox" /></div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="statusCodeStatus"
                        checked={this.state.statusCodeStatus}
                        onChange={(e) => this.handleOnChange(e, "statusCodeStatus")}
                      />
                      <div><Trans i18nKey="common:orders.statusCodeCheckbox" /></div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="createdOnStatus"
                        checked={this.state.createdOnStatus}
                        onChange={(e) => this.handleOnChange(e, "createdOnStatus")}
                      />
                      <div><Trans i18nKey="common:orders.createdOnCheckbox" /></div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="consigneeNameStatus"
                        checked={this.state.consigneeNameStatus}
                        onChange={(e) => this.handleOnChange(e, "consigneeNameStatus")}
                      />
                      <div><Trans i18nKey="common:orders.consigneeNameCheckbox" /></div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="consigneeEmailStatus"
                        checked={this.state.consigneeEmailStatus}
                        onChange={(e) => this.handleOnChange(e, "consigneeEmailStatus")}
                      />
                      <div><Trans i18nKey="common:orders.consigneeEmailCheckbox" /></div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="pickupCountryStatus"
                        checked={this.state.pickupCountryStatus}
                        onChange={(e) => this.handleOnChange(e, "pickupCountryStatus")}
                      />
                      <div><Trans i18nKey="common:orders.pickupCountryCheckbox" /></div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="consigneeCountryStatus"
                        checked={this.state.consigneeCountryStatus}
                        onChange={(e) => this.handleOnChange(e, "consigneeCountryStatus")}
                      />
                      <div><Trans i18nKey="common:orders.consigneeCountryCheckbox" /></div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="consigneeAddressStatus"
                        checked={this.state.consigneeAddressStatus}
                        onChange={(e) => this.handleOnChange(e, "consigneeAddressStatus")}
                      />
                      <div><Trans i18nKey="common:orders.consigneeAddressCheckbox" /></div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="uploadBatchNoStatus"
                        checked={this.state.uploadBatchNoStatus}
                        onChange={(e) => this.handleOnChange(e, "uploadBatchNoStatus")}
                      />
                      <div><Trans i18nKey="common:orders.uploadBatchNoCheckbox" /></div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="consigneeCustomsDocuments"
                        checked={this.state.consigneeCustomsDocuments}
                        onChange={(e) => this.handleOnChange(e, "consigneeCustomsDocuments")}
                      />
                      <div><Trans i18nKey="common:orders.consigneeCustomDocumentCheckbox" /></div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="phoneNumberStatus"
                        checked={this.state.phoneNumberStatus}
                        onChange={(e) => this.handleOnChange(e, "phoneNumberStatus")}
                      />
                      <div><Trans i18nKey="common:orders.phoneNumberCheckbox" /></div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="pickupDateStatus"
                        checked={this.state.pickupDateStatus}
                        onChange={(e) => this.handleOnChange(e, "pickupDateStatus")}
                      />
                      <div><Trans i18nKey="common:orders.pickupDateCheckbox" /></div>
                    </div>

                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="orderTypeStatus"
                        checked={this.state.orderTypeStatus}
                        onChange={(e) => this.handleOnChange(e, "orderTypeStatus")}
                      />
                      <div>Order Type</div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="forwardTrackingNoStatus"
                        checked={this.state.forwardTrackingNoStatus}
                        onChange={(e) => this.handleOnChange(e, "forwardTrackingNoStatus")}
                      />
                      <div>Forward/Return Link</div>
                    </div>

                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="identification_document_name"
                        checked={this.state.identification_document_name}
                        onChange={(e) => this.handleOnChange(e, "identification_document_name")}
                      />
                      <div>Identification Document Name</div>
                    </div>
                    <div className="d-flex mt-1 align-items-center mr-2">
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="consignee_identifcation_number"
                        checked={this.state.consignee_identifcation_number}
                        onChange={(e) => this.handleOnChange(e, "consignee_identifcation_number")}
                      />
                      <div>Identification Number</div>
                    </div>
                  </div>
                </div>
            }
          </Jumbotron>
        </div>
        <Modal
          title=""
          className="mergeModal text-center"
          width={350}
          centered
          visible={this.state.showPrintingModal}
          footer={null}
        >
          <div className="d-flex flex-column align-items-center"> 
          <img src={print} height={75} width={75} alt={'Merge Label Pringing...'} />
          <b>Sorting your Labels by Shipper order ID</b>
          </div>
        </Modal>
      </div>
    )
  }

  renderOrderActions() {
    return (
      <div className="mt-3">
        {
          this.state.error !== undefined && this.state.error !== null ?
            <div className={`alert ${this.state.error ? "alert-danger" : "alert-success"}`} role="alert">
              {this.props.message}
            </div>
            :
            null
        }

        <Jumbotron className="p-4 border border-secondary">
          <div className="h5 font-weight-bold mt-3"><Trans i18nKey="common:orders.actions" /></div>
          {this.renderPrintButton()}
        </Jumbotron>
      </div>
    )
  }

  renderPrintButton = () => {
    let printButtonDisabled = !!this.state.isPrinting
    let enablePrintButtons = !this.state.selectedDataList.some(o => {
      const obj = o._original || o
      return obj.is_processing || !obj.order_label_url
    });
    const printButtons = enablePrintButtons && !!this.state.selectedDataList.length ?
      <div>
        <button
          onClick={this.handlePrintSelectedOrdersStickersAndCommercialInvoice}
          type="button"
          className="mb-1 w-100 btn btn-success"
          disabled={printButtonDisabled}>
          <Trans i18nKey="common:orders.printSelectedOrdersStickersAndCommercialInvoice" />
        </button>
      </div>:
      <div>
        <button
          onClick={this.handlePrintSelectedOrdersStickersAndCommercialInvoice}
          type="button"
          className="mb-1 w-100 btn btn-secondary"
          disabled={true}>
          <Trans i18nKey="common:orders.printSelectedOrdersStickersAndCommercialInvoice" />
        </button>
      </div>;

    return printButtons;
  }

  renderFixedBottomButton = () => {
    let printButtonDisabled = !!this.state.isPrinting
    let button = null;

    let enablePrintButtons = !this.state.selectedDataList.some(o => {
      const obj = o._original || o
      return obj.is_processing || !obj.order_label_url
    });

    if (this.state.selectedAllDataList.length > 0) {
      if (enablePrintButtons) {
        if (window.innerWidth > 300 && window.innerWidth < 1000) {
          button = <div className="text-center fixed-bottom mb-2">
            <button
              type="button"
              className="btn btn-info"
              onClick={this.handlePrintSelectedOrdersStickersAndCommercialInvoice}
              disabled={printButtonDisabled}>
              <Trans i18nKey="common:orders.printLabels" />
            </button>
            <button
              type="button"
              className="btn btn-info ml-2"
              onClick={this.handleOpenMultipleTrackingNo}>
              <Trans i18nKey="common:orders.trackOrders" />
            </button>
          </div>;
        } else if (window.innerWidth > 1000) {
          button = <div>
            <button
              type="button"
              className="btn btn-info"
              style={{ position: 'absolute', left: '50%', right: '50%', bottom: '2%', width: '9%' }}
              onClick={this.handlePrintSelectedOrdersStickersAndCommercialInvoice}
              disabled={printButtonDisabled}>
              <Trans i18nKey="common:orders.printLabels" />
            </button>
            <button
              type="button"
              className="btn btn-info"
              style={{ position: 'absolute', left: '60%', right: '50%', bottom: '2%', width: '10%' }}
              onClick={this.handleOpenMultipleTrackingNo}>
              <Trans i18nKey="common:orders.trackOrders" />
            </button>
          </div>;
        }
      } else {
        if (window.innerWidth > 300 && window.innerWidth < 1000) {
          button = <div className="text-center fixed-bottom mb-2">
            <button
              type="button"
              className="btn btn-secondary"
              disabled={true}
              onClick={this.handlePrintSelectedOrdersStickersAndCommercialInvoice}>
              <Trans i18nKey="common:orders.printLabels" />
            </button>
            <button
              type="button"
              className="btn btn-secondary ml-2"
              disabled={true}
              onClick={this.handleOpenMultipleTrackingNo}>
              <Trans i18nKey="common:orders.trackOrders" />
            </button>
          </div>;
        } else if (window.innerWidth > 1000) {
          button = <div>
            <button
              type="button"
              className="btn btn-secondary"
              disabled={true}
              style={{ position: 'absolute', left: '50%', right: '50%', bottom: '2%', width: '9%' }}
              onClick={this.handlePrintSelectedOrdersStickersAndCommercialInvoice}>
              <Trans i18nKey="common:orders.printLabels" />
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              disabled={true}
              style={{ position: 'absolute', left: '60%', right: '50%', bottom: '2%', width: '10%' }}
              onClick={this.handleOpenMultipleTrackingNo}>
              <Trans i18nKey="common:orders.trackOrders" />
            </button>
          </div>;
        }
      }
    }

    return button;
  }

  renderPartnerLogo = () => {
    let partnerLogoImgs = [];
    let partnerLogoImgStr = [];

    if (this.props.data !== undefined) {
      const partnerLogoUrlsList = _.map(this.props.data, (item, i) => {
        return item.partner_logo_urls;
      });
      _.map(partnerLogoUrlsList, (item, i) => {
        _.map(item, (value, i) => {
          if (!_.includes(partnerLogoImgStr, value)) {
            partnerLogoImgStr.push(value);
          }
        });
      });

      _.map(partnerLogoImgStr, (item, i) => {
        const index = i + 1;
        partnerLogoImgs.push(<img key={i} id={'partnerLogo' + index} crossOrigin="Anonymous" alt="" src={item} style={{ display: 'none' }} />);
      });
    }

    return partnerLogoImgs;
  }

  fetchOrders() {
    let {
      secretKey,
      serviceId,
      trackingNo,
      shipperOrderId,
      statusCode,
      uploadBatchNo,
      pickupCountry,
      consigneeCountry,
      consigneeName,
      consigneeEmail,
      phoneNumber,
      fromDate,
      toDate,
      pickupDateFrom,
      pickupDateTo,
      isProcessing,
      page,
      pageSize,
      queryId,
      publicStatusCode
    } = this.state;

    fromDate = fromDate ? moment(fromDate).startOf('day').format() : fromDate;
    toDate = toDate ? moment(toDate).endOf('day').format() : toDate;

    // Increase QueryId by 1 for each fetchOrders call and update the state
    queryId += 1
    this.setState({
      queryId: queryId
    })

    const [publicStatus, publicStatusVersion] = publicStatusCode.split("-")
    let public_status, public_status_v2_shipper
    if (publicStatusVersion === "v2") {
      public_status_v2_shipper = publicStatus
    } else {
      public_status = publicStatus
    }

    const filterParams = {
      secretKey, serviceId, trackingNo,
      shipperOrderId, statusCode, uploadBatchNo,
      pickupCountry, consigneeCountry, fromDate,
      toDate, isProcessing, page, pageSize, queryId,
      consigneeName, consigneeEmail, phoneNumber,
      pickupDateFrom, pickupDateTo,
      public_status, public_status_v2_shipper
    }

    this.props.fetchOrderByFilter(filterParams);
  }

  handleDropdownChange = (e, type) => {
    let value = e.serviceId;
    if (value !== undefined) {
      value = e.serviceId;
    } else {
      value = 0;
    }

    if (type === 'serviceId') {
      const secretKey = this.props.shipperDetails.agent_application_secret_key;
      const selectedServiceId = parseInt(value, 10);

      const location = window.location.search
      const queries = queryString.parse(location)

      if (!value) {
        delete queries.serviceId
      } else {
        queries.serviceId = value
      }
      const stringified = queryString.stringify(queries)
      const url = `${window.location.pathname}?${stringified}`
      window.history.pushState({}, '', url);

      this.setState({
        secretKey: secretKey,
        serviceId: selectedServiceId,
        selectedService: e
      });
    }

    if (type === "publicStatusCode") {
      this.setState({
        publicStatusCode: e
      });
    }
  }

  handleUploadBatchNoChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleStatusCodeChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handlePickupCountryChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleConsigneeCountryChange = (e) => {
    // this.changePathFunc(e);
    this.setState({ [e.target.name]: e.target.value })
  }

  changePathFunc = (e) => {
    this.props.clearOrderErrors();
    this.setState({
      loading: true,
      page: 1
    });
    this.resetToggleSelectAll();

    const { name, value } = e.target;

    const location = window.location.search
    const queries = queryString.parse(location)

    if (!value) {
      delete queries[name]
    } else {
      queries[name] = value
    }
    const stringified = queryString.stringify(queries)
    const url = `${window.location.pathname}?${stringified}`
    window.history.pushState({}, '', url);
    this.setState({ [name]: value }, () => {
      this.debouncedFetch();
    });
  }

  handleDateChange = (e, fieldName) => {
    if (fieldName === 'fromDate') {
      e = e ? moment(e).startOf('day').format() : e
    } else if (fieldName === 'toDate') {
      e = e ? moment(e).endOf('day').format() : e
    }

    const location = window.location.search
    let queries = queryString.parse(location)
    if (e) {
      queries[fieldName] = e
    } else {
      delete queries[fieldName]
    }

    const stringified = queryString.stringify(queries)
    const url = `${window.location.pathname}?${stringified}`
    window.history.pushState({}, '', url);

    // this.setState({ [fieldName]: e }, () => {
    //   this.debouncedFetch();
    // });
    this.setState({ [fieldName]: e });
  }


  handleDateRangeChange = (date) => {
    this.setState({ pickupDateFrom: date[0].format('YYYY-MM-DD'), pickupDateTo: date[1].format('YYYY-MM-DD') }, () => {
      const location = window.location.search
      let queries = queryString.parse(location)
      queries['pickupDateFrom'] = this.state.pickupDateFrom
      queries['pickupDateTo'] = this.state.pickupDateTo


      const stringified = queryString.stringify(queries)
      const url = `${window.location.pathname}?${stringified}`
      window.history.pushState({}, '', url);
      // this.debouncedFetch();
    })
    
  }

  toggleSelectAll = (selectedAllDataList) => {
    this.props.clearOrderErrors();

    const checkboxList = document.querySelectorAll('input[type=checkbox]');
    if (!this.state.selectAll) {
      checkboxList.forEach((item) => {
        item.checked = true;
      });

      this.setState({
        selectAll: true,
        selectedDataList: selectedAllDataList,
        selectedAllDataList: selectedAllDataList
      });
    } else {
      checkboxList.forEach((item) => {
        item.checked = false;
      });

      this.setState({
        selectAll: false,
        selectedDataList: [],
        selectedAllDataList: []
      });
    }
  }

  resetToggleSelectAll = () => {
    const checkboxList = document.querySelectorAll('input[type=checkbox]');
    checkboxList.forEach((item) => {
      item.checked = false;
    });
    this.setState({
      selectAll: false,
      selectedDataList: [],
      selectedAllDataList: []
    });
  }

  handleResetFilters = () => {
    this.setState({
      serviceId: 0,
      clearValue: true,
      fromDate: null,
      pickupDate: null,
      pickupDateFrom: null,
      pickupDateTo: null,
      trackingNo: '',
      shipperOrderId: '',
      statusCode: '',
      uploadBatchNo: '',
      pickupCountry: '',
      consigneeCountry: '',
      phoneNumber: '',
      consigneeName: '',
      loading: true,
      consigneeEmail: '',
      publicStatusCode: '',
      page: 1
    }, () => {
      history.push({
        pathname: '/view-orders'
      })
      this.debouncedFetch()
    });
    this.resetToggleSelectAll();
  }

  handleApplyFilters = () => {
    this.props.clearOrderErrors();
    this.setState({
      loading: true,
      page: 1,
      clearValue: null
    });
    this.resetToggleSelectAll();
    this.fetchOrders()
  }
    
  handleCheckboxChange = (e, value) => {
    this.props.clearOrderErrors();

    if (e.target.checked) {
      this.setState({
        selectedDataList: this.state.selectedDataList.concat(value),
        selectedAllDataList: this.state.selectedAllDataList.concat(value)
      });
    } else {
      this.setState({
        selectedDataList: this.state.selectedDataList.filter((item) => item.order_id !== value.order_id),
        selectedAllDataList: this.state.selectedAllDataList.filter((item) => item.order_id !== value.order_id)
      });
    }
  }

  handleViewButton = (orderId) => {
    this.props.clearOrderErrors();

    history.push({
      // pathname: `/view-order-details/${orderId}`
      pathname: `/view-orders/${orderId}`
    });
  }

  handleTrackButton = (trackingNo) => {
    window.open(`https://tracker.janio.asia/${trackingNo}`);
  }

  showModal = () => {
    this.setState({showPrintingModal: true})
    setTimeout(() => {
      this.setState({showPrintingModal: false})
    },1600)
  }

  // method to generate pdf labels
  generateLabels = (payload) => {

    // set loading states for printing of individual and multiple orders
    if (payload.tracking_nos.length > 1){
      this.setState({isPrinting: true}, this.showModal)
    }else{
      let newPrinting = [...this.state.printingOrder]
      newPrinting.push(payload.tracking_nos[0])
      this.setState({printingOrder: newPrinting})
    }

    axios.post(`${ROOT_URL}/label/generate-label-invoice/`,
      payload,
        {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
          const external_urls = response.headers['external-labels'].replace(/ /g,'').split(',')
          const url = URL.createObjectURL(new Blob([response.data], {type:"application/pdf"}));
          window.open(url);

          if (external_urls[0]){
            external_urls.forEach(element => {
              window.open(element)
            });
          }


          // reset loading states for printing of individual and multiple orders
          if (this.state.isPrinting === true){
            this.setState({isPrinting: false})
          }else{
            let newPrinting = [...this.state.printingOrder]
            _.remove(newPrinting, (item) => (item === payload.tracking_nos[0]))
            this.setState({printingOrder: newPrinting})
          }
        })
        .catch(error => {
          var decodedString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
          var obj = JSON.parse(decodedString);

          if (obj['message']){
          }else {
          }

          // reset loading states for printing of individual and multiple orders
          if (this.state.isPrinting === true){
            this.setState({isPrinting: false})
          }else{
            let newPrinting = [...this.state.printingOrder]
            _.remove(newPrinting, (item) => (item === payload.tracking_nos[0]))
            this.setState({printingOrder: newPrinting})
          }
        });
  };
  
  // method to merge olus into one file
  mergeLabels = (payload) => {
    this.setState({isPrinting: true}, this.showModal)
    axios.post(ROOT_URL + "/label/generate-label-by-olu/",
      payload,
        {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], {type:"application/pdf"}));
          this.setState({pdfURL: url})
          window.open(url)

          this.setState({isPrinting: false})
        })
        .catch(error => {
          this.setState({isPrinting: false})
          var decodedString = String.fromCharCode.apply(null, new Uint8Array(error.data));
          var obj = JSON.parse(decodedString);

          if (obj['message']){
            this.setState({errorMessage: obj['message']})
          }else {
            this.setState({errorMessage: 'Unable to generate labels, please check your tracking numbers and date format.'})
          }
        });
  };

  handlePrintButton = (value) => {
    window.open(value.order_label_url)
  }

  confirmCancel = (trackingNo) => {
    let newConfirm = [...this.state.confirmingCancelOrders]
    newConfirm.push(trackingNo)
    this.setState({confirmingCancelOrders: newConfirm})
  }
  
  cancelOrder = (trackingNo) => {
    let payload = {
      secret_key: this.props.shipperDetails.agent_application_secret_key,
      tracking_nos: [trackingNo]
    }
    axios.post("/api/order/cancel-orders/",
      payload,
      {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
      })
      .then((response) => {
        message.success(response.data['detail'])
      })
      .catch(error => {
        message.error(JSON.stringify(error.data).slice(1,-1), 4)
      }).finally(() =>{
        let newCancelling = [...this.state.cancellingOrders]
        _.remove(newCancelling, (item) => (item === trackingNo))
        this.setState({cancellingOrders: newCancelling})
      });
  }

  renderSinglePrintButton = (orderData) => {
    if (orderData.order_label_url) {
      return (
        <button type="button" className="btn btn-info mr-1" onClick={() => this.handlePrintButton(orderData)}>
          <Trans i18nKey="common:orders.print" />
        </button>
      )
    }else {
      return (
        <button type="button" className="btn btn-secondary mr-1" disabled onClick={() => this.handlePrintButton(orderData)}>
          <Trans i18nKey="common:orders.print" />
        </button>
      )
    }
  }

  renderCancelButton = (orderData) => {

    const {tracking_no} = orderData
    if (this.state.confirmingCancelOrders.includes(tracking_no)) {
      return ( 
        <button type="button" className="btn btn-danger mr-1" onClick={() => this.handleCancelButton(tracking_no)}>
          <Trans i18nKey="common:orders.confirm" />
        </button>
      )
    }
    else if (this.state.cancellingOrders.includes(tracking_no)) {
      return ( 
        <button type="button" className="btn btn-secondary mr-1" disabled>
          <Trans i18nKey="common:orders.cancelling" />
        </button>
      )
    }
    else if (this.validateCancelOrder(orderData)) {
      return ( 
        <button type="button" className="btn btn-warning mr-1" onClick={() => this.confirmCancel(tracking_no)}>
          <Trans i18nKey="common:orders.cancel" />
        </button>
      )
    }
    else {
      return ( 
        <button type="button" className="btn btn-secondary mr-1" disabled>
          <Trans i18nKey="common:orders.cancel" />
        </button>
      )
    }
  }

  validateCancelOrder = (orderData) => {
    const {tracker_status_code} = orderData
    if (tracker_status_code !== 'ORDER_INFO_RECEIVED') {
      return false
    }

    return true
  }

  handleCancelButton = (trackingNo) => {
    let newConfirming = [...this.state.confirmingCancelOrders]
    _.remove(newConfirming, (item) => (item === trackingNo))
    let newCancelling = [...this.state.cancellingOrders]
    newCancelling.push(trackingNo)
    this.setState({cancellingOrders: newCancelling, confirmingCancelOrders: newConfirming})
    this.cancelOrder(trackingNo)
  }

  handleDeleteButton = (orderId) => {
    this.props.clearOrderErrors();
    if (this.state.secretKey.length > 0) {
      this.props.deleteOrder(orderId, this.state.secretKey);
    }
  }

  handlePrintSelectedOrdersStickersAndCommercialInvoice = () => {
    this.props.clearOrderErrors();

    if (_.isEqual(this.state.selectedDataList, this.state.selectedAllDataList)) {
      // generate both pdf selectAll
      if (this.state.selectedAllDataList.length > 0) {
        const itemList = _.map(this.state.selectedAllDataList, (item, i) => {
          if (_.has(item, 'checkbox')) {
            return item.checkbox;
          } else {
            return item;
          }
        });

        const result = itemList.reduce((result, curr, index, arr)=>{
          if (!curr.order_label_url){
            result.has_olu = false
          }
          result.olus.push(curr.order_label_url)
          result.tracking_nos.push(curr.tracking_no)
          return result
        },{has_olu: true, olus:[], tracking_nos:[]})

        if (result.has_olu){
          this.mergeLabels({
            tracking_nos: result.tracking_nos
          })
        }
      }
    } else {
      // generate both pdf checked one
      if (this.state.selectedDataList.length > 0) {
        const itemList = _.map(this.state.selectedDataList, (item, i) => {
          if (_.has(item, 'checkbox')) {
            return item.checkbox;
          } else {
            return item;
          }
        });
        const result = itemList.reduce((result, curr, index, arr)=>{
          if (!curr.order_label_url){
            result.has_olu = false
          }
          result.olus.push(curr.order_label_url)
          result.tracking_nos.push(curr.tracking_no)
          return result
        },{has_olu: true, olus:[], tracking_nos:[]})

        if (result.has_olu){
          this.mergeLabels({
            tracking_nos: result.tracking_nos
          })
        }
      }
    }
  }

  handleOpenMultipleTrackingNo = () => {
    this.props.clearOrderErrors();

    if (_.isEqual(this.state.selectedDataList, this.state.selectedAllDataList)) {
      // selectAll
      if (this.state.selectedAllDataList.length > 0) {
        const itemList = _.map(this.state.selectedAllDataList, (item, i) => {
          if (_.has(item, 'checkbox')) {
            return item.checkbox;
          } else {
            return item;
          }
        });

        let trackingNo = '';
        _.map(itemList, (item, i) => {
          if (i !== itemList.length - 1) {
            trackingNo += item.tracking_no + ',';
          } else {
            trackingNo += item.tracking_no;
          }
        });

        const url = window.location.href;
        if (url.includes('janio-shipper.herokuapp.com') || url.includes('merchant.janio.asia')) {
          window.open(`https://tracker.janio.asia/${trackingNo}`);
        } else if (url.includes('janio-shipper-int.herokuapp.com')) {
          window.open(`https://janio-tracker-int.herokuapp.com/${trackingNo}`);
        } else {
          window.open(`https://janio-tracker-demo.herokuapp.com/${trackingNo}`);
        }
      }
    } else {
      // checked one
      if (this.state.selectedDataList.length > 0) {
        let trackingNo = '';
        _.map(this.state.selectedDataList, (item, i) => {
          if (i !== this.state.selectedDataList.length - 1) {
            trackingNo += item.tracking_no + ',';
          } else {
            trackingNo += item.tracking_no;
          }
        });

        const url = window.location.href;
        if (url.includes('janio-shipper.herokuapp.com') || url.includes('merchant.janio.asia')) {
          window.open(`https://tracker.janio.asia/${trackingNo}`);
        } else if (url.includes('janio-shipper-int.herokuapp.com')) {
          window.open(`https://janio-tracker-int.herokuapp.com/${trackingNo}`);
        } else {
          window.open(`https://janio-tracker-demo.herokuapp.com/${trackingNo}`);
        }
      }
    }
  }

  handleOnChange = (e) => {
    const { name, value, checked } = e.target;
    if (e.target.type === 'checkbox') {
      this.setState({ [name]: checked });
    } else {
      let newDataObj = {};
      newDataObj[name] = value;

      const location = window.location.search
      let queries = queryString.parse(location)
      if (value) {
        queries[name] = value.match(/[A-Za-z0-9-]+/g).join(',')
      } else {
        delete queries[name]
        if (name === 'shipperOrderId') {
          delete queries['ids[]']
        }
      }

      const stringified = queryString.stringify(queries)
      const url = `${window.location.pathname}?${stringified}`
      window.history.pushState({}, '', url);

      this.setState({ [name]: value });
    }
  }

  handlePreviousButton = () => {
    this.props.clearOrderErrors();

    if (this.state.page > 1) {
      this.setState({
        loading: true
      });
      this.resetToggleSelectAll();

      this.setState({
        page: this.state.page - 1
      }, () => { this.debouncedFetch() });
    }
  }

  handleNextButton = () => {
    this.props.clearOrderErrors();

    if (this.state.page < this.props.totalPages) {
      this.setState({
        loading: true
      });
      this.resetToggleSelectAll();

      this.setState({
        page: this.state.page + 1
      }, () => { this.debouncedFetch() });
    }
  }

  handlePageClick = (e) => {
    this.props.clearOrderErrors();

    this.setState({
      loading: true
    });
    this.resetToggleSelectAll();

    this.setState({
      page: parseInt(e.target.innerHTML, 10)
    }, () => { this.debouncedFetch() });
  }

  handleShowAllPageNums = (e) => {
    this.setState({
      showAllPageNums: true,
    });
    this.resetToggleSelectAll();
  }

  handlePaginationChanges = (page, pageSize) => {
    this.props.clearOrderErrors();

    this.setState({
      page, pageSize,
      loading: true
    }, () => { this.debouncedFetch() })

    this.resetToggleSelectAll();
  }

  render() {
    const columns = [
      {
        id: "checkbox",
        accessor: "",
        width: 50,
        Cell: ({ row, value }) => {
          return (
            <input
              type="checkbox"
              className="checkbox ml-3"
              //checked={}
              onChange={(e) => this.handleCheckboxChange(e, value)}
            />
          );
        },
        Header: (row) => {
          const selectedAllDataList = row.data;
          return (
            <input
              type="checkbox"
              className="checkbox"
              //checked={}
              //ref={}
              onChange={() => this.toggleSelectAll(selectedAllDataList)}
            />
          );
        },
        show: true
      },
      {
        Header: 'Shipper order id',
        accessor: 'shipper_order_id',
        Cell: (props) => {
          const shipperOrderId = props.original.shipper_order_id;
          return shipperOrderId !== null && shipperOrderId.length > 0 ? shipperOrderId : 'N/A';
        },
        show: this.state.shipperOrderIdStatus
      },
      {
        Header: 'Type',
        accessor: 'is_return_order',
        Cell: ({ value }) => {
          return value ?
            <Alert message="Returns" type="warning" />:
            <Alert message="Forward" type="info" />
        },
        show: this.state.orderTypeStatus
      },
      {
        Header: 'Tracking no',
        accessor: 'tracking_no',
        width: 200,
        show: this.state.trackingNoStatus,
        Cell: ({ value, original }) => {
          if (["MPS", "PACKAGE"].includes(original.order_type)) {
            return (
              <div>
                <MPSIcon orderType={original.order_type} /> {value}
              </div>
            )
          }
          return value
        }
      },
      {
        Header: 'Status code',
        accessor: 'public_tracker_status_v2',
        show: this.state.statusCodeStatus,
        Cell: ({ value, row }) => capitalizeUnderscore(value || row._original.tracker_status_code)
      },
      {
        Header: 'Consignee name',
        accessor: 'consignee_name',
        show: this.state.consigneeNameStatus
      },
      {
        Header: 'Consignee email',
        accessor: 'consignee_email',
        show: this.state.consigneeEmailStatus
      },
      {
        Header: 'Pickup country',
        accessor: 'pickup_country',
        show: this.state.pickupCountryStatus
      },
      {
        Header: 'Consignee country',
        accessor: 'consignee_country',
        show: this.state.consigneeCountryStatus
      },
      {
        Header: 'Consignee address',
        accessor: 'consignee_address',
        show: this.state.consigneeAddressStatus
      },
      {
        Header: 'Upload batch no',
        accessor: 'upload_batch_no',
        show: this.state.uploadBatchNoStatus
      },
      {
        Header: 'Phone Number',
        accessor: 'consignee_number',
        show: this.state.phoneNumberStatus
      },
      {
        Header: 'Forward/Return Link',
        accessor: 'forward_order_id',
        show: this.state.forwardTrackingNoStatus,
        Cell: ({ value, original }) => {
          if (original.forward_tracking_no) {
            return (
              <p className="mb-0">
                <a href={`/view-order-details/${original.forward_order_id}`} target="_blank" rel="noopener noreferrer">{original.forward_tracking_no}</a>
              </p>
            )
          } else if (original.return_tracking_no) {
            return (
              <p className="mb-0">
                <a href={`/view-order-details/${original.return_order_id}`} target="_blank" rel="noopener noreferrer">{original.return_tracking_no}</a>
              </p>
            )
          } else {
            return 'N/A'
          }
        },
      },
      {
        Header: 'Scheduled Pickup Date',
        accessor: 'pickup_date',
        Cell: (props) => {
          return props.value ? moment(props.value).format('DD/MM/YY') : props.value;
        },
        show: this.state.pickupDateStatus
      },
      {
        Header: 'Created on',
        accessor: 'created_on',
        Cell: (props) => {
          return moment(props.value).format('DD/MM/YY HHmm[HRS]');
        },
        show: this.state.createdOnStatus
      },
      {
        Header: 'Consignee Customs Document',
        accessor: 'customs_documents_status',
        Cell: (props) => {
          let fontColor = '#000000'
          switch(props.value){
            case 'Pending Documents': 
                fontColor = '#FF8900';
                break;
            case 'Documents Uploaded':
                fontColor = '#27AE60';
                break;
            default:
              return
          }
          
          let label = (
            <label style={{ color:fontColor }}>{props.value}</label>
          )
          return props.value ? label : 'N/A';
        },
        show: this.state.consigneeCustomsDocuments
      },

      {
        Header: 'Identification Document Name',
        accessor: 'identification_document_name',
        show: this.state.identification_document_name,
        Cell: ({ value }) => value || 'N/A'
      },
      {
        Header: 'Identification Number',
        accessor: 'consignee_identifcation_number',
        show: this.state.consignee_identifcation_number,
        Cell: ({ value }) => value || 'N/A'
      },
      
      {
        id: "button",
        Header: 'Action',
        accessor: "",
        width: 260,
        Cell: ({ row, value }) => {
          const orderId = value.order_id;
          const trackingNo = value.tracking_no;
          const isProcessing = value.is_processing
          let printButton = this.renderSinglePrintButton(value)
          let cancelButton = this.renderCancelButton(value)
          let actionView = null;
          if (isProcessing) {
            actionView = (
              <div>
                <Trans i18nKey="common:orders.orderIsProcessing" />
              </div>
            )
          } else {
            actionView = <div>
              <button type="button" className="btn btn-primary mr-1" onClick={() => this.handleViewButton(orderId)}>
                <Trans i18nKey="common:orders.view" />
              </button>
              <button type="button" className="btn btn-success mr-1" onClick={() => this.handleTrackButton(trackingNo)}>
                <Trans i18nKey="common:orders.track" />
              </button>
              {printButton}
              {cancelButton}
            </div>;
          }

          return actionView;
        },
        show: true
      },
    ];

    _.filter(columns, (item, i) => {
      return item.show;
    });

    const pagination = !!this.state.data ? (
      <div className="mb-2 d-flex justify-content-between">
        <Pagination
          total={this.state.totalOrders}
          showQuickJumper={true}
          defaultCurrent={1}
          current={this.state.page}
          onChange={this.handlePaginationChanges}

          showSizeChanger={true}
          defaultPageSize={50}
          pageSizeOptions={['30', '50', '100', '200', '300']}
          pageSize={this.state.pageSize}
          onShowSizeChange={(_, size) => this.handlePaginationChanges(this.state.page, size)}
        />
        <button className="btn btn-secondary" disabled>{this.state.totalOrders} Orders</button>
      </div>
      ) : null

    let renderDiv = null;
    if (this.state.secretKey.length === 0) {
      renderDiv = <div>
        <div className="mt-4" style={{ textAlign: 'center' }}>
          <div style={{ display: 'inline-block' }}>
            <ClipLoader
              color={'#273272'}
              loading={true}
            />
          </div>
        </div>
      </div>;
    } else {
      renderDiv = <div className="mt-4 container">
        {this.renderFilters()}

        {
          this.state.loading ?
            <div className="alert alert-warning text-center w-100 mt-0 mb-3" role="alert"><Trans i18nKey="common:orders.orderListLoading" /></div>
            :
            null
        }

        {pagination}
        <ReactTable
          noDataText="No order found"
          data={this.state.data}
          columns={columns}
          pageSize={this.state.pageSize}
          minRows={5}
          showPagination={false}
        />
        {pagination}

        {
          this.state.loading ?
            <div className="alert alert-warning text-center w-100 mt-3 mb-3" role="alert"><Trans i18nKey="common:orders.orderListLoading" /></div>
            :
            null
        }

        {this.renderOrderActions()}

        {this.renderFixedBottomButton()}

        <img id="twoGoExpressLogo" crossOrigin="Anonymous" alt="" src={twoGoExpressLogo} style={{ display: 'none' }} />
        <img id="logo" crossOrigin="Anonymous" alt="" src={stickerLogo} style={{ display: 'none' }} />
        {this.renderPartnerLogo()}
        <img id="barcode" alt="" style={{ display: 'none' }} />
        <img id="secondBarcode" alt="" style={{ display: 'none' }} />
        <canvas id="canvas" style={{ display: 'none' }} />
      </div>;
    }

    return (
      <div>
        {renderDiv}
      </div>
    );
  }
}

function mapStateToProps({ shipperDetails, agentAppDetails, order }) {
  return ({
    error: order.error,
    shipperDetails: shipperDetails.shipperDetails,
    message: order.message,
    publicStatuses: order.publicStatuses,
    countries: order.countries,
    uploadBatchNo: order.updateBatchNo,
    service: order.service,
    data: order.orders,
    totalOrders: order.totalOrders,
    totalPages: order.totalPages,
    lastUpdated: order.lastUpdated,
    queryId: order.queryId
  });
}

export default compose(
  connect(mapStateToProps, {
    fetchShipperDetails,
    fetchService,
    fetchStatuses,
    fetchAllCountries,
    fetchPrevious,
    fetchNext,
    fetchOrderByFilter,
    deleteOrder,
    clearOrderErrors
  }),
  withStyles(styles),
  withTranslation('common')
)(ViewOrders);
