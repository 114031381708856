import React from 'react'
import { Trans } from 'react-i18next'
// import { Checkbox } from 'antd'
import { prohibitedItemListDoc } from '../../utils/constants'

const ShipmentRestrictionAgreement = ({
  value,
  onChange
}) => {
  return (
    <div>
      <h3 style={{fontSize: "1.1rem", fontWeight:"bold"}}>
        <Trans i18nKey='common:submitOrder.shippingRestrictions'/>
      </h3>
      {/* <Checkbox
        style={{ marginTop: 8 }}
        onChange={onChange}
        checked={value}>
        <Trans i18nKey='common:submitOrder.shippingRestrictionsText'>
          <a href={prohibitedItemListDoc} target='_blank' rel="noopener noreferrer" > </a>
        </Trans>
      </Checkbox> */}
      <div className="d-flex flex-row" style={{ marginTop: 8 }}>
        <div className="custom-control custom-checkbox mr-1">
          <input
            type="checkbox"
            id="shipmentAgreement"
            className="custom-control-input mr-2"
            name="shipmentAgreement"
            checked={value}
            onChange={onChange}
          />
          <label
            className="custom-control-label"
            htmlFor="shipmentAgreement"
          >
            <Trans i18nKey='common:submitOrder.shippingRestrictionsText'>
              <a href={prohibitedItemListDoc} target='_blank' rel="noopener noreferrer" > </a>
            </Trans>
          </label>
        </div>
      </div>
    </div>
  )
}

export default ShipmentRestrictionAgreement
