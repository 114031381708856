import { useEffect } from 'react'
import { useSelector } from 'react-redux';


export const useSecretKey = () => {
  const {secretKey} = useSelector((state) => {
    if( state.shipperDetails.shipperDetails ){
      return {
        secretKey: state.shipperDetails.shipperDetails.agent_application_secret_key,
      }
    }
    return {secretKey: null};
  });

  useEffect(() => {
    if(!secretKey){
      // dispatch
      console.log('no secret key')
    }
  }, [secretKey])

  return [secretKey]
}

