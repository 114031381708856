import { useState, useEffect } from 'react'
import axios from 'axios'

const useServiceLevel = (pickupCountry, consigneeCountry, secretKey) => {
  const [serviceLevels, setServiceLevels] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getServiceLevels = async () => {
      setLoading(true)
      const response = await axios.get('/api/order/service-level/', {
        params: {
          pickup_country: pickupCountry,
          consignee_country: consigneeCountry,
          secret_key: secretKey,
        }
      })
      setServiceLevels(response.data)
      setLoading(false)
    }
    if (!!pickupCountry && !!consigneeCountry && !!secretKey) {
      getServiceLevels()
    }
  }, [pickupCountry, consigneeCountry, secretKey])

  return [serviceLevels, loading]
}

export default useServiceLevel