import { useState, useEffect } from 'react'
import axios from 'axios'

const useCities = (country, states, for_returns_pickup=false, shouldFetch=true, for_service_level='') => {
  const [cities, setCities] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {  
    const getCities = async () => {
      setIsLoading(true)
      let params = {
        countries: country,
        states: states
      }
      if (for_returns_pickup) {
        params = {...params, for_returns_pickup}
      }
      if (!!for_service_level) {
        params = {...params, for_service_level}
      }
      const response = await axios.get('/api/location/cities/', {
        params,
        headers: { 'Content-Type': 'application/json'}
      })
      setCities(response.data)
      setIsLoading(false)
    }
    if (country && shouldFetch){
      getCities()
    }
  }, [country, states, for_returns_pickup, for_service_level, shouldFetch])

  return [cities, isLoading]
}

export default useCities