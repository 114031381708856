import React, { Component } from "react";
import axios from 'axios'
import { ROOT_URL } from '../../actions'
import { Jumbotron } from 'react-bootstrap';
import { connect } from "react-redux";
import compose from 'recompose/compose';
import { withTranslation, Trans } from 'react-i18next';
import { history } from '../../utils/historyUtils';
import { validate } from '../../utils/validatorUtils';
import { fetchAllCountries } from '../../actions/orderActions';
import { addConsigneeAddress, clearConsigneeErrors } from '../../actions/consigneeAddressActions';
import InputField from '../common/InputField';
import DropdownField from '../common/DropdownField';
import { withRouter } from "react-router";
import _ from 'lodash';


class AddConsigneeAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: true,
      errorData: null,
      states: ['pick one...'],
      cities: ['pick one...'],
      stateLoading: false,
      cityLoading: false,

      consigneeName: '',
      consigneeEmail: '',
      consigneeNumber: '',
      consigneeAddress: '',
      consigneeCountry: '',
      consigneeState: '',
      consigneeCity: '',
      consigneePostal: ''
    };
  }

  componentDidMount() {
    if (this.props.countries === undefined) {
      this.props.fetchAllCountries();
    }
  }

  componentWillUnmount() {
    this.props.clearConsigneeErrors();
  }

  componentDidUpdate() {
    let error, errorData
    error = this.props.error;
    errorData = this.props.errorData;

    if (error === false) {
      if (this.props.onSuccess) {
        this.props.onSuccess()
        return;
      }
      history.push('/add-consignee-point/success');
    }

    if (error !== this.state.error) {
      this.setState({
        error: error
      });
    }

    if (errorData !== this.state.errorData) {
      this.setState({
        errorData: errorData
      });
    }
  }

  renderCountries = () => {
    let option = [{
      value: 'select one...',
      label: 'select one...'
    }];

    _.map(this.props.countries, (item, i) => {
      let data = {
        value: item,
        label: item
      }
      option.push(data);
    });

    return option;
  }

  renderStates = () => {
    let option = [{
      value: 'select one...',
      label: 'select one...'
    }];

    _.map(this.state.states, (item, i) => {
      let data = {
        value: item.state_name,
        label: item.state_name
      }
      option.push(data);
    });

    return option;
  }

  renderCities = () => {
    let option = [{
      value: 'select one...',
      label: 'select one...'
    }];

    _.map(this.state.cities, (item, i) => {
      let data = {
        value: item.city_name,
        label: item.display_name
      }
      option.push(data);
    });

    return option;
  }

  renderField = (fieldName, name, type, id, placeholder, stateValue) => {
    return (
      <div className="form-group">
        <label className="h5 font-weight-bold">{fieldName}</label>
        <input
          name={name}
          type={type}
          className="form-control"
          id={id}
          placeholder={placeholder}
          value={stateValue}
          onChange={(e) => this.handleOnChange(e)}
        />
      </div>
    );
  }

  fetchStates = () => {
    this.setState({ stateLoading: true });
    const url = `${ROOT_URL}/location/states/`
    axios.get(`${url}?countries=${this.state.consigneeCountry}`)
      .then((response) => {
        this.setState({ stateLoading: false });
        this.setState({ states: response.data });
      })
      .catch((error) => {
        this.setState({ stateLoading: false });
      });
  }

  fetchCities = () => {
    this.setState({ cityLoading: true })
    const url = '/api/location/cities/'
    axios.get(`${url}?countries=${this.state.consigneeCountry}&states=${this.state.consigneeState}`)
      .then((response) => {
        this.setState({ cityLoading: false });
        this.setState({ cities: response.data });
      })
      .catch((error) => {
        this.setState({ cityLoading: false });
      });
  }

  handleDropdownChange = (e, type) => {
    if (type === 'consigneeCountry') {
      this.setState({
        consigneeState: '',
        consigneeCity: ''
      })
      const selectedPickupPointCountry = e.value;

      if (selectedPickupPointCountry !== 'select one...') {
        this.setState({
          consigneeCountry: selectedPickupPointCountry,
          updated: true
        }, () => {
          this.fetchStates()
        });
      } else {
        this.setState({
          consigneeCountry: '',
          updated: true
        });
      }
    }
    else if (type === 'consigneeState') {
      this.setState({
        consigneeCity: ''
      })
      const selectedPickupPointState = e.value;

      if (selectedPickupPointState !== 'select one...') {
        this.setState({
          consigneeState: selectedPickupPointState,
          updated: true
        }, this.fetchCities);
      } else {
        this.setState({
          consigneeState: '',
          updated: true
        });
      }
    }
    else {
      const selectedPickupPointCity = e.value;

      if (selectedPickupPointCity !== 'select one...') {
        this.setState({
          consigneeCity: selectedPickupPointCity,
          updated: true
        });
      } else {
        this.setState({
          consigneeCity: '',
          updated: true
        });
      }
    }
  }

  handleOnChange(e) {
    this.props.clearConsigneeErrors();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = () => {
    this.props.clearConsigneeErrors();
    const validatorList = [
      { fieldName: 'consigneeName', optional: false, type: 'text' },
      { fieldName: 'consigneeAddress', optional: false, type: 'text' },
      { fieldName: 'consigneeCountry', optional: false, type: 'text' },
      { fieldName: 'consigneeState', optional: false, type: 'text' },
      { fieldName: 'consigneeCity', optional: false, type: 'text' },
      { fieldName: 'consigneePostal', optional: false, type: 'text' },
      { fieldName: 'consigneeEmail', optional: false, type: 'email' },
      { fieldName: 'consigneeNumber', optional: false, type: 'number' },
    ];
    const canSubmit = validate(validatorList, this.state, true);

    if (canSubmit) {
      let data = {
        'name': this.state.consigneeName,
        'contact_name': this.state.consigneeName,
        'contact_number': this.state.consigneeNumber,
        'contact_phone': this.state.consigneeNumber,
        'contact_email': this.state.consigneeEmail,
        'address': this.state.consigneeAddress,
        'country': this.state.consigneeCountry,
        'state': this.state.consigneeState,
        'city': this.state.consigneeCity,
        'postal': this.state.consigneePostal,
      };
      this.props.addConsigneeAddress(data);
    }
  }

  render() {
    const isDisabled = !(
      this.state.consigneeName &&
      this.state.consigneeAddress &&
      this.state.consigneeNumber &&
      this.state.consigneeCountry &&
      this.state.consigneeState &&
      this.state.consigneeCity &&
      this.state.consigneePostal &&
      this.state.consigneeEmail)

    let error, errorData
    let formDiv;

    error = this.props.error
    errorData = this.props.errorData
    formDiv = (
      <div className="w-100 mt-3 container max-width-40" style={{ padding: '10px' }}>
        <Jumbotron className="p-4 border border-secondary">
          <InputField fieldName='consignee_name' i18nKey='pickupPoints.consigneeName' placeholder='pickupPoints.consigneeNamePlaceholder' name='consigneeName' type='text' stateValue={this.state.consigneeName} onChange={this.handleOnChange.bind(this)} />
          <InputField fieldName='consignee_email' i18nKey='pickupPoints.consigneeEmail' placeholder='pickupPoints.consigneeEmailPlaceholder' name='consigneeEmail' type='text' stateValue={this.state.consigneeEmail} onChange={this.handleOnChange.bind(this)} />
          <InputField fieldName='consignee_number' i18nKey='pickupPoints.consigneeNumber' placeholder='pickupPoints.consigneeNumberPlaceholder' name='consigneeNumber' type='text' stateValue={this.state.consigneeNumber} onChange={this.handleOnChange.bind(this)} validateSpecialChar={true} />
          <InputField fieldName='consignee_address' i18nKey='pickupPoints.consigneeAddress' placeholder='pickupPoints.consigneeAddressPlaceholder' name='consigneeAddress' type='text' stateValue={this.state.consigneeAddress} onChange={this.handleOnChange.bind(this)} />
          <DropdownField fieldName='consignee_country' i18nKey='pickupPoints.consigneeCountry' placeholder={this.state.consigneeCountry ? this.state.consigneeCountry : 'select one...'} labelClassName="mb-2" dropdownClassName="mb-3" disableLabel={false} onChange={(e) => this.handleDropdownChange(e, 'consigneeCountry')} renderItems={this.renderCountries()} />
          <DropdownField loading={this.state.stateLoading} value={this.state.consigneeState} fieldName='consignee_state' i18nKey='pickupPoints.consigneeState' placeholder={this.state.consigneeState ? this.state.consigneeState : 'select one...'} labelClassName="mb-2" dropdownClassName="mb-3" disableLabel={false} onChange={(e) => this.handleDropdownChange(e, 'consigneeState')} renderItems={this.renderStates()} />
          <DropdownField loading={this.state.cityLoading} value={this.state.consigneeCity} fieldName='consignee_city' i18nKey='pickupPoints.consigneeCity' placeholder={this.state.consigneeCity ? this.state.consigneeCity : 'select one...'} labelClassName="mb-2" dropdownClassName="mb-3" disableLabel={false} onChange={(e) => this.handleDropdownChange(e, 'consigneeCity')} renderItems={this.renderCities()} />
          <InputField fieldName='consignee_postal' i18nKey='pickupPoints.consigneePostal' placeholder='pickupPoints.consigneePostalPlaceholder' name='consigneePostal' type='text' stateValue={this.state.consigneePostal} onChange={this.handleOnChange.bind(this)} />

          {
            error === false ?
              <div className="alert alert-success mt-2 mb-0" role="alert">
                {this.props.message}
              </div>
              :
              null
          }

          {
            errorData ?
              <div className="alert alert-danger mt-2 mb-0" role="alert">
                <div><b><Trans i18nKey="common:orders.error" /></b></div>
                {JSON.stringify(errorData)}
              </div>
              :
              null
          }

          <button
            type="button"
            className={isDisabled ? "mt-3 w-100 btn btn-lg btn-secondary" : "mt-3 w-100 btn btn-lg btn-success"}
            onClick={this.handleSubmit}
            disabled={isDisabled}>
            <Trans i18nKey="common:pickupPoints.submit" />
          </button>
        </Jumbotron>
      </div>
    )

    return (
      <div>
        <div className="w-100 mt-3 container max-width-40" style={{ padding: '10px' }}>
          {formDiv}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ consigneeAddress, order }) {
  return ({
    error: consigneeAddress.error,
    errorData: consigneeAddress.errorData,
    countries: order.countries,
  });
}

export default compose(
  connect(mapStateToProps, {
    fetchAllCountries,
    addConsigneeAddress,
    clearConsigneeErrors
  }),
  withRouter,
  withTranslation('common'),
)(AddConsigneeAddress);