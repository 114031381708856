import {
  OPEN_TERMS_AND_CONDITIONS_MODAL,
  CLOSE_TERMS_AND_CONDITIONS_MODAL,
  CONFIRM_TERMS_AND_CONDITIONS,
  CONFIRM_TERMS_SUCCESS,
  CONFIRM_TERMS_FAILURE,
  SUBMIT_TERMS_AND_CONDITIONS,
  SET_TERMS_AND_CONDITIONS
} from '../actions/types'


export default function(
  state={
    isTermsModalOpen: false,
    isLoading: false,
    isSubmitting: false,
    data: null
  },
  action
) 
{
  switch (action.type) {
    case OPEN_TERMS_AND_CONDITIONS_MODAL:
      return {
        ...state,
        isTermsModalOpen: true,
        data: action.payload || state.data
      }
    
    case SET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        data: action.payload
      }

    case CLOSE_TERMS_AND_CONDITIONS_MODAL:
      return {
        ...state,
        isTermsModalOpen: false,
        isSubmitting: false
      }
    
    case SUBMIT_TERMS_AND_CONDITIONS:
      return {
        ...state, isSubmitting: true
      }
    
    case CONFIRM_TERMS_AND_CONDITIONS:
      return {
        ...state,
        isLoading: true
      }

    case CONFIRM_TERMS_SUCCESS:
    case CONFIRM_TERMS_FAILURE:
      return {
        ...state,
        isLoading: false
      }
      
    default:
      return state;
  }
}