import { useState, useEffect } from 'react'
import axios from 'axios'

const useCountries = () => {
  const [countries, setCountries] = useState([])

  useEffect(() => {
    const getCountries = async () => {
      const token = localStorage.getItem('token')
      const response = await axios.get(`/api/data/places/`,
        { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } }
      )
      setCountries(response.data)
    }
    getCountries()
  }, [])

  return countries
}

export default useCountries