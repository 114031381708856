import {
  FETCH_RETURN_POINT,
  FETCH_RETURN_POINT_BY_ID,
  ADD_RETURN_POINT,
  EDIT_RETURN_POINT,
  DELETE_RETURN_POINT,
  CLEAR_RETURN_POINT_ERRORS
} from '../actions/types';

export default function(state = {}, action) {
  switch(action.type) {
    case FETCH_RETURN_POINT:
      return { ...state, returnPoints: action.payload };
    case FETCH_RETURN_POINT_BY_ID:
      return { ...state, returnPoint: action.payload };
    case ADD_RETURN_POINT:
      return {
        ...state,
        error: action.payload,
        errorData: action.errorData
      };
    case EDIT_RETURN_POINT:
      return {
        ...state,
        error: action.payload,
        errorData: action.errorData,
        message: 'Update return point success!'
      };
    case DELETE_RETURN_POINT:
      return {
        ...state,
        error: action.payload,
        message: 'Delete return point success!',
        status: action.status
      };
    case CLEAR_RETURN_POINT_ERRORS:
      return { ...state, error: null, errorData: null, message: null, status: null };
    default:
      return state;
  }
}
