import React from 'react'
import { Collapse } from 'antd'

const { Panel } = Collapse

const TermAndCondition = ({ tncDocumentData }) => {
  console.log('tncDocumentData', tncDocumentData)
  if (!tncDocumentData) {
    return <p>Loading...</p>
  }
  return (
    <div>
      {tncDocumentData.introduction &&
      <p>{tncDocumentData.introduction}</p>
      }

      {!!tncDocumentData.documents.length &&
      <div style={{marginTop: 24}}>
        <Collapse>
          {tncDocumentData.documents.map(document => {
            return (
              <Panel header={document.name}>
                <div
                dangerouslySetInnerHTML={{
                  __html: document.html
                }}>
                </div>
              </Panel>      
            )
          })}
          <Panel header="Claim SOP">
            <div>
              <a href="https://janio-b2c-documents.s3.ap-southeast-1.amazonaws.com/Janio+Claims+External+SOP.pdf" target="_blank" rel="noopener noreferrer">Click to view</a>
            </div>
          </Panel>
        </Collapse>
      </div>
      }
    </div>
  )
}

export default TermAndCondition