import React, { Component } from 'react';
import{ Checkbox, Modal } from 'antd';
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import compose from 'recompose/compose'
import { signoutUser } from '../../actions/authActions'
import {
  confirmTerms,
  openTermsModal,
  closeTermsModal
} from '../../actions/termsActions'
import { history } from '../../utils/historyUtils'
// import TermsandConditions from './termsAndConditions/termsEnglish'
import TermAndCondition from './termsAndConditions/TermAndCondition';


class TermsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      animationClass: 'terms-modal-no-animation',
      hasAcceptedTerms: false,
      hasError: false
    }

    this.timeout = null;
    }

    componentWillUnmount() {
      clearTimeout(this.timeout)
    }

    // commented out to remove scrolling animation
    // static getDerivedStateFromProps(props, state) {
    //   if(props.isTermsModalOpen && state.animationClass === '') {
    //     return {
    //       ...state,
    //       animationClass: 'terms-modal-no-animation'
    //     }
    //   }
    //   else if(!props.isTermsModalOpen && state.animationClass === 'terms-modal-no-animation') {
    //     return {
    //       ...state,
    //         animationClass: 'terms-modal-no-animation'
    //     }
    //   }
    //   return state
    // }

    handleCancel = () => {
      console.log('Closing terms modal...')
      this.props.closeTermsModal()

      const confirm = Modal.confirm({
        className: 'terms-cancel-dialog',
        title: 'Are you sure you want to close?',
        content:'If you choose to close, you will be logged out of the Merchant Portal',
        okText: 'No',
        cancelText: "Yes, I'm sure",
        onCancel: () => {
          this.props.signoutUser(history);
          confirm.destroy();
        },
        onOk: () => {
          this.props.openTermsModal();
          confirm.destroy();
        },
        width: 611,
        okButtonProps: {
          style: {
            background: '#050593'
          }
        },
        cancelButtonProps: {
          style: {
            width: 129,
            borderRadius: 4
          }
        },
        style: { top: 234 }
        });
    };

    onCheckboxChange = e => {
      this.setState({ hasAcceptedTerms: e.target.checked });
    };

    onConfirmTerms = () => {
      if (this.state.hasAcceptedTerms) {
        this.props.confirmTerms(this.props.secretKey, this.props.tncDocumentData.version)  
      } else {
        this.showError();
      }
    };

    showError = () => {
      this.setState({ hasError: true });

      this.timeout = setTimeout(() => {
        this.setState({ hasError: false });
      }, 3000);
    };

    render() {
      const { hasError } = this.state;

      return (
        <Modal
          className={`terms-modal ${this.state.animationClass}`}
          centered
          title={<p className='terms-modal__title'>Terms of Service</p>}
          visible={this.props.isTermsModalOpen}
          // visible={true}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <div className='d-flex flex-column align-items-start'>
              <Checkbox
                className=''
                checked={this.state.hasAcceptedTerms}
                onChange={this.onCheckboxChange}
              >
                I agree to the above Terms of Service
              </Checkbox>
              <p className={`terms-modal__error ${
                hasError ? '' : 'terms-modal__error--hidden'
              }`}
              >
                You have to click agree in order to proceed
              </p>
              <button
                className="btn btn-primary btn-submit"
                loading={this.props.isLoading || this.props.isSubmitting}
                disabled={this.props.isSubmitting}
                onClick={this.onConfirmTerms}
                style={{
                  borderRadius: 4,
                  fontSize: 18,
                  padding: '0.375rem 1.25rem'
                }}
              >
                {this.props.isSubmitting ? 'Submitting...':'Submit'}
              </button>
            </div>
          ]}
          width={800}
          style={{ top: -1000, height: 665 }}
        >
          {/* <TermsandConditions /> */}
          <TermAndCondition tncDocumentData={this.props.tncDocumentData} />
        </Modal>
      );
    }
}

function mapStateToProps({ shipperDetails, terms }) {
  return {
    ...terms,
    secretKey: shipperDetails.shipperDetails
      ? shipperDetails.shipperDetails.agent_application_secret_key 
      : null,
    tncDocumentData: terms.data
  };
}

export default compose(
  connect(mapStateToProps, {
    openTermsModal,
    closeTermsModal,
    confirmTerms,
    signoutUser
  }),
  withTranslation('common')
)(TermsModal);
