import React from 'react'
import { Tag } from 'antd'

const statusDisplay = {
  'NEW': 'Insufficient Info',
  'ERROR': 'Insufficient Info',
  'READY_TO_SHIP': 'Ready to Ship',
  'CANCELLED': 'Cancelled',
  'SHIPPED': 'Info pushed to Janio',
}

const statusColors = {
  'NEW': 'red',
  'ERROR': 'red',
  'READY_TO_SHIP': 'blue',
  'CANCELLED': 'blue',
  'SHIPPED': 'green',
}

export const StatusTag = ({children}) => {
  const color = statusColors[children]
  const display = statusDisplay[children]
  return <Tag color={color}>{display}</Tag>
}

