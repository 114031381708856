import React, { useMemo } from 'react'
import { Select, Form } from 'antd'
import useCountries from './hooks/useCountries2'
const { Option } = Select

const SelectCountry = ({ formItemProps, onChange, disabled, placeholderText }) => {
  const [countries, countriesLoading] = useCountries()
  const countryOptions = useMemo(() => {
    return countries.map((country, index) => {
      return <Option value={country} key={`country-${index}`}>{country}</Option>
    })
  }, [countries])

  return (
    <Form.Item
      colon={false}
      {...formItemProps}
    >
      <Select
        showSearch
        style={{ width: 300 }}
        placeholder={placeholderText || 'Select origin country'}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={onChange}
        disabled={disabled || countriesLoading}>
        {countryOptions}
      </Select>
    </Form.Item>
  )
}

export default SelectCountry
