import React, { Component } from "react";
import SubmitSuccessMessage from '../common/SubmitSuccessMessage';

export default class AddConsigneeAddressSuccess extends Component {
  render() {
    let data = {
      title: "Consignee Address Added!",
      subTitleList: [
        'You may select the new consignee address when submitting orders now.'
      ],
      mainButtonList: [
        {
          link: '/manage-address/',
          text: 'View Consignee Addresses'
        }
      ]
    }

    return (
      <div>
        <SubmitSuccessMessage
          title={data.title}
          subTitleList={data.subTitleList}
          mainButtonList={data.mainButtonList}
        />
      </div>
    );
  }
}