import React, { useState, useEffect, useCallback } from "react";
import { getCleanedRawObjects, getOrderObjects, getMpsOrderObjects } from "./utils";
import { connect } from "react-redux";
import axios from "axios";
import { fetchService } from "../../actions/orderActions";
import { fetchPickupPoint } from "../../actions/pickupPointActions";
import { formatError } from "./formatError";

const mapState = ({ shipperDetails }) => {
  return {
    shipperDetails: shipperDetails.shipperDetails
  };
};
const mapDispatch = {
  fetchService,
  fetchPickupPoint
};

export const OrderSubmitBulkContext = React.createContext();
export const OrderSubmitBulkContextProvider = connect(
  mapState,
  mapDispatch
)(props => {
  const secretKey =
    (props.shipperDetails &&
      props.shipperDetails.agent_application_secret_key) ||
    "";
  const [page, setPage] = useState(0);
  const [fileState, setFileState] = useState({
    filename: "",
    rawObjects: null,
    selected: false,
    triggerEffect: false
  });
  const [loading, setLoading] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [errors, setErrors] = useState();
  const [orderObjects, setOrderObjects] = useState();
  const [isMpsOrder, setIsMpsOrder] = useState(false)
  const [cleanedRawObjects, setCleanedRawObjects] = useState([])
  const [totalOrders, setTotalOrders] = useState(0)

  const mpsOrderCount = useCallback(() => {
    if (!isMpsOrder || !cleanedRawObjects.length) {
      return 0
    }
    const groupedMpsId = cleanedRawObjects.map(row => row.mps_group_id)
    return [...new Set(groupedMpsId)].length
  }, [isMpsOrder, cleanedRawObjects])

  useEffect(() => {
    if (secretKey) {
      props.fetchService(secretKey);
      props.fetchPickupPoint();
    }
  }, [secretKey]);

  const validateRawObjects = async () => {
    setLoading(true);
    const cleanedRawObjects = getCleanedRawObjects(fileState.rawObjects, {
      isMpsOrder
    });
    const data = {
      secret_key: secretKey,
      orders: cleanedRawObjects,
      is_mps: isMpsOrder
    };
    const url = `/api/order/validate-csv/`;
    try {
      const response = await axios.post(url, data);
      setSelectedServices(response.data)
      setCleanedRawObjects(cleanedRawObjects)
      setPage(1);
    } catch (err) {
      const response = err.data;
      setErrors(response.message);
      setSelectedServices([]);
      setPage(2);
    } finally {
      setLoading(false);
    }
  };

  const submitBulkOrder = async (serviceValues, allowHiphenInTn) => {
    setLoading(true);
    const cleanedRawObjects = getCleanedRawObjects(fileState.rawObjects);

    try {
      if (isMpsOrder) {
        const url = '/api/order/mps/'
        const orderObjects = getMpsOrderObjects(cleanedRawObjects, serviceValues)
        setOrderObjects(orderObjects)
        const response = await axios.post(url,  {
          secret_key: secretKey,
          orders: orderObjects
        });
        setTotalOrders(response.data.tracking_nos.length);
      } else {
        console.log("bulk");
        const url = '/api/order/orders/'
        const orderObjects = getOrderObjects(cleanedRawObjects, serviceValues, allowHiphenInTn)
        setOrderObjects(orderObjects)
        const data = {
          secret_key: secretKey,
          orders: orderObjects
        }
        const response = await axios.post(url, data);
        setTotalOrders(response.data.tracking_nos.length);
      }
      setPage(3);
      setFileState({
        filename: "",
        rawObjects: null,
        selected: false,
        triggerEffect: false
      });
    } catch (err) {
      console.error(err);
      const response = err.data;
      const errors = formatError(cleanedRawObjects, response);
      setErrors(errors);
      setSelectedServices([]);
      setPage(2);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fileState.triggerEffect) {
      validateRawObjects();
    }
  }, [fileState.triggerEffect]);

  const handleBackButton = () => {
    setPage(page - 1);
  };

  return (
    <OrderSubmitBulkContext.Provider
      value={{
        page,
        setPage,
        fileState,
        setFileState,
        validateRawObjects,
        submitBulkOrder,
        loading,
        errors,
        selectedServices,
        orderObjects,
        handleBackButton,
        isMpsOrder, setIsMpsOrder,
        mpsOrderCount,
        totalOrders
      }}
    >
      {props.children}
    </OrderSubmitBulkContext.Provider>
  );
});
