import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Jumbotron } from 'react-bootstrap'
import { Radio } from 'antd'
import { Trans } from 'react-i18next'
import { showLabelShipperAddressAction } from '../../actions/agentAppInfoActions';
import { displayShipperAddressOnShippingLabelApi } from './changeLabelApi'

const DisplayShipperAddress = ({ agentAppDetails, showLabelShipperAddressAction }) => {
  const [showShipperAddress, setShowShipperAddress] = useState();
  const [loading, setloading] = useState(false);

  const handleChange = e => {
    let { value } = e.target
    setShowShipperAddress(value)
  };

  const handleSave = async () => {
    setloading(true);
    const data = await displayShipperAddressOnShippingLabelApi(
      agentAppDetails.agent_application_id,
      agentAppDetails.agent_application_secret_key,
      showShipperAddress
    );
    if (!data.isError) {
      showLabelShipperAddressAction({
        label_shipper_address: showShipperAddress
      });
    }
    setloading(false);
  }

  useEffect(() => {
    if (agentAppDetails.label_shipper_address !== showShipperAddress) {
      setShowShipperAddress(agentAppDetails.label_shipper_address)
    };
  }, [agentAppDetails.label_shipper_address])  

  return (
    <Jumbotron className="p-4 border border-secondary">
      <div className="h5 font-weight-bold capitalize mb-1">Display Shipper Address On Shipping Label</div>
      <div className="mt-3">
        <Radio.Group onChange={handleChange} value={showShipperAddress}>
          <Radio value={true}>Show</Radio>
          <Radio value={false}>Hide</Radio>
        </Radio.Group>
      </div>

      <button
        type="button"
        className="mt-4 w-100 btn btn-lg btn-success"
        onClick={handleSave}
        disabled={(agentAppDetails.label_shipper_address === showShipperAddress) || loading}
        >
          {loading ? 'Saving...':<Trans i18nKey="common:common.save" />}
      </button>
    </Jumbotron>
  )
}

const mapState = ({ agentAppDetails }) => ({
  agentAppDetails
})

export default connect(
  mapState, {
    showLabelShipperAddressAction: showLabelShipperAddressAction
  }
)(DisplayShipperAddress)