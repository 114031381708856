import React, { useState } from 'react'
import { Jumbotron } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import axios from 'axios';
import { Checkbox } from 'antd'

const UserNotificationSettings = ({ agentAppDetails, ...props }) => {
  const [state, setState] = useState({
    sendNotificationEmail: agentAppDetails.send_notification_email,
    sendNotificationSms: agentAppDetails.send_notification_sms,
    loading: false,
    showSuccess: false,
    showError: false,
  });

  const handleSave = async () => {
    setState({
      ...state,
      loading: true,
      showSuccess: false,
      showError: false
    })
    try {
      await axios.put(`/api/order/shipper-agent-app/${agentAppDetails.agent_application_id}/`, {
        'secret_key': agentAppDetails.agent_application_secret_key,
        'send_notification_email': state.sendNotificationEmail,
        'send_notification_sms': state.sendNotificationSms
      })
      setState({
        ...state,
        showError: false,
        showSuccess: true,
        loading: false
      });        
    } catch(err) {
      // const response = (err.response && err.response.data) || {}
      setState({
        ...state,
        showError: true,
        showSuccess: false,
        loading: false
      });
    }


  }

  const isFormChanged = () => {
    if(state.sendNotificationEmail === agentAppDetails.send_notification_email &&
      state.sendNotificationSms === agentAppDetails.send_notification_sms) {
        return false
    }
    return true;
  }

  return (
    <Jumbotron className="p-4 border border-secondary">
      <div className="h5 font-weight-bold capitalize mb-1">Notification Settings</div>
      <div><Checkbox checked={state.sendNotificationEmail} onChange={(e) => {
        setState({...state, sendNotificationEmail: e.target.checked});
      }} >Email notifications</Checkbox> </div>
      <div><Checkbox checked={state.sendNotificationSms}  onChange={(e) => {
        setState({...state, sendNotificationSms: e.target.checked});

      }} >SMS notifications</Checkbox> </div>

      <button
        type="button"
        className="mt-4 w-100 btn btn-lg btn-success"
        onClick={handleSave}
        disabled={!isFormChanged() || state.loading}
        >
          {state.loading ? 'Saving...':<Trans i18nKey="common:common.save" />}
      </button>
    </Jumbotron>
  )
}

export default UserNotificationSettings;