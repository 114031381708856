import axios from 'axios'
import {
  UNAUTH_USER,
  FETCH_AGENTAPP_DETAILS,
  EDIT_AGENTAPP_DETAILS,
  SHOW_LABEL_SHIPPER_ADDRESS
} from './types'

export function fetchAgentAppDetailsAction(data=null) {
  console.log('fetching agentapp details...')

  return function(dispatch, getState) {
    const state = getState()

    const fetchAgentAppDetails = async () => {
      const secretKey = state.shipperDetails.shipperDetails.agent_application_secret_key
      const response = await axios.get(`/api/order/shipper-agent-app/?secret_key=${secretKey}`)
      try {
        dispatch({
          type: FETCH_AGENTAPP_DETAILS,
          payload: response.data
        })
        localStorage.setItem('agent_application_id', response.data.agent_application_id)
      } catch(err) {
        console.log(err)
        console.log(err.response.status)
        dispatch({ type: UNAUTH_USER })
      }
    }

    if (data) {
      dispatch({
        type: FETCH_AGENTAPP_DETAILS,
        payload: data
      })
    } else {
      if (state.shipperDetails.hasOwnProperty('shipperDetails') &&
        !Object.entries(state.agentAppDetails).length) {
        fetchAgentAppDetails()
      }
    }
  }
}

export function updateAgentAppLabelPreference(data) {
  return function(dispatch) {
    dispatch({
      type: EDIT_AGENTAPP_DETAILS,
      payload: data
    })
  }
}

export function showLabelShipperAddressAction(data) {
  return function(dispatch) {
    dispatch({
      type: SHOW_LABEL_SHIPPER_ADDRESS,
      payload: data
    })
  }
}