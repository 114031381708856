import React, { useState, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { List, Button, Card, Modal, Popover, Row, Col } from 'antd'

import { createShipments, resetCreateShipments } from './store';

const decodeSelectedOrders = (payload) => {
  try{
    return JSON.parse(atob(payload))
  }
  catch(e){
    console.error(e)
    return {
      orderIds: [],
      lanesCounter: {}
    }
  }
}

const ResultAlert = ({successCount}) => {
  return (
    <Row style={{padding: '24px', textAlign: 'center'}} type="flex" justify="center">
      <Col span={24}>
        <h3>{successCount} Shipments created successfully!</h3>
      </Col>
      <Col span={24}>
        <p>You may go to Manage Orders to print your shipping labels.</p>
      </Col>
      <Col span={24} style={{marginTop: '12px'}}>
        <Row type="flex" justify="space-around">
          <Link to="/view-draft-orders">
            <Button type="default">
              Submit new shipments
            </Button>
          </Link>
          <Link to="/view-orders">
            <Button type="primary">
              Go to Manage Orders
            </Button>
          </Link>
        </Row>
      </Col>
    </Row>
  )
}

const OrderSummary = ({lanesCounter}) => {
  return (
    <List
      style={{marginBottom: '16px'}}
      bordered
      header={<span style={{fontSize: '1.2em', fontWeight: 'bold'}}>Summary</span>}
      dataSource={Object.entries(lanesCounter)}
      renderItem={([lane, count]) => {
        const [origin, dest] = lane.split('-')
        return (<List.Item>{origin} to {dest} - {count} shipments</List.Item>)
      }}
    />
  )
}

export const CreateShipments = (props) => {
  const dispatch = useDispatch()
  const payload = decodeSelectedOrders(props.match.params.ids)
  const draftOrderIds = payload.orderIds

  const [hasResult, setHasResult] = useState(false)

  const secretKey = useSelector( (state) => {
    if(state.shipperDetails && state.shipperDetails.shipperDetails){
      return state.shipperDetails.shipperDetails.agent_application_secret_key;
    }
    return null;
  });


  useEffect(() => {
    dispatch(resetCreateShipments())
  }, [dispatch])

  const {isCreatingShipment, result} = useSelector((state) => {
    return {
      isCreatingShipment: state.draftOrders.createShipments.isLoading,
      result: state.draftOrders.createShipments.result,
    };
  });

  const onShipmentsCreated = () => {
    setHasResult(true)
  }

  const onCreateClick = () => {
    const params = {
      'draft_order_ids': draftOrderIds,
    }
    dispatch(createShipments(secretKey, {params, callback: onShipmentsCreated}))
  }

  const CreateShipmentButton = () => (
    <Button
      type="primary"
      onClick={onCreateClick}
      disabled={!(!result.hasResult && draftOrderIds.length > 0)}
      loading={isCreatingShipment}>
      Submit to Janio
    </Button>
  )

  return (
    <div>
      <Modal visible={hasResult} footer={null} closable={false} closeIcon={null}>
        <ResultAlert successCount={result.successCount}/>
      </Modal>
      <Link to={"/view-draft-orders/"}>
        <Button icon="left" size="large" type="link">Back to shipments</Button>
      </Link>
      <Card title={`Creating ${draftOrderIds.length} Shipments`}>
        <OrderSummary lanesCounter={payload.lanesCounter}/>
        <div>
          <h6>Before clicking Submit to Janio, you hereby agree to the below policy and declaration:</h6>
        </div>
        <div>
          <h6>Shipment Reweights Policy</h6>
          <p>
            I confirm that the declared weight and dimensions in this form are correct and accurate.
            <Popover
              overlayStyle={{width: '300px'}}
              content={<Trans i18nKey="common:submitOrder.shipmentReweighsPolicyText" />}
              title="Shipment Reweighs Policy"
              trigger="click">
              <Button type="link" style={{padding: 0}}>Why is this needed?</Button>
            </Popover>
          </p>
        </div>
        <div>
          <h6>Order Value Declaration</h6>
            <p>
            I confirm that the declared order value is correct, complete, and accurate.
            <Popover
              overlayStyle={{width: '300px'}}
              content={<Trans i18nKey="common:submitOrder.orderValueDeclarationText" />}
              title="Order Value Declaration"
              trigger="click">
              <Button type="link" style={{padding: 0}}>Why is this needed?</Button>
            </Popover>
            </p>
        </div>
        <div>
          <CreateShipmentButton />
        </div>
      </Card>
    </div>
  )
}
