import axios from 'axios';
import {
  UNAUTH_USER,
  FETCH_CONSIGNEE_ADDRESS,
  FETCH_CONSIGNEE_ADDRESS_BY_ID,
  ADD_CONSIGNEE_ADDRESS,
  EDIT_CONSIGNEE_ADDRESS,
  CLEAR_CONSIGNEE_ADDRESS_ERRORS
} from './types';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const defaultToken = localStorage.getItem('token');
if (defaultToken) {
  axios.defaults.headers.common['Authorization'] = 'Token ' + defaultToken;
}

export function fetchConsigneeAddress() {
  const token = localStorage.getItem('token');

  return function (dispatch) {
    const url = `/api/shipper/consignee-address/`
    axios.get(url,
      { headers: { 'Authorization': 'Token ' + token } }
    )
      .then((response) => {
        dispatch({
          type: FETCH_CONSIGNEE_ADDRESS,
          payload: response.data
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: UNAUTH_USER });
      })
  }
}

export function fetchConsigneeAddressById(id) {
  const token = localStorage.getItem('token');

  return function (dispatch) {
    const url = `/api/shipper/consignee-address/${id}/`
    axios.get(url,
      { headers: { 'Authorization': 'Token ' + token } }
    )
      .then((response) => {
        console.log(response);
        dispatch({
          type: FETCH_CONSIGNEE_ADDRESS_BY_ID,
          payload: response.data
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: UNAUTH_USER });
      })
  }
}

export function addConsigneeAddress(data) {
  const token = localStorage.getItem('token');

  return function (dispatch) {
    const url = `/api/shipper/consignee-address/`
    axios.post(url,
      data,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } }
    )
      .then((response) => {
        dispatch({
          type: ADD_CONSIGNEE_ADDRESS,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: ADD_CONSIGNEE_ADDRESS,
          payload: true,
          errorData: error.data
        });
      });
  }
}

export function editConsigneeAddress(consigneeAddressId, data) {
  const token = localStorage.getItem('token');

  return function (dispatch) {
    const url = `/api/shipper/consignee-address/${consigneeAddressId}/`
    axios.patch(url,
      data,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } }
    )
      .then((response) => {
        dispatch({
          type: EDIT_CONSIGNEE_ADDRESS,
          payload: false
        });
      })
      .catch((error) => {
        dispatch({
          type: EDIT_CONSIGNEE_ADDRESS,
          payload: true,
          errorData: error.data
        });
      });
  }
}

export function clearConsigneeErrors() {
  return {
    type: CLEAR_CONSIGNEE_ADDRESS_ERRORS
  }
}
