// @ts-nocheck
import React, { useEffect } from "react";
import { Table, Switch, Button, Modal,Card, Tag, Row, Col, Tabs} from 'antd'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { useSecretKey } from '../../auth/useSecretKey'
import { fetchShipmentRulesList, performToggleModal,performToggleModalAlert, updateFormStatus, deleteFormData, updateShipmentRulesOrder, setitemCategoryDataList, setParcelDataList, setServiceDataList } from './store'

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { MenuOutlined } from '@ant-design/icons';

const dateFormat = (date)=>{
  let today = new Date(date);
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();

  return mm + '/' + dd + '/' + yyyy;
}

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const { TabPane } = Tabs;

export const ShipmentRuleDashboard = (props) => {
  const dispatch = useDispatch()
  const [secretKey] = useSecretKey()
  const shipmentRuleStore = useSelector((state) => {
    return state.shipmentRules
  });
  
  const onSortEnd = ({ oldIndex, newIndex, collection, nodes, ...rest  }) => {

    let checkType = nodes[oldIndex].node.attributes[1].value
    let dataSource = []
    
    if(checkType === 'SERVICE_TYPE') {
      dataSource = segregateDataArrWithIndex[1]
      if (oldIndex !== newIndex) {
        const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
        console.log("test", newData);
        dispatch(setServiceDataList(newData))
        dispatch(updateShipmentRulesOrder(secretKey, newData[newIndex].id, dataSource[newIndex].id))
      }
    } else if(checkType === 'ITEM_CATEGORY') {
      dataSource = segregateDataArrWithIndex[0]
      if (oldIndex !== newIndex) {
        const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
        dispatch(setitemCategoryDataList(newData))
        dispatch(updateShipmentRulesOrder(secretKey, newData[newIndex].id, dataSource[newIndex].id))
      }
    } else if(checkType === 'DIMENSIONS') {
      dataSource = segregateDataArrWithIndex[2]
      if (oldIndex !== newIndex) {
        const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
        dispatch(setParcelDataList(newData))
        dispatch(updateShipmentRulesOrder(secretKey, newData[newIndex].id, dataSource[newIndex].id))
      }
    }
  };
  
  const DraggableContainer = props => {
    return <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  };

  const DraggableBodyRowZero = ({ className, style, ...restProps }) => {
    const dataSource = segregateDataArrWithIndex[0];
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => {
      return x.index === restProps['data-row-key']
    })
    const ruleType = 'ITEM_CATEGORY'
    return <SortableItem index={index} {...restProps} value={ruleType} />;
  };

  const DraggableBodyRowOne = ({ className, style, ...restProps }) => {
    const dataSource = segregateDataArrWithIndex[1];
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => {
      return x.index === restProps['data-row-key']
    })
    const ruleType = 'SERVICE_TYPE'
    return <SortableItem index={index} {...restProps} value={ruleType} />;
  };

  const DraggableBodyRowTwo = ({ className, style, ...restProps }) => {
    const dataSource = segregateDataArrWithIndex[2];
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => {
      return x.index === restProps['data-row-key']
    })
    const ruleType = 'DIMENSIONS'
    return <SortableItem index={index} {...restProps} value={ruleType} />;
  };

  const urlMappring = {
    "ITEM_CATEGORY":"item-category",
    "DIMENSIONS":"parcel-dimension",
    "SERVICE_TYPE":"service-type"
  }
  const segregateData = () => {
    let itemCategoryData = []
    let serviceTypeData = []
    let parcelData = []

    shipmentRuleStore.dashboard.entries.forEach((item, index) => {
      if(item.action_type === 'ITEM_CATEGORY') {
        itemCategoryData.push(item)
      } else if(item.action_type === 'SERVICE_TYPE') {
        serviceTypeData.push(item)
      } else if(item.action_type === 'DIMENSIONS') {
        parcelData.push(item)
      }
    })
    return [itemCategoryData, serviceTypeData, parcelData]
  }
  const segregateDataArray = segregateData()
  const addIndexKey = () => {
    let newItemCategoryData = []
    let itemCategoryData = []
    let newServiceTypeData = []
    let serviceTypeData = []
    let newParcelTypeData = []
    let parcelTypeData = []

    if(segregateDataArray[0].length > 0 && segregateDataArray[0][0].action_type === 'ITEM_CATEGORY') {
      segregateDataArray[0].forEach((item, index) => {
        itemCategoryData = Object.assign({index: index}, item);
        newItemCategoryData.push(itemCategoryData)
      })
      // if(newItemCategoryData.length > 0) {
      //   // dispatch(setitemCategoryDataList(newItemCategoryData))
      // }
    } 
    if(segregateDataArray[1].length > 0 && segregateDataArray[1][0].action_type === 'SERVICE_TYPE') {
      segregateDataArray[1].forEach((item, index) => {
        serviceTypeData = Object.assign({index: index}, item);
        newServiceTypeData.push(serviceTypeData)
      })
    }
    if(segregateDataArray[2].length > 0 && segregateDataArray[2][0].action_type === 'DIMENSIONS') {
      segregateDataArray[2].forEach((item, index) => {
        parcelTypeData = Object.assign({index: index}, item);
        newParcelTypeData.push(parcelTypeData)
      })
    }
    return [newItemCategoryData, newServiceTypeData, newParcelTypeData]
  }
  const segregateDataArrWithIndex = addIndexKey()
  const COLUMNS = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Name',
      dataIndex: 'rule_name',
    },
    {
      title: 'Date Created',
      dataIndex: 'created_on',
      render: date =>(<div>{dateFormat(date)}</div>)
    },
    {
      title: 'Rule status',
      dataIndex: 'is_active',
      render: tag => (
        <Tag color={"green"}> {tag?"active":"inactive"}</Tag>
      ),
    },
    {
      title: 'Rule Type',
      dataIndex: 'action_type',
      render: type => (
        <Tag > {type === 'SERVICE_TYPE' ? "Service Type" : type === 'DIMENSIONS' ? 'Dimensions Rule' : 'Item Category Rule'}</Tag>
      ),
    },
    {
      title: 'Switch',
      dataIndex: 'switch',
      render: (text, record)=>(
        <Switch defaultChecked onChange={(value, event)=>{
          let new_record = {...record}
          new_record.is_active = value
          dispatch(updateFormStatus(secretKey, record.id, new_record))
        }} checked={record.is_active}/>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record)=>(
        
        <div>
            <Link to={`/shipment-rules/${urlMappring[record.action_type]}/${record.id}`}><Button type="link" onClick={clearModalData}>Edit</Button></Link>
            <Button type="link" onClick={(event)=>{dispatch(deleteFormData(secretKey, record.id))}}>Delete</Button>
        </div>
      )
    }
  ];

  const clearModalData = () => {
    if(shipmentRuleStore.dashboard.isModalVisibleAlert) {
      dispatch(performToggleModalAlert())
    }
  }

  const showModal = (event) => {
    if(shipmentRuleStore.dashboard.isModalVisibleAlert) {
      dispatch(performToggleModalAlert())
    }
    dispatch(performToggleModal())
  };

  useEffect(() => {
    if(secretKey && !shipmentRuleStore.loading){
      dispatch(fetchShipmentRulesList(secretKey));
    }
    if(props.history.action === 'PUSH') {
      localStorage.removeItem('activeKey')
    }
  }, [dispatch, props.history.action, secretKey, shipmentRuleStore.loading]);

  const callbackFn = (key) => {
    console.log('keykey', key);
    localStorage.setItem('activeKey', key)
  }
  
  return (
    <div>
      <div className="link-container">     
        <Button type="primary" onClick={showModal}>Add shipment rule </Button>
      </div>
      <Card>
      <Tabs defaultActiveKey={localStorage.getItem('activeKey') && props.history.action === 'POP' ? localStorage.getItem('activeKey') : '1'} onChange={callbackFn}>
        <TabPane tab="Service Type" key="1">
          {/* {shipmentRuleStore.dashboard.multiSelectRecords.length>0 && segregateDataArrWithIndex[1][0] !== undefined && segregateDataArrWithIndex[1][0].action_type === shipmentRuleStore.dashboard.multiSelectRecords[0].action_type?<Button type="primary" onClick={handleMultiDelete} className="mb-4" size={'large'}><Icon type="delete" style={{color: '#ffffff' }}/></Button>:null} */}
          <Table
              // rowSelection={{
              // type: "checkbox",
              // ...rowSelection,
              // }}
              // rowKey="id"
              dataSource={shipmentRuleStore.dashboard.serviceDataList.length > 0 ? shipmentRuleStore.dashboard.serviceDataList : segregateDataArrWithIndex[1]}
              columns={COLUMNS}
              loading={shipmentRuleStore.dashboard.loading}
              rowKey="index"
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRowOne,
                },
              }}
          />
        </TabPane>
        <TabPane tab="Item Category" key="2">
          {/* {shipmentRuleStore.dashboard.multiSelectRecords.length>0 && segregateDataArrWithIndex[0][0] !== undefined && segregateDataArrWithIndex[0][0].action_type === shipmentRuleStore.dashboard.multiSelectRecords[0].action_type?<Button type="primary" onClick={handleMultiDelete} className="mb-4" size={'large'}><Icon type="delete" style={{color: '#ffffff' }}/></Button>:null} */}
          <Table
              // rowSelection={{
              // type: "checkbox",
              // ...rowSelection,
              // }}
              // rowKey="id"
              dataSource={shipmentRuleStore.dashboard.itemCategoryDataList.length > 0 ? shipmentRuleStore.dashboard.itemCategoryDataList : segregateDataArrWithIndex[0]}
              columns={COLUMNS}
              loading={shipmentRuleStore.dashboard.loading} 
              rowKey="index"
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRowZero,
                },
              }}
          />
        </TabPane>
        <TabPane tab="Parcel Dimension" key="3">
          {/* {shipmentRuleStore.dashboard.multiSelectRecords.length>0 && segregateDataArrWithIndex[2][0] !== undefined && segregateDataArrWithIndex[2][0].action_type === shipmentRuleStore.dashboard.multiSelectRecords[0].action_type?<Button type="primary" onClick={handleMultiDelete} className="mb-4" size={'large'}><Icon type="delete" style={{color: '#ffffff' }}/></Button>:null} */}
          <Table
              // rowSelection={{
              // type: "checkbox",
              // ...rowSelection,
              // }}
              // rowKey="id"
              dataSource={shipmentRuleStore.dashboard.parcelDataList.length > 0 ? shipmentRuleStore.dashboard.parcelDataList : segregateDataArrWithIndex[2]}
              columns={COLUMNS}
              loading={shipmentRuleStore.dashboard.loading}
              rowKey="index"
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRowTwo,
                },
              }}
          />
        </TabPane>
      </Tabs>
      
      </Card>
        <Modal className="modal-title" title="Choose a shipment rule" visible={shipmentRuleStore.dashboard.isModalVisible} onCancel={showModal} footer={null} width={720} bodyStyle={{'fontSize': '20px'}}>
        <div className="site-card-wrapper">
          <Row gutter={16}>
            <Col span={8}>
              <Card bordered={false} style={{boxShadow: '1px 5px 9px #d6d6d6'}}>
                <Link align="center" to={"/shipment-rules/service-type"}>
                  <p style={{color: '#0e0e97',fontSize: '14px',fontWeight: 600, marginBottom: '7px'}} type="primary" ghost size={"large"} target="/shipment-rules/parcel-dimension">Service Type</p>
                </Link>
                <p align="center" style={{fontSize: '10px',color: 'rgb(119 116 116)'}}>Determine service type based on pickup and destination country.</p>
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false} style={{boxShadow: '1px 5px 9px #d6d6d6'}}>
                <Link align="center" to={"/shipment-rules/item-category"}>
                  <p style={{color: '#0e0e97',fontSize: '14px',fontWeight: 600, marginBottom: '7px'}} type="primary" ghost size={"large"} target="/shipment-rules/item-category">Item Category</p>
                </Link>
                <p align="center" style={{fontSize: '10px',color: 'rgb(119 116 116)'}}>Determine item category based on SKUs or item title.</p>
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false} style={{boxShadow: '1px 5px 9px #d6d6d6'}}>
                <Link align="center" to={"/shipment-rules/parcel-dimension"}>
                  <p style={{color: '#0e0e97',fontSize: '14px',fontWeight: 600, marginBottom: '7px'}} type="primary" ghost size={"large"} target="/shipment-rules/parcel-dimension">Parcel Dimension</p>
                </Link>
                <p align="center" style={{fontSize: '10px',color: 'rgb(119 116 116)'}}>Determine parcel dimension based on item weight or item quantity.</p>
              </Card>
            </Col>
          </Row>
        </div>
          {/* <Row>
            <Col span={24} align="middle">
              <Link to={"/shipment-rules/service-type"}>
                <Button type="primary" ghost size={"large"} target="/shipment-rules/parcel-dimension">Service Type Rule</Button>
              </Link>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col span={24} align="middle">
              <Link to={"/shipment-rules/item-category"}>
                <Button type="primary" ghost size={"large"} target="/shipment-rules/item-category">Item Category Rule</Button>
              </Link>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col span={24} align="middle">
              <Link to={"/shipment-rules/parcel-dimension"}>
                <Button type="primary" ghost size={"large"} target="/shipment-rules/parcel-dimension">Parcel Dimension Rule</Button>
              </Link>
            </Col>
          </Row> */}
        </Modal>
    </div>
  );
}
