import { createSlice } from "@reduxjs/toolkit";
import * as api from "./api";

const initialState = {
  entries: {
    data: [],
    loading: false,
    loaded: false,
  },
  filterData: {
    data: null,
    loading: false,
    loaded: false,
  },
  shopeeAuth: {
    url: null,
    loading: false,
    loaded: false,
  },
  shopeeOnboard: {
    loading: false,
    loaded: false,
    validationErrors: null,
  },
  lazadaAuth: {
    url: null,
    loading: false,
    loaded: false,
  },
  lazadaOnboard: {
    loading: false,
    loaded: false,
    validationErrors: null,
  },
};

const slice = createSlice({
  name: "channels",
  initialState: initialState,
  reducers: {
    fetchChannels: (state) => {
      state.entries.loaded = false;
      state.entries.loading = true;
    },
    setEntries: (state, action) => {
      state.entries.loaded = true;
      state.entries.loading = false;
      state.entries.data = action.payload.entries;
    },
    fetchFilterData: (state) => {
      state.filterData.loaded = false;
      state.filterData.loading = true;
    },
    setFilterData: (state, action) => {
      state.filterData.loaded = true;
      state.filterData.loading = false;
      state.filterData.data = action.payload.entries;
    },
    fetchShopeeAuthUrl: (state) => {
      state.shopeeAuth.loaded = false;
      state.shopeeAuth.loading = true;
    },
    setShopeeAuthurlData: (state, action) => {
      console.log(action);
      state.shopeeAuth.loaded = true;
      state.shopeeAuth.loading = false;
      state.shopeeAuth.url = action.payload.redirectUrlShopee;
    },
    onboardShopee: (state, action) => {
      state.shopeeOnboard.loaded = false;
      state.shopeeOnboard.loading = true;
    },
    setOnboardShopeeData: (state, action) => {
      state.shopeeOnboard.loaded = true;
      state.shopeeOnboard.loading = false;
    },
    setValidationErrorsForShopee: (state, action) => {
      state.shopeeOnboard.loading = false;
      state.shopeeOnboard.validationErrors = action.payload;
    },
    fetchLazadaAuthUrl: (state) => {
      state.lazadaAuth.loaded = false;
      state.lazadaAuth.loading = true;
    },
    setLazadaAuthUrlData: (state, action) => {
      state.lazadaAuth.loaded = true;
      state.lazadaAuth.loading = false;
      state.lazadaAuth.url = action.payload.redirectUrlLazada;
    },
    onboardLazada: (state, action) => {
      state.lazadaOnboard.loaded = false;
      state.lazadaOnboard.loading = true;
    },
    setOnboardLazadaData: (state, action) => {
      state.lazadaOnboard.loaded = true;
      state.lazadaOnboard.loading = false;
    },
    setValidationErrorsForLazada: (state, action) => {
      state.lazadaOnboard.loading = false;
      state.lazadaOnboard.validationErrors = action.payload;
    },
  },
});

export const getChannels = (secretKey, params) => async (dispatch) => {
  dispatch(slice.actions.fetchChannels());

  api.getChannels(secretKey, params).then(({ data: { results, count, next, previous } }) => {
    dispatch(
      slice.actions.setEntries({
        entries: results,
        totalCount: count,
      })
    );
  });
};

export const getFilterData = (secretKey, params) => async (dispatch) => {
  dispatch(slice.actions.fetchFilterData());

  api.getFilterData(secretKey, params).then((response) => {
    dispatch(
      slice.actions.setFilterData({
        entries: response.data,
      })
    );
  });
};

export const handleShopeeAuth = () => async (dispatch) => {
  dispatch(slice.actions.fetchShopeeAuthUrl());

  api.fetchShopeeAuthUrl().then((response) => {
    console.log(response);
    dispatch(
      slice.actions.setShopeeAuthurlData({
        redirectUrlShopee: response.data.redirectUrlShopee,
      })
    );
  });
};

export const handleShopeeOnboard = (code, shop_id, shop_name, shop_country) => async (dispatch) => {
  dispatch(slice.actions.onboardShopee());

  api
    .onboardShopee(code, shop_id, shop_name, shop_country)
    .then((response) => {
      dispatch(slice.actions.setOnboardShopeeData({}));
    })
    .catch((error) => {
      if (error.data) {
        dispatch(slice.actions.setValidationErrorsForShopee(error.data));
      }
    });
};

export const handleLazadaAuth = () => async (dispatch) => {
  dispatch(slice.actions.fetchLazadaAuthUrl());
  const res = await api.fetchLazadaAuthUrl();
  dispatch(
    slice.actions.setLazadaAuthUrlData({
      redirectUrlLazada: res.data.redirectUrlLazada,
    })
  );
};

export const handleLazadaOnboard = (code, shop_id, shop_name, shop_country) => async (dispatch) => {
  dispatch(slice.actions.onboardLazada());
  try {
    // const res = await api.onboardLazada(code, shop_id, shop_name, shop_country);
    dispatch(slice.actions.setOnboardLazadaData({}));
  } catch (err) {
    if (err.data) {
      dispatch(slice.actions.setValidationErrorsForLazada(err.data));
    }
  }
};

export default slice.reducer;
