import React, {useState, useEffect} from 'react';

import './Popup.css';
import { acceptTermsAndConditions } from '../../actions/authActions';

function Tnc(props) {
    const [triggerValue, setTriggerValue] = useState(props.trigger);

    useEffect(() => {
        if(localStorage.getItem('TermsAndConditionAccepted')==='yes'){
            setTriggerValue(false)
        } else {
            setTriggerValue(true)
        }
    }, [])

    const AcceptTermsAndConditions = (event) => {
        event.preventDefault();
        acceptTermsAndConditions();
        setTriggerValue(false);
    }

    return (triggerValue) ? (
        <div className='popup'>
            <div className='popup-inner'>
                <p className='popup-header'>Terms of Service</p>
                <div className='popup-content'>
                    <p className='popup-paragraph'>Dear valued client, <br/>
                    Thank you for your continued support in these difficult times. <br/><br/>
                    As part of Janio’s commitment to ensuring transparency in our operations and services to you, we are writing to inform you of the updates to our general terms and conditions and rates. <br/><br/>
                    The updated general terms and conditions of services of Janio (the “New T&Cs”) can be found at <a href='https://janio.asia/terms-conditions-2022/' target='_blank' rel="noopener noreferrer">Janio-Terms-and-Conditions-2022</a>. This will apply to you regardless of the services provided by Janio. <br/><br/>
                    The updated rates (the “Updated Rates”) can be found in the individual emails that has been sent to you. <br/><br/>
                    Please note that if you continue to engage Janio’s services after 1st February 2022 (the “effective date”), the services will be provided on the basis of the New T&Cs and  Updated Rates, and you will be deemed to have agreed to the New T&Cs and Updated Rates upon acknowledgment to this notification. Failure to acknowledge will result in a stoppage of services from the effective date. <br/>
                    The New T&Cs and Updated Rates, including schedules referred to therein, will be incorporated into and supersede any existing agreements that Janio has with you. <br/><br/>
                    Please feel free to contact us if you have any questions or require any clarifications. Thank you once again for your continued support. 
                    </p>
                </div>
                <button className='btn-tnc accept' onClick={(event) => AcceptTermsAndConditions(event)}>I Agree</button>                            
            </div>
        </div>
    ) : ""
}

export default Tnc