import React, { useContext, useState } from 'react'
import { Trans, withTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import classNames from 'classnames'
import { ClipLoader } from "react-spinners"
import * as yup from 'yup'
import Select from 'react-select'
import { AutoComplete, Select as AntSelect } from 'antd';
import useCities from '../hooks/useCities'
import useStates from '../hooks/useStates'
import useCountryCode from '../../common/hooks/useCountryCode'
import { ShopifyOrderContext } from '../ShopifyOrderContext'
import { REQUIREMENT_DOCUMENT_FOR_COUNTRIES } from '../constants'
import useConsigneeAddress from '../hooks/useConsigneeAddress'
import axios from 'axios';
import * as _ from 'lodash';

const { Option } = AntSelect

const stateCityDisabledCountries = {
  Singapore: {
    defaultState: 'Central Singapore',
    defaultCity: 'Singapore'
  }
}

const RecipientDetailsForm = props => {
  const context = useContext(ShopifyOrderContext)
  const { selectedOrder, shopifyOrders, validateToBackend, updateOrderData, nextPage, prevPage } = context
  const [selectedState, setSelectedState] = useState("");
  const shouldLoadStateCity = !Object.keys(stateCityDisabledCountries).includes(selectedOrder.consignee_country)
  
  const [saveConsigneeAddress, setSaveConsigneeAddress] = useState(false);

  const [cities] = useCities(
    selectedOrder.consignee_country,
    selectedState,
    false,
    shouldLoadStateCity,
    selectedOrder.service_level || ''
  )

  const [consigneeAddresses] = useConsigneeAddress(selectedOrder.consignee_country);

  const consigneeAddressOptions = consigneeAddresses.map((consigneeAddress) => (
    { 'value': consigneeAddress.id, 'label': consigneeAddress.name }
  ));

  const cityOptions = cities.map((city, i) => (
    <Option
      key={`city-option-${i}`}
      value={city.city_name}
      disabled={city.is_available === false}
    >
      {city.city_name}
    </Option>
  ))

  const [states, statesLoading] = useStates(
    selectedOrder.consignee_country,
    false,
    shouldLoadStateCity,
    selectedOrder.service_level || ''
  )
  const stateOptions = states.map((state, i) => (
    {
      'value': state.state_name,
      'label': state.state_name,
      isDisabled: state.is_available === false
    }
  ))

  const [countryCode, countryCodeLoading] = useCountryCode(selectedOrder.consignee_country)

  const getOrderCountryCode = () => {
    if (selectedOrder.consignee_number && countryCode && countryCode.country_calling_code) {
      return selectedOrder.consignee_number.substr(countryCode.country_calling_code.length);
    }
    return '';
  }

  const mapPropsToValues = () => {
    let { consignee_country, consignee_state, consignee_city } = selectedOrder
    if (Object.keys(stateCityDisabledCountries).includes(consignee_country)) {
      consignee_state = stateCityDisabledCountries[consignee_country].defaultState || ''
      consignee_city = stateCityDisabledCountries[consignee_country].defaultCity || ''
    }

    let values = {
      consignee_name: selectedOrder.consignee_name || '',
      consignee_email: selectedOrder.consignee_email || '',
      // consignee_number: (selectedOrder.consignee_number && selectedOrder.consignee_number.replace(/\D/g, '')) || '',
      consignee_number: getOrderCountryCode(),
      consignee_state: consignee_state || '',
      consignee_province: selectedOrder.consignee_province || '',
      consignee_postal: selectedOrder.consignee_postal || '',
      consignee_address: selectedOrder.consignee_address || '',
      consignee_address1: selectedOrder.consignee_address1 || '',
      consignee_city: consignee_city || '',
      consignee_country: selectedOrder.consignee_country || (
        selectedOrder.service && selectedOrder.service.consignee_country
      )
    }
    if (
      !!selectedOrder.identification_document_name
      || Object.keys(REQUIREMENT_DOCUMENT_FOR_COUNTRIES)
        .includes(selectedOrder.consignee_country)
      ) {
      values = {
        ...values,
        identification_document_name: selectedOrder.identification_document_name || '',
        consignee_identifcation_number: selectedOrder.consignee_identifcation_number || '',
      }
    }
    // TODO - REMOVE this once client is informed
    // if (selectedOrder.consignee_country === 'Taiwan') {
    //   values = {
    //     ...values,
    //     consignee_identifcation_number: selectedOrder.consignee_identifcation_number || '',
    //   }
    // }
    return values
  }
  const validationSchema = () => {
    const required = props.t('common.required')
    const mustNumber = props.t('common.mustNumber')

    return yup.lazy(values => {
      let schema = {
        consignee_name: yup.string().required(required),
        consignee_email: yup.string().email('Must be a valid email'),
        consignee_number: yup.string().required(required).matches(/^[0-9]*$/, mustNumber),
        consignee_state: yup.string().required("Please select state"),
        consignee_province: yup.string(),
        consignee_city: yup.string().required("Please select city"),
        consignee_postal: yup.string().required(required),
        consignee_address: yup.string().required(required)
      }

      if (Object.keys(REQUIREMENT_DOCUMENT_FOR_COUNTRIES).includes(values.consignee_country)) {
        schema = {
          ...schema,
          identification_document_name: yup.string(),
          consignee_identifcation_number: yup.string(),
        }
      }

      // TODO - REMOVE this once client is informed
      // if (values.consignee_country === 'Taiwan') {
      //  schema = {
      //     ...schema,
      //     consignee_identifcation_number: yup.string().required(required),
      //   }
      // }
      return yup.object().shape(schema)
    })
  }
  const handleSubmit = async (values, {setErrors, setSubmitting}) => {
    if (!countryCode.country_calling_code) {
      setErrors({
        consignee_number: 'Invalid phone number'
      })
      return
    }
    const validationData = {
      ...values,
      pickup_country: selectedOrder.pickup_country,
      consignee_number: countryCode.country_calling_code + values.consignee_number,
      service_level: selectedOrder.service_level
    }
    setSubmitting(true)
    const response = await validateToBackend(validationData)
    if (response.error) {
      const {
        consignee_name,
        consignee_email,
        consignee_number,
        consignee_state,
        consignee_province,
        consignee_city,
        consignee_postal,
        consignee_address,
        consignee_address1,
        ...rest
      } = response
      setErrors({
        consignee_name,
        consignee_email,
        consignee_number,
        consignee_state,
        consignee_province,
        consignee_city,
        consignee_postal,
        consignee_address,
        consignee_address1,
        nonFieldErrors: rest
      })
      setSubmitting(false)
      return
    } else {
      if (saveConsigneeAddress) {
        const token = localStorage.getItem('token');
        const data = {
          'name': values.consignee_address1,
          'contact_name': values.consignee_address1,
          'contact_number': values.consignee_number,
          'contact_phone': values.consignee_number,
          'contact_email': values.consignee_email,
          'address': values.consignee_address,
          'country': values.consignee_country,
          'state': values.consignee_state,
          'city': values.consignee_city,
          'postal': values.consignee_postal,
        };
        const url = `/api/shipper/consignee-address/`;
        axios.post(url,
          data,
          { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } }
        ).then(() => console.log('consignee address saved successfully')).catch(() => console.log('error occured'));
      }
    }

    setSubmitting(false)
    values.consignee_number = countryCode.country_calling_code + values.consignee_number
    updateOrderData(values)
    nextPage()
  }

  const getSelectedConsigneeAddress = (consigneeAddressId) => {
    const selectedAddress = _.find(consigneeAddresses, x => x.id === consigneeAddressId);
    return selectedAddress;
  };

  return (
    <Formik
      initialValues={mapPropsToValues()}
      enableReinitialize={true}
      validationSchema={validationSchema()}
      onSubmit={handleSubmit}
    >
      {formikProps => {
        const { values, errors, touched, isSubmitting, handleChange, setValues, setTouched, setFieldValue } = formikProps

        return (
        <Form>
          {shopifyOrders.isShopify &&
          <p className="font-italic">*We auto-filled the recipient details based on your order data. Feel free to change anything here!</p>
          }

          <div className="row">
            <div className='col-md-4 col-xs-12'>
              <div className="form-group">
                <label htmlFor="consignee_name"><Trans i18nKey='common:submitOrder.name' /></label>
                <input type="text" className={classNames(
                  'form-control',
                  {'is-invalid': !!(errors.consignee_name && touched.consignee_name)}
                  )}
                  id="consignee_name" name="consignee_name"
                  onChange={handleChange} value={values.consignee_name}
                  disabled={selectedOrder.submitted} />
                {touched.consignee_name && errors.consignee_name &&
                <div className="invalid-feedback">{errors.consignee_name}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="consignee_email"><Trans i18nKey='common:submitOrder.email' /></label>
                <input type="text" className={classNames(
                  'form-control',
                  {'is-invalid': !!(errors.consignee_email && touched.consignee_email && touched.consignee_email)}
                  )}
                  id="consignee_email" name="consignee_email"
                  onChange={handleChange} value={values.consignee_email}
                  disabled={selectedOrder.submitted} />
                {touched.consignee_email && errors.consignee_email &&
                <div className="invalid-feedback">{errors.consignee_email}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="consignee_number"><Trans i18nKey='common:submitOrder.phone' /></label>
                <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    {countryCode.country_calling_code &&
                    <img src={`https://www.countryflags.io/${countryCode.country_code}/flat/16.png`} alt=""/>
                    }
                    <span style={{marginLeft: 8}}>{countryCode.country_calling_code}</span>
                  </div>
                </div>
                <input type="text"
                  className={classNames(
                    'form-control',
                      {'is-invalid': !!(errors.consignee_number && touched.consignee_number)}
                    )}
                  id="consignee_number" name="consignee_number"
                  onChange={e => {
                    const re = /^[0-9\b]+$/
                    if (e.target.value === '' || re.test(e.target.value)) {
                      handleChange(e)
                    }
                    return
                  }} value={values.consignee_number}
                  disabled={selectedOrder.submitted || countryCodeLoading}
                />
                {touched.consignee_number && errors.consignee_number &&
                <div className="invalid-feedback">{errors.consignee_number}</div>}
                </div>
              </div>
              </div>
              <div className='col-md-4 col-xs-12'>
                {consigneeAddresses && consigneeAddresses.length === 0 && <div className="form-group mb-0">
                  <label htmlFor="consignee_address1"><Trans i18nKey='common:submitOrder.consigneeAddress' /></label>
                  <input type="text" className='form-control'
                    id="consignee_address1" name="consignee_address1"
                    onChange={handleChange} value={values.consignee_address1}
                    disabled={selectedOrder.submitted} />
                  {touched.consignee_address1 && errors.consignee_address1 &&
                    <div className="invalid-feedback">{errors.consignee_address1}</div>}
                </div>}

                {consigneeAddresses && consigneeAddresses.length > 0 && <div className="form-group mb-0">
                  <label htmlFor="consignee_address1"><Trans i18nKey='common:submitOrder.consigneeAddress' /></label>
                  <Select disabled={(selectedOrder.submitted || isSubmitting)} name="consignee_address1"
                    classNamePrefix='react-select'
                    className={classNames(
                      'basic-single',
                      { 'is-invalid': !!(errors.consignee_address1 && touched.consignee_address1) }
                    )}
                    style={{ width: 150 }} onChange={e => {
                      const selectedAddress = getSelectedConsigneeAddress(e.value);
                      setValues({
                        ...values,
                        'consignee_address1': selectedAddress.name,
                        'consignee_address_id': selectedAddress.id,
                        'consignee_postal': selectedAddress.postal,
                        'consignee_address': selectedAddress.address,
                        'consignee_email': selectedAddress.contact_email,
                        // 'consignee_number': selectedAddress.contact_number.substr(countryCode.country_calling_code.length),
                        'consignee_number' : selectedAddress.contact_number,
                        'consignee_country': selectedAddress.country,
                        'consignee_state': selectedAddress.state,
                        'consignee_city': selectedAddress.city
                      })
                    }}
                    value={values.consignee_address1 ? {
                      value: values.consignee_address1, label: values.consignee_address1
                    } : ''}
                    options={consigneeAddressOptions}
                  >
                  </Select>
                  {touched.consignee_address1 && errors.consignee_address1 &&
                    <div className="text-danger">{errors.consignee_address1}</div>}
                </div>}
                <p className='mb-0'>
                  <a href="/add-consignee-point" target='_blank'>{props.t('submitOrder.addConsigneeAddress')}</a>
                </p>
              </div>

              {Object.keys(REQUIREMENT_DOCUMENT_FOR_COUNTRIES).includes(values.consignee_country) &&
              <div className='col-md-4 col-xs-12'>
              <div className="form-group">
                <label htmlFor="identification_document_name"><Trans i18nKey='common:submitOrder.identificationDocument' /></label>
                <AntSelect
                  disabled={(selectedOrder.submitted || isSubmitting)}
                  id="identification_document_name"
                  onChange={value => {
                    setFieldValue('identification_document_name', value)
                  }}
                  value={values.identification_document_name}>
                    {Object.entries(
                      REQUIREMENT_DOCUMENT_FOR_COUNTRIES[values.consignee_country]
                      ).map(([ value, label ]) => {
                      return (
                        <Option key={value} value={value}>{label}</Option>
                      )
                    })}
                </AntSelect>
                {touched.identification_document_name && errors.identification_document_name &&
                <div className="text-danger">{errors.identification_document_name}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="consignee_identifcation_number">
                  <Trans i18nKey='common:submitOrder.identificationDocumentNo' />
                </label>
                <input type="text" className={classNames(
                  'form-control',
                  {'is-invalid': !!(errors.consignee_identifcation_number && touched.consignee_identifcation_number)}
                  )}
                  id="consignee_identifcation_number" name="consignee_identifcation_number"
                  onChange={handleChange} value={values.consignee_identifcation_number}
                  disabled={selectedOrder.submitted || isSubmitting || !values.identification_document_name} />
                {touched.consignee_identifcation_number && errors.consignee_identifcation_number &&
                <div className="invalid-feedback">{errors.consignee_identifcation_number}</div>}
              </div>
            </div>
            }
              
            {/*values.consignee_country === 'Taiwan' &&
                <div className='col-md-4 col-xs-12'>
                  <div className="form-group">
                    <label htmlFor="consignee_identifcation_number">
                      <Trans i18nKey='common:submitOrder.identificationDocumentNo' />
                    </label>
                    <input type="text" className={classNames(
                      'form-control',
                      { 'is-invalid': !!(errors.consignee_identifcation_number && touched.consignee_identifcation_number) }
                    )}
                      id="consignee_identifcation_number" name="consignee_identifcation_number"
                      onChange={handleChange} value={values.consignee_identifcation_number}
                    />
                    {touched.consignee_identifcation_number && errors.consignee_identifcation_number &&
                      <div className="invalid-feedback">{errors.consignee_identifcation_number}</div>}
                  </div>
                </div>
                    */}
            </div>
            
          <hr />
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="consignee_country"><Trans i18nKey='common:submitOrder.country' /></label>
                <input type="text" className={classNames(
                  'form-control',
                  {'is-invalid': !!(errors.consignee_country && touched.consignee_country)}
                  )}
                  id="consignee_country" name="consignee_country"
                  defaultValue={values.consignee_country}
                  disabled />
                {touched.consignee_country && errors.consignee_country &&
                <div className="invalid-feedback">{errors.consignee_country}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="consignee_state"><Trans i18nKey='common:submitOrder.state' /></label>
                <Select
                  isDisabled={(selectedOrder.submitted || !shouldLoadStateCity)}
                  name="consignee_state"
                  id="consignee_state"
                  isLoading={statesLoading}
                  placeholder = {props.t('submitOrder.selectState')}
                  className={classNames(
                    'basic-single',
                    {'is-invalid' : !!(errors.consignee_state && touched.consignee_state)}
                  )}
                  style={{width: 150}} onChange={e => {
                    setValues({
                      ...values,
                      consignee_state: e.label.split(', ')[0]
                    })
                    setSelectedState(e.label.split(', ')[0]);
                    setTouched({
                      consignee_state: true
                    }, false)
                  }}
                  value={values.consignee_state ? {
                    value: values.consignee_state , label: values.consignee_state
                      }: ''}
                  options={stateOptions}>
                </Select>
                {touched.consignee_state && errors.consignee_state &&
                <div className="text-danger">{errors.consignee_state}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="consignee_city"><Trans i18nKey='common:submitOrder.city' /></label>
                <AutoComplete
                  disabled={(selectedOrder.submitted || !shouldLoadStateCity)}
                  name="consignee_city"
                  id="consignee_city"
                  className={classNames(
                    'form-control',
                    {'is-invalid': !!(errors.consignee_city && touched.consignee_city)}
                  )}
                  onChange={value => {
                    setValues({
                      ...values,
                      consignee_city: value
                    })
                    setTouched({
                      ...touched,
                      consignee_city: true
                    }, false)
                  }}
                  value={values.consignee_city}
                  dataSource={cityOptions}
                  filterOption={true}
                />
                {touched.consignee_city && errors.consignee_city &&
                <div className="text-danger">{errors.consignee_city}</div>}
              </div>

              {/* <div className="form-group">
                <label htmlFor="consignee_province">Province</label>
                <input type="text" className={classNames(
                  'form-control',
                  {'is-invalid': !!(errors.consignee_province && touched.consignee_province)}
                  )}
                  id="consignee_province" name="consignee_province"
                  onChange={handleChange} value={values.consignee_province}
                  disabled={selectedOrder.submitted} />
                {touched.consignee_province && errors.consignee_province &&
                <div className="invalid-feedback">{errors.consignee_province}</div>}
              </div> */}
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="consignee_postal"><Trans i18nKey='common:submitOrder.postalCode' /></label>
                <input type="text" style={{width: 100}}
                  className={classNames(
                    'form-control',
                    {'is-invalid': !!(errors.consignee_postal && touched.consignee_postal)}
                    )}
                  id="consignee_postal" name="consignee_postal"
                  onChange={handleChange} value={values.consignee_postal}
                  disabled={selectedOrder.submitted} />
                {touched.consignee_postal && errors.consignee_postal &&
                <div className="invalid-feedback">{errors.consignee_postal}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="consignee_address"><Trans i18nKey='common:submitOrder.address' /></label>
                <textarea className={classNames(
                  'form-control',
                  {'is-invalid': !!(errors.consignee_address && touched.consignee_address)}
                  )}
                  rows="4" id="consignee_address" name="consignee_address"
                  onChange={handleChange} value={values.consignee_address}
                  disabled={selectedOrder.submitted}></textarea>
                {touched.consignee_address && errors.consignee_address &&
                <div className="invalid-feedback">{errors.consignee_address}</div>}
              </div>
            </div>
            </div>
            
            {consigneeAddresses && consigneeAddresses.length === 0 &&<div className="row">
              <div className="col-12">
                <div className="form-group form-check mb-0">
                  <input type="checkbox" className="form-check-input" id="saveConsigneeCheckbox"
                    onChange={e => setSaveConsigneeAddress(e.target.checked)} checked={saveConsigneeAddress} />
                  <label className="form-check-label" htmlFor="saveConsigneeCheckbox">Do you want to save this consignee details for future orders.</label>
                </div>
              </div>
            </div>}

          {errors.nonFieldErrors &&
            <div className="mt-3">
              <p className="mb-0">{JSON.stringify(errors.nonFieldErrors)}</p>
            </div>
          }

          <div className="d-flex justify-content-between mt-4">
            <button
              className='btn btn-light' type='button' onClick={() => prevPage() }
              disabled={selectedOrder.submitted || isSubmitting}
            >
              <Trans i18nKey='common:submitOrder.previousStep' />
            </button>
            <button className='btn btn-primary d-flex justify-content-center align-items-center' type="submit"
              disabled={isSubmitting || selectedOrder.submitted}>
              {isSubmitting ?
              <>
              <ClipLoader
                color={'#fff'}
                loading={true}
                size={16}
              /> <span className='ml-2'><Trans i18nKey='common:submitOrder.loading' /></span>
              </>:
              props.t('submitOrder.saveAndContinue')
              }
              </button>
          </div>
        </Form>
        )
      }}
    </Formik>
  )
}

export default withTranslation('common')(RecipientDetailsForm)
