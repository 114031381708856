import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Row, Col
} from 'antd'
import { Field } from 'formik'
import { FormField } from './input'
import { fetchItemCategory } from '../../../actions/orderActions'
import {resetDraftOrderItemsErrors} from "../store";

export const DraftOrderItems = ({draftOrder, formikProps}) => {
  const dispatch = useDispatch();
  const {itemCategories} = useSelector((state) => {
    return {
      itemCategories: state.order.itemCategory,
    };
  });
  useEffect(() => {
    if(itemCategories === undefined){
      dispatch(fetchItemCategory())
    }
  }, [dispatch, itemCategories]);

  const resetFieldError = (fieldName) => {
    if('items' in draftOrder.validation_result.errors){
      const itemName = fieldName.split('.')[1];
      dispatch(resetDraftOrderItemsErrors(itemName, draftOrder));
    }
  };

  return (
    <Card title="Items" style={{marginTop: '12px', marginBottom: '12px'}}>
      {
        draftOrder.items && draftOrder.items.map((item, index) => (
        <Row gutter={8} key={item.id}>
          <Col span={5}>
            <Field name={`items[${index}].item_sku`}>
              {({field}) => (
                <FormField {...{field, resetFieldError, formikProps, draftOrder, inputType: 'text', label: `SKU`}} />
              )}
            </Field>
          </Col>
          <Col span={5}>
            <Field name={`items[${index}].item_description`}>
              {({field}) => (
                <FormField {...{field, resetFieldError, formikProps, draftOrder, inputType: 'text', label: `Description`}} />
              )}
            </Field>
          </Col>
          <Col span={4}>
            <Field name={`items[${index}].item_qty`}>
              {({field}) => (
                <FormField {...{field, resetFieldError, formikProps, draftOrder, inputType: 'number', label: 'Qty'}} />
              )}
            </Field>
          </Col>
          <Col span={4}>
            <Field name={`items[${index}].item_price_value`}>
              {({field}) => (
                <FormField {...{field, resetFieldError, formikProps, draftOrder, inputType: 'number', label: 'Value', inputProps: {suffix: item.item_price_currency}}} />
              )}
            </Field>
          </Col>
          <Col span={6}>
            <Field name={`items[${index}].item_category`}>
              {({field}) => ( itemCategories ?
              <FormField {...{
                field,
                resetFieldError,
                formikProps,
                draftOrder,
                inputType: 'select',
                options: itemCategories.map(i => ({label: i, value: i})),
                label: 'Item Category'}} />
              : <p>Loading</p>
              )}
            </Field>
          </Col>
        </Row>
        ))
      }
    </Card>
  )
}
