import React, { useEffect } from "react";
import { Card, Button, message } from 'antd'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { LaneConfigForm } from './LaneConfigForm'
import { fetchLaneConfigDetail, updateLaneConfig } from './store'


export const EditLaneConfig = (props) => {
  const dispatch = useDispatch()
  const laneConfigId = parseInt(props.match.params.id, 10);

  const secretKey = useSelector( state => {
    return state.shipperDetails?.shipperDetails?.agent_application_secret_key
  })


  const updateLaneState = useSelector( state => {
    return state.laneConfigs.editLaneConfig
  })

  useEffect(() => {
    if(secretKey){
      dispatch(fetchLaneConfigDetail(secretKey, laneConfigId))
    }
  }, [laneConfigId, secretKey])


  useEffect(() => {
    if(updateLaneState.result){
      message.success('Lane configuration has been updated', 1, () => props.history.goBack())
    }
  }, [updateLaneState.result, updateLaneState.error])

  return (
    <div>
      <Link to={"/channels/lane-configs"}>
        <Button type="link">Back</Button>
      </Link>
      <Card>
        <LaneConfigForm
          laneConfig={updateLaneState.laneConfig}
          errors={updateLaneState.error}
          onSubmit={(params) => dispatch(updateLaneConfig(secretKey, laneConfigId, params))}
          isLoading={updateLaneState.loading}
          isSubmitting={updateLaneState.updating}/>
      </Card>
    </div>
  );
}
