import React from 'react'

const PickupInformation = ({titleType="", pickup, pickupDate, type, deliveryOption}) => {
  let title
  if (type.toLowerCase().includes('dropoff')) {
    title = 'Drop-off'
  } else {
    title = 'Pickup'
  }
  title += ' Information'

  let timingText
  if (pickup.pickup_country === 'Indonesia') {
    // if (pickup.pickup_state === 'DKI Jakarta') {
    //   timingText = (
    //     <>
    //     <p className='mb-0'>Same Day: Timings are fixed between 12pm - 8pm</p>
    //     <p className='mb-0'>Next Day: Timings are fixed between 10am - 5pm</p>
    //     </>
    //   )
    // } else {
    //   timingText = (
    //     <p className='mb-0'>Time: Timings are fixed between 10am - 5pm</p>
    //   )
    // }
    timingText = (
      <>
      <p className='mb-0'>Same Day: <span style={{fontStyle: "italic"}}>Please ensure that orders are submitted before 12pm. Pickup timings are fixed between 12pm - 8pm</span></p>
      <p className='mb-1'>Next Day: <span style={{fontStyle: "italic"}}>Timings are fixed between 10am - 5pm</span></p>
      </>
    )
  }else {
    timingText = (
      <p className='mb-0'>Time: Timings are fixed between 9am - 7pm</p>
    )
  }

  return (
    <div>
      {
        titleType === "large" ?
        <h5>{title}</h5>:
        <label className="submit-title">{title}</label>
      }
      <div style={{ padding: 12 }} className="pickup-info-box">
        {
          type.toLowerCase().includes('pickup') &&
          <>
          <strong>{ !!pickupDate ? 'Pickup Date & Time':'Pickup Time'}</strong>

          <div className="mb-3">
            {!!pickupDate && <p className='mb-0'>Date: {pickupDate.format('DD-MM-YY')}</p>}
            {timingText}
          </div>
          </>
        }

        <div className="mb-3">
          <strong>Contact Person</strong>
          <p className='mb-0'>Name: {pickup.pickup_contact_name}</p>
          <p className='mb-0'>Phone: {pickup.pickup_contact_number}</p>
        </div>

        {deliveryOption &&
        <div className="mb-3">
          <strong>Delivery Service Level</strong>
          <p className="mb-0">{deliveryOption}</p>
        </div>
        }

        <div>
          <strong>Pickup Location</strong>
          <p className='mb-3'>{pickup.pickup_address}</p>
          <p className='mb-0'>Postal Code: {pickup.pickup_postal}</p>
          <p className='mb-0'>State: {pickup.pickup_state}</p>
          <p className='mb-0'>City: {pickup.pickup_city}</p>
          {
            pickup.pickup_province &&
            <p className='mb-0'>Province: {pickup.pickup_province}</p>
          }
        </div>
      </div>
    </div>
  )
}

export default PickupInformation
