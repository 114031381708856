import React, { useEffect } from "react";
import {Row, Col, Button, Form as F, Input, Select, Icon, Checkbox, Modal} from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { useSecretKey } from '../../auth/useSecretKey';
import useItemCategories from '../../shopifyOrders/hooks/useItemCategories.js'
import {addFormCondition, handleSave, handleDelete, handleSourceInput, handleTargetInput, handleInput, submitForm as submitItemCategoryForm, setFormData, updateForm as updateItemCategoryForm, resetFormData, performToggleModal, performToggleModalAlert} from './store'


const ItemCategoryForm = (props) => {
  const dispatch = useDispatch()
  const [secretKey] = useSecretKey()
  const formType = "itemCategoryForm"

  const shipmentRuleStore = useSelector((state) => {
    return state.shipmentRules
  });

  const isUpdateFormFlag = props.match.params.id?true:false
  const itemCategories = useItemCategories(true)

  useEffect(() => {
    if(!secretKey){
      // dispatch
      console.log('no secret key')
    }
    if (isUpdateFormFlag){
      dispatch(setFormData(secretKey, props.match.params.id))

    } else{
      dispatch(resetFormData(formType))
      dispatch(performToggleModal())
      if(shipmentRuleStore.dashboard.isModalVisibleAlert) {
        dispatch(performToggleModalAlert())
      }
    }
    
  }, [secretKey])

  

  

  const handleSourceInputChange = (event, name, index)=>{
    dispatch(handleSourceInput(formType, event, name, index))
  }

  const handleTargetInputChange = (event, name, index)=>{
    dispatch(handleTargetInput(formType, event, name, index))
  }

  const handleInputChange = (event)=>{
    dispatch(handleInput(formType, event.target.value, event.target.name))
  }

  const handleInputCheck = (event)=>{
    dispatch(handleInput(formType, event.target.checked, event.target.name))
  }

  const addConditionChange = (event)=>{
    dispatch(addFormCondition(formType))
  }

  const getItemCategoryOptions = ()=>{
    return itemCategories.map((item)=><Select.Option value={item}>{item}</Select.Option>)
  }

  const getSourceFieldOptions = ()=>{
    let options = shipmentRuleStore.itemCategoryForm.itemCategorySourceFields
    return Object.keys(options).sort().map((item)=><Select.Option value={options[item]}>{item}</Select.Option>)
  }


  const getOperatorOptions = (key)=>{
    let options = shipmentRuleStore.itemCategoryForm.operators[key]
    return Object.keys(options).map((item)=><Select.Option value={options[item]}>{item}</Select.Option>)
  }

  const handleSaveDelete = (event, index)=>{
    if (event.target.name === 'save')
      dispatch(handleSave(formType, index))
    else
      dispatch(handleDelete(formType, index))
      
  }

  const submitForm = (event)=>{
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(submitItemCategoryForm(secretKey, shipmentRuleStore.itemCategoryForm))
      }
    });
    
  }

  const updateForm = (event)=>{
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(updateItemCategoryForm(secretKey, shipmentRuleStore.itemCategoryForm.id, shipmentRuleStore.itemCategoryForm))
      }
    });
  }
  const { getFieldDecorator } = props.form;

  const conditionRows = ()=>{
    let fields = shipmentRuleStore.itemCategoryForm.rule_source_fields
    
    if(fields[0].source_field_name === '' && document.getElementsByClassName('ant-select-selection-selected-value')[0] !== undefined) {
      document.getElementsByClassName('ant-select-selection-selected-value')[0].innerText = ''
    }
    return fields.map((item, index)=>{
      return <Row>
      <Col span={6}>
        <F.Item label="Condition">
        {getFieldDecorator(`source_field_name${index}`,{
                rules: [{ required: true, message: 'This field is required' }], initialValue:item["source_field_name"]
              })(
                <Select value={item["source_field_name"]} style={{ width: '80%' }} onChange={(e)=>handleSourceInputChange(e, "source_field_name",index)}>
                  {getSourceFieldOptions()}
                </Select>
              )}
        </F.Item>
      </Col>
      <Col span={6}>
        {shipmentRuleStore.itemCategoryForm.rule_source_fields[index].source_field_name.length>0?<F.Item label="operator">
        {getFieldDecorator(`operator${index}`, {
                rules: [{ required: true, message: 'This field is required' }],initialValue:item["operator"]

              })(
                <Select value={item["operator"]} style={{ width: "80%" }} onChange={(e)=>handleSourceInputChange(e, "operator", index)}>
                {getOperatorOptions(item['source_field_name'])}
              </Select>
              )}
        </F.Item>:null}
      </Col>
      <Col span={6}>
        {shipmentRuleStore.itemCategoryForm.rule_source_fields[index].source_field_name.length>0?<F.Item label={shipmentRuleStore.itemCategoryForm.sourceFieldLabel[item["source_field_name"]]}>
        {getFieldDecorator(`source_field_value${index}`, {
                rules: [
                  { required: true, message: 'This field is required' },
                  {
                    // eslint-disable-next-line no-useless-escape
                    pattern: new RegExp(/^[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$;,:=/\(\),\-\w\s+]+$/),
                    message: " Special Characters not allowed"
                  },
                ],
                initialValue:item["matches_to_value"]
              })(
              <Input name={"matches_to_value"} value={item["matches_to_value"]} onChange={(e)=>handleSourceInputChange(e.target.value, "matches_to_value", index)}/>
              )}
        </F.Item>:null}
      </Col>
      <Col span={6}>
        {shipmentRuleStore.itemCategoryForm.rule_source_fields[index].source_field_name.length>0 || index>0?<F.Item className="mt-4 ml-4">
          {((item.is_saved) || (index>0 && item.source_field_name.length===0))?<Icon type="delete" style={{ fontSize: '15px', color: '#08c' }} onClick={(e)=>handleSaveDelete(e, index)} theme="outlined" />:<Button type="primary" name="save" disabled={item.matches_to_value && item.operator ? false : true} onClick={(e)=>handleSaveDelete(e, index)} >Save</Button>}
        </F.Item>:null}
      </Col>
      {item.is_saved && fields.length > 1 ? <Col span={8}><h5 style={{ margin: '-10px 0 10px 0' }}><strong>OR</strong></h5></Col> : ''}
    </Row>
    })
  }

  const showModalAlert = (event) => {

    dispatch(performToggleModalAlert())
  }

  return (
    <div>
      <F layout="horizontal" name="itemCategoryForm">

      <Button  style={{border: 'none',padding: '0px 10px 0 0',marginBottom: '10px',fontWeight: '600'}} onClick={showModalAlert} ><Icon type="arrow-left" style={{ fontSize: '15px', color: '#08c','position': 'relative',top: '-2px' }}/></Button><span style={{fontSize: '14px'}}>Back to Shipment Rules</span>

        {/* {isUpdateFormFlag?"Update":"Create"} */}

        <h4><strong> Item Category Rule </strong></h4>
        <Row>
          <Col span={8}>
            <F.Item label="Name">
              {getFieldDecorator('rule-name', {
                rules: [{ required: true, message: 'This field is required' }], initialValue:shipmentRuleStore.itemCategoryForm.rule_name
              })(
                <Input maxLength="32" placeholder="name" name="rule_name" value={shipmentRuleStore.itemCategoryForm.rule_name} onChange={handleInputChange}/>
              )}
            </F.Item>
          </Col>
        </Row>
        <h6><strong>When</strong> (Select the condition) </h6>
        {conditionRows()}
        <Button type="primary" ghost className="mb-4" disabled={shipmentRuleStore.itemCategoryForm.rule_source_fields.slice(-1).pop().is_saved?false:true} onClick={addConditionChange}>+ Add Condition</Button>
        <h5> <strong>Trigger (Assign item to)</strong></h5>
        <Row>
          <Col span={10}>
            <F.Item label="Item Category">
            {getFieldDecorator('target_field_value', {
                rules: [{ required: true, message: 'This field is required' }],initialValue:shipmentRuleStore.itemCategoryForm.rule_target_fields[0].target_value
              })(
                <Select value={shipmentRuleStore.itemCategoryForm.rule_target_fields[0].target_value} style={{ width: "80%" }} onChange={(e)=>handleTargetInputChange(e, "target_value", 0)} disabled={shipmentRuleStore.itemCategoryForm.rule_source_fields[0].is_saved?false:true}>
                {getItemCategoryOptions()}
              </Select>
              )}
            </F.Item>
          </Col>
        </Row>
        <F.Item >
          <Checkbox onChange={handleInputCheck} name="apply_on_existing_order" value={shipmentRuleStore[formType].apply_on_existing_order}>Apply to new and existing shipments</Checkbox>
        </F.Item>
        <Button type="primary" onClick={(event)=>isUpdateFormFlag?updateForm(event):submitForm(event)} disabled={shipmentRuleStore.itemCategoryForm.rule_source_fields[0].is_saved>0?false:true}>{isUpdateFormFlag?"Update":"Create"} rule</Button>

        <Button style={{marginLeft: '10px'}} onClick={showModalAlert}>Cancel</Button>
      </F>
      <Modal title="Are you sure you want to quit ?" visible={shipmentRuleStore.dashboard.isModalVisibleAlert} onCancel={showModalAlert} footer={null}>
          <Row>
            <Col span={24} align="middle">
              <h6 style={{marginBottom: '20px'}}>All progress in this session will be Lost!</h6>
              <Button onClick={showModalAlert} >Cancel</Button>
              <Button type="primary" style={{marginLeft: '10px'}} onClick={() => props.history.goBack()}>Quit</Button>
            </Col>
          </Row>
          
        </Modal>
    </div>
    
  );
}



export const ShipmentRuleItemCategoryForm = F.create({ name: 'Item_category_form' })(ItemCategoryForm)