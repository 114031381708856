import { useState, useEffect } from 'react'
import axios from 'axios'

const useConsigneeAddress = (country) => {
  const [consigneeAddress, setConsigneeAddress] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getConsigneeAddress = async () => {
      setIsLoading(true)
      let params = {
        country: country
      };
      const response = await axios.get('/api/shipper/consignee-address/', {
        params,
        headers: { 'Content-Type': 'application/json' }
      })
      setConsigneeAddress(response.data);
      setIsLoading(false);
    }
    getConsigneeAddress();
  }, [country]);

  return [consigneeAddress, isLoading];
}

export default useConsigneeAddress;