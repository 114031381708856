import React, { useEffect } from "react";
import { Card, Button, message } from 'antd'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { LaneConfigForm } from './LaneConfigForm'
import { createNewLaneConfig } from './store'


export const NewLaneConfig = (props) => {
  const dispatch = useDispatch()

  const secretKey = useSelector( state => {
    return state.shipperDetails?.shipperDetails?.agent_application_secret_key
  })

  const createState = useSelector( state => {
    return state.laneConfigs.createLaneConfig
  })

  const submit = (params) => {
    dispatch(createNewLaneConfig(secretKey, params))
  }

  useEffect(() => {
    if(createState.result){
      message.success('Lane configuration has been created', 1, () => props.history.goBack())
    }
  }, [createState.result, createState.error])

  return (
    <div>
      <Link to={"/channels/lane-configs"}>
        <Button type="link">Back</Button>
      </Link>
      <Card>
        <LaneConfigForm
          laneConfig={{}}
          errors={createState.error}
          onSubmit={submit}
          isLoading={false}
          isSubmitting={createState.loading}/>
      </Card>
    </div>
  );
}
