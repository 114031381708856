import React, { Component } from "react";
import { connect } from "react-redux";

import SubmitSuccessMessage from '../common/SubmitSuccessMessage';

class AddPickupPointSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pointType: "pickup"
    };
  }

  render() {
    let data
    if (this.state.pointType === "return") {
      data = {
        title: "Return Point Added!",
        subTitleList: [
          'You may select the new return point when submitting return order now.'
        ],
        mainButtonList: [
          {
            link: '/manage-address/',
            text: 'View Return Points'
          }
        ]
      }
    } else {
      data = {
        title: "Pickup Point Added!",
        subTitleList: [
          'You may select the new pickup point when submitting orders now.'
        ],
        mainButtonList: [
          {
            link: '/manage-address/',
            text: 'View Pickup Points'
          }
        ]
      }
    }

    return (
      <div>
        <SubmitSuccessMessage
        title={data.title}
        subTitleList={data.subTitleList}
        mainButtonList={data.mainButtonList}
        />
      </div>
    );
  }
}

function mapStateToProps({ pickupPoint }) {
  return ({
    address_added_type: pickupPoint.address_added_type || "pickup"
  });
}

export default connect(mapStateToProps, {})(AddPickupPointSuccess);
