import React, { useState } from 'react';
import { Modal, Steps, Button } from 'antd';

import step1Image from '../../images/draftOrder/onboard_1.png'
import step2Image from '../../images/draftOrder/onboard_2.png'
import step3Image from '../../images/draftOrder/onboard_3.png'
import step4Image from '../../images/draftOrder/onboard_4.png'
import step5Image from '../../images/draftOrder/onboard_5.png'
import step6Image from '../../images/draftOrder/onboard_6.png'

const stepLabels = [
  'Import all your orders',
  'Add pickup point',
  'Convert orders to Ready to Ship status',
  'Edit error in order creation',
  'Ship orders in bulk',
  'Print and paste labels'
]

const stepSubtitles = [
  'Janio imports and syncs all e-commerce your order.',
  'Click on Manage Addresses to add your pickup point.',
  'Go back to Shopify Page to find your orders and click on Details button to convert your shipment to Ready to Ship status.',
  'Resolve the errors highlighted in the order creation form to proceed.',
  'Once all your orders are inside the Ready to Ship tab, you can multi-select the orders and click on Create Shipments button.',
  'Go to manage orders to print your labels and paste it on your parcels.'
]

const stepImages = [
  step1Image,
  step2Image,
  step3Image,
  step4Image,
  step5Image,
  step6Image,
]

const StepContent = ({step}) => {
  return (
    <div>
      <img src={stepImages[step]} style={{marginBottom: '1.5em', height: '100%', width: '100%'}} alt=""/>
      <h1 style={{fontWeight: 'bold', color: '#1890ff'}}>
        {stepLabels[step]}
      </h1>
      <p style={{width: '400px', textAlign: 'center', margin: '1.5em auto'}}>
        {stepSubtitles[step]}
      </p>
    </div>
  )
}

const STEP_COUNT = 6

export const WelcomeModal = ({dismissModal}) => {
  const [step, setStep] = useState(0)

  const nextStep = () => {
    if(step < STEP_COUNT - 1){
      setStep(step + 1)
    }
    else{
      dismissModal()
    }
  }

  const prevStep = () => {
    if(step > 0){
      setStep(step - 1)
    }
  }

  const modalFooter = (
    <div>
      <Button type="link" style={{float: 'left'}} onClick={dismissModal}>
        Skip tour
      </Button>
      <div style={{float: 'right'}}>
        {step > 0 &&
        <Button type="default" onClick={prevStep}>
          Back
        </Button>
        }
        <Button type="primary" onClick={nextStep}>
          {step === STEP_COUNT - 1 ? 'Close' : 'Next'}
        </Button>
      </div>
      <div style={{clear: 'both'}}></div>
    </div>
  )

  return (
    <Modal visible={true} footer={modalFooter} closable={false} width={600}>
      <div style={{textAlign: 'center'}}>
        <StepContent step={step}/>
      </div>
      <Steps current={step} >
        <Steps.Step key={0} />
        <Steps.Step key={1} />
        <Steps.Step key={2} />
        <Steps.Step key={3} />
        <Steps.Step key={4} />
        <Steps.Step key={5} />
      </Steps>
    </Modal>
  )
}
