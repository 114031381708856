import React, { useState, useEffect } from 'react'
import { Jumbotron } from 'react-bootstrap'
import { Radio } from 'antd'
import { withTranslation, Trans } from 'react-i18next'
import compose from 'recompose/compose'
import { connect } from "react-redux"
import { updateLabelPreferenceApi } from './changeLabelApi'
import { updateAgentAppLabelPreference } from '../../actions/agentAppInfoActions';
import './changeLabelType.scss'


const ChangeLabelType = ({ agentAppDetails, updateAgentAppLabelPreference, onChange }) => {
  const [selectedLabel, setselectedLabel] = useState('default')
  const [showSuccess, setshowSuccess] = useState(false)
  const [showError, setshowError] = useState(false)
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if (!!agentAppDetails.label_preference && agentAppDetails.label_preference !== selectedLabel) {
      setselectedLabel(agentAppDetails.label_preference)
    }
  }, [agentAppDetails.label_preference, selectedLabel])

  const saveLabelPreference = async () => {
    setshowSuccess(false)
    setshowError(false)
    setloading(true)
    const data = await updateLabelPreferenceApi(
      agentAppDetails.agent_application_id,
      agentAppDetails.agent_application_secret_key,
      selectedLabel
    )
    if (!data.isError) {
      updateAgentAppLabelPreference({
        label_preference: selectedLabel
      })
      setshowSuccess(true)
    } else {
      setshowError(true)
    }
    setloading(false)
  }
  
  if (!Object.entries(agentAppDetails).length) {
    return <h5>Loading label preference...</h5>
  }

  return (
    <Jumbotron className="p-4 border border-secondary">
      <div className="h5 font-weight-bold capitalize mb-3"><Trans i18nKey="common:users.label_preference"/></div>
          <Radio.Group 
          value={selectedLabel} 
          onChange={e => {setselectedLabel(e.target.value)}}>
            <div className="d-flex">
              <Radio value="no_invoice">
              </Radio>
              <div>
                <h5 className="font-weight-bold"><Trans i18nKey="common:users.label_no_invoice"/></h5>
                <p className="text-muted"><Trans i18nKey="common:users.label_no_invoice_size"/></p>
              </div>
            </div>
            <div className="d-flex">
              <Radio value="default">
              </Radio>
              <div>
                <h5 className="font-weight-bold"><Trans i18nKey="common:users.label_default"/></h5>
                <p className="text-muted"><Trans i18nKey="common:users.label_default_size"/></p>
              </div>
            </div>
            <div className="d-flex">
              <Radio value="compact">
              </Radio>
              <div>
                <h5 className="font-weight-bold"><Trans i18nKey="common:users.label_compact"/></h5>
                <p className="text-muted"><Trans i18nKey="common:users.label_compact_size"/></p>
              </div>
            </div>
          </Radio.Group>
      {
        showSuccess &&
        <div className="alert alert-success mt-4 mb-0">Update label preference success!</div>
      }
      {
        showError &&
        <div className="alert alert-danger mt-4 mb-0">Error</div>
      }
      
      <button
        type="button"
        className="mt-4 w-100 btn btn-lg btn-success"
        onClick={saveLabelPreference}
        disabled={(agentAppDetails.label_preference === selectedLabel) || loading}
        >
          {loading ? 'Saving...':<Trans i18nKey="common:common.save" />}
      </button>
    </Jumbotron>
  )
}

const mapStateToProps = ({ agentAppDetails }) => ({
  agentAppDetails
});

export default compose(
  connect(mapStateToProps, {
    updateAgentAppLabelPreference
  }),
  withTranslation('common')
)(ChangeLabelType);
