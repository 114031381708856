import { COUNTRY_TO_CURRENCY } from '../../utils/currency'

const FIELD_NAME_MAPPING = {
  'tracking_no': 'Tracking Number',
  'service_id': 'Service Type',
  'shipper_order_id': 'Shipper Order ID',
  'consignee_name': 'Consignee Name',
  'consignee_address': 'Consignee Address',
  'consignee_postal': 'Consignee Postal Code',
  'consignee_country': 'Consignee Country',
  'consignee_state': 'Consignee State',
  'consignee_city': 'Consignee City',
  'consignee_province': 'Consignee Province',
  'consignee_number': 'Consignee Number',
  'consignee_email': 'Consignee Email',
  'order_length': 'Order Length',
  'order_width': 'Order Width',
  'order_height': 'Order Height',
  'order_weight': 'Order Weight',
  'pickup_contact_name': 'Pickup Contact Name',
  'pickup_contact_number': 'Pickup Contact Number',
  'pickup_address': 'Pickup Address',
  'pickup_postal': 'Pickup Postal',
  'pickup_country': 'Pickup Country',
  'pickup_state': 'Pickup State',
  'pickup_city': 'Pickup City',
  'pickup_province': 'Pickup Province',
  'payment_type': 'Payment Type',
  'incoterm': 'Incoterm',
  'delivery_note': 'Delivery Note',
  'pickup_date': 'Pickup Date',
  'cod_amt_to_collect': 'COD Amount to Collect',
}

export const translateErrorField = (field) => {
  if( field in FIELD_NAME_MAPPING ){
    return FIELD_NAME_MAPPING[field];
  }

  return field.replace('_', ' ')
}

export const translateErrorMsg = (field, errorMsg, values) => {
  if(field === 'incoterm'){
    if(errorMsg.includes('null')){
      return errorMsg.replace('null', 'None')
    }
  }

  if(field === 'cod_amt_to_collect'){
    if(values[field] === null && values['ref_cod_amt_to_collect']){
      const merchantCurrency = values['ref_cod_currency'];
      const merchantCodAmt = values['ref_cod_amt_to_collect']
      const localCurrency = COUNTRY_TO_CURRENCY[values.consignee_country]
      const formattedAmt = merchantCodAmt
      return `Please convert COD amount ${merchantCurrency}${formattedAmt} to local currency (${localCurrency})`
    }
  }

  if(field === 'payment_type'){
    if(errorMsg.includes('Payment Type of "COD" is not available')){
      return `Janio does not provide COD service in ${values['consignee_country']}.`
    }
  }

  if(field === 'service_type'){
    if(errorMsg.includes('is not available for this location')){
      return `${values[field]} service is not available. Please choose another service type.`
    }
  }

  return errorMsg
}
