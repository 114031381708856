import { useState, useEffect } from 'react'
import axios from 'axios'

const useStoreAddresses = (type="pickup", params={}, shouldFetch=true) => {
  const [addresses, setAddresses] = useState([])

  const token = localStorage.getItem('token')
  const getAddresses = async () => {
    const url = `/api/shipper/${type}-point/`
    const response = await axios.get(url, {
      params,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + token
      }
    })
    setAddresses(response.data)
  }

  useEffect(() => {
    if (shouldFetch) {
      getAddresses()
    }
  }, [shouldFetch])

  return [addresses, setAddresses]
}

export default useStoreAddresses