import React from 'react';
import moment from 'moment';
import {
  Card,
  Row, Col,
  Descriptions as Desc
} from 'antd';
import { ServiceSelector } from './ServiceSelector'

const LocationViewer = ({ values }) => {
  const title =
    values.service_type === "Dropoff" ? "Dropoff Location" : "Pickup Location";
  return (
    <Desc title={title}>
      <Desc.Item label="Contact Person">{values.pickup_contact_name}</Desc.Item>
      <Desc.Item label="Contact Number">
        {values.pickup_contact_number}
      </Desc.Item>
      <Desc.Item label="Address">{values.pickup_address}</Desc.Item>
      <Desc.Item label="City">{values.pickup_city}</Desc.Item>
      <Desc.Item label="Postal">{values.pickup_postal}</Desc.Item>
      <Desc.Item label="State">{values.pickup_state}</Desc.Item>
      <Desc.Item label="Country">{values.pickup_country}</Desc.Item>
    </Desc>
  );
};

export const EmbeddedServiceSelector = ({ formikProps }) => {
  const { setFieldValue, values, errors } = formikProps;

  const onServiceChange = (origin, dest, serviceResult) => {
    if (serviceResult.hasService) {
      setFieldValue("service_type", serviceResult.serviceType);
      setFieldValue("service_id", serviceResult.serviceId);
      if (serviceResult.serviceType === "Dropoff") {
        setFieldValue(
          "extra_data.dropoff_id",
          serviceResult.serviceDefinition.dropoff_id
        );
        setFieldValue("extra_data.pickup_point_id", null);
        setFieldValue("pickup_date", null);
        setFieldValue(
          "pickup_contact_name",
          serviceResult.serviceDefinition.dropoff_point_contact_person
        );
        setFieldValue(
          "pickup_contact_number",
          serviceResult.serviceDefinition.dropoff_point_number
        );
        setFieldValue(
          "pickup_address",
          serviceResult.serviceDefinition.dropoff_address
        );
        setFieldValue(
          "pickup_postal",
          serviceResult.serviceDefinition.dropoff_postal
        );
        setFieldValue(
          "pickup_state",
          serviceResult.serviceDefinition.dropoff_point_state
        );
        setFieldValue(
          "pickup_city",
          serviceResult.serviceDefinition.dropoff_point_city
        );
        setFieldValue(
          "pickup_province",
          serviceResult.serviceDefinition.dropoff_point_province
        );
      } else if (serviceResult.serviceType === "Pickup") {
        setFieldValue("extra_data.dropoff_id", null);
        setFieldValue(
          "extra_data.pickup_point_id",
          serviceResult.selectedPickup.pickup_point_id
        );
        setFieldValue(
          "pickup_contact_name",
          serviceResult.selectedPickup.pickup_point_contact_person
        );
        setFieldValue(
          "pickup_contact_number",
          serviceResult.selectedPickup.pickup_point_number
        );
        setFieldValue(
          "pickup_address",
          serviceResult.selectedPickup.pickup_point_address
        );
        setFieldValue(
          "pickup_postal",
          serviceResult.selectedPickup.pickup_point_postal
        );
        setFieldValue(
          "pickup_state",
          serviceResult.selectedPickup.pickup_point_state
        );
        setFieldValue(
          "pickup_city",
          serviceResult.selectedPickup.pickup_point_city
        );
        setFieldValue(
          "pickup_province",
          serviceResult.selectedPickup.pickup_point_province
        );
      } else {
        setFieldValue("extra_data.dropoff_id", null);
        setFieldValue("extra_data.pickup_point_id", null);
        setFieldValue("pickup_date", null);
        setFieldValue("pickup_contact_name", null);
        setFieldValue("pickup_contact_number", null);
        setFieldValue("pickup_address", null);
        setFieldValue("pickup_postal", null);
        setFieldValue("pickup_state", null);
        setFieldValue("pickup_city", null);
        setFieldValue("pickup_province", null);
      }
    } else {
      setFieldValue("extra_data.dropoff_id", null);
      setFieldValue("extra_data.pickup_point_id", null);
      setFieldValue("service_type", serviceResult.serviceType);
      setFieldValue("service_id", null);
      setFieldValue("pickup_date", null);
      setFieldValue("pickup_contact_name", null);
      setFieldValue("pickup_contact_number", null);
      setFieldValue("pickup_address", null);
      setFieldValue("pickup_postal", null);
      setFieldValue("pickup_state", null);
      setFieldValue("pickup_city", null);
      setFieldValue("pickup_province", null);
    }
  };

  const onPickupDateChange = (val) => {
    if (val) {
      setFieldValue("pickup_date", val.format("YYYY-MM-DD"));
    } else {
      setFieldValue("pickup_date", val);
    }
  };

  const onPickupCountryChange = (val) => {
    setFieldValue("pickup_country", val);
    setFieldValue("extra_data.dropoff_id", null);
    setFieldValue("extra_data.pickup_point_id", null);
    setFieldValue("service_type", null);
    setFieldValue("service_id", null);
    setFieldValue("pickup_date", null);
    setFieldValue("pickup_contact_name", null);
    setFieldValue("pickup_contact_number", null);
    setFieldValue("pickup_address", null);
    setFieldValue("pickup_postal", null);
    setFieldValue("pickup_state", null);
    setFieldValue("pickup_city", null);
    setFieldValue("pickup_province", null);
  };
  // const pickUpPointId = () => {
  //   return JSON.parse(values.extra_data).pickup_point_id
  // }
  // const dropOffPointId = () => {
  //   return JSON.parse(values.extra_data).dropoff_id
  // }
  return (
    <Card
      title={`${values.pickup_country ? values.pickup_country : "-"} to ${
        values.consignee_country
      }`}
      style={{ marginTop: "12px", marginBottom: "12px" }}
    >
      <Row gutter={8}>
        <Col span={12}>
          <ServiceSelector
            errors={errors}
            pickupDate={
              values.pickup_date && moment(values.pickup_date, "YYYY-MM-DD")
            }
            onPickupDateChange={onPickupDateChange}
            originCountry={values.pickup_country}
            destinationCountry={values.consignee_country}
            defaultServiceType={values.service_type}
            defaultDropoffId={values.extra_data && values.extra_data.dropoff_id}
            defaultPickupPointId={
              values.extra_data && values.extra_data.pickup_point_id
            }
            onPickupCountryChange={onPickupCountryChange}
            // defaultDropoffId={values.extra_data && parseInt(dropOffPointId())}
            // defaultPickupPointId={values.extra_data && parseInt(pickUpPointId())}
            onServiceChange={onServiceChange}
          />
        </Col>
        <Col span={12}>
          {values.service_type && values.service_id && (
            <LocationViewer values={values} />
          )}
        </Col>
      </Row>
    </Card>
  );
};
