import {
  FETCH_LANGUAGES,
  FETCH_SHIPPER_DETAILS,
  EDIT_SHIPPER_DETAILS,
  CLEAR_SHIPPER_DETAILS_ERRORS,
  FETCH_SHIPPER_DETAILS_LOADING
} from '../actions/types';
import i18next from "i18next";

export default function(state = {
  loading: false
}, action) {
  switch(action.type) {
    case FETCH_LANGUAGES:
      return { ...state, languages: action.payload, loading: false };
    case FETCH_SHIPPER_DETAILS:
      const { language } = action.payload
      if (!!language) {
        localStorage.setItem("lang", language)
        i18next.changeLanguage(language)
      }
      return { ...state, shipperDetails: action.payload, loading: false };
    case FETCH_SHIPPER_DETAILS_LOADING:
      return {...state, loading: true}
    case EDIT_SHIPPER_DETAILS:
      return { ...state, error: action.payload, loading: false };
    case CLEAR_SHIPPER_DETAILS_ERRORS:
      return { ...state, error: null, loading: false };
    default:
      return state;
  }
}
