import React, { useState, useEffect } from 'react';
import { Table, Card, Button, Modal, Tabs, Spin, Row, Col } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { getDraftOrders, deleteDraftOrders, applySyncRule } from './store';
import { getFilterData } from './channelStore';
import { StatusTag } from './statusTag';
import { Alert } from 'antd';
import { WelcomeModal } from './welcomeModal';
import { EmptyChannel } from './components/EmptyChannel';

const COLUMNS = [
  {
    title: 'Shop Name',
    dataIndex: 'channel.channel_name',
    filters: [],
  },
  {
    title: 'Order ID',
    dataIndex: 'shipper_order_id',
    render: (text, record) => (record.shipper_order_id || record.channel_order_id || '')
  },
  {
    title: 'Consignee',
    dataIndex: 'consignee_name',
  },
  {
    title: 'Consignee Country',
    dataIndex: 'consignee_country',
  },
  {
    title: 'Shipment Type',
    dataIndex: 'service_level',
  },
  {
    title: 'Payment Status',
    dataIndex: 'payment_status',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (text, record) => (<StatusTag>{text}</StatusTag>)
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      if(record.order_id){
        return (
          <Link to={"/view-orders/"}>
            <Button type="default">View Order</Button>
          </Link>
        );
      }
      return (
        <Link to={"/view-draft-order-details/"+record.id}>
          <Button type="primary">Update Info</Button>
        </Link>
      );
    }
  }
];

const getColumnData = (filterData, appliedFilters) => {
  let columns = COLUMNS
  columns[0].filters = filterData.channels.map(c => ({text: c.channel_name, value: c.id}))
  if(appliedFilters['channel.channel_name']){
    columns[0].filteredValue = appliedFilters['channel.channel_name']
  }

  columns[3].filters = filterData.countries.map(c => ({text: c, value: c}))
  if(appliedFilters['consignee_country']){
    columns[3].filteredValue = appliedFilters['consignee_country']
  }

  // Shipment Type
  columns[4].filters = filterData.service_levels.map(c => ({text: c, value: c}))
  if(appliedFilters['service_level']){
    columns[4].filteredValue = appliedFilters['service_level']
  }
  
  // Payment Status
  columns[5].filters = filterData.payment_statuses && filterData.payment_statuses.map(c => ({text: c, value: c}))
  if(appliedFilters['payment_status']){
    columns[5].filteredValue = appliedFilters['payment_status']
  }

  // Status
  columns[6].filters = filterData.other_statuses && filterData.other_statuses.map(c => ({text: c, value: c}))
  if(appliedFilters['status']){
    columns[6].filteredValue = appliedFilters['status']
  }
  return columns
}

const constructLane = (origin, destination) => `${origin}-${destination}`
const encodeSelectedOrders = (orders) => {
  const lanesCounter = {};
  orders.forEach((order) => {
    const lane = constructLane(order.pickup_country, order.consignee_country)
    if(lane in lanesCounter){
      lanesCounter[lane]++
    }else{
      lanesCounter[lane] = 1
    }
  });

  const payload = {
    'orderIds': orders.map(o => o.id.toString()),
    'lanesCounter': lanesCounter
  }

  return btoa(JSON.stringify(payload))
}

const tabStatusMapping = {
  'ready': 'READY_TO_SHIP',
  'not_ready': 'ERROR,NEW',
  'all': null
}

const getTableHeader = (activeTab, orders, selectedOrders, totalCount) => {
  if(activeTab === 'ready'){
    if(selectedOrders.length){
      return `${selectedOrders.length} orders selected`
    }
    return 'Select the orders to ship with Janio'
  }
  return `${totalCount} orders`
}

export const LViewDraftOrders = ({history}) => {
  const params = new URLSearchParams(history.location.search);
  const tabParams = params.get('tab') || 'not_ready';

  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [filters, setFilters] = useState({})
  const [selectedOrders, setSelectedOrders] = useState([])
  const [activeTab, setActiveTab] = useState(tabParams)
  const [statuses, setStatuses] = useState(tabStatusMapping[activeTab])
  const [isFromShopify, setIsFromShopfy] = useState(localStorage.getItem('from_shopify') === 'true')
  const [showSyncModal, setSyncModal] = useState(false)

  const onSwitchPage = (page) => {
    setPage(page);
  }

  const onPageSizeChange = (current, pageSize) => {
    setPage(1)
    setPageSize(pageSize)
  }

  const {secretKey} = useSelector( (state) => {
    if(state.shipperDetails && state.shipperDetails.shipperDetails){
      return {
        secretKey: state.shipperDetails.shipperDetails.agent_application_secret_key,
      }
    }
    return {secretKey: null};
  });
  const {entries, isLoading, totalCount} = useSelector((state) => {
    return {
      entries: state.draftOrders.entries.data,
      isLoading: state.draftOrders.entries.loading,
      totalCount: state.draftOrders.entries.pagination.totalCount,
    };
  });
  const filterData = useSelector((state) => {
    return state.channels.filterData
  });

  useEffect(() => {
    if(secretKey){
      const channelFilters = filters['channel.channel_name'] && filters['channel.channel_name'].join(',')
      const countryFilters = filters['consignee_country'] && filters['consignee_country'].join(',')
      const serviceLevelFilters = filters['service_level'] && filters['service_level'].join(',')
      const paymentStatusFilters = filters['payment_status'] && filters['payment_status'].join(',')
      const statusFilters = filters['status'] && filters['status'].join(',');
      const params = {
        page: page,
        page_size: pageSize,
        status: statuses,
        channel_ids: channelFilters,
        consignee_countries: countryFilters,
        service_levels: serviceLevelFilters,
        payment_statuses: paymentStatusFilters,
        other_status: statusFilters,
      }
      dispatch(getDraftOrders(secretKey, params));
    }

    if(secretKey && !filterData.loaded){
      dispatch(getFilterData(secretKey, {}));
    }
  }, [secretKey, filters, statuses, page, pageSize, filterData.loaded, dispatch]);

  const dismissWelcomeModal = () => {
    localStorage.setItem('from_shopify', 'false')
    setIsFromShopfy(false)
  }

  const onCreateShipmentsClick = () => {
    const payload = encodeSelectedOrders(selectedOrders)
    history.push('/create-shipments/' + payload)
  }

  const CreateShipmentButton = () => {
    if(activeTab === 'ready'){
      return (
        <Button
          className="ml-2"
          onClick={onCreateShipmentsClick}
          type="primary"
          disabled={selectedOrders.length < 1}>
          Ship with Janio
        </Button>
      )
    }
    return null
  }

  let errmsg = null;
  if (localStorage.getItem("shopify_err") !== null) {
    errmsg = <Alert
      message="Shopify Store Connection"
      description={localStorage.getItem("shopify_err")}
      type="error"
      closable
    />
  }

  const onTabChange = (tabKey) => {
    setActiveTab(tabKey)
    history.push({
      pathname: history.location.pathname,
      search: '?tab='+tabKey
    })

    setStatuses(tabStatusMapping[tabKey])
  }

  const onTableChange = (pagination, newFilter) => {
    setFilters({...filters, ...newFilter})
  }

  if(isLoading || ! filterData.loaded){
    return (
      <div style={{textAlign: 'center', margin: '3em'}}>
        <Spin size="large"/>
      </div>
    )
  }

  if(filterData.loaded && filterData.data.channels.length < 1){
    return <EmptyChannel />
  }

  // const EnvCheck = () => {
  //   const isDev = window.location.origin.includes('localhost') || window.location.origin.includes('staging')
  //   if(isDev){
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  const TableButtons = () => {
    return (
      <div>
        <Button onClick={()=>{setSyncModal(true)}} type="primary" >Sync shipment rule</Button>
        <Button 
        disabled={selectedOrders.length < 1}
        onClick={()=>{
          dispatch(deleteDraftOrders(
            secretKey, {"draft_order_ids": selectedOrders.map((order) => order.id) }, function() {
              dispatch(getDraftOrders(secretKey, {page: 1, status: "READY_TO_SHIP"}));
            }));
        }} type="danger" style={{marginLeft: 10}}>Delete</Button>
        <CreateShipmentButton/>
      </div>
    )
  }


  const ordersTable = (
    <Card title={getTableHeader(activeTab, entries, selectedOrders, totalCount)}
      extra={activeTab === 'ready' && <TableButtons /> }
      >
      <Table
        rowSelection={activeTab === 'not_ready' ? null : {
          type: 'checkbox',
          onChange: (selectedRowKeys, selectedRows) => (setSelectedOrders(selectedRows)),
          getCheckboxProps: record => ({disabled: record.status !== 'READY_TO_SHIP'})
        }}
        rowKey="id"
        dataSource={entries}
        onChange={onTableChange}
        pagination={{
          onChange: onSwitchPage,
          total: totalCount,
          showSizeChanger: true,
          defaultPageSize: pageSize,
          current: page,
          pageSize: pageSize,
          onShowSizeChange: onPageSizeChange,
          pageSizeOptions: ['20', '50', '100']
        }}
        columns={getColumnData(filterData.data, filters)}
        loading={isLoading} />
    </Card>
  )

  return (
    <>
      {errmsg}
      {isFromShopify && <WelcomeModal dismissModal={dismissWelcomeModal}/>}
      <div>
        <button
          onClick={() => window.location.reload()}
          type="button"
          className="btn btn-primary mb-1">
          Refresh
        </button>
      </div>
      <Alert
        type="info"
        message="Thank you for installing our Janio Shopify App!"
        description={
          <span>If you run into any trouble while creating your shipments and need help, you can read up on our detailed guide <a target="_blank" rel="noopener noreferrer"  href="https://support.janio.asia/hc/en-us/articles/360048618351-Janio-Shopify-Help-guide-">here</a></span>
        }/>
      <Tabs defaultActiveKey={activeTab} onChange={onTabChange}>
        <Tabs.TabPane tab="Not Ready" key="not_ready">
          {ordersTable}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Ready to Ship" key="ready">
          {ordersTable}
        </Tabs.TabPane>
        <Tabs.TabPane tab="All" key="all">
          {ordersTable}
        </Tabs.TabPane>
      </Tabs>
      <Modal title="Are you sure you would like to sync shipment rules ?" visible={showSyncModal} onCancel={()=>{setSyncModal(false)}} footer={null}>
          <Row>
            <Col span={24} align="middle">
              <h6 style={{marginBottom: '20px'}}>Orders will be updated with your new shipment rules</h6>
              <Button onClick={()=>{setSyncModal(false)}} >Cancel</Button>
              <Button type="primary" style={{marginLeft: '10px'}} onClick={() => {dispatch(applySyncRule(secretKey, {}));setSyncModal(false)}}>Apply rules</Button>
            </Col>
          </Row>
      </Modal>
    </>
  );
}

export const ViewDraftOrders = withRouter(LViewDraftOrders)
