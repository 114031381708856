import React, { useEffect } from "react";
import { Table, Button, } from 'antd'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getChannels } from '../draftOrder/channelStore'
import { useSecretKey } from '../auth/useSecretKey'
import moment from 'moment';
import './manage_channels.scss'

const COLUMNS = [
  {
    title: 'Platform',
    dataIndex: 'channel_type',
  },
  {
    title: 'Shop Name',
    dataIndex: 'shop_name',
  },
  {
    title: 'Channel Name',
    dataIndex: 'channel_name',
  },
  {
    title: 'Added on',
    dataIndex: 'created_on',
    render: (date) => (<span>{moment(date).format('DD/MM/YY HHmm[HRS]')}</span>)
  },
];

export const ManageChannels = ({shipperDetails}) => {
  const dispatch = useDispatch()
  const [secretKey] = useSecretKey()

  const channelsStore = useSelector((state) => {
    return state.channels.entries
  });

  useEffect(() => {
    if(secretKey && !channelsStore.loaded){
      dispatch(getChannels(secretKey, {}));
    }
  }, [secretKey]);

  return (
    <div>
      <div className="link-container">
        <Link to={"/add-channel/"}>
          <Button type="primary">Add Store</Button>
        </Link>
      </div>
      <Table
        rowKey="id"
        dataSource={channelsStore.data}
        columns={COLUMNS}
        loading={channelsStore.loading} />
    </div>
  );
}
