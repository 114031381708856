import React from 'react'
import store from 'store'
import Chip from '@material-ui/core/Chip'


export const SeenOnce = ({ featureName, validUntil, children }) => {
  let shouldShow
  const validUntilDate = new Date(validUntil)
  const today = new Date()
  const isPassed = today > validUntilDate
  const seenFeatures = store.get('seenFeatures', [])
  if (seenFeatures.includes(featureName) || isPassed) {
    shouldShow = false
  } else {
    shouldShow = true
  }

  return shouldShow ? children:null
}

export const NewFeatureBadge = ({ label, featureName, validUntil }) => {
  return (
    <SeenOnce featureName={featureName} validUntil={validUntil}>
      <Chip label={
        <span style={{
          fontSize: 12
        }}>{label || 'New'}</span>
      } color="secondary" style={{
        height: 24,
      }} />
    </SeenOnce>
  )
}

export const addSeenFeature = featureName => {
  const seenFeatures = store.get('seenFeatures', [])
  if (!seenFeatures.includes(featureName)) {
    store.set('seenFeatures', [...seenFeatures, featureName])
  }
}

export default NewFeatureBadge