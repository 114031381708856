import { useEffect } from 'react'
import { useFetch } from 'use-http'


const token = localStorage.getItem('token')

const useServiceDef = (secretKey, additionalFilters) => {
  const {get, data, loading} = useFetch(`/api/order/service-definitions/?${new URLSearchParams({
    secret_key: secretKey,
    ...(additionalFilters ?? {})
  })}`, {
    data: [],
    headers: {
      Authorization: `Token ${token}`
    }
  })

  useEffect(() => {
    if (secretKey) {
      get()
    }
  }, [get, secretKey])

  return [data, loading]
}

export default useServiceDef