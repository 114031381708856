import { Alert, Button, Divider, Form, Input } from "antd";
import i18n from "i18next";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import backgroundImg from "../../images/janio-login-background.png";
import chineseImg from "../../images/janio-main-logo-chinese.png";
import img from "../../images/janio-main-logo-new.png";
import createStoreOrderApi from "./api";

const MetroBlackFridayForm = (props) => {
  const [formValues, setFormValues] = useState({
    address: undefined,
    postalCode: undefined,
    phoneNumber: undefined,
    email: undefined,
    metroReceiptNo: undefined,
    items: undefined,
    receiptValue: undefined,
    name: undefined,
    agentId: undefined,
  });

  const { data, refetch } = useQuery(
    "createStoreOrder",
    async () =>
      await createStoreOrderApi({
        name: formValues.name,
        address: formValues.address,
        postal_code: formValues.postalCode,
        ph_number: formValues.phoneNumber,
        email: formValues.email,
        receipt_no: formValues.metroReceiptNo,
        item_qty: Number(formValues.items),
        receipt_val: Number(formValues.receiptValue),
        agent_id: formValues.agentId,
      }),
    { refetchOnWindowFocus: false, refetchOnMount: false, enabled: false }
  );

  useEffect(() => {
    const agentId = parseInt(window.location.href.substr(window.location.href.lastIndexOf("/") + 1));
    setFormValues({ ...formValues, agentId });
  }, []);

  const { getFieldDecorator, resetFields } = props.form;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    // console.log(formValues);
    refetch();
  };

  useEffect(() => {
    const agentId = parseInt(window.location.href.substr(window.location.href.lastIndexOf("/") + 1));
    if (data && !data.isError) {
      resetFields();
      setFormValues({
        agentId,
        address: undefined,
        postalCode: undefined,
        phoneNumber: undefined,
        email: undefined,
        metroReceiptNo: undefined,
        items: undefined,
        receiptValue: undefined,
        name: undefined,
      });
    }
  }, [data]);

  return (
    <div className="d-flex flex-column align-items-center vh-100" style={{ backgroundColor: "#F4FDFC", overflow: "auto" }}>
      <div className="d-flex flex-column align-items-center w-100 mb-2">
        <img src={i18n.language.includes("ch") ? chineseImg : img} className="logo-container img-fluid mb-2 mt-4 pt-4" alt="" />
      </div>

      {data?.tracking_no ? (
        <div className="content-container card mt-4 mb-5 p-5 d-flex align-items-center" style={{ zIndex: 1 }}>
          <h4 className="font-weight-bold" style={{ letterSpacing: "1px" }}>
            Thank You!
          </h4>
          <div className="font-weight-bold mt-4" style={{ letterSpacing: "1px", textAlign: "center" }}>
            We have created a shipment for you with Tracking Number
          </div>
          <h3 className="mt-4" style={{ color: "#28a745" }}>
            {data.tracking_no}
          </h3>
          <div className="font-weight-bold mt-4" style={{ letterSpacing: "1px", textAlign: "center" }}>
            Please note down the tracking number or take a screenshot for reference
          </div>
        </div>
      ) : (
        <div className="content-container card mt-4 mb-5 p-5" style={{ zIndex: 1 }}>
          {data?.errorData && data?.isError && (
            <>
              {data.errorData.map((err) => (
                <Alert className="mb-2" message={err} type="error" />
              ))}
            </>
          )}

          <div className="d-flex align-items-center justify-content-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h4 className="font-weight-bold" style={{ letterSpacing: "1px" }}>
                {formValues.agentId === 2522 && "METRO PARAGON"}
                {formValues.agentId === 5658 && "METRO CAUSEWAY POINT"}
              </h4>
              <h5 className="font-weight-bold" style={{ letterSpacing: "1px" }}>
                {/* <Trans i18nKey="common:signin.merchantLogin" /> */}
                ORDER DETAILS
              </h5>
            </div>
          </div>
          <Divider style={{ marginTop: "5px", marginBottom: "15px" }} />
          <Form onChange={handleOnChange} onSubmit={handleSubmit} hideRequiredMark>
            <div>
              <h5 className="font-weight-bold">Full Name</h5>
              <Form.Item>
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "Please enter name" }],
                })(<Input name="name" size="large" placeholder="Enter name..." />)}
              </Form.Item>
            </div>
            <div className="mt-3">
              <h5 className="font-weight-bold">Full Address</h5>
              <Form.Item>
                {getFieldDecorator("address", {
                  rules: [{ required: true, message: "Please enter address" }],
                })(<Input.TextArea name="address" rows={3} placeholder="Enter address..." />)}
              </Form.Item>
            </div>
            <div className="mt-3">
              <h5 className="font-weight-bold">Postal Code</h5>
              <Form.Item>
                {getFieldDecorator("postalCode", {
                  rules: [
                    { min: 6, max: 6, message: "Postal code should be 6 digits" },
                    { required: true, message: "Please enter postal code" },
                  ],
                })(<Input type="number" name="postalCode" placeholder="Enter postal code..." />)}
              </Form.Item>
            </div>
            <div className="mt-3">
              <h5 className="font-weight-bold">Telephone Number</h5>
              <Form.Item>
                {getFieldDecorator("phoneNumber", {
                  rules: [
                    { min: 8, max: 8, message: "Phone number should be 8 digits" },
                    { required: true, message: "Please enter phone number" },
                  ],
                })(<Input type="number" name="phoneNumber" placeholder="Enter phone number..." />)}
              </Form.Item>
            </div>
            <div className="mt-3">
              <h5 className="font-weight-bold">Email Address</h5>
              <Form.Item>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      type: "email",
                      message: "Enter a valid email address",
                    },
                    {
                      required: false,
                    },
                  ],
                })(<Input name="email" size="large" placeholder="Enter email..." />)}
              </Form.Item>
            </div>
            <div className="mt-3">
              <h5 className="font-weight-bold">Metro Receipt Number</h5>
              <Form.Item>
                {getFieldDecorator("metroReceiptNo", {
                  rules: [{ required: true, message: "Please enter receipt number" }],
                })(<Input name="metroReceiptNo" size="large" placeholder="Enter receipt number..." />)}
              </Form.Item>
            </div>
            <div className="mt-3">
              <h5 className="font-weight-bold">No. of Items</h5>
              <Form.Item>
                {getFieldDecorator("items", {
                  rules: [{ required: true, message: "Please enter number of items between 1-50", pattern: new RegExp(/^\b([1-9]|[1234][0-9]|50)\b/) }],
                })(<Input type="number" name="items" size="large" placeholder="Enter items number..." />)}
              </Form.Item>
            </div>
            <div className="mt-3">
              <h5 className="font-weight-bold">Receipt Value (SGD)</h5>
              <Form.Item>
                {getFieldDecorator("receiptValue", {
                  rules: [{ required: true, message: "Please enter price" }],
                })(<Input type="number" name="receiptValue" size="large" className="w-100" placeholder="Enter price..." />)}
              </Form.Item>
            </div>
          </Form>
          <Button
            shape="round"
            size="large"
            style={{ backgroundColor: "#0DC9C5", borderColor: "#0DC9C5", height: "50px", color: "white" }}
            className="w-100 mt-3"
            type="primary"
            onClick={handleSubmit}
            disabled={
              !formValues.name ||
              !formValues.address ||
              !formValues.postalCode ||
              !formValues.phoneNumber ||
              !formValues.metroReceiptNo ||
              !formValues.items ||
              !formValues.receiptValue
            }
          >
            Submit
          </Button>
        </div>
      )}

      <div className="login-image w-100">
        <img style={{ height: "100%", width: "100%", backgroundSize: "cover" }} src={backgroundImg} alt="" />
      </div>
    </div>
  );
};

export default Form.create({ name: "MetroBlackFridayForm" })(MetroBlackFridayForm);
