import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Jumbotron } from 'react-bootstrap';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { fetchAllCountries } from '../../actions/orderActions';
import { clearConsigneeErrors, editConsigneeAddress, fetchConsigneeAddressById } from '../../actions/consigneeAddressActions';
import { validate } from '../../utils/validatorUtils';
import compose from 'recompose/compose';
import DropdownField from '../common/DropdownField';
import InputField from '../common/InputField';
import axios from 'axios';
import _ from 'lodash';

const styles = (theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
  },
});

class EditConsigneeAddress extends Component {
  constructor(props) {
    super(props);
    const { match: { params } } = props
    this.state = {
      id: params.id,
      error: true,
      errorData: null,
      status: null,
      updatedStatus: false,
      anchorEl: null,
      stateLoading: false,
      cityLoading: false,

      consigneeAddressId: 0,
      consigneeName: '',
      consigneeEmail: '',
      consigneeNumber: '',
      consigneeAddress: '',
      consigneeCountry: '',
      consigneeState: '',
      consigneeCity: '',
      consigneePostal: ''
    };
  }

  componentDidMount() {
    this.props.fetchConsigneeAddressById(this.state.id);

    if (this.props.countries === undefined) {
      this.props.fetchAllCountries();
    }
  }

  componentWillUnmount() {
    this.props.clearConsigneeErrors();
  }

  componentDidUpdate() {
    if (this.props.consigneeAddress && !this.state.consigneeName) {
      this.setState({
        consigneeAddressId: this.props.consigneeAddress.id,
        consigneeName: this.props.consigneeAddress.contact_name,
        consigneeEmail: this.props.consigneeAddress.contact_email,
        consigneeNumber: this.props.consigneeAddress.contact_number,
        consigneeAddress: this.props.consigneeAddress.address,
        consigneeCountry: this.props.consigneeAddress.country,
        consigneeState: this.props.consigneeAddress.state,
        consigneeCity: this.props.consigneeAddress.city,
        consigneePostal: this.props.consigneeAddress.postal
      });
    }

    if (this.props.error !== this.state.error) {
      this.setState({
        error: this.props.error
      });
    }

    if (this.props.errorData !== this.state.errorData) {
      this.setState({
        errorData: this.props.errorData
      });
    }

    if (this.props.status !== this.state.status) {
      this.setState({
        status: this.props.status
      });
    }
  }

  handlePopoverOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  renderCountries = () => {
    let option = [{
      value: 'select one...',
      label: 'select one...'
    }];

    _.map(this.props.countries, (item, i) => {
      let data = {
        value: item,
        label: item
      }
      option.push(data);
    });

    return option;
  }

  renderStates = () => {
    let option = [{
      value: 'select one...',
      label: 'select one...'
    }];

    _.map(this.state.states, (item, i) => {
      let data = {
        value: item.state_name,
        label: item.state_name
      }
      option.push(data);
    });

    return option;
  }

  renderCities = () => {
    let option = [{
      value: 'select one...',
      label: 'select one...'
    }];

    _.map(this.state.cities, (item, i) => {
      let data = {
        value: item.city_name,
        label: item.display_name
      }
      option.push(data);
    });

    return option;
  }

  handleOnChange(e) {
    this.props.clearConsigneeErrors();
    this.setState({
      updatedStatus: true
    });

    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  fetchStates = () => {
    this.setState({ stateLoading: true });
    const url = '/api/location/states/'
    axios.get(`${url}?countries=${this.state.consigneeCountry}`)
      .then((response) => {
        this.setState({ stateLoading: false })
        console.log(response)
        this.setState({ states: response.data })
      })
      .catch((error) => {
        this.setState({ stateLoading: false })
        console.log(error.response.data)
      });
  }

  fetchCities = () => {
    this.setState({ cityLoading: true });
    const url = '/api/location/cities/'
    axios.get(`${url}?countries=${this.state.consigneeCountry}&states=${this.state.consigneeState}`)
      .then((response) => {
        this.setState({ cityLoading: false })
        console.log(response)
        this.setState({ cities: response.data })
      })
      .catch((error) => {
        this.setState({ cityLoading: false })
        console.log(error.response.data)
      });
  }

  handleDropdownChange = (e, type) => {

    if (type === 'consigneeCountry') {
      this.setState({
        consigneeState: '',
        consigneeCity: ''
      })
      const selectedPickupPointCountry = e.value;

      if (selectedPickupPointCountry !== 'select one...') {
        this.setState({
          consigneeCountry: selectedPickupPointCountry,
          updatedStatus: true
        }, this.fetchStates);
      } else {
        this.setState({
          consigneeCountry: '',
          updatedStatus: true
        });
      }
    }
    else if (type === 'consigneeState') {
      this.setState({
        consigneeCity: ''
      })
      const selectedPickupPointState = e.value;

      if (selectedPickupPointState !== 'select one...') {
        this.setState({
          consigneeState: selectedPickupPointState,
          updatedStatus: true
        }, this.fetchCities);
      } else {
        this.setState({
          consigneeState: '',
          updatedStatus: true
        });
      }
    }
    else {
      const selectedPickupPointCity = e.value;

      if (selectedPickupPointCity !== 'select one...') {
        this.setState({
          consigneeCity: selectedPickupPointCity,
          updatedStatus: true
        });
      } else {
        this.setState({
          consigneeCity: '',
          updatedStatus: true
        });
      }
    }
  }
  handleEdit = () => {
    this.props.clearConsigneeErrors();

    const validatorList = [
      { fieldName: 'consigneeName', optional: false, type: 'text' },
      { fieldName: 'consigneeAddress', optional: false, type: 'text' },
      { fieldName: 'consigneeCountry', optional: false, type: 'text' },
      { fieldName: 'consigneeState', optional: false, type: 'text' },
      { fieldName: 'consigneeCity', optional: false, type: 'text' },
      { fieldName: 'consigneePostal', optional: false, type: 'text' },
      { fieldName: 'consigneeEmail', optional: false, type: 'email' },
      { fieldName: 'consigneeNumber', optional: false, type: 'number' }
    ];
    const canSubmit = validate(validatorList, this.state, true);

    if (canSubmit) {
      let data = {
        'name': this.state.consigneeName,
        'contact_name': this.state.consigneeName,
        'contact_number': this.state.consigneeNumber,
        'contact_phone': this.state.consigneeNumber,
        'contact_email': this.state.consigneeEmail,
        'address': this.state.consigneeAddress,
        'country': this.state.consigneeCountry,
        'state': this.state.consigneeState,
        'city': this.state.consigneeCity,
        'postal': this.state.consigneePostal
      };
      this.props.editConsigneeAddress(this.state.consigneeAddressId, data);
      this.setState({
        updatedStatus: false
      });
    }
  }

  render() {
    let renderDiv = null;
    let error = this.props.error
    let errorData = this.props.errorData
    renderDiv = (
      <div className="w-100 mt-3 container max-width-40" style={{ padding: '10px' }}>
        <Jumbotron className="p-4 border border-secondary">
          <InputField fieldName='consignee_name' i18nKey='pickupPoints.consigneeName' placeholder='pickupPoints.consigneeNamePlaceholder' name='consigneeName' type='text' stateValue={this.state.consigneeName} onChange={this.handleOnChange.bind(this)} />
          <InputField fieldName='consignee_email' i18nKey='pickupPoints.consigneeEmail' placeholder='pickupPoints.consigneeEmailPlaceholder' name='consigneeEmail' type='text' stateValue={this.state.consigneeEmail} onChange={this.handleOnChange.bind(this)} />
          <InputField fieldName='consignee_number' i18nKey='pickupPoints.consigneeNumber' placeholder='pickupPoints.consigneeNumberPlaceholder' name='consigneeNumber' type='text' stateValue={this.state.consigneeNumber} onChange={this.handleOnChange.bind(this)} validateSpecialChar={true} />
          <InputField fieldName='consignee_address' i18nKey='pickupPoints.consigneeAddress' placeholder='pickupPoints.consigneeAddressPlaceholder' name='consigneeAddress' type='text' stateValue={this.state.consigneeAddress} onChange={this.handleOnChange.bind(this)} />
          <DropdownField fieldName='consignee_country' i18nKey='pickupPoints.consigneeCountry' placeholder={this.state.consigneeCountry ? this.state.consigneeCountry : 'select one...'} labelClassName="mb-2" dropdownClassName="mb-3" disableLabel={false} onChange={(e) => this.handleDropdownChange(e, 'consigneeCountry')} renderItems={this.renderCountries()} />
          <DropdownField loading={this.state.stateLoading} value={this.state.consigneeState} fieldName='consignee_state' i18nKey='pickupPoints.consigneeState' placeholder={this.state.consigneeState ? this.state.consigneeState : 'select one...'} labelClassName="mb-2" dropdownClassName="mb-3" disableLabel={false} onChange={(e) => this.handleDropdownChange(e, 'consigneeState')} renderItems={this.renderStates()} />
          <DropdownField loading={this.state.cityLoading} value={this.state.consigneeCity} fieldName='consignee_city' i18nKey='pickupPoints.consigneeCity' placeholder={this.state.consigneeCity ? this.state.consigneeCity : 'select one...'} labelClassName="mb-2" dropdownClassName="mb-3" disableLabel={false} onChange={(e) => this.handleDropdownChange(e, 'consigneeCity')} renderItems={this.renderCities()} />
          <InputField fieldName='consignee_postal' i18nKey='pickupPoints.consigneePostal' placeholder='pickupPoints.consigneePostalPlaceholder' name='consigneePostal' type='text' stateValue={this.state.consigneePostal} onChange={this.handleOnChange.bind(this)} />

          {
            error === false ?
              <div className="alert alert-success mt-2 mb-0" role="alert">
                {this.props.message}
              </div>
              :
              null
          }

          {
            errorData ?
              <div className="alert alert-danger mt-2 mb-0" role="alert">
                <div><b><Trans i18nKey="common:orders.error" /></b></div>
                {JSON.stringify(errorData)}
              </div>
              :
              null
          }

          <button
            type="button"
            className="mt-3 w-100 btn btn-lg btn-success"
            onClick={this.handleEdit}
            disabled={this.state.updatedStatus ? false : true}>
            <Trans i18nKey="common:pickupPoints.edit" />
          </button>
        </Jumbotron>
      </div>
    )

    return (
      <div>
        {renderDiv}
      </div>
    )
  }
}

function mapStateToProps({ consigneeAddress, order }) {
  return ({
    error: consigneeAddress.error,
    errorData: consigneeAddress.errorData,
    consigneeAddress: consigneeAddress.consigneeAddress,
    countries: order.countries,
    message: consigneeAddress.message,
    status: consigneeAddress.status
  });
}

export default compose(
  connect(mapStateToProps, {
    fetchAllCountries,
    fetchConsigneeAddressById,
    editConsigneeAddress,
    clearConsigneeErrors
  }),
  withStyles(styles),
  withTranslation('common')
)(EditConsigneeAddress);
