import { createSlice } from '@reduxjs/toolkit';
import * as api from '../../draftOrder/api';


const initialState = {
  entries: {
    data: [],
    loading: false,
    loaded: false
  },
  createLaneConfig: {
    loading: false,
    error: null,
    result: null,
  },
  editLaneConfig: {
    loading: false,
    updating: false,
    error: null,
    result: null,
    laneConfig: null
  }
};

const slice = createSlice({
  name: 'lane_configs',
  initialState: initialState,
  reducers: {
    fetchConfigs: (state, action) => {
      state.entries.loaded = false;
      state.entries.loading = true;
    },
    setEntries: (state, action) => {
      state.entries.loaded = true;
      state.entries.loading = false;
      state.entries.data = action.payload.entries;
    },
    startCreate: (state) => {
      state.createLaneConfig.loading = true;
    },
    stopCreate: (state) => {
      state.createLaneConfig.loading = false;
    },
    setCreateResult: (state, action) => {
      state.createLaneConfig.result = action.payload
      state.createLaneConfig.error = null
    },
    setCreateError: (state, action) => {
      state.createLaneConfig.result = null
      state.createLaneConfig.error = action.payload
    },
    fetchConfigDetail: (state) => {
      state.editLaneConfig.loading = true
    },
    setConfigDetail: (state, action) => {
      state.editLaneConfig.loading = false
      state.editLaneConfig.laneConfig = action.payload
    },
    startConfigUpdate: (state) => {
      state.editLaneConfig.updating = true
    },
    setConfigUpdateResult: (state, action) => {
      state.editLaneConfig.updating = false
      state.editLaneConfig.result = action.payload
      state.editLaneConfig.error = null
    },
    setConfigUpdateError: (state, action) => {
      state.editLaneConfig.updating = false
      state.editLaneConfig.error = action.payload
      state.editLaneConfig.result = null
    },
  }
});


export const fetchLaneConfigs = (secretKey) => async (dispatch) => {
  dispatch(slice.actions.fetchConfigs());

  api.getLaneConfigs(secretKey, {})
    .then(({data: {results, count, next, previous}}) => {
      dispatch(slice.actions.setEntries({
        entries: results,
        totalCount: count
      }));
    });
};

export const createNewLaneConfig = (secretKey, params) => async (dispatch) => {
  dispatch(slice.actions.startCreate())

  api.createLaneConfigs(secretKey, params)
    .then(({data: result}) => {
      dispatch(slice.actions.stopCreate())
      dispatch(slice.actions.setCreateResult(result))
    }).catch( e => {
      dispatch(slice.actions.stopCreate())
      dispatch(slice.actions.setCreateError(e.data))
    })
}

export const fetchLaneConfigDetail = (secretKey, laneConfigId) => async (dispatch) => {
  dispatch(slice.actions.fetchConfigDetail());

  api.getLaneConfigDetail(secretKey, laneConfigId, {})
    .then(({data: result}) => {
      dispatch(slice.actions.setConfigDetail(result))
    });
};

export const updateLaneConfig = (secretKey, laneConfigId, params) => async (dispatch) => {
  dispatch(slice.actions.startConfigUpdate())

  api.updateLaneConfig(secretKey, laneConfigId, params)
    .then(({data: result}) => {
      dispatch(slice.actions.setConfigUpdateResult(result))
    }).catch( e => {
      dispatch(slice.actions.setConfigUpdateError(e.data))
    })
}

export default slice.reducer;
