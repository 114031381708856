import {
	FETCH_CONSIGNEE_ADDRESS,
	FETCH_CONSIGNEE_ADDRESS_BY_ID,
	ADD_CONSIGNEE_ADDRESS,
	EDIT_CONSIGNEE_ADDRESS,
	CLEAR_CONSIGNEE_ADDRESS_ERRORS
} from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case FETCH_CONSIGNEE_ADDRESS:
			return { ...state, consigneeAddresses: action.payload };
		case FETCH_CONSIGNEE_ADDRESS_BY_ID:
			return { ...state, consigneeAddress: action.payload };
		case ADD_CONSIGNEE_ADDRESS:
			return {
				...state,
				error: action.payload,
				errorData: action.errorData
			};
		case EDIT_CONSIGNEE_ADDRESS:
			return {
				...state,
				error: action.payload,
				errorData: action.errorData,
				message: 'Update consignee address success!'
			};
		case CLEAR_CONSIGNEE_ADDRESS_ERRORS:
			return { ...state, error: null, errorData: null, message: null, status: null };
		default:
			return state;
	}
}
