import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, DatePicker } from "antd";
import { Select, Form as F } from "antd";

import { fetchAllCountries } from "../../../actions/orderActions";

import useServiceDef from "../../orderSubmitBulk/hooks/useServiceDef";
import useServices from "../../orderSubmit/hooks/useServices";
import useStoreAddresses from "../../shopifyOrders/hooks/useStoreAddresses";

export const getDropoffOptions = (
  serviceDefinitions,
  origin,
  destination,
  agentApplicationName,
  serviceType = "Dropoff"
) => {
  console.log("asdfasf", serviceType);
  let exclusive = serviceDefinitions.filter(
    (serviceDef) =>
      serviceDef.origin_country === origin &&
      serviceDef.destination_country === destination &&
      serviceDef.service_type === serviceType &&
      serviceDef.exclusive_agents.includes(agentApplicationName)
  );

  if (exclusive.length > 0) {
    return exclusive;
  }

  return serviceDefinitions.filter(
    (def) =>
      origin === def.origin_country &&
      destination === def.destination_country &&
      def.service_type === serviceType
  );
};

export const getFilteredServiceDefinitions = (
  serviceDefinitions,
  agentApplicationName,
  origin,
  destination,
  serviceType,
  dropoffId
) => {
  let exclusive = serviceDefinitions.filter(
    (serviceDef) =>
      serviceDef.origin_country === origin &&
      serviceDef.destination_country === destination &&
      serviceDef.service_type === serviceType &&
      serviceDef.exclusive_agents.includes(agentApplicationName) &&
      (dropoffId ? serviceDef.dropoff_id === dropoffId : true)
  );
  if (exclusive.length > 0) {
    return exclusive;
  } else {
    return serviceDefinitions.filter(
      (serviceDef) =>
        serviceDef.origin_country === origin &&
        serviceDef.destination_country === destination &&
        serviceDef.service_type === serviceType &&
        (dropoffId ? serviceDef.dropoff_id === dropoffId : true)
    );
  }
};

const PickupAddressSelector = ({
  originCountry,
  onChange,
  selectedPickup,
  defaultPickupPointId,
  errors,
  pickupDate,
  onPickupDateChange,
}) => {
  const [addresses] = useStoreAddresses();

  const onSelectChange = (selectedPickupPointId) => {
    const selectedAddress = addresses.filter(
      (a) => a.pickup_point_id === parseInt(selectedPickupPointId, 10)
    )[0];

    onChange(selectedAddress);
  };

  return (
    <>
      <Col span={14}>
        <F.Item
          colon={false}
          label="Pickup Address"
          validateStatus={errors.service_id ? "error" : ""}
          help={errors.service_id}
        >
          <Select
            style={{ width: "100%" }}
            onChange={onSelectChange}
            defaultValue={defaultPickupPointId}
          >
            {addresses
              .filter((a) => a.pickup_point_country === originCountry)
              .map((a, i) => (
                <Select.Option key={i} value={a.pickup_point_id}>
                  {a.pickup_point_name} - {a.pickup_point_address}
                </Select.Option>
              ))}
          </Select>
        </F.Item>
      </Col>
      <Col span={10}>
        <F.Item colon={false} label="Pickup Date (Optional)">
          <DatePicker
            style={{ width: "100%" }}
            value={pickupDate}
            onChange={onPickupDateChange}
          />
        </F.Item>
      </Col>
    </>
  );
};

const DropoffSelector = ({
  originCountry,
  onChange,
  selectedDropoffService,
  dropOffServices,
  defaultDropoffId,
  errors,
}) => {
  const onSelectChange = (selectedDropoffServiceId) => {
    const selectedService = dropOffServices.filter(
      (def) => def.dropoff_id === selectedDropoffServiceId
    )[0];
    onChange(selectedService);
  };

  return (
    <Col span={24}>
      <F.Item
        colon={false}
        label="Dropoff Address"
        validateStatus={errors.service_id ? "error" : ""}
        help={errors.service_id}
      >
        <Select
          style={{ width: "100%" }}
          onChange={onSelectChange}
          defaultValue={defaultDropoffId}
        >
          {dropOffServices.map((def, i) => (
            <Select.Option key={i} value={def.dropoff_id}>
              {def.dropoff_address}
            </Select.Option>
          ))}
        </Select>
      </F.Item>
    </Col>
  );
};

export const ServiceSelector = ({
  originCountry,
  destinationCountry,
  onServiceChange,
  defaultServiceType,
  defaultPickupPointId,
  defaultDropoffId,
  errors,
  pickupDate,
  onPickupDateChange,
  onPickupCountryChange,
}) => {
  const dispatch = useDispatch();
  const hasMounted = useRef(false);
  const { secretKey, agentApplicationName } = useSelector((state) => {
    if (state.shipperDetails && state.shipperDetails.shipperDetails) {
      return {
        secretKey:
          state.shipperDetails.shipperDetails.agent_application_secret_key,
        agentApplicationName: state.shipperDetails.shipperDetails.shipper_name,
      };
    }
    return { secretKey: null, agentApplicationName: "" };
  });

  const serviceDefFilters = useMemo(() => ({
    origin_countries: originCountry,
    destination_countries: destinationCountry
  }), [originCountry, destinationCountry])
  const [serviceDefinitions, isServicedefinitionLoading] = useServiceDef(
    secretKey,
    serviceDefFilters
  );
  console.log(serviceDefinitions)

  const dropOffServices = getDropoffOptions(
    serviceDefinitions,
    originCountry,
    destinationCountry,
    agentApplicationName
  );
  const [serviceType, setServiceType] = useState(defaultServiceType);
  const [pickupCountry, setPickupCountry] = useState(originCountry);
  const [selectedPickup, setSelectedPickup] = useState();
  const [selectedDropoff, setSelectedDropoff] = useState();

  const serviceTypes = useMemo(() => {
    const services = serviceDefinitions
    .filter(
      (def) =>
        pickupCountry === def.origin_country &&
        destinationCountry === def.destination_country
    )
    .map((serviceDef) => serviceDef.service_type)
    const uniqueServiceTypes = [...new Set(services)]
    return uniqueServiceTypes
  }, [destinationCountry, pickupCountry, serviceDefinitions])

  const { countries } = useSelector((state) => ({
    countries: state.order.countries,
  }));

  useEffect(() => {
    // To not run on first mount
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }

    let filteredServiceDef = [];
    let serviceResult;

    if (serviceType === "Pickup" && selectedPickup) {
      filteredServiceDef = getFilteredServiceDefinitions(
        serviceDefinitions,
        agentApplicationName,
        originCountry,
        destinationCountry,
        serviceType
      );
    }

    if (serviceType === "Dropoff" && selectedDropoff) {
      filteredServiceDef = getFilteredServiceDefinitions(
        serviceDefinitions,
        agentApplicationName,
        originCountry,
        destinationCountry,
        serviceType,
        selectedDropoff.dropoff_id
      );
    }

    if (filteredServiceDef.length < 1 && (selectedPickup || selectedDropoff)) {
      serviceResult = {
        hasService: false,
        serviceType: null,
        serviceId: null,
        serviceDefinition: null,
      };
    } else if (filteredServiceDef.length === 1) {
      const serviceDef = filteredServiceDef[0];
      serviceResult = {
        hasService: true,
        serviceType: serviceType,
        serviceId: serviceDef.service_id,
        serviceDefinition: serviceDef,
        selectedPickup: selectedPickup,
      };
    } else {
      serviceResult = {
        hasService: false,
        serviceType: serviceType,
        serviceId: null,
        serviceDefinition: null,
      };
    }

    onServiceChange(originCountry, destinationCountry, serviceResult);
  }, [serviceType, selectedPickup, selectedDropoff]);

  const onServiceTypeChange = (val) => {
    setServiceType(val);
    if (val === "Pickup") {
      setSelectedDropoff(null);
    } else if (val === "Dropoff") {
      setSelectedPickup(null);
    } else {
      //For custmos clearance
      setSelectedDropoff(null);
      setSelectedPickup(null);
    }
  };

  const onCountryChange = (val) => {
    setPickupCountry(val);
    onPickupCountryChange(val);
    setServiceType(undefined);
    setSelectedDropoff(undefined);
    setSelectedPickup(undefined);
  };

  useEffect(() => {
    dispatch(fetchAllCountries());
  }, []);

  return (
    <Row gutter={8}>
      <Col span={24}>
        <F.Item
          colon={false}
          label="Pickup Country"
          validateStatus={errors.pickup_point_country ? "error" : ""}
          help={errors.pickup_point_country}
        >
          <Select
            style={{ width: "100%" }}
            onChange={onCountryChange}
            value={pickupCountry}
          >
            {countries &&
              countries.map((country, i) => (
                <Select.Option key={i} value={country}>
                  {country}
                </Select.Option>
              ))}
          </Select>
        </F.Item>
      </Col>
      <Col span={24}>
        <F.Item
          colon={false}
          label="Service Type"
          validateStatus={errors.service_type ? "error" : ""}
          help={errors.service_type}
        >
          <Select
            style={{ width: "100%" }}
            onChange={onServiceTypeChange}
            value={serviceType}
            disabled={isServicedefinitionLoading}
            loading={isServicedefinitionLoading}
          >
            {serviceTypes.map((serviceType, i) => (
              <Select.Option key={i} value={serviceType}>
                {serviceType}
              </Select.Option>
            ))}
          </Select>
        </F.Item>
      </Col>
      {serviceType === "Pickup" && (
        <PickupAddressSelector
          errors={errors}
          pickupDate={pickupDate}
          onPickupDateChange={onPickupDateChange}
          defaultPickupPointId={defaultPickupPointId}
          selectedPickup={selectedPickup}
          originCountry={originCountry}
          onChange={setSelectedPickup}
        />
      )}
      {serviceType === "Dropoff" && (
        <DropoffSelector
          errors={errors}
          originCountry={originCountry}
          defaultDropoffId={defaultDropoffId}
          selectedDropoffService={selectedDropoff}
          dropOffServices={dropOffServices}
          onChange={setSelectedDropoff}
        />
      )}
    </Row>
  );
};
