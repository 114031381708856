import { createSlice } from '@reduxjs/toolkit';
import * as api from '../../draftOrder/api';
import {notification} from 'antd'

const INITIAL_STATE = {
    "dashboard":{
       "entries":[

       ],
       "itemCategoryDataList" : [],
       "serviceDataList" : [],
       "parcelDataList" : [],
       "loading":false,
       "isModalVisible":false,
       "isModalVisibleAlert":false,
       "multiSelectRecords":[

       ]
    },
    "itemCategoryForm":{
       "rule_name":"",
       "action_type":"ITEM_CATEGORY",
       "rule_source_fields":[
          {
             "source_field_name":"",
             "operator":"",
             "matches_to_value":"",
             "is_saved":false
          }
       ],
       "rule_target_fields":[
          {
             "target_name":"item_category",
             "target_value":""
          }
       ],
       "itemCategorySourceFields":{
          "SKU (Stock Keeping Units)":"item_sku",
          "Item Title":"item_description"
       },
       "operators":{
          "item_sku":{
             "equals":"=",
             "contains":"in"
          },
          "item_description":{
             "equals":"=",
             "contains":"in"
          }
       },
       "apply_on_existing_order":false,
       "sourceFieldLabel":{
          "item_sku":"SKU (Stock Keeping Unit)",
          "item_description":"Item Name"
       },
       "addConditionFlag":false,
       "isUpdateFlag":false
    },
    "parcelDimensionForm":{
       "rule_name":"",
       "action_type":"DIMENSIONS",
       "rule_source_fields":[
          {
             "source_field_name":"",
             "operator":"",
             "matches_to_value":"",
             "is_saved":false
          }
       ],
       "rule_target_fields":[
          {
            "target_name":"draft_order.order_length",
            "target_value":""
          },
          {
             "target_name":"draft_order.order_width",
             "target_value":""
          },{
            "target_name":"draft_order.order_height",
            "target_value":""
          },
          {
             "target_name":"draft_order.order_weight",
             "target_value":""
          }
       ],
       "apply_on_existing_order":false,
       "itemCategorySourceFields":{
          "Item weight is":"draft_order.order_weight",
          "Item quantity is":"item_qty"
       },
       "operators":{
          "draft_order.order_weight":{
             "equals":"=",
             "less than":"<",
             "greater than":">"
          },
          "item_qty":{
             "equals":"=",
             "less than":"<",
             "greater than":">"
          }
       },
       "sourceFieldLabel":{
          "draft_order.order_weight":"Weight",
          "item_qty":"Quantity"
       },
       "addConditionFlag":false,
       "isUpdateFlag":false
    },
    "serviceTypeForm":{
        "rule_name":"",
        "action_type":"SERVICE_TYPE",
        "rule_source_fields":[
           {
              "source_field_name":"draft_order.pickup_country",
              "operator":"=",
              "matches_to_value":"",
              "is_saved":false
           },
           {
            "source_field_name":"draft_order.consignee_country",
            "operator":"=",
            "matches_to_value":"",
            "is_saved":false
         }
        ],
        "rule_target_fields":[
           {
              "target_name":"",
              "target_value":""
           }
        ],
        "apply_on_existing_order":false,
        "addConditionFlag":false,
        "isUpdateFlag":false,
        "meta_data":{"serviceType":""},
        "addressID":"",
        "targetFieldOptions":[],
        "conflictModalFlag":false
     }
 }


const slice = createSlice({
    name: 'shipment_rule',
    initialState: INITIAL_STATE,
    reducers: {
        preFetchDashboard: (state, action) => {
            state.dashboard.loading = true
        },
        setEntries: (state, action) =>{
            state.dashboard.entries = action.payload.entries;
            state.dashboard.loading = false;
        },
        setitemCategoryEntries: (state, action) =>{
            state.dashboard.itemCategoryDataList = action.payload;
            state.dashboard.loading = false;
        },
        serviceDataEntries: (state, action) =>{
            state.dashboard.serviceDataList = action.payload;
            state.dashboard.loading = false;
        },
        parcelDataEntries: (state, action) =>{
            state.dashboard.parcelDataList = action.payload;
            state.dashboard.loading = false;
        },
        toggleModal:(state, action) =>{
            state.dashboard.isModalVisible = state.dashboard.isModalVisible?false:true
        },
        toggleModalAlert:(state, action) =>{
            state.dashboard.isModalVisibleAlert = state.dashboard.isModalVisibleAlert?false:true
        },
        addFormCondition:(state, action)=>{
            let [formType, condition] = action.payload
            state[formType].rule_source_fields.push(condition)
        },
        handleSave: (state, action)=>{
            let [formType, value] = action.payload
            state[formType].rule_source_fields[value].is_saved = true
        },
        handleDelete: (state, action)=>{
            let [formType, itemIndex] = action.payload
            let value = state[formType].rule_source_fields.filter((item, index)=>{return index!==itemIndex?item:false})
            if (value.length === 0)
                state[formType].rule_source_fields = [{
                    "source_field_name":"",
                    "operator":"",
                    "matches_to_value":"",
                    "is_saved":false
                }]
            else
                state[formType].rule_source_fields  = value

        },
        handleSourceInput: (state, action)=>{
            const [formType, value, field, index] = action.payload
            state[formType].rule_source_fields[index][field] = value
        },
        handleTargetInput: (state, action)=>{
            const [formType, value, field, index] = action.payload
            state[formType].rule_target_fields[index][field] = value
        },
        handleInput: (state, action)=>{
            const [formType, value, field] = action.payload
            state[formType][field] = value
        },
        setFormData: (state, action)=>{
            let [formType, value] = action.payload
            state[formType]["isUpdateFlag"] = value
        },
        setFormEntry: (state, action)=>{
            let [formType, data] = action.payload
            state[formType].rule_name = data["rule_name"]
            state[formType].rule_source_fields = data['rule_source_fields'].map((item)=>{item['is_saved']=true; return item})
            state[formType].rule_target_fields = data["rule_target_fields"]
            state[formType]["id"] = data["id"]
            state[formType]['meta_data'] = data["meta_data"]
            state[formType]['addressID'] = data?.addressID
        },
        preStatusUpdateEntries:(state, action)=>{
            state.dashboard.entries = state.dashboard.entries.map((item)=>{ if(item.id === action.payload){item['is_active']=!item['is_active']} ;return item})
        },
        postStatusUpdateEntries: (state, action)=>{
            if (state.dashboard.entries.length>0){
                state.dashboard.entries = state.dashboard.entries.map((item)=>item.id === action.payload.id?action.payload:item)
            }
        },
        resetFormData: (state, action)=>{
            state[action.payload] = INITIAL_STATE[action.payload]
        },
        setMultiSelectRecord: (state, action)=>{
            state.dashboard.multiSelectRecords = action.payload
        },
        handleServiceTypeChoice: (state, action)=>{
            state.serviceTypeForm.meta_data.serviceType = action.payload
        },
        toggleConflictModal: (state, action)=>{
            state.serviceTypeForm.conflictModalFlag = !state.serviceTypeForm.conflictModalFlag
        }


    }
});

notification.config({
    duration:2
  })

  const openNotificationWithIcon = (type, title, errorMessage) => {
    notification[type]({
      message: title,
      description:errorMessage

    });
  };

export const fetchShipmentRulesList = (secretKey) => async(dispatch)=>{
    dispatch(slice.actions.preFetchDashboard());
    api.getShipmentRules(secretKey, {})
    .then(({data: {results, count, next, previous}}) => {
        dispatch(slice.actions.setEntries({
            entries: results,
            totalCount: count
          }));
    });
}
export const setitemCategoryDataList = (data) => async(dispatch)=>{
    dispatch(slice.actions.setitemCategoryEntries(data))
}
export const setServiceDataList = (data) => async(dispatch)=>{
    dispatch(slice.actions.serviceDataEntries(data))
}
export const setParcelDataList = (data) => async(dispatch)=>{
    dispatch(slice.actions.parcelDataEntries(data))
}

export const performToggleModal = () => async(dispatch)=>{
    dispatch(slice.actions.toggleModal());
}
export const performToggleModalAlert = (result) => async(dispatch)=>{
    dispatch(slice.actions.toggleModalAlert());
}

export const addFormCondition = (formType)=>async(dispatch)=>{
    let payload = {
        "source_field_name":"",
        "operator":"",
        "matches_to_value":"",
        "is_saved":false
    }
    dispatch(slice.actions.addFormCondition([formType, payload]))
}

export const handleSave = (formType, index)=>async(dispatch)=>{
    dispatch(slice.actions.handleSave([formType, index]))
}

export const handleDelete = (formType, index)=>async(dispatch)=>{
    dispatch(slice.actions.handleDelete([formType, index]))
}

export const handleSourceInput = (formType, value, field, index)=>async(dispatch)=>{
    dispatch(slice.actions.handleSourceInput([formType, value, field, index]))
}

export const handleTargetInput = (formType, value, field, index)=>async(dispatch)=>{
    dispatch(slice.actions.handleTargetInput([formType, value, field, index]))
}

export const handleInput = (formType, value, field)=>async(dispatch)=>{
    dispatch(slice.actions.handleInput([formType, value, field]))
}
export const submitServiceTypeForm = (secretKey, params)=>async(dispatch)=>{
    api.createShipmentRules(secretKey, params)
    .then((data) => {
        openNotificationWithIcon('success', 'Success', 'Rule created successfully')
        window.open("/shipment-rules","_self");
    }).catch(error=>{
        if(error?.data?.includes('IntegrityError')){
            openNotificationWithIcon('error', 'Error', 'Rule name already exists')
        }else{
            openNotificationWithIcon('error', 'Error', 'Server error')
        }
    });
}

export const submitForm = (secretKey, params)=>async(dispatch)=>{
    let newParams = Object.assign({}, params)
    newParams.rule_target_fields = params.rule_target_fields.filter(
      (item) => (typeof item.target_value === 'object' && item.target_value !== null) ||
                (item.target_value !== null && item.target_value.length>0) ? item : null
    )

    api.createShipmentRules(secretKey, newParams)
    .then((data) => {
        openNotificationWithIcon('success', 'Success', 'Rule created successfully')
        window.open("/shipment-rules","_self");
    }).catch(error=>{
        if(error?.data?.includes('IntegrityError')){
            openNotificationWithIcon('error', 'Error', 'Rule name already exists')
        }else{
            openNotificationWithIcon('error', 'Error', 'Server error')
        }
    });
}

export const setFormData = (secretKey, id)=>async(dispatch)=>{
    api.getShipmentRuleById(secretKey, id, {})
        .then((data) => {
            let formType = null
            if (data.data.action_type === "DIMENSIONS") {
                formType = "parcelDimensionForm"
                if (data.data.rule_target_fields.length === 3) {
                    data.data.rule_target_fields.push({
                        "target_name": "draft_order.order_weight",
                        "target_value": ""
                    })
                }
            }
            else if (data.data.action_type === "ITEM_CATEGORY") {
                formType = "itemCategoryForm"
            } else if (data.data.action_type === "SERVICE_TYPE") {
                formType = "serviceTypeForm"

            }
            dispatch(slice.actions.setFormEntry([formType, data["data"]]))
        }).catch(err => console.log('error', err));
}

export const setServiceTypeFormData = (secretKey, id, getDropoffOptions, serviceDefinitions, agentApplicationName, storeAdresses)=>async(dispatch)=>{
    api.getShipmentRuleById(secretKey, id, {})
    .then((data) => {
      data = Object.assign({}, data["data"])
        data["addressID"] = data.rule_target_fields[1].target_value
        dispatch(slice.actions.setFormEntry(["serviceTypeForm", data]))
    }).catch(err => console.log('error', err));
}

export const updateFormStatus = (secretKey, id, params)=>async(dispatch)=>{
    dispatch(slice.actions.preStatusUpdateEntries(id))
    let newParams = Object.assign({}, params)
    newParams.rule_target_fields = params.rule_target_fields.filter((item)=>(typeof item.target_value === 'object' && item.target_value !== null) || (item.target_value !== null &&  item.target_value.length>0) ? item:null)
    api.updateShipmentRules(secretKey, id, newParams)
    .then((data) => {
        openNotificationWithIcon('success', 'Success', 'Rule status updated successfully')
        dispatch(slice.actions.postStatusUpdateEntries(params))
    }).catch(error=>{
        openNotificationWithIcon('error', 'Error', 'Server error')
    });
}

export const updateForm = (secretKey, id, params)=>async(dispatch)=>{
    let newParams = Object.assign({}, params)
    newParams.rule_target_fields = params.rule_target_fields.filter(
        item => item.target_value.length > 0 || (typeof item.target_value === 'object' && item.target_value !== null ? item : null))
    api.updateShipmentRules(secretKey, id, newParams)
    .then((data) => {
        openNotificationWithIcon('success', 'Success', 'Rule updated successfully')
        window.open("/shipment-rules","_self");
    }).catch(error=>{
        if(error.data.includes('IntegrityError')){
            openNotificationWithIcon('error', 'Error', 'Rule name already exists')
        }else{
            openNotificationWithIcon('error', 'Error', 'Server error')
        }
    });
}

export const updateShipmentRulesOrder = (secretKey, idTop,idBottom, params)=>async(dispatch)=>{
    // dispatch(slice.actions.preStatusUpdateEntries(id))
    let newParams = Object.assign({}, params)
    // newParams.rule_target_fields = params.rule_target_fields.filter((item)=>item.target_value.length>0?item:null)
    api.updateShipmentRulesOrder(secretKey, idTop,idBottom, newParams)
    .then((data) => {
        console.log('datasucess', data)
        openNotificationWithIcon('success', 'Success', 'Rules order changed successfully')
        window.open("/shipment-rules","_self");
    }).catch(error=>{
        console.log('errorerror', error)
        openNotificationWithIcon('error', 'Error', 'Server error')
        window.open("/shipment-rules","_self");
    });
}

export const deleteFormData = (secretKey, id)=>async(dispatch)=>{
    api.deleteShipmentRules(secretKey, id)
    .then((data) => {
        openNotificationWithIcon('success', 'Success', 'Rule deleted successfully')
        window.open("/shipment-rules","_self");
    });
}

export const resetFormData = (formType)=>async(dispatch)=>{
    dispatch(slice.actions.resetFormData([formType]))
}

export const setMultiSelectRecord = (item)=>async(dispatch)=>{
    dispatch(slice.actions.setMultiSelectRecord(item))
}

export const mulitDeleteRecords = (secretKey, items)=>async(dispatch)=>{
    for (const record in items){
        api.deleteShipmentRules(secretKey, items[record].id)
        .then((data) => {
            window.open("/shipment-rules","_self");
        });
    }

}

export const handleServiceTypeChoice = (value)=>async(dispatch)=>{
    dispatch(slice.actions.handleServiceTypeChoice(value))
}

export const toggleConflictModal = (value)=>async(dispatch)=>{
    dispatch(slice.actions.toggleConflictModal())
}


export default slice.reducer;
