import React from 'react';
import { getIn } from 'formik';
import {
  Input,
  Form as F,
  Select,
} from 'antd';
import {translateErrorMsg} from '../util';
import _ from 'lodash';

export const FormField = (props) => {
  const {inputType, label, field, resetFieldError, draftOrder, inputProps, formikProps} = props;
  const {errors, setFieldValue} = formikProps

  let fieldErrors = []
  if (draftOrder && draftOrder.validation_result){
    const serverErrors = getIn(draftOrder.validation_result.errors, field.name)
    if(serverErrors){
      fieldErrors = fieldErrors.concat(serverErrors.map(e => translateErrorMsg(field.name, e, draftOrder)))
    }
  }

  const clientError = getIn(errors, field.name)
  if(clientError){
    fieldErrors.push(translateErrorMsg(field.name, clientError, draftOrder))
  }

  const onInputChange = (field) => {
    if(!_.isEmpty(draftOrder.validation_result)) {
      if(!_.isEmpty(draftOrder.validation_result.errors)) {
          resetFieldError(field.name);
      }
    }
  };

  if(inputType === 'select'){
    const {options} = props
    const {name, onBlur, value} = field

    return (
      <F.Item
        label={label}
        colon={false}
        validateStatus={fieldErrors.length > 0 ? 'error' : ''}
        help={fieldErrors.join(',')}>
        <Select name={name} onChange={(val) => {setFieldValue(name, val); onInputChange(field)}} onBlur={onBlur} defaultValue={value} {...inputProps}>
          {options.map(option => (<Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>))}
        </Select>
      </F.Item>
    )
  }
  return (
    <F.Item
      label={label}
      colon={false}
      validateStatus={fieldErrors.length > 0 ? 'error' : ''}
      help={fieldErrors.join(',')}>
      <Input type={inputType} {...field} {...inputProps} onChange={(e) => {setFieldValue(field.name, e.target.value); onInputChange(field) } } />
    </F.Item>
  )
}
