import { createSlice } from '@reduxjs/toolkit';
import * as api from './api';
import _ from 'lodash';

const initialState = {
  entries: {
    data: [],
    pagination: {
      totalCount: 0,
      next: null,
      prev: null,
    },
    loading: false
  },
  detail: {
    data: {},
    loading: true,
  },
  isValidating: false,
  validationErrors: null,
  createShipments: {
    isLoading: false,
    result: {
      hasResult: false,
      successCount: 0,
      failedCount: 0,
    }
  },
  showSyncModal: true
};

const slice = createSlice({
  name: 'draft-orders',
  initialState: initialState,
  reducers: {
    resetDraftOrderFieldError: (state, action) => {
      delete state.detail.data.validation_result.errors[action.payload];
    },
    resetDraftOrderItemsFieldError: (state, action) => {
      delete state.detail.data.validation_result.errors['items'][action.payload['index']][action.payload['fieldName']];
    },
    fetchDraftOrders: (state, action) => {
      state.entries.loading = true;
    },
    setEntries: (state, action) => {
      state.entries.loading = false;
      state.entries.data = action.payload.entries;
      state.entries.pagination.totalCount = action.payload.totalCount;
    },
    fetchDetailOrder: (state, action) => {
      state.detail.loading = true;
    },
    setDetailOrder: (state, action) => {
      state.detail.loading = false;
      state.detail.data = action.payload.data;
    },
    startOrderValidation: (state, action) => {
      state.isValidating = true;
    },
    stopOrderValidation: (state, action) => {
      state.isValidating = false;
    },
    setValidationError: (state, action) => {
      state.isValidating = false;
      state.validationErrors = action.payload
    },
    resetCreateShipments: (state, action) => {
      state.createShipments = initialState.createShipments
    },
    startCreateShipments: (state, action) => {
      state.createShipments.isLoading = true
      state.createShipments.result.hasResult = false
    },
    stopCreateShipments: (state, action) => {
      state.createShipments.isLoading = false
      state.createShipments.result.hasResult = true
      state.createShipments.result.successCount = action.payload.successCount
      state.createShipments.result.failedCount = action.payload.failedCount
    },
  }
});

export const getDraftOrderDetail = (secretKey, {orderId}) => async (dispatch) => {
  dispatch(slice.actions.fetchDetailOrder());

  api.getDraftOrderDetail(secretKey, {orderId})
    .then(({data: draftOrder}) => {
      dispatch(slice.actions.setDetailOrder({
        data: draftOrder
      }));
    });
}

export const getDraftOrders = (secretKey, params) => async (dispatch) => {
  dispatch(slice.actions.fetchDraftOrders());

  api.getDraftOrders(secretKey, params)
    .then(({data: {results, count, next, previous}}) => {
      dispatch(slice.actions.setEntries({
        entries: results,
        totalCount: count
      }));
    });
};

export const deleteDraftOrders = (secretKey, params, callback) => async (dispatch) => {
  api.deleteDraftOrders(secretKey, params)
    .then(() => {
      callback();
    });
};

export const applySyncRule = (secretKey, params) => async(dispatch)=>{
  api.applySyncRule(secretKey, params)
  .then((data) => {
    console.log('draft orders rule synced')
  });
}

export const validateOrder = (secretKey, {orderId, params, callback}) => async (dispatch) => {
  dispatch(slice.actions.startOrderValidation());

  api.validateDraftOrder(secretKey, {orderId, params})
    .then(({data: draftOrder}) => {
      dispatch(slice.actions.stopOrderValidation());
      dispatch(slice.actions.setDetailOrder({
        data: draftOrder
      }));

      if(callback){
        callback(draftOrder);
      }

    })
    .catch((error) => {
      if(error.data){
        dispatch(slice.actions.setValidationError(error.data))
      }
    });
};

export const createShipment = (secretKey, {orderId, params}) => async (dispatch) => {
  dispatch(slice.actions.startCreateShipments());

  api.createShipment(secretKey, {orderId, params})
    .then(({data: draftOrder}) => {
      dispatch(slice.actions.stopOrderValidation());
      dispatch(slice.actions.setDetailOrder({
        data: draftOrder
      }));
    });
};

export const createShipments = (secretKey, {params, callback}) => async (dispatch) => {
  dispatch(slice.actions.startCreateShipments());

  api.createShipments(secretKey, {params})
    .then(({data}) => {
      dispatch(slice.actions.stopCreateShipments({successCount: data.success_count, failedCount: data.failed_count}));
      if(callback){
        callback();
      }
    });

}

export const resetCreateShipments = () => (dispatch) => {
  dispatch(slice.actions.resetCreateShipments())
}

export const resetDraftOrderErrors = (fieldName) => async (dispatch) => {
  dispatch(slice.actions.resetDraftOrderFieldError(fieldName));
};

export const resetDraftOrderItemsErrors = (fieldName, orderDetails) => async (dispatch) => {
  orderDetails.validation_result.errors['items'].forEach((item_errors, index) => {
    for(const field in item_errors){
      if(field===fieldName){
        if(!_.isEmpty(orderDetails.validation_result.errors['items'][index][fieldName])){
          let fieldData = {'fieldName': fieldName, 'index': index};
          dispatch(slice.actions.resetDraftOrderItemsFieldError(fieldData));
        }
      }
    }
  })
};

export default slice.reducer;
