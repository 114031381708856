import React, { Component } from "react";
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from "react-redux";
import compose from 'recompose/compose';
import { withTranslation, Trans } from 'react-i18next';
import TermsModal from '../common/TermsModal'
import { Alert, Icon } from "antd"

import {
  fetchShipperDetails
} from '../../actions/shipperDetailsActions';
import { fetchStats } from '../../actions/statsActions';
import {
  fetchAllCountries
} from '../../actions/orderActions';
import _ from 'lodash';

import { Link } from "react-router-dom";
import { capitalizeUnderscore, isAgentApp } from "../../utils";

const PublicStatuses = [
  "ORDER_CREATED",
  "PENDING_PICKUP",
  "READY_FOR_COLLECTION",
  "IN_TRANSIT_WITHIN_ORIGIN_COUNTRY",
  "CROSS_BORDER_TRANSIT",
  "DESTINATION_COUNTRY_CUSTOMS",
  "IN_TRANSIT_WITHIN_DESTINATION_COUNTRY",
  "DELIVERY_IN_PROGRESS",
  "DELIVERY_COMPLETED",
  "FAILED_DELIVERY",
  "RETURN_TO_SENDER",
  "EXCEPTION",
  "CANCELLED",
]

class Stats extends Component {
  constructor() {
    super();
    this.state = {
      secretKey: '',
      selectedPickupCountry: '',
      selectedConsigneeCountry: '',
      stats: [],
      formated: false,
      updated: true,
      countByStatus: {}
    };
  }

  componentDidMount() {
    this.props.fetchShipperDetails();
    if (this.props.countries === undefined) {
      this.props.fetchAllCountries();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.stats !== this.state.stats) {
      const countByStatus = {}
      if (this.props.stats && !!this.props.stats.length) {
        this.props.stats.forEach(d => {
          countByStatus[d.public_status_v2] = d.public_status_v2__count
        })
      }
      this.setState({
        stats: this.props.stats,
        countByStatus
      });
    }

    if (prevState.stats !== this.state.stats) {
      if (this.state.stats !== undefined && this.state.stats.length > 0 && !this.state.formated) {
        this.formatStats();
        this.setState({
          formated: true
        });
      }
    }

    // if (this.state.secretKey.length > 0 && this.state.updated) {
    //   if (this.props.shipperDetails !== undefined) {
    //     const privilege = this.props.shipperDetails.privilege;
    //     if (privilege === 'admin' || privilege === 'viewer') {
    //       this.props.fetchStats(this.state.secretKey, this.state.selectedPickupCountry, this.state.selectedConsigneeCountry);
    //       this.setState({
    //         formated: false,
    //         updated: false
    //       });
    //     }
    //   }
    // }

    const { shipperDetails } = this.props
    if (shipperDetails && shipperDetails.hasOwnProperty('agent_application_secret_key') && this.state.secretKey.length === 0) {
      this.setState({
        secretKey: this.props.shipperDetails.agent_application_secret_key
      });
    }
  }

  handleDropdownChange = (e, type) => {
    if (type === 'pickupCountry') {
      const selectedPickupCountry = e.value;

      if (selectedPickupCountry !== undefined) {
        this.setState({
          selectedPickupCountry: selectedPickupCountry,
          updated: true
        });
      } else {
        this.setState({
          selectedPickupCountry: '',
          updated: true
        });
      }
    } else if (type === 'consigneeCountry') {
      const selectedConsigneeCountry = e.value;

      if (selectedConsigneeCountry !== undefined) {
        this.setState({
          selectedConsigneeCountry: selectedConsigneeCountry,
          updated: true
        });
      } else {
        this.setState({
          selectedConsigneeCountry: '',
          updated: true
        });
      }
    }

    if (this.state.stats) {
      _.forEach(this.state.stats, (stat) => {
        this.setState({ [stat.tracker_status_code]: 0 });
      });
    }
  }

  renderCountries = () => {
    let option = [{
      value: '',
      label: this.props.t('common.allCountries')
    }];

    _.map(this.props.countries, (item, i) => {
      let data = {
        value: item,
        label: item
      }
      option.push(data);
    });

    return option;
  }

  renderStatBox = () => {
    const statBoxes = PublicStatuses.map(status => {
      return (
        <div key={status} className="card m-1" style={{ width: '8rem' }}>
        {/* <LinkContainer key={key} to={'/view-orders/statusCode=' + value} className="card m-1 pointer" style={{ width: '8rem' }}> */}
          <div className="card-body p-1">
            <h5 className="h2 card-title text-center font-weight-bold mt-3">
              {this.state.countByStatus[status] || 0}
            </h5>
            <h6 className="card-subtitle text-center mb-2">{capitalizeUnderscore(status)}</h6>
          </div>
        {/* </LinkContainer> */}
        </div>
      )
    })
    return statBoxes;
  }

  renderBoxes = () => {
    let renderBoxes = null;

    if (this.props.shipperDetails !== undefined) {
      const privilege = this.props.shipperDetails.privilege;

      if (privilege === 'admin') {
        renderBoxes = <div>
          {/* <div>
            <div className="mt-2 p-4 w-100 border border-secondary d-flex flex-wrap justify-content-start container max-width-70 rounded">
              <div className="row">
                <div className="ml-4 mb-4 h1 font-weight-bold janio-colour"><Trans i18nKey='common:stats.60DaysOverview' /></div>
              </div>
              <div className="row">
                <div className="col-sm-5 pl-4">
                  <DropdownField fieldName='pickup country' placeholder={this.props.t('common.allCountries')} i18nKey='stats.pickupCountry' labelClassName="mb-2" dropdownClassName="mb-3" disableLabel={false} onChange={(e) => this.handleDropdownChange(e, 'pickupCountry')} renderItems={this.renderCountries()} />
                  <div className="mb-3" />
                  <DropdownField fieldName='consignee country' placeholder={this.props.t('common.allCountries')} i18nKey='stats.consigneeCountry' labelClassName="mb-2" dropdownClassName="mb-3" disableLabel={false} onChange={(e) => this.handleDropdownChange(e, 'consigneeCountry')} renderItems={this.renderCountries()} />
                </div>

                <div className="col-sm-7">
                  <div className="w-100 d-flex flex-wrap justify-content-center container pr-0">
                    {this.renderStatBox()}
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="d-flex justify-content-between mt-2 p-4 w-100 border border-secondary d-flex flex-wrap justify-content-start container max-width-70 rounded">
            <div>
              {/* <div className="ml-2 mb-0 h1 font-weight-bold janio-colour"><Trans i18nKey='common:stats.orders' /></div> */}
              <div className="ml-2 mb-0 h1 font-weight-bold janio-colour">{this.props.t('stats.orders')}</div>
            </div>
            <div className="p-0 m-0">
              <LinkContainer to='/view-orders/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert">{this.props.t('stats.manageOrders')}</div>
              </LinkContainer>
              <LinkContainer to='/submit-order/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert">{this.props.t('common:stats.submitSingleOrder')}</div>
              </LinkContainer>
              <LinkContainer to='/submit-order-csv/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert">{this.props.t('common:stats.submitBulkOrders')}</div>
              </LinkContainer>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-2 p-4 w-100 border border-secondary d-flex flex-wrap justify-content-start container max-width-70 rounded">
            <div>
              <div className="ml-2 mb-0 h1 font-weight-bold janio-colour"><Trans i18nKey='common:stats.analytics' /></div>
            </div>
            <div className="p-0 m-0">
              {/* <LinkContainer to='/performance/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.performance' /></div>
              </LinkContainer> */}
              <LinkContainer to='/reports/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.reports' /></div>
              </LinkContainer>
              
              {!isAgentApp(['iexpress'], this.props.agentAppDetails) &&
              <LinkContainer to='/invoices/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.billingAndInvoices' /></div>
              </LinkContainer>
              }
            </div>
          </div>

          <div className="d-flex justify-content-between mt-2 p-4 w-100 border border-secondary d-flex flex-wrap justify-content-start container max-width-70 rounded">
            <div>
              <div className="ml-2 mb-0 h1 font-weight-bold janio-colour"><Trans i18nKey='common:stats.config' /></div>
            </div>
            <div className="p-0 m-0">
              <LinkContainer to='/change-password/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.changePassword' /></div>
              </LinkContainer>
              <LinkContainer to='/manage-address/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.addOrEditPickup' /></div>
              </LinkContainer>
              <LinkContainer to='/merchant-details/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.editCompanyProfile' /></div>
              </LinkContainer>
              <LinkContainer to='/user/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.editUserSettings' /></div>
              </LinkContainer>
            </div>
          </div>
        </div>;
      } else if (privilege === 'manager') {
        renderBoxes = <div>
          {/* <div>
            <div className="mt-2 p-4 w-100 border border-secondary d-flex flex-wrap justify-content-start container max-width-70 rounded">
              <div className="row">
                <div className="ml-4 mb-4 h1 font-weight-bold janio-colour"><Trans i18nKey='common:stats.60DaysOverview' /></div>
              </div>
              <div className="row">
                <div className="col-sm-5 pl-4">
                  <DropdownField fieldName='pickup country' placeholder='All Countries' labelClassName="mb-2" dropdownClassName="mb-3" disableLabel={false} onChange={(e) => this.handleDropdownChange(e, 'pickupCountry')} renderItems={this.renderCountries()} />
                  <div className="mb-3" />
                  <DropdownField fieldName='consignee country' placeholder='All Countries' labelClassName="mb-2" dropdownClassName="mb-3" disableLabel={false} onChange={(e) => this.handleDropdownChange(e, 'consigneeCountry')} renderItems={this.renderCountries()} />
                </div>

                <div className="col-sm-7">
                  <div className="w-100 d-flex flex-wrap justify-content-center container pr-0">
                    {this.renderStatBox()}
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="d-flex justify-content-between mt-2 p-4 w-100 border border-secondary d-flex flex-wrap justify-content-start container max-width-70 rounded">
            <div>
              <div className="ml-2 mb-0 h1 font-weight-bold janio-colour"><Trans i18nKey='common:stats.orders' /></div>
            </div>
            <div className="p-0 m-0">
              <LinkContainer to='/view-orders/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.manageOrders' /></div>
              </LinkContainer>
              <LinkContainer to='/submit-order/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.submitSingleOrder' /></div>
              </LinkContainer>
              <LinkContainer to='/submit-order-csv/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.submitBulkOrders' /></div>
              </LinkContainer>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-2 p-4 w-100 border border-secondary d-flex flex-wrap justify-content-start container max-width-70 rounded">
            <div>
              <div className="ml-2 mb-0 h1 font-weight-bold janio-colour"><Trans i18nKey='common:stats.analytics' /></div>
            </div>
            <div className="p-0 m-0">
              <LinkContainer to='/reports/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.reports' /></div>
              </LinkContainer>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-2 p-4 w-100 border border-secondary d-flex flex-wrap justify-content-start container max-width-70 rounded">
            <div>
              <div className="ml-2 mb-0 h1 font-weight-bold janio-colour"><Trans i18nKey='common:stats.config' /></div>
            </div>
            <div className="p-0 m-0">
              <LinkContainer to='/change-password/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.changePassword' /></div>
              </LinkContainer>
              <LinkContainer to='/manage-address/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.addOrEditPickup' /></div>
              </LinkContainer>
            </div>
          </div>
        </div>;
      } else if (privilege === 'viewer') {
        renderBoxes = <div>
          {/* <div>
            <div className="mt-2 p-4 w-100 border border-secondary d-flex flex-wrap justify-content-start container max-width-70 rounded">
              <div className="row">
                <div className="ml-4 mb-4 h1 font-weight-bold janio-colour"><Trans i18nKey='common:stats.60DaysOverview' /></div>
              </div>
              <div className="row">
                <div className="col-sm-5 pl-4">
                  <DropdownField fieldName='pickup country' labelClassName="mb-2" dropdownClassName="mb-3" disableLabel={false} onChange={(e) => this.handleDropdownChange(e, 'pickupCountry')} renderItems={this.renderCountries()} />
                  <div className="mb-3" />
                  <DropdownField fieldName='consignee country' labelClassName="mb-2" dropdownClassName="mb-3" disableLabel={false} onChange={(e) => this.handleDropdownChange(e, 'consigneeCountry')} renderItems={this.renderCountries()} />
                </div>

                <div className="col-sm-7">
                  <div className="w-100 d-flex flex-wrap justify-content-center container pr-0">
                    {this.renderStatBox()}
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="d-flex justify-content-between mt-2 p-4 w-100 border border-secondary d-flex flex-wrap justify-content-start container max-width-70 rounded">
            <div>
              <div className="ml-2 mb-0 h1 font-weight-bold janio-colour"><Trans i18nKey='common:stats.orders' /></div>
            </div>
            <div className="p-0 m-0">
              <LinkContainer to='/view-orders/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.manageOrders' /></div>
              </LinkContainer>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-2 p-4 w-100 border border-secondary d-flex flex-wrap justify-content-start container max-width-70 rounded">
            <div>
              <div className="ml-2 mb-0 h1 font-weight-bold janio-colour"><Trans i18nKey='common:stats.config' /></div>
            </div>
            <div className="p-0 m-0">
              <LinkContainer to='/change-password/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.changePassword' /></div>
              </LinkContainer>
              <LinkContainer to='/manage-address/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.addOrEditPickup' /></div>
              </LinkContainer>
            </div>
          </div>
        </div>;
      } else if (privilege === 'creator') {
        renderBoxes = <div>
          <div className="d-flex justify-content-between mt-2 p-4 w-100 border border-secondary d-flex flex-wrap justify-content-start container max-width-70 rounded">
            <div>
              <div className="ml-2 mb-0 h1 font-weight-bold janio-colour"><Trans i18nKey='common:stats.orders' /></div>
            </div>
            <div className="p-0 m-0">
              <LinkContainer to='/submit-order/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.submitSingleOrder' /></div>
              </LinkContainer>
              <LinkContainer to='/submit-order-csv/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.submitBulkOrders' /></div>
              </LinkContainer>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-2 p-4 w-100 border border-secondary d-flex flex-wrap justify-content-start container max-width-70 rounded">
            <div>
              <div className="ml-2 mb-0 h1 font-weight-bold janio-colour"><Trans i18nKey='common:stats.config' /></div>
            </div>
            <div className="p-0 m-0">
              <LinkContainer to='/change-password/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.changePassword' /></div>
              </LinkContainer>
              <LinkContainer to='/manage-address/'>
                <div className="btn btn-outline-secondary btn-large ml-2 mt-1" role="alert"><Trans i18nKey='common:stats.addOrEditPickup' /></div>
              </LinkContainer>
            </div>
          </div>
        </div>;
      }
    }

    return renderBoxes;
  }

  formatStats = () => {
    if (this.state.stats) {
      _.forEach(this.state.stats, (stat) => {
        this.setState({ [stat.tracker_status_code]: stat.tracker_status_code__count });
      });
    }
  }

  render() {
    return (
      <div>
        <TermsModal />
        <div className="mb-3 p-0 w-100 d-flex flex-wrap justify-content-start container max-width-70">
          <Alert
            message={<AlertMessage />}
            type="info"
          />
        </div>
        {this.renderBoxes()}

        <div className="my-5" />
      </div>

    );
  }
}

const AlertMessage = () => {
  return (
    <div className="d-flex flex-row align-items-center">
      <Icon type="info-circle-o" className="text-info mr-3" style={{fontSize:"35px"}}/>
      <p className="mb-0">
        You may now see the entire shipping timeline of orders in our merchant dashboard.
        Simply head over to <Link to="/view-orders">Manage Orders</Link> and click view on the order that you wish to track. Let us know how it is working for you and don't hesitate to share with us any feedback or thoughts on how we can serve you better through the Help widget at the bottom right of your screen.</p>
    </div>
  )
}

function mapStateToProps({ shipperDetails, stats, order, agentAppDetails }) {
  return ({
    shipperDetails: shipperDetails.shipperDetails,
    stats: stats.stats,
    countries: order.countries,
    agentAppDetails
  });
}

export default compose(
  connect(mapStateToProps, {
    fetchShipperDetails,
    fetchStats,
    fetchAllCountries
  }),
  withTranslation('common')
)(Stats);
