import React, { useEffect } from "react";
import {Row, Col, Button, Form as F, Input, Select, Icon, Checkbox, Modal} from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { useSecretKey } from '../../auth/useSecretKey';
import {addFormCondition, handleSave, handleDelete, handleSourceInput, handleTargetInput, handleInput, submitForm as submitItemCategoryForm, setFormData, updateForm as updateItemCategoryForm, resetFormData, performToggleModal, performToggleModalAlert} from './store'


const ParcelDimension = (props) => {
  const dispatch = useDispatch()
  const [secretKey] = useSecretKey()
  const formType = "parcelDimensionForm"
  const shipmentRuleStore = useSelector((state) => {
    return state.shipmentRules
  }); 
  const isUpdateFormFlag = props.match.params.id?true:false

  useEffect(() => {
    if(!secretKey){
      // dispatch
      console.log('no secret key')
    }
    if (isUpdateFormFlag){
      dispatch(setFormData(secretKey, props.match.params.id))
  
    }else{
      dispatch(resetFormData(formType))
      dispatch(performToggleModal())
      if(shipmentRuleStore.dashboard.isModalVisibleAlert) {
        dispatch(performToggleModalAlert())
      }
    }
  }, [secretKey])

  

  

  const handleSourceInputChange = (event, name, index)=>{
    dispatch(handleSourceInput(formType, event, name, index))
  }

  const handleTargetInputChange = (event, name, index)=>{
    dispatch(handleTargetInput(formType, event, name, index))
  }

  const handleInputChange = (event)=>{
    dispatch(handleInput(formType, event.target.value, event.target.name))
  }

  const handleInputCheck = (event)=>{
    dispatch(handleInput(formType, event.target.checked, event.target.name))
  }

  const addConditionChange = (event)=>{
    dispatch(addFormCondition(formType))
  }

  const getSourceFieldOptions = ()=>{
    let options = shipmentRuleStore[formType].itemCategorySourceFields
    return Object.keys(options).map((item)=><Select.Option value={options[item]}>{item}</Select.Option>)
  }

  const getOperatorOptions = (key)=>{
    let options = shipmentRuleStore[formType].operators[key]
    return Object.keys(options).map((item)=><Select.Option value={options[item]}>{item}</Select.Option>)
  }

  const handleSaveDelete = (event, index)=>{
    if (event.target.name === 'save')
      dispatch(handleSave(formType, index))
    else
      dispatch(handleDelete(formType, index))
  }

  const submitForm = (event)=>{
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(submitItemCategoryForm(secretKey, shipmentRuleStore[formType]))
      }
    });
    
  }

  const updateForm = (event)=>{
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(updateItemCategoryForm(secretKey, shipmentRuleStore[formType].id, shipmentRuleStore[formType]))
      }
    });
  }

  
  const { getFieldDecorator } = props.form;

  const conditionRows = ()=>{
    let fields = shipmentRuleStore[formType].rule_source_fields

    return fields.map((item, index)=>{
      return <Row>
      <Col span={6}>
        <F.Item label="Condition">
        {getFieldDecorator(`source_field_name${index}`,{
                rules: [{ required: true, message: 'This field is required' }], initialValue:item["source_field_name"]
              })(
                <Select value={item["source_field_name"]} style={{ width: '80%' }} onChange={(e)=>handleSourceInputChange(e, "source_field_name",index)}>
                  {getSourceFieldOptions()}
                </Select>
              )}
        </F.Item>
      </Col>
      <Col span={6}>
        {shipmentRuleStore[formType].rule_source_fields[index].source_field_name.length>0?<F.Item label="Operator">
        {getFieldDecorator(`operator${index}`, {
                rules: [{ required: true, message: 'This field is required' }],initialValue:item["operator"]

              })(
                <Select value={item["operator"]} style={{ width: "80%" }} onChange={(e)=>handleSourceInputChange(e, "operator", index)}>
                {getOperatorOptions(item['source_field_name'])}
              </Select>
              )}
        </F.Item>:null}
      </Col>
      <Col span={6}>
        {shipmentRuleStore[formType].rule_source_fields[index].source_field_name.length>0?<F.Item label={shipmentRuleStore[formType].sourceFieldLabel[item["source_field_name"]]}>
               {getFieldDecorator(`source_field_value${index}`, {
                rules: [
                  { required: true, message: 'This field is required' },
                  shipmentRuleStore[formType].sourceFieldLabel[item["source_field_name"]] === 'Weight' ?
                  {
                    pattern: new RegExp(/^[0-9]\d{0,9}(\.\d{1,2})?%?$/) || new RegExp(/^\d*\.?\d+$/),
                    message: "Only positive values upto 2 decimal places is allowed."
                  } : 
                  {
                    pattern: new RegExp(/^(0|\+?[1-9]\d*)$/),
                    message: "Only positive value is allowed."
                  }
                ],
                initialValue:item["matches_to_value"]
              })(
              <Input 
                type="number" 
                name={"matches_to_value"} 
                min="1" 
                value={item["matches_to_value"]} 
                onChange={(e)=>handleSourceInputChange(e.target.value, "matches_to_value", index)}
                suffix={shipmentRuleStore[formType].sourceFieldLabel[item["source_field_name"]] === 'Weight' ? 'kg' : ''}
              />
              )}
              
        </F.Item>:null}
      </Col>
      <Col span={6}>
        {shipmentRuleStore[formType].rule_source_fields[index].source_field_name.length>0?<F.Item className="mt-4 ml-4">
          {item.is_saved?<Icon type="delete" style={{ fontSize: '15px', color: '#08c' }} onClick={(e)=>handleSaveDelete(e, index)} theme="outlined" />:<Button type="primary" name="save" disabled={item.matches_to_value && item.operator ? false : true} onClick={(e)=>handleSaveDelete(e, index)} >Save</Button>}
        </F.Item>:null}
      </Col>
      {item.is_saved && fields.length > 1 ? <Col span={8}><h5 style={{ margin: '-10px 0 10px 0' }}><strong>OR</strong></h5></Col> : ''}
    </Row>
    })
  }

  const showModalAlert = (event) => {

    dispatch(performToggleModalAlert())
  }
  const initialValueLengthFn = () => {
    let initialValueLength = shipmentRuleStore[formType].rule_target_fields.filter(item => item.target_name === 'draft_order.order_length')
    return initialValueLength[0].target_value
  }
  const initialValueWidthFn = () => {
    let initialValueWidth = shipmentRuleStore[formType].rule_target_fields.filter(item => item.target_name === 'draft_order.order_width')
    return initialValueWidth[0].target_value
  }
  const initialValueHeightFn = () => {
    let initialValueHeight = shipmentRuleStore[formType].rule_target_fields.filter(item => item.target_name === 'draft_order.order_height')
    return initialValueHeight[0].target_value
  }
  const lengthIndex = () => {
    let x = shipmentRuleStore[formType].rule_target_fields
    let index = x.findIndex(x => x.target_name === 'draft_order.order_length')

    return index
  }
  const widthIndex = () => {
    let x = shipmentRuleStore[formType].rule_target_fields
    let index = x.findIndex(x => x.target_name === 'draft_order.order_width')

    return index
  }
  const heightIndex = () => {
    let x = shipmentRuleStore[formType].rule_target_fields
    let index = x.findIndex(x => x.target_name === 'draft_order.order_height')

    return index
  }
  const weightIndex = () => {
    let x = shipmentRuleStore[formType].rule_target_fields
    let index = x.findIndex(x => x.target_name === 'draft_order.order_weight')

    return index
  }

  return (

    <div>
      <F layout="horizontal" name="itemCategoryForm">
      <Button  style={{border: 'none',padding: '0px 10px 0 0',marginBottom: '10px',fontWeight: '600'}} onClick={showModalAlert} ><Icon type="arrow-left" style={{ fontSize: '15px', color: '#08c','position': 'relative',top: '-2px' }}/></Button><span style={{fontSize: '14px'}}>Back to Shipment Rules</span>

        <h4> Parcel Dimension Rule </h4>
        <Row>
          <Col span={8}>
            <F.Item label="Name">
              {getFieldDecorator('rule-name', {
                rules: [{ required: true, message: 'This field is required' }], initialValue:shipmentRuleStore[formType].rule_name
              })(
                <Input maxLength="32" placeholder="name" name="rule_name" value={shipmentRuleStore[formType].rule_name} onChange={handleInputChange}/>
              )}
            </F.Item>
          </Col>
        </Row>
        <h6><strong>When</strong> (Select the condition) </h6>
        {conditionRows()}
        <Button type="primary" ghost className="mb-4" disabled={shipmentRuleStore[formType].rule_source_fields.slice(-1).pop().is_saved?false:true} onClick={addConditionChange}>+ Add Condition</Button>
        <h5> Perform these actions </h5>
        <Row>
          <Col span={6}>
            <F.Item label="Length">
            {getFieldDecorator('target_field_value0', {
                rules: [
                  { required: true, message: 'This field is required' },
                  {
                    pattern: new RegExp(/^[0-9]\d{0,9}(\.\d{0,1})?%?$/) || new RegExp(/^\d*\.?\d+$/),
                    message: "Only positive values upto 1 decimal place is allowed."
                  }
                ],
                initialValue: initialValueLengthFn()
              })(
               <Input type="number" suffix="cm" style={{width:"80%"}} onChange={(e)=>handleTargetInputChange(e.target.value, "target_value", lengthIndex())} disabled={shipmentRuleStore[formType].rule_source_fields[0].is_saved?false:true}/>
              )}
            </F.Item>
          </Col>
          <Col span={6}>
            <F.Item label="Width">
            {getFieldDecorator('target_field_value1', {
                rules: [
                  { required: true, message: 'This field is required' },
                  {
                    pattern: new RegExp(/^[0-9]\d{0,9}(\.\d{0,1})?%?$/) || new RegExp(/^\d*\.?\d+$/),
                    message: "Only positive values upto 1 decimal place is allowed."
                  }
                ],
                initialValue: initialValueWidthFn()
              })(
               <Input suffix="cm" type="number" style={{width:"80%"}} onChange={(e)=>handleTargetInputChange(e.target.value, "target_value", widthIndex())} disabled={shipmentRuleStore[formType].rule_source_fields[0].is_saved?false:true}/>
              )}
            </F.Item>
          </Col>
          <Col span={6}>
            <F.Item label="Height">
            {getFieldDecorator('target_field_value2', {
                rules: [
                  { required: true, message: 'This field is required' },
                  {
                    pattern: new RegExp(/^[0-9]\d{0,9}(\.\d{0,1})?%?$/) || new RegExp(/^\d*\.?\d+$/),
                    message: "Only positive values upto 1 decimal place is allowed."
                  }
                ],
                initialValue: initialValueHeightFn()
              })(
               <Input type="number" suffix="cm" style={{width:"80%"}} onChange={(e)=>handleTargetInputChange(e.target.value, "target_value", heightIndex())} disabled={shipmentRuleStore[formType].rule_source_fields[0].is_saved?false:true}/>
              )}
            </F.Item>
          </Col>
          <Col span={6}>
            <F.Item label="Weight (optional)">
              <Input suffix="kg" type="number" style={{width:"80%"}} value={shipmentRuleStore[formType].rule_target_fields[weightIndex()].target_value} onChange={(e)=>handleTargetInputChange(e.target.value, "target_value", weightIndex())} disabled={shipmentRuleStore[formType].rule_source_fields[0].is_saved?false:true}/>
            </F.Item>
          </Col>
        </Row>
        <F.Item >
          <Checkbox onChange={handleInputCheck} name="apply_on_existing_order" value={shipmentRuleStore[formType].apply_on_existing_order}>Apply to new and existing shipments</Checkbox>
        </F.Item>
        <Button type="primary" onClick={(event)=>isUpdateFormFlag?updateForm(event):submitForm(event)} disabled={shipmentRuleStore[formType].rule_source_fields[0].is_saved>0?false:true}>{isUpdateFormFlag?"Update":"Create"} rule</Button>

        <Button style={{marginLeft: '10px'}} onClick={showModalAlert}>Cancel</Button>
      </F>
      <Modal title="Are you sure you want to quit ?" visible={shipmentRuleStore.dashboard.isModalVisibleAlert} onCancel={showModalAlert} footer={null}>
          <Row>
            <Col span={24} align="middle">
              <h6 style={{marginBottom: '20px'}}>All progress in this session will be Lost!</h6>
              <Button onClick={showModalAlert} >Cancel</Button>
              <Button type="primary" style={{marginLeft: '10px'}} onClick={() => props.history.goBack()}>Quit</Button>
            </Col>
          </Row>
          
        </Modal>
    </div>
    
  );
}



export const ShipmentRuleParcelDimensionForm = F.create({ name: 'Item_category_form' })(ParcelDimension)