import { useState, useEffect } from 'react'
import axios from 'axios'

const useCurrencyMappings = (shouldFetch=true) => {
  const [state, setState] = useState({
    loading: true,
    data: {}
  })

  const getCurrencies = async () => {
    const response = await axios.get('/api/data/country-currency-mappings/',
      {headers: {
        'Content-Type': 'application/json'
      }}
    )
    setState({
      ...state, loading: false, data: response.data
    })
  }

  useEffect(() => {
    if (shouldFetch) {
      getCurrencies()
    }
  }, [shouldFetch])

  return [state.data, state.loading]
}

export default useCurrencyMappings