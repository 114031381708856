import axios from 'axios'
import { useQuery } from 'react-query'

const useCountries = () => {
  const token = localStorage.getItem('token')
  const {data, isLoading} = useQuery('countries',
  async () => {
    const response = await axios.get(`/api/data/places/`,
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } }
    )
    return response.data
  }, {
    staleTime: Infinity,
    refetchInterval: false
  })
  return [(data || []), isLoading]
}

export default useCountries