import React, { useEffect } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";

import { checkAllowedFeaturesAgentApp, ChannelContainer } from "../../utils";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import shopifyLogo from "../../images/shopify_logo_black.svg";
import shopeeLogo from "../../images/shopee_logo.svg";
import lazadaLogo from "../../images/lazada_logo.svg";

import { useDispatch, useSelector } from "react-redux";

import { handleLazadaAuth, handleShopeeAuth } from "../draftOrder/channelStore";

const imgStyle = {
  width: "100%",
  height: 200,
};

const getChannelsLink = (isDev) => {
  if (isDev) {
    return [
      {
        name: "Shopify",
        url: "https://apps.shopify.com/janio-asia",
        logo: shopifyLogo,
      },
    ];
  }

  return [
    {
      name: "Shopify",
      url: "https://apps.shopify.com/janio-asia",
      logo: shopifyLogo,
    },
  ];
};

const NewChannelView = ({ shipperDetails, hasShopeeFeature, hasLazadaFeature }) => {
  const dispatch = useDispatch();

  const isDev = window.location.origin.includes("localhost") || window.location.origin.includes("staging");
  const channelsLink = getChannelsLink(isDev);

  // feature flag for shopee

  const redirectUrlShopee = useSelector((state) => {
    return state.channels.shopeeAuth.url;
  });

  const redirectUrlLazada = useSelector((state) => {
    return state.channels.lazadaAuth.url;
  });

  useEffect(() => {
    if (!redirectUrlShopee) {
      dispatch(handleShopeeAuth());
    }
    if (!redirectUrlShopee) {
      dispatch(handleLazadaAuth());
    }
  });

  if (hasShopeeFeature) {
    channelsLink.push({
      name: "Shopee",
      url: redirectUrlShopee,
      logo: shopeeLogo,
    });
  }

  if (hasLazadaFeature) {
    channelsLink.push({
      name: "Lazada",
      url: redirectUrlLazada,
      logo: lazadaLogo,
    });
  }

  return (
    <div>
      <div style={{ marginBottom: "4em" }}>
        <Link to={"/channels/"}>
          <Button type="link">Back to stores</Button>
        </Link>
      </div>
      <Row gutter={32}>
        {channelsLink.map((channel) => (
          <Col span={8}>
            <ChannelContainer>
              <a href={channel.url}>
                <img style={imgStyle} src={channel.logo} alt={`${channel.name} logo`} />
              </a>
            </ChannelContainer>
          </Col>
        ))}
      </Row>
    </div>
  );
};

function mapStateToProps({ agentAppDetails }) {
  const hasShopeeFeature = checkAllowedFeaturesAgentApp(agentAppDetails, "shopee");
  const hasLazadaFeature = checkAllowedFeaturesAgentApp(agentAppDetails, "lazada");

  return {
    hasShopeeFeature,
    hasLazadaFeature,
  };
}

export const NewChannel = compose(connect(mapStateToProps, {}))(NewChannelView);
