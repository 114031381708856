import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import _ from "lodash";

import {
  Card as AntCard,
  Icon,
  Row,
  Col,
  Button,
  Form as F,
  Alert,
  Radio,
  message,
  Tooltip,
} from "antd";

import {
  getDraftOrderDetail,
  validateOrder,
  resetDraftOrderErrors,
} from "./store";
import { translateErrorMsg, translateErrorField } from "./util";
import { StatusTag } from "./statusTag";
import { FormField } from "./components/input";
import { DraftOrderItems } from "./components/DraftOrderItems";
import { EmbeddedServiceSelector } from "./components/EmbeddedServiceSelector";
import { COUNTRY_TO_CURRENCY } from "../../utils/currency";

const DraftOrderItemSchema = Yup.object().shape({
  item_category: Yup.string()
    .required(
      "Please select the appropriate item category in the dropdown box available"
    )
    .nullable(),
  item_description: Yup.string().required("Required").nullable(),
  item_price_currency: Yup.string().required("Required").nullable(),
  item_price_value: Yup.number().required("Required").nullable(),
  item_qty: Yup.number().required("Required").nullable(),
  item_sku: Yup.string().nullable(),
});

const DraftOrderSchema = Yup.object().shape({
  consignee_address: Yup.string().required("Required").nullable(),
  consignee_country: Yup.string().required("Required").nullable(),
  consignee_name: Yup.string().required("Required").nullable(),
  consignee_number: Yup.string().required("Required").nullable(),
  consignee_postal: Yup.string().required("Required").nullable(),
  consignee_state: Yup.string().required("Required").nullable(),
  order_height: Yup.number().required("Required").nullable(),
  order_length: Yup.number().required("Required").nullable(),
  order_weight: Yup.number().required("Required").nullable(),
  order_width: Yup.number().required("Required").nullable(),
  service_id: Yup.number().required("Please select a location").nullable(),
  // We use this message as the 'Required' validation message as it will only ever be null
  // as a result of not finding any matching service because it should default to Pickup
  service_type: Yup.string()
    .required(
      "The service type is not available for this location. Please choose a different one."
    )
    .nullable(),
  cod_amt_to_collect: Yup.number()
    .nullable()
    .when("payment_type", (payment_type, schema) =>
      payment_type === "cod" ? schema.required("Required") : schema
    ),
  items: Yup.array().of(DraftOrderItemSchema),
});

export const ValidationResult = ({ validationResult }) => {
  const isDev =
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("staging");
  if (isDev && validationResult && validationResult.errors) {
    return (
      <Card title="Validation Result">
        <pre>{JSON.stringify(validationResult.errors, null, "\t")}</pre>
      </Card>
    );
  }
  return null;
};

const ErrorAlert = ({
  validationResult,
  formErrors,
  submissionErrors,
  values,
}) => {
  let errors = {};
  if (validationResult && validationResult.errors) {
    // each entry in `errors` is a list of string.
    // or a list of object, each with a list of string
    for (const k in validationResult.errors) {
      if (k in errors) {
        Array.prototype.push.apply(errors[k], validationResult.errors[k]);
      } else {
        errors[k] = validationResult.errors[k];
      }
    }
  }

  if (formErrors) {
    for (const k in formErrors) {
      // forErrors[k] is either a string, or a list of object, or an object
      const keyError = formErrors[k];
      if (typeof keyError === "string") {
        if (k in errors) {
          errors[k] = Object.assign([], errors[k]);
          errors[k].push(keyError);
        } else {
          errors[k] = [keyError];
        }
      } else if (Array.isArray(keyError)) {
        keyError.forEach((entry, i) => {
          if (typeof entry === "object") {
            for (const innerKey in entry) {
              const errorKey = `${k} ${i + 1} - ${innerKey}`;
              errors[errorKey] = [entry[innerKey]];
            }
          }
        });
      }
    }
  }

  if (submissionErrors) {
    for (const k in submissionErrors) {
      if (k in errors) {
        Array.prototype.push.apply(errors[k], submissionErrors[k]);
      } else {
        errors[k] = submissionErrors[k];
      }
    }
  }

  if (_.isEmpty(errors)) {
    return null;
  }

  if ("items" in errors) {
    let itemErrorsCount = 0;

    errors["items"].forEach((item_errors, i) => {
      if (Array.isArray(item_errors)) {
        for (const field in item_errors) {
          errors[`Items ${i + 1} - ${field}`] = item_errors[field];
        }
        itemErrorsCount += 1;
      } else if (typeof item_errors === "object") {
        for (const field in item_errors) {
          errors[`Items ${i + 1} - ${field}`] = item_errors[field];
        }
        itemErrorsCount += 1;
      }
    });

    //To track if all the item errors are related to item data, and not the `items`
    //property on the parent form, e.g. "Items are empty", in which case we want to delete the
    //errors on the parent field and display them on the individual items instead.
    if (itemErrorsCount === errors["items"].length) {
      delete errors.items;
    }
  }

  const errorContent = (
    <ul>
      {Object.keys(errors).map((field, i) => {
        if (Array.isArray(errors[field])) {
          return (
            <li key={i}>
              <strong>{translateErrorField(field)}:</strong>{" "}
              {`${errors[field]
                .map((e) => translateErrorMsg(field, e, values))
                .join(",")}`}
            </li>
          );
        } else {
          return Object.keys(errors[field]).map((innerField, innerIndex) => {
            let fieldName = `${field} - ${innerField}`;
            let errorMsg = `${translateErrorMsg(
              fieldName,
              errors[field][innerField],
              values
            )}`;
            return (
              <li key={innerIndex}>
                <strong>{translateErrorField(fieldName)}:</strong> {errorMsg}
              </li>
            );
          });
        }
      })}
    </ul>
  );

  return (
    <Alert
      showIcon
      message="Your shipment order is almost complete - Please provide the below info"
      description={errorContent}
      type="error"
    />
  );
};

const Card = (props) => {
  return (
    <AntCard style={{ marginTop: "12px", marginBottom: "12px" }} {...props}>
      {props.children}
    </AntCard>
  );
};

export const ViewDraftOrderDetails = withRouter((props) => {
  const draftOrderId = parseInt(props.match.params.id, 10);
  const dispatch = useDispatch();
  const formRef = useRef();

  // To validate on load
  useEffect(() => {
    if (formRef.current) {
      formRef.current.getFormikActions().validateForm();
    }
  });

  const secretKey = useSelector((state) => {
    if (state.shipperDetails && state.shipperDetails.shipperDetails) {
      return state.shipperDetails.shipperDetails.agent_application_secret_key;
    }
    return null;
  });

  useEffect(() => {
    if (secretKey) {
      dispatch(getDraftOrderDetail(secretKey, { orderId: draftOrderId }));
    }
  }, [secretKey, draftOrderId, dispatch]);

  const { draftOrder, isLoading, isValidating, validationErrors } = useSelector(
    (state) => {
      return {
        draftOrder: state.draftOrders.detail.data,
        isLoading: state.draftOrders.detail.loading,
        isValidating: state.draftOrders.isValidating,
        validationErrors: state.draftOrders.validationErrors,
      };
    }
  );

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const submit = (values, actions) => {
    dispatch(
      validateOrder(secretKey, {
        orderId: draftOrderId,
        params: values,
        callback: (result) => {
          if (result.status === "READY_TO_SHIP") {
            message.success("Order is now moved to Ready to Ship!", 1, () =>
              props.history.goBack()
            );
          }
        },
      })
    );
  };
  const resetFieldError = (fieldName) => {
    if (draftOrder.validation_result.errors[fieldName]) {
      dispatch(resetDraftOrderErrors(fieldName));
    }
  };

  return (
    <div>
      <Formik
        ref={formRef}
        validateOnBlur
        initialValues={draftOrder}
        isInitialValid={true}
        onSubmit={submit}
        validationSchema={DraftOrderSchema}
      >
        {(formikProps) => (
          <Form>
            <Row gutter={12} type="flex" align="middle">
              <Col span={18}>
                <p>
                  <Button type="link" onClick={() => props.history.goBack()}>
                    <Icon style={{ fontSize: "24px" }} type="arrow-left" />
                  </Button>
                  <span
                    style={{
                      fontSize: "1.5em",
                      marginRight: "8px",
                      marginLeft: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Editing Order #{draftOrder.shipper_order_id}
                  </span>
                  <span>
                    Status: <StatusTag>{draftOrder.status}</StatusTag>
                  </span>
                </p>
              </Col>
              <Col span={6}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={!formikProps.isValid}
                  loading={isLoading || isValidating}
                >
                  Save and continue
                </Button>
              </Col>
            </Row>
            {
              <ErrorAlert
                values={draftOrder}
                validationResult={draftOrder.validation_result}
                formErrors={formikProps.errors}
                submissionErrors={validationErrors}
              />
            }
            <EmbeddedServiceSelector formikProps={formikProps} />
            <DraftOrderItems
              draftOrder={draftOrder}
              formikProps={formikProps}
            />
            <Card title="Order Details">
              <Row gutter={8}>
                <Col span={6}>
                  <Field name="order_width">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "number",
                          label: "Width",
                          inputProps: { suffix: "cm" },
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={6}>
                  <Field name="order_length">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "number",
                          label: "Length",
                          inputProps: { suffix: "cm" },
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={6}>
                  <Field name="order_height">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "number",
                          label: "Height",
                          inputProps: { suffix: "cm" },
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={6}>
                  <Field name="order_weight">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "number",
                          label: "Weight",
                          inputProps: { suffix: "kg" },
                        }}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={10}>
                  <Field name="delivery_note" type="text">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "text",
                          label: "Delivery Note",
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={5}>
                  <Field name="incoterm" type="text">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "select",
                          options: [
                            { label: "None", value: null },
                            { label: "DDU", value: "DDU" },
                            { label: "DDP", value: "DDP" },
                          ],
                          label: (
                            <>
                              Incoterm &nbsp;
                              <Tooltip
                                title={
                                  <>
                                    <p>
                                      <strong>DDP</strong> indicates that the
                                      seller must cover duties, import
                                      clearance, and any taxes.
                                    </p>
                                    <p>
                                      <strong>DDU</strong> means that the seller
                                      is responsible for ensuring goods arrive
                                      safely to a destination; the buyer is
                                      responsible for import duties.
                                    </p>
                                  </>
                                }
                              >
                                <Icon type="question-circle" />
                              </Tooltip>
                            </>
                          ),
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={5}>
                  <Field name="payment_type" type="text">
                    {({ field }) => (
                      <F.Item
                        label="Payment Type"
                        validateStatus={
                          formikProps.errors[field.name] ? "error" : ""
                        }
                        help={formikProps.errors[field.name]}
                      >
                        <Radio.Group {...field} disabled={true}>
                          <Radio value="prepaid">Pre-Paid</Radio>
                          <Radio value="cod">COD</Radio>
                        </Radio.Group>
                      </F.Item>
                    )}
                  </Field>
                </Col>
                <Col span={4}>
                  <Field name="cod_amt_to_collect" type="number">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "number",
                          label: (
                            <>
                              COD Amount &nbsp;
                              <Tooltip title="We do not define foreign currency exchange rate. Please input the COD value in the local currency.">
                                <Icon type="question-circle" />
                              </Tooltip>
                            </>
                          ),
                          inputProps: {
                            disabled: formikProps.values.payment_type !== "cod",
                            suffix:
                              COUNTRY_TO_CURRENCY[
                                formikProps.values.consignee_country
                              ],
                          },
                        }}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </Card>
            <Card title="Customer Details">
              <Row gutter={8}>
                <Col span={12}>
                  <Field name="consignee_name" type="text">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          formikProps,
                          draftOrder,
                          inputType: "text",
                          label: "Consignee Name",
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={12}>
                  <Field name="consignee_number" type="text">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "text",
                          label: "Consignee Number",
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={12}>
                  <Field name="consignee_email" type="text">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "text",
                          label: "Consignee Email",
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={12}>
                  <Field name="consignee_country" type="text">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "text",
                          label: "Country",
                          // inputProps: draftOrder.consignee_country !== '' ? {disabled : true} : {disabled : false}
                          inputProps: { disabled: true },
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={12}>
                  <Field name="consignee_address" type="text">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "text",
                          label: "Address",
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={12}>
                  <Field name="consignee_city" type="text">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "text",
                          label: "City",
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={12}>
                  <Field name="consignee_state" type="text">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "text",
                          label: "State",
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={12}>
                  <Field name="consignee_postal" type="text">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "text",
                          label: "Postal",
                        }}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Field name="consignee_province" type="text">
                    {({ field }) => (
                      <FormField
                        {...{
                          field,
                          resetFieldError,
                          draftOrder,
                          formikProps,
                          inputType: "text",
                          label: "Province",
                        }}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      </Formik>
      {<ValidationResult validationResult={draftOrder.validation_result} />}
    </div>
  );
});
