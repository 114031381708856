import axios from 'axios'

export const updateLabelPreferenceApi = async (agentAppId, secretKey, labelType) => {
  try {
    const response = await axios.put(`/api/order/shipper-agent-app/${agentAppId}/`, {
      'secret_key': secretKey,
      'label_preference': labelType
    })
    return response.data
  } catch(err) {
    const response = (err.response && err.response.data) || {}
    response.isError = true
    return response
  }
}

export const updateReturnConsolidationPeriodApi = async (agentAppId, secretKey, period) => {
  try {
    const response = await axios.put(`/api/order/shipper-agent-app/${agentAppId}/`, {
      'secret_key': secretKey,
      'default_returns_consolidation_period': period
    })
    return response.data
  } catch(err) {
    const response = (err.response && err.response.data) || {}
    response.isError = true
    return response
  }
}


export const displayShipperAddressOnShippingLabelApi = async (agentAppId, secretKey, showLabelShipperAddress) => {
  try {
    const response = await axios.put(`/api/order/shipper-agent-app/${agentAppId}/`, {
      'secret_key': secretKey,
      'label_shipper_address': showLabelShipperAddress
    })
    return response.data
  } catch(err) {
    const response = (err.response && err.response.data) || {}
    response.isError = true
    return response
  }
}