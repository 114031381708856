import { useState, useEffect } from 'react'
import axios from 'axios'

const useStates = (country, for_returns_pickup=false, shouldFetch=true, for_service_level='') => {
  const [states, setStates] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {  
    const getstates = async () => {
      console.log('getting states...')
      setIsLoading(true)
      let params = {
        countries: country,
      }
      if (for_returns_pickup) {
        params = {...params, for_returns_pickup}
      }
      if (!!for_service_level) {
        params = {...params, for_service_level}
      }
      const response = await axios.get('/api/location/states/', {
        params,
        headers: { 'Content-Type': 'application/json'}
      })
      setStates(response.data)
      setIsLoading(false)
    }
    if (country && shouldFetch){
      getstates()
    }
  }, [country, shouldFetch, for_returns_pickup, for_service_level])

  return [states, isLoading]
}

export default useStates