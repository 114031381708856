import React, { Component } from 'react'
import { connect } from 'react-redux'
// import TermsInEnglish from '../common/termsAndConditions/termsEnglish'
import TermAndCondition from '../common/termsAndConditions/TermAndCondition';
import { fetchShipperDetails } from '../../actions/shipperDetailsActions';


class Terms extends Component {
  constructor() {
    super();
    this.state = {}
  }

  componentDidMount() {
    this.props.fetchShipperDetails();
  }

  render() {
    return (
      <div>
        {/* <TermsInEnglish className='terms-and-conditions--page' /> */}
        <TermAndCondition tncDocumentData={this.props.tncDocumentData} />
      </div>
    )
  }
}

function mapState({ terms }) {
  return {
    tncDocumentData: terms.data
  }
}

export default connect(mapState, {
  fetchShipperDetails
})(Terms)
