import { useState, useEffect } from 'react'
import axios from 'axios'

const useItemCategories = (shouldFetch=true) => {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    if (shouldFetch) {
      const getCategories = async () => {
        const response = await axios.get('/api/data/item-categories/')
        setCategories(response.data)
      }
      getCategories()
    }
  }, [shouldFetch])

  return categories
}

export default useItemCategories