import { useEffect } from 'react'
import { useFetch } from 'use-http'

const token = localStorage.getItem('token')

const useServices = (secretKey) => {
  const {data, loading, get} = useFetch(
    `/api/order/service/?${new URLSearchParams({
      secret_key: secretKey,
      new: true
    })}`,
    {
      data: [],
      headers: {
        Authorization: `Token ${token}`
      }
    }
  )

  useEffect(() => {
    if (secretKey) {
      get()
    }
  }, [secretKey, get])

  return [data, loading]
}

export default useServices