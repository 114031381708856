import React, { useEffect } from "react";
import {Row, Col, Button, Form as F, Input, Select, Icon, Checkbox, Modal} from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { useSecretKey } from '../../auth/useSecretKey';
import useCountries from '../../shopifyOrders/hooks/useCountries.js'
import useStoreAddresses from '../../shopifyOrders/hooks/useStoreAddresses'
import useServiceDef from '../../orderSubmitBulk/hooks/useServiceDef'
import { getDropoffOptions, getFilteredServiceDefinitions } from '../../draftOrder/components/ServiceSelector'
import { handleSourceInput, handleServiceTypeChoice, handleInput, submitForm as submitItemCategoryForm, setServiceTypeFormData, updateForm as updateItemCategoryForm, resetFormData, performToggleModal, performToggleModalAlert,  toggleConflictModal,submitServiceTypeForm } from './store'


const ItemCategoryForm = (props) => {
  const dispatch = useDispatch()
  const [secretKey] = useSecretKey()
  const formType = "serviceTypeForm"

  const [shipmentRuleStore, agentApplicationName] = useSelector((state) => {
    return [state.shipmentRules, state.shipperDetails?.shipperDetails?.shipper_name]
  });

  const isUpdateFormFlag = props.match.params.id?true:false
  const itemCountries = useCountries()
  const storeAdresses = useStoreAddresses()
  const [serviceDefinitions] = useServiceDef(secretKey)

  useEffect(() => {
    if(!secretKey){
      // dispatch
      console.log('no secret key')
    }

    if (isUpdateFormFlag){
      dispatch(setServiceTypeFormData(secretKey, props.match.params.id, getDropoffOptions, serviceDefinitions, agentApplicationName, storeAdresses))
    }else{
      dispatch(resetFormData(formType))
      dispatch(performToggleModal())

      if(shipmentRuleStore.dashboard.isModalVisibleAlert) {
        dispatch(performToggleModalAlert())
      }
    }
  }, [secretKey])


  // if (shipmentRuleStore[formType].meta_data.serviceType.length>0 && shipmentRuleStore[formType].targetFieldOptions.length<=0){
  //   console.log("******************************")
  //   let targetFieldOptions = []
  //     if (shipmentRuleStore[formType].meta_data.serviceType === 'dropoff'){
  //       targetFieldOptions = getDropoffOptions(serviceDefinitions, shipmentRuleStore[formType].rule_source_fields[0].matches_to_value, shipmentRuleStore[formType].rule_source_fields[1].matches_to_value, agentApplicationName)
  //     }else{
  //       targetFieldOptions = storeAdresses[0].filter((item)=>item.pickup_point_country === shipmentRuleStore[formType].rule_source_fields[0].matches_to_value)
  //     }
  //     dispatch(handleInput(formType, targetFieldOptions, 'targetFieldOptions'))
  // }


  const handleSourceInputChange = (event, name, index)=>{
    dispatch(handleSourceInput(formType, event, name, index))
  }

  const handleServiceTypeChange = (value, name)=>{
    dispatch(handleServiceTypeChoice(value))
    let targetFieldOptions = []
    if (value === 'dropoff' || value === "dropoff_express"){
      let ss = value === "dropoff_express" ? "Dropoff Express" : "Dropoff";
      targetFieldOptions = getDropoffOptions(serviceDefinitions, shipmentRuleStore[formType].rule_source_fields[0].matches_to_value, shipmentRuleStore[formType].rule_source_fields[1].matches_to_value, agentApplicationName, ss);
    }else{
      console.log("asdfasf", shipmentRuleStore[formType].rule_source_fields[0].matches_to_value);
      targetFieldOptions = storeAdresses[0].filter((item)=>item.pickup_point_country === shipmentRuleStore[formType].rule_source_fields[0].matches_to_value)
    }
    dispatch(handleInput(formType, targetFieldOptions, 'targetFieldOptions'))

  }

  const handleInputChange = (value, name)=>{
    let serviceType = shipmentRuleStore[formType].meta_data.serviceType
    dispatch(handleInput(formType, value, name))
    if (name === "addressID"){
      let addressObj;
      if (serviceType === 'dropoff' || serviceType === "dropoff_express") {
        console.log("asdfasdfj", serviceType,shipmentRuleStore[formType].meta_data.serviceType)
        addressObj = targetFiledsOnUpdate().filter((item)=>item.dropoff_id === value)[0]
      } else {
        console.log("asdfasdfj", serviceType,shipmentRuleStore[formType].meta_data.serviceType)
        let ss_type = shipmentRuleStore[formType].meta_data.serviceType === "pickup_express" ? "pickup_point" : shipmentRuleStore[formType].meta_data.serviceType;
        addressObj = targetFiledsForPickUpOnUpdate().filter((item)=>item[`${ss_type}_id`] === value)[0]
      }
      
      const originCountry = shipmentRuleStore[formType].rule_source_fields[0].matches_to_value
      const destinationCOuntry = shipmentRuleStore[formType].rule_source_fields[1].matches_to_value

      
      if (serviceType === 'pickup_point'){
        serviceType = 'Pickup';
      } else if(serviceType === "pickup_express") {
        serviceType = 'Pickup Express';
      } else if (serviceType === 'dropoff') {
        serviceType = 'Dropoff'
      } else if (serviceType === "dropoff_express") {
        serviceType = 'Dropoff Express'
      }

      const valueLookup = {
        "pickup_point":['pickup_point_contact_person', 'pickup_point_number', 'pickup_point_state', 'pickup_point_city', 'pickup_point_province', 'pickup_point_address', 'pickup_point_postal'],
        "pickup_express":['pickup_point_contact_person', 'pickup_point_number', 'pickup_point_state', 'pickup_point_city', 'pickup_point_province', 'pickup_point_address', 'pickup_point_postal'],
        "dropoff":['dropoff_point_contact_person', 'dropoff_point_number', 'dropoff_point_state', 'dropoff_point_city', 'dropoff_point_province', 'dropoff_address', 'dropoff_postal'],
        "dropoff_express":['dropoff_point_contact_person', 'dropoff_point_number', 'dropoff_point_state', 'dropoff_point_city', 'dropoff_point_province', 'dropoff_address', 'dropoff_postal']
      }
      let filteredServiceDef = getFilteredServiceDefinitions(
        serviceDefinitions,
        agentApplicationName,
        originCountry,
        destinationCOuntry,
        serviceType,
        addressObj.dropoff_id
      )
      let extraData = serviceType === 'Dropoff' || serviceType === "Dropoff Express" ? {"dropoff_id": addressObj.dropoff_id} : {"pickup_point_id": addressObj.pickup_point_id}

      const serviceID = filteredServiceDef[0].service_id
      const rule_target_fields = [
          {
            "target_name":"draft_order.pickup_contact_name",
            "target_value":addressObj[valueLookup[shipmentRuleStore[formType].meta_data.serviceType][0]]
          },
          {
            "target_name":"draft_order.pickup_contact_number",
            "target_value":addressObj[valueLookup[shipmentRuleStore[formType].meta_data.serviceType][1]]
          },
          {
            "target_name":"draft_order.pickup_state",
            "target_value":addressObj[valueLookup[shipmentRuleStore[formType].meta_data.serviceType][2]]
          },
          {
            "target_name": "draft_order.pickup_city",
            "target_value": addressObj[valueLookup[shipmentRuleStore[formType].meta_data.serviceType][3]]
          },
          {
            "target_name": "draft_order.pickup_province",
            "target_value": addressObj[valueLookup[shipmentRuleStore[formType].meta_data.serviceType][4]]
          },
          {
            "target_name": "draft_order.pickup_address",
            "target_value": addressObj[valueLookup[shipmentRuleStore[formType].meta_data.serviceType][5]]
          },
          {
            "target_name": "draft_order.pickup_postal",
            "target_value": addressObj[valueLookup[shipmentRuleStore[formType].meta_data.serviceType][6]]
          },
          { "target_name":"draft_order.service_id",
            "target_value":serviceID.toString()
          },
          { "target_name":"draft_order.service_type",
            "target_value":serviceType
          },
          { "target_name":"draft_order.extra_data",
            "target_value":JSON.stringify(extraData)
          }
      ]
      dispatch(handleInput(formType, rule_target_fields, 'rule_target_fields'))
    }
  }

  const handleInputCheck = (event)=>{
    dispatch(handleInput(formType, event.target.checked, event.target.name))
  }



  const getItemCountryOptions = ()=>{
    return itemCountries.map((item)=><Select.Option value={item}>{item}</Select.Option>)
  }

  const submitForm = (event)=>{
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("testj", shipmentRuleStore[formType])
        dispatch(submitItemCategoryForm(secretKey, shipmentRuleStore[formType]))
      }
    });

  }

  const updateForm = (event)=>{
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(updateItemCategoryForm(secretKey, shipmentRuleStore[formType].id, shipmentRuleStore[formType]))
      }
    });
  }

  const getValueFromID = (id)=>{
    let ss = shipmentRuleStore[formType].meta_data.serviceType ;
   if (shipmentRuleStore[formType].meta_data.serviceType === "dropoff_express") {
   ss = "dropoff"; 
   } else if (shipmentRuleStore[formType].meta_data.serviceType === "pickup_express" ) {
   ss ="pickup_point"  ;
   }
    const item = shipmentRuleStore[formType]?.targetFieldOptions?.filter((item)=>item[`${ss}_id`] === id)

    if (item?.length>0){
      return item[0][`${ss}_address`]
    }else{
      if (id.length>0){
        return shipmentRuleStore[formType].rule_target_fields.filter((item)=>item.target_name === "draft_order.pickup_address")[0].target_value
      }
      else
        return null
    }

  }


  const targetFiledsOnUpdate = () => {
    let ss = shipmentRuleStore[formType].meta_data.serviceType === "dropoff_express" ? "Dropoff Express" : "Dropoff";
    console.log("asdfasf targetFiledsOnUpdate", ss);
    let targetFieldOptions = getDropoffOptions(serviceDefinitions, shipmentRuleStore[formType].rule_source_fields[0].matches_to_value, shipmentRuleStore[formType].rule_source_fields[1].matches_to_value, agentApplicationName,ss)

    console.log(targetFieldOptions);

    return targetFieldOptions
  }
  const targetFiledsForPickUpOnUpdate = () => {
    let targetFieldOptions = storeAdresses[0].filter((item)=>item.pickup_point_country === shipmentRuleStore[formType].rule_source_fields[0].matches_to_value)
    return targetFieldOptions
  }
  const { getFieldDecorator } = props.form;

  const conditionRows = ()=>{
    const fields = shipmentRuleStore[formType].rule_source_fields
    const pickupCountryValue = fields.find(x => x.source_field_name === 'draft_order.pickup_country')
    const consigneeCountryValue = fields.find(x => x.source_field_name === 'draft_order.consignee_country')
    return <Row>
    <Col span={6}>
      <F.Item label="Pickup country">
      {getFieldDecorator(`pickup_country`,{
              rules: [{ required: true, message: 'This field is required' }], initialValue:pickupCountryValue.matches_to_value
            })(
              <Select showSearch value={pickupCountryValue.matches_to_value} style={{ width: '80%' }} onChange={(e)=>handleSourceInputChange(e, "matches_to_value",0)}>
                {getItemCountryOptions()}
              </Select>
            )}
      </F.Item>
    </Col>
    <Col span={6}>
      <F.Item label="Destination country">
      {getFieldDecorator(`destination_country`, {
              rules: [{ required: true, message: 'This field is required' }],initialValue:consigneeCountryValue.matches_to_value
            })(
              <Select showSearch value={consigneeCountryValue.matches_to_value} style={{ width: '80%' }} onChange={(e)=>handleSourceInputChange(e, "matches_to_value",1)}>
              {getItemCountryOptions()}
            </Select>
            )}
      </F.Item>
    </Col>
  </Row>

  }

  const showModalAlert = (event) => {
    dispatch(performToggleModalAlert())
  }
  return (

    <div>
      <F layout="horizontal" name="serviceTypeForm">

        <Button  style={{border: 'none',padding: '0px 10px 0 0',marginBottom: '10px',fontWeight: '600'}} onClick={showModalAlert} ><Icon type="arrow-left" style={{ fontSize: '15px', color: '#08c','position': 'relative',top: '-2px' }}/></Button><span style={{fontSize: '14px'}}>Back to Shipment Rules</span>

        <h4> Service Type Rule </h4>
        <Row>
          <Col span={8}>
            <F.Item label="Name">
              {getFieldDecorator('rule-name', {
                rules: [{ required: true, message: 'This field is required' }], initialValue:shipmentRuleStore[formType].rule_name
              })(
                <Input maxLength="32" placeholder="name" name="rule_name" value={shipmentRuleStore[formType].rule_name} onChange={(e)=>handleInputChange(e.target.value, e.target.name)}/>
              )}
            </F.Item>
          </Col>
        </Row>
        <h5> Select the condition </h5>
        {conditionRows()}
        <h5> Perform these actions </h5>
        <Row>
          <Col span={6}>
            <F.Item label="Service Type">
            {getFieldDecorator('service_type', {
                rules: [{ required: true, message: 'This field is required' }],initialValue:shipmentRuleStore[formType].meta_data.serviceType
              })(
                <Select value={shipmentRuleStore[formType].meta_data.serviceType} style={{ width: "80%" }} onChange={(e)=>handleServiceTypeChange(e)} disabled={shipmentRuleStore[formType].rule_source_fields[0].matches_to_value.length>0?false:true}>
                  <Select.Option value="pickup_point">Pickup</Select.Option>
                  <Select.Option value="dropoff">Dropoff</Select.Option>
                  {props.form.getFieldsValue().pickup_country === "Indonesia" && props.form.getFieldsValue().destination_country === "US" && 
                   <Select.Option  value="pickup_express">Pickup Express</Select.Option>
                 }
                 {props.form.getFieldsValue().pickup_country === "Indonesia" && props.form.getFieldsValue().destination_country === "US" && 
                    <Select.Option value="dropoff_express">Dropoff Express</Select.Option>
                 }
              </Select>
              )}
            </F.Item>
          </Col>
          {(shipmentRuleStore[formType].meta_data.serviceType==='pickup_point' || shipmentRuleStore[formType].meta_data.serviceType === "pickup_express") && <Col span={6}>
            <F.Item label={`Pickup address`}>
            {getFieldDecorator('pickup_point_address', {
                rules: [{ required: true, message: 'This field is required' }],initialValue:getValueFromID(shipmentRuleStore[formType].addressID)
              })(
                <Select value={getValueFromID(shipmentRuleStore[formType].addressID)} style={{ width: "80%" }} onChange={(e)=>handleInputChange(e, 'addressID')} disabled={shipmentRuleStore[formType].rule_source_fields[0].matches_to_value.length>0?false:true}>
                  {
                    targetFiledsForPickUpOnUpdate().map((item)=><Select.Option value={item.pickup_point_id}>{item.pickup_point_address}</Select.Option>)
                  }
                </Select>
              )}
            </F.Item>
          </Col>}
          {(shipmentRuleStore[formType].meta_data.serviceType==='dropoff' || shipmentRuleStore[formType].meta_data.serviceType==='dropoff_express') && <Col span={6}>
            <F.Item label={`Dropoff address`}>
            {getFieldDecorator('dropoff_address', {
                rules: [{ required: true, message: 'This field is required' }],initialValue:getValueFromID(shipmentRuleStore[formType].addressID)
              })(
                <Select value={getValueFromID(shipmentRuleStore[formType].addressID)} style={{ width: "80%" }} onChange={(e)=>handleInputChange(e, 'addressID')} disabled={shipmentRuleStore[formType].rule_source_fields[0].matches_to_value.length>0?false:true}>
                  {
                    targetFiledsOnUpdate().map((item)=><Select.Option value={item.dropoff_id}>{item.dropoff_address}</Select.Option>)
                  }
                </Select>
              )}
            </F.Item>
          </Col>}
        </Row>
        <F.Item >
          <Checkbox onChange={handleInputCheck} name="apply_on_existing_order" value={shipmentRuleStore[formType].apply_on_existing_order}>Apply to new and existing shipments</Checkbox>
        </F.Item>
        {isUpdateFormFlag?<Button type="primary" onClick={(event)=>updateForm(event)}>Update rule</Button>:<Button disabled={shipmentRuleStore[formType].addressID.length>0} type="primary" onClick={(event)=>submitForm(event)}>Create rule</Button>}

        <Button style={{marginLeft: '10px'}} onClick={showModalAlert}>Cancel</Button>
      </F>

      <Modal title="Are you sure you want to quit ?" visible={shipmentRuleStore.dashboard.isModalVisibleAlert} onCancel={showModalAlert} footer={null}>
        <Row>
          <Col span={24} align="middle">
            <h6 style={{marginBottom: '20px'}}>All progress in this session will be Lost!</h6>
            <Button onClick={showModalAlert} >Cancel</Button>
            <Button type="primary" style={{marginLeft: '10px'}} onClick={() => props.history.goBack()}>Quit</Button>
          </Col>
        </Row>

      </Modal>
      <Modal title="This rule seems to be exsisted" visible={shipmentRuleStore.serviceTypeForm.conflictModalFlag} onCancel={()=>{dispatch(toggleConflictModal())}} footer={null}>
          <Row>
            <Col span={24} align="middle">
              <h6 style={{marginBottom: '20px'}}>If you continue to create this rule, your previous rule will not be applied</h6>
              <Button onClick={()=>{dispatch(toggleConflictModal())}} >Cancel</Button>
              <Button type="primary" style={{marginLeft: '10px'}} onClick={() => {dispatch(dispatch(submitServiceTypeForm(secretKey, shipmentRuleStore[formType])))}}>Apply rules</Button>
            </Col>
          </Row>
          
        </Modal>
    </div>

  );
}



export const ServiceTypeForm = F.create({ name: 'Item_category_form' })(ItemCategoryForm)
