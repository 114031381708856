import {
  FETCH_AGENTAPP_DETAILS,
  EDIT_AGENTAPP_DETAILS,
  CLEAR_AGENTAPP_DETAILS,
  SHOW_LABEL_SHIPPER_ADDRESS
} from '../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_AGENTAPP_DETAILS:
      return { ...action.payload };
    case EDIT_AGENTAPP_DETAILS:
      return { ...state, ...action.payload };
    case CLEAR_AGENTAPP_DETAILS:
      return { ...state, error: null };
    case SHOW_LABEL_SHIPPER_ADDRESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
