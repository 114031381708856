import { combineReducers } from 'redux';
import authReducer from './authReducer';
import statsReducer from './statsReducer';
import registerReducer from './registerReducer';
import shipperDetailsReducer from './shipperDetailsReducer';
import pickupPointReducer from './pickupPointReducer';
import consigneeAddressReducer from './consigneeAddressReducer';
import searchPostalCodeReducer from './searchPostalCodeReducer';
import storeReducer from './storeReducer';
import orderReducer from './orderReducer';
import submitShopifyOrdersReducer from './submitShopifyOrdersReducer';
import itemDetailsReducer from './itemDetailsReducer';
import reportsReducer from './reportsReducer';
import invoicesReducer from './invoicesReducer';
import subProfilesReducer from './subProfilesReducer';
import analyticsReducer from './analyticsReducer';
import orderDefaultValueRedcuer from './orderDefaultValueRedcuer'
import othersReducer from './othersReducer'
import termsReducer from './termsReducer'
import agentAppDetailsReducer from './agentAppDetailsReducer'
import returnPointReducer from './returnPointReducer';
import draftOrderReducers from '../components/draftOrder/store';
import channelReducer from '../components/draftOrder/channelStore';
import laneConfigReducer from '../components/channels/laneConfigs/store';
import shipmentRuleReducer from '../components/channels/shipmentRules/store'

import { UNAUTH_USER } from '../actions/types';

const appReducer = combineReducers({
  auth: authReducer,
  stats: statsReducer,
  register: registerReducer,
  shipperDetails: shipperDetailsReducer,
  agentAppDetails: agentAppDetailsReducer,
  pickupPoint: pickupPointReducer,
  returnPoint: returnPointReducer,
  consigneeAddress: consigneeAddressReducer,
  searchPostalCode: searchPostalCodeReducer,
  store: storeReducer,
  order: orderReducer,
  submitShopifyOrders: submitShopifyOrdersReducer,
  itemDetails: itemDetailsReducer,
  reports: reportsReducer,
  invoices: invoicesReducer,
  subProfiles: subProfilesReducer,
  analytics: analyticsReducer,
  orderDefaultValue: orderDefaultValueRedcuer,
  others: othersReducer,
  terms: termsReducer,
  draftOrders: draftOrderReducers,
  channels: channelReducer,
  laneConfigs: laneConfigReducer,
  shipmentRules:shipmentRuleReducer
});

const rootReducer = (state, action) => {
  if (action.type === UNAUTH_USER) {
    state = {};
  }

  return appReducer(state, action);
}

export default rootReducer;
