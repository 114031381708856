import React, { useContext } from "react";
import { withTranslation } from 'react-i18next';
import { OrderSubmitBulkContext } from "../orderSubmitBulk.context";
import ButtonSelectCSVExcel from "../../common/ButtonSelectCSVExcel";
import { csvCovidTemplateLink, excelTemplateLink } from "../constants";
import { getCSVTemplateUrl, getMpsCSVTemplateUrl } from "../utils";

const CSVSelection = props => {
  const context = useContext(OrderSubmitBulkContext)

  const handleResult = (jsonObjects, file) => {
    context.setFileState({
      rawObjects: jsonObjects,
      selected: true,
      filename: file.name
    });
  };

  return (
    <div>
      <div style={{ padding: "20px 0" }}>
        <p className="mb-1">{props.t("bulkSubmit.pleaseUploadCSV")}</p>
        <div className="custom-control custom-checkbox mr-1 d-flex align-items-center mb-2">
          <input
            type="checkbox"
            id="is-mps-order"
            className="custom-control-input"
            name="is-mps-order"
            checked={context.isMpsOrder}
            onChange={e => {
              context.setIsMpsOrder(e.target.checked)
            }}
          />
          <label
            className="custom-control-label"
            htmlFor="is-mps-order"
          >
            MPS Orders
          </label>
        </div>
        {context.fileState.selected ? (
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ButtonSelectCSVExcel
                className="btn btn-primary"
                disabled={context.loading}
                text={`${context.fileState.filename}`}
                handleResult={handleResult}
              />
              <span style={{ padding: "0 20px" }}>{props.t("bulkSubmit.fileUploaded")}</span>
            </div>
            <div style={{ textAlign: "right" }}>
              <button
                onClick={() => context.validateRawObjects()}
                className="btn btn-primary"
                style={{
                  background: "#050593",
                  paddingLeft: 30,
                  paddingRight: 30
                }}
                disabled={context.loading}
              >
                {context.loading ? props.t("bulkSubmit.validate") : props.t("bulkSubmit.nextButton")}
              </button>
            </div>
          </div>
        ) : (
          <ButtonSelectCSVExcel
            disabled={context.loading}
            text = {props.t("bulkSubmit.uploadButton")}
            handleResult={handleResult}
          />
        )}
      </div>

      <hr />
      <div style={{ padding: "20px 0" }}>
        <p>{props.t("bulkSubmit.downloadCSV")}</p>
        <a
          href={context.isMpsOrder? getMpsCSVTemplateUrl():getCSVTemplateUrl()}
          className="btn"
          style={{ color: "#050593", border: "1px solid #050593" }}
        >
          {props.t("bulkSubmit.downloadCSVTemplate")}
        </a>
      </div>

      {!context.isMpsOrder &&
      <>
      <hr />
      <div style={{ padding: "20px 0" }}>
        <p>
        {props.t("bulkSubmit.ordersToPhilMalThai")}
        </p>
        <a
          href={excelTemplateLink}
          className="btn"
          style={{ color: "#050593", border: "1px solid #050593" }}
        >
          {props.t("bulkSubmit.downloadExcelTemplate")}
        </a>
      </div>

      <hr />
      <div style={{ padding: "20px 0" }}>
        <p>If you are importing Covid-19 tax exempted items into Indonesia, please use this CSV template.</p>
        <a
          href={csvCovidTemplateLink}
          className="btn"
          style={{ color: "#050593", border: "1px solid #050593" }}
        >
          {props.t("bulkSubmit.downloadCSVTemplate")}
        </a>
      </div>

      </>
      }
    </div>
  );
};

export default withTranslation("common")(CSVSelection);
