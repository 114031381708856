/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { DatePicker } from 'antd';
import { OrderSubmitBulkContext } from "../orderSubmitBulk.context";
import * as yup from "yup";
import Popup from "reactjs-popup";
import PickupInformation from "../../common/PickupInformation";
import { getServiceDefinition } from "../utils";
import DialogAddPickupPoint from "./DialogAddPickupPoint";
import {CustomPopUp} from './customPopUp'
import useServiceDef from '../hooks/useServiceDef'
import { getPickupDataFromPickupPoint, getPickupDataFromServiceDefinition } from "../../../utils/createOrder";
import ShipmentRestrictionAgreement from "../../common/ShipmentRestrictionAgreement";
import { Trans } from 'react-i18next'

// const { TextArea } = Input;


const ServiceDefinitionSelection = props => {
  const context = useContext(OrderSubmitBulkContext);
  const secretKey =
    (props.shipperDetails &&
      props.shipperDetails.agent_application_secret_key) ||
    "";
  const agentApplicationName =
    (props.shipperDetails &&
      props.shipperDetails.shipper_name) ||
    "";
  const [shipmentReweighsPolicy, setShipmentReweighsPolicy] = useState(false);
  const [openReweighPolicy, setOpenReweighPolicy] = useState(false);
  const [agreeShippingRestrictions, setAgreeShippingRestrictions] = useState(false)
  const [orderValueDeclaration, setOrderValueDeclaration] = useState(false);
  const [openOrderValueDeclaration, setOpenOrderValueDeclaration] = useState(
    false
  );
  const [serviceDefinitions, serviceDefinitionsLoading] = useServiceDef(secretKey, context.selectedServices.reduce((originCountries, serviceObj) => {
    if (!originCountries.origin_countries.includes(serviceObj.origin)){
      originCountries.origin_countries += `${serviceObj.origin},`
    }
    return originCountries
  },{origin_countries:''}))

  const [showAddPickupPointModal, setShowAddPickupPointModal] = useState(false);
  const [allowHiphenInTn, setAllowHiphenInTn] = useState(false);

  const getInitialValue = () => {
    const values = {};
    context.selectedServices.forEach((selectedService, index) => {
      const serviceLevel = selectedService.service_level || ''
      const fieldName = `${selectedService.origin}-${selectedService.destination}-${serviceLevel}`;
      values[fieldName] = {
        service: undefined,
        serviceDefinition: undefined,
        serviceType: undefined,
        dropoffPoint: undefined,
        pickupPoint: undefined,
        pickupDate: undefined,
        serviceLevel: selectedService.service_level
      };
    });
    return values;
  };
  const getValidationSchema = () => {
    const objects = {};
    context.selectedServices.forEach((selectedService, index) => {
      const serviceLevel = selectedService.service_level || ''
      const fieldName = `${selectedService.origin}-${selectedService.destination}-${serviceLevel}`;

      objects[fieldName] = yup.object().shape({
        service: yup.object().required("Required"),
        pickupPoint: yup.object().when("service", {
          is: value =>
            value &&
            value.service &&
            value.service.service_category.includes("pickup"),
          then: yup.object().required("Required")
        }),
        serviceDefinition: yup.array().required("serviceDefinition required").max(1,"Unable to find unique service.")
      });
    });
    const schema = yup.object().shape(objects);
    return schema;
  };

  const onSubmit = (values, actions) => {
    context.submitBulkOrder(values, allowHiphenInTn);
  };
  
  const formik = useFormik({
    initialValues: getInitialValue(),
    validationSchema: getValidationSchema(),
    onSubmit
  })

  const { values, errors, touched, setFieldValue, isSubmitting, isValid } = formik

  const getServiceTypeOptions = (selectedService)=>{
    return serviceDefinitions.filter(def=>selectedService.origin === def.origin_country && selectedService.destination === def.destination_country).map(serviceDef => serviceDef.service_type)
  .filter((value, index, self) => self.indexOf(value) === index).map(serviceType =>({value: serviceType, label:serviceType, serviceType}))
  }

  const getDropoffOptions = (selectedService) =>{
    // console.log("service ", `${selectedService.origin}-${selectedService.destination}-${selectedService.service_level || ''}`);
    // console.log("selected Service", selectedService);
    // console.log("values", values);  
    // console.log("extracted values", values[`${selectedService.origin}-${selectedService.destination}-${selectedService.service_level || ''}`]);
    const serviceType = values[`${selectedService.origin}-${selectedService.destination}-${selectedService.service_level || ''}`]?.serviceType?.serviceType;
    let exclusive = serviceDefinitions.filter(serviceDef=>(
      serviceDef.origin_country === selectedService.origin &&
      serviceDef.destination_country === selectedService.destination &&
      serviceDef.service_type === serviceType &&
      serviceDef.exclusive_agents.includes(agentApplicationName)
    ))
    if (exclusive.length > 0){
      return exclusive.map(serviceDefinition =>({
        label: serviceDefinition.dropoff_address,
        value: serviceDefinition.dropoff_id,
        serviceDefinition
      }))
    }else{
      return serviceDefinitions.filter(def => (selectedService.origin === def.origin_country && selectedService.destination === def.destination_country && def.service_type === serviceType)).map(serviceDefinition =>({
        label: serviceDefinition.dropoff_address,
        value: serviceDefinition.dropoff_id,
        serviceDefinition
      }))
    }
  }

  const getPickupPointOptions = ({ origin, destination }) => {
    return props.pickupPoints
      ? props.pickupPoints
          .filter(pickupPoint => pickupPoint.pickup_point_country === origin)
          .map(pickupPoint => ({
            value: pickupPoint.pickup_point_id,
            label: `${pickupPoint.pickup_point_name} - ${pickupPoint.pickup_point_address}`,
            pickupPoint
          }))
      : [];
  };



  return (
    <div>
      <DialogAddPickupPoint
        open={showAddPickupPointModal}
        onClose={() => setShowAddPickupPointModal(false)}
      />
      <p style={{ paddingTop: "20px"}}>
        Select pickup points for your orders. You may add more than one pickup
        points for each country pairings.
      </p>
      
      <form onSubmit={formik.handleSubmit}>
        {context.selectedServices.map((selectedService, index) => {
          const serviceLevel = selectedService.service_level || ''
          const fieldName = `${selectedService.origin}-${selectedService.destination}-${serviceLevel}`;

          let pickupData;
          const dropoffPoint =
            values[fieldName] && values[fieldName].dropoffPoint
              ? values[fieldName].dropoffPoint
              : null;
          const pickupPoint =
            values[fieldName] && values[fieldName].pickupPoint
              ? values[fieldName].pickupPoint.pickupPoint
              : null;
              console.log(values[fieldName]);
          if (pickupPoint) {
            pickupData = getPickupDataFromPickupPoint(pickupPoint);
          } else if (
            dropoffPoint
          ) {
            pickupData = getPickupDataFromServiceDefinition(dropoffPoint.serviceDefinition);
          }

          const showPickupDate = (
            values[fieldName] &&
            values[fieldName].serviceType &&
            (values[fieldName].serviceType.serviceType === 'Pickup' || values[fieldName].serviceType.serviceType === 'Pickup Express')   && 
            pickupData && ["Singapore", "Indonesia"].includes(selectedService.origin)
          )

          const getDisabledDate = (currentDate) => {
            const startDate = new Date()

            const pickupPoint = values[fieldName].pickupPoint.pickupPoint
            const { pickup_point_country, pickup_point_state } = pickupPoint

            if (pickup_point_country === 'Indonesia') {
              if (pickup_point_state === 'DKI Jakarta') {
                if (startDate.getHours() >= 12) {
                  startDate.setDate(startDate.getDate() + 1)
                }
              } else {
                if (startDate.getHours() <= 17) {
                  startDate.setDate(startDate.getDate() + 1)
                } else {
                  startDate.setDate(startDate.getDate() + 2)
                }
              }
            } else {
              if (startDate.getHours() >= 13) {
                startDate.setDate(startDate.getDate() + 1)
              }
            }
            return currentDate.diff(new Date(), 'days') >= 14 || currentDate.diff(startDate, 'days') < 0
          }

          const showDropoffError = (
            values[fieldName] &&
            (values[fieldName].dropoffPoint || (serviceDefinitions.filter(serviceDef=>(
              serviceDef.origin_country === selectedService.origin &&
              serviceDef.destination_country === selectedService.destination &&
              ["Dropoff", "Dropoff Express"].includes(serviceDef.service_type)
            ))).length ===0) &&
            errors[fieldName] &&
            errors[fieldName].serviceDefinition
          )

          const dropoffSelection = (
            <div>
              <div className="form-group">
                <label>
                  {props.t(
                    "submitOrder.selectDropoffAddressPlaceholder"
                  )}
                </label>
        
                <CustomPopUp 
                  message={'Choose a dropoff point to submit your orders.'} 
                  isOpen={false} 
                />
        
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <Select
                      isDisabled={showDropoffError}
                      isLoading={serviceDefinitionsLoading}
                      value={values[fieldName].dropoffPoint}
                      options={getDropoffOptions(selectedService)}
                      onChange={v => {
                        const filteredServiceDef = getServiceDefinition(agentApplicationName,serviceDefinitions,selectedService, values[fieldName].serviceType?.serviceType, v)
                        const dropoffPoint = v
                        const serviceDefinition = filteredServiceDef
                        const service = filteredServiceDef[0]


                        const laneValues = {
                          ...values[`${fieldName}`],
                          dropoffPoint,
                          serviceDefinition,
                          service
                        }

                        formik.setValues({
                          ...values,
                          [fieldName]: {
                            ...laneValues
                          }
                        })
                      }}
                    />
                  </div>
                </div>
                { showDropoffError&& (
                    <div className="invalid-text">
                      {errors[fieldName].serviceDefinition}
                    </div>
                  )}
              </div>
            </div>
          )
        
          const pickupSelection = (
            <div>
              <div className="form-group">
                <label>
                  {props.t(
                    "submitOrder.selectPickupAddressPlaceholder"
                  )}
                </label>
        
                <CustomPopUp 
                  message={'Choose a pickup or dropoff point where your orders are collected.'} 
                  isOpen={false} 
                />
        
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <Select
                      isDisabled={errors[fieldName] && errors[fieldName].serviceDefinition}
                      value={values[fieldName].pickupPoint}
                      options={getPickupPointOptions(
                        selectedService
                      )}
                      onChange={v => {
                        const laneValues = {
                          ...values[fieldName],
                          pickupPoint: v,
                          pickupDate: null
                        }
                        formik.setValues({
                          ...values,
                          [fieldName]: {
                            ...laneValues
                          }
                        })
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={e =>
                      setShowAddPickupPointModal(true)
                    }
                    className="btn btn-link"
                  >
                    Add
                  </button>
                </div>
                {
                  errors[fieldName] &&
                  errors[fieldName].serviceDefinition && (
                    <div className="invalid-text">
                      {errors[fieldName].serviceDefinition}
                    </div>
                  )}
              </div>
            </div>
          )

          return (
            <div className="row mb-4" key={index}>
              <div className="col-md-4">
                <div>
                  <p
                    style={{
                      marginBottom: 0,
                      fontWeight: "bold",
                      fontSize: 15
                    }}
                  >
                    {`${selectedService.origin} to ${selectedService.destination}`}
                  </p>
                  <p>{props.t("bulkSubmit.totalOrders")}: {selectedService.count}</p>
                </div>

                {!!selectedService.count
                && selectedService.service_level
                && selectedService.origin === "Indonesia"
                && selectedService.destination === "Indonesia" &&
                <div>
                  <p
                    style={{
                      marginBottom: 0,
                      fontWeight: "bold",
                      fontSize: 15
                    }}
                  >
                    Delivery Service Level
                  </p>
                  <p>{selectedService.service_level}</p>
                </div>
                }
              </div>

              <div className="col-md-8">
              

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        {props.t("submitOrder.selectServiceTypePlaceholder")}
                      </label>

                      <CustomPopUp 
                        message={'Choose a type of service available for the country pairing.'} 
                        isOpen={false} 
                      />
                      <Select
                        placeholder={props.t("bulkSubmit.nextButton")}
                        isLoading = {serviceDefinitionsLoading}
                        isDisabled = {serviceDefinitionsLoading}
                        value={values[fieldName].serviceType}
                        options={getServiceTypeOptions(selectedService)}
                        onChange={v => {
                          if (values[fieldName].dropoffPoint) {delete values[fieldName].dropoffPoint}
                          if (values[fieldName].pickupPoint) {delete values[fieldName].pickupPoint}

                          let laneValues = {
                            ...values[`${fieldName}`],
                            serviceType: v,
                          }
                          console.log(v);

                          let serviceDefinition, service, dropoffPoint
                          if (v.value === "Pickup" || v.value === "Pickup Express"){
                            let filteredServiceDef = getServiceDefinition(agentApplicationName, serviceDefinitions, selectedService, v.value, v)
                            serviceDefinition = filteredServiceDef
                            service = filteredServiceDef[0]
                          } else if (v.value.startsWith('Customs')){
                            let filteredServiceDef = getServiceDefinition(agentApplicationName, serviceDefinitions, selectedService, v.value, v)
                            serviceDefinition = filteredServiceDef
                            service = filteredServiceDef[0]
                            dropoffPoint = filteredServiceDef[0].service
                          }

                          laneValues = {
                            ...laneValues,
                            serviceDefinition,
                            service,
                            dropoffPoint
                          }

                          formik.setValues({
                            ...values,
                            [fieldName]: {
                              ...laneValues
                            }
                          })
                        }}
                      />
                      
                      {touched[fieldName] &&
                          errors[fieldName] &&
                          errors[fieldName].serviceType && (
                            <div className="invalid-text">
                              {errors[fieldName].serviceType}
                              </div>
                            )}
                    </div>
                  </div>
                    {values[fieldName] &&
                    values[fieldName].serviceType &&
                    (values[fieldName].serviceType.serviceType === 'Pickup' || values[fieldName].serviceType.serviceType === 'Pickup Express') && (
                    <div className="col-md-6">
                      {pickupSelection}
                    </div>
                    )}
                    {values[fieldName] &&
                    values[fieldName].serviceType &&
                    (values[fieldName].serviceType.serviceType === 'Dropoff' || values[fieldName].serviceType.serviceType === "Dropoff Express") && (
                    <div className="col-md-6">
                      {dropoffSelection}
                    </div>
                    )}
                </div>

                <div className="row">
                  <div className="col-md-6">
                    {showPickupDate &&
                      <div className="form-group">
                        <label>Pickup Date (Optional)</label>
                        <div>
                          <DatePicker
                            size="large"
                            name="pickup_date"
                            format="DD/MM/YY"
                            disabledDate={getDisabledDate}
                            onChange={v => {setFieldValue(`${fieldName}.pickupDate`, v)}}
                            style={{ width: "100%"}}
                            value={values[fieldName].pickupDate}
                          />
                          {touched.pickup_date && errors.pickup_date && (
                            <span className="invalid-text">{errors.pickup_date}</span>
                          )}
                        </div>
                      </div>
                    }
                  </div>

                  {pickupData && 
                    <div className="col-md-6">
                      <PickupInformation
                        pickup={pickupData}
                        pickupDate={values[fieldName].pickupDate}
                        title={`${values[fieldName].serviceType.serviceType} Information`}
                        type={values[fieldName].serviceType.serviceType}
                        deliveryOption={values[fieldName].serviceLevel}
                      />
                    </div>
                  }
                </div>

                {/* <div className="row">
                  <div className="col-md-6">
                    {
                      values[fieldName] &&
                      values[fieldName].serviceType &&
                      values[fieldName].serviceType.serviceType == 'Pickup' && pickupData && 
                      <div className="form-group">
                        <label>Pickup Notes (Optional)</label>
                        <div>
                          <TextArea
                            placeholder="Please let us know if you have any special pickup requests..."
                            autosize={{ minRows: 6, maxRows: 6 }}
                            style={{ height: "178px" }}
                            name="pickup_notes"
                            value={values[fieldName].pickup_notes}
                            onChange={v => {
                              setFieldValue(
                                `${fieldName}.pickup_notes`,
                                v.target.value 
                                )
                              }}
                          />
                        </div>
                      </div>  
                    }
                  </div>
                </div> */}
              </div>
          </div>
          );
        })}

        <hr />
        <div style={{ marginTop: 50}}>
        <div >

          <h3 style={{fontSize: "1.1rem", fontWeight:"bold" }}><Trans i18nKey='common:submitOrder.allowHiphensInTn' /></h3>

          <div className=" d-flex align-items-center">
            <div className="custom-control custom-radio">
              <input type="radio" className="custom-control-input" id="allow_hyphen_in_tracking_number-no" name="allow_hyphen_in_tracking_number"
                checked={allowHiphenInTn === false}
                onChange={e => {
                  setAllowHiphenInTn(false);
                }}
              />
              <label className="custom-control-label" htmlFor="allow_hyphen_in_tracking_number-no"><Trans i18nKey='common:submitOrder.no' /></label>
            </div>

            <div className="custom-control custom-radio ml-4">
              <input type="radio" className="custom-control-input" id="allow_hyphen_in_tracking_number-yes" name="allow_hyphen_in_tracking_number"
                checked={allowHiphenInTn === true}
                onChange={e => {
                  setAllowHiphenInTn(true);
                }}
              />
              <label className="custom-control-label" htmlFor="allow_hyphen_in_tracking_number-yes"><Trans i18nKey='common:submitOrder.yes' /></label>
            </div>

          </div>
        </div>

          <h3 style={{fontSize: "1.1rem", fontWeight:"bold", marginTop: 50 }}>Shipment Reweighs Policy</h3>
          <div className="d-flex flex-row" style={{ marginTop: 8 }}>
            <div className="custom-control custom-checkbox mr-1">
              <input
                type="checkbox"
                id="shipmentReweighsPolicy"
                className="custom-control-input mr-2"
                name="shipmentReweighsPolicy"
                checked={shipmentReweighsPolicy}
                onChange={e => {
                  setShipmentReweighsPolicy(e.target.checked);
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="shipmentReweighsPolicy"
              >
                {props.t("submitOrder.reweighCheckboxText")}
              </label>
            </div>

            <Popup
              trigger={
                <a
                  style={{
                    borderBottom: "1px dashed currentColor",
                    textDecoration: "none"
                  }}
                  onClick={e => {
                    e.preventDefault();
                    setOpenReweighPolicy(true);
                  }}
                >
                  {props.t("submitOrder.whyIsThisNeeded")}
                </a>
              }
              position="top left"
              open={openReweighPolicy}
              onOpen={() => setOpenReweighPolicy(true)}
              contentStyle={{
                width: 280,
                height: 190,
                borderRadius: "5px",
                boxShadow: "5px 5px 15px darkgrey",
                borderWidth: 0
              }}
            >
              <div className="d-flex flex-column align-content-center">
                <label className="font-weight-bold m-3">
                  <span>
                    {props.t("submitOrder.shipmentReweighsPolicy")}
                  </span>
                </label>
                <label className="ml-3 mr-3" style={{ fontSize: 13 }}>
                  {props.t("submitOrder.shipmentReweighsPolicyText")}
                </label>
                <div className="d-flex justify-content-end mb-1">
                  <button
                    type="button"
                    className="btn btn-primary mt-1 mr-3"
                    onClick={() => setOpenReweighPolicy(false)}
                  >
                    {props.t("submitOrder.gotIt")}
                  </button>
                </div>
              </div>
            </Popup>
          </div>
        </div>

        <div style={{ marginTop: 50 }}>
          <h3 style={{fontSize: "1.1rem", fontWeight:"bold"}}>Order Value Declaration</h3>
          <div className="d-flex flex-row" style={{ marginTop: 8 }}>
            <div className="custom-control custom-checkbox mr-1">
              <input
                id="orderValueDeclaration"
                type="checkbox"
                className="custom-control-input mr-2"
                name="declaration_checkbox"
                checked={orderValueDeclaration}
                onChange={e => {
                  setOrderValueDeclaration(e.target.checked);
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="orderValueDeclaration"
              >
                {props.t("submitOrder.declarationBulkCheckboxText")}
              </label>
            </div>

            <Popup
              trigger={
                <a
                  style={{
                    borderBottom: "1px dashed currentColor",
                    textDecoration: "none"
                  }}
                  onClick={e => {
                    e.preventDefault();
                    setOpenOrderValueDeclaration(true);
                  }}
                >
                  {props.t("submitOrder.whyIsThisNeeded")}
                </a>
              }
              position="top left"
              open={openOrderValueDeclaration}
              onOpen={() => setOpenOrderValueDeclaration(true)}
              contentStyle={{
                width: 280,
                height: 190,
                borderRadius: "5px",
                boxShadow: "5px 5px 15px darkgrey",
                borderWidth: 0
              }}
            >
              <div className="d-flex flex-column align-content-center">
                <label className="font-weight-bold m-3">
                  <span>
                    {props.t("submitOrder.orderValueDeclaration")}
                  </span>
                </label>
                <label className="ml-3 mr-3" style={{ fontSize: 13 }}>
                  {props.t("submitOrder.orderValueDeclarationText")}
                </label>
                <div className="d-flex justify-content-end mb-1">
                  <button
                    type="button"
                    className="btn btn-primary mt-1 mr-3"
                    onClick={() => setOpenOrderValueDeclaration(false)}
                  >
                    {props.t("submitOrder.gotIt")}
                  </button>
                </div>
              </div>
            </Popup>
          </div>
        </div>

        <div style={{ marginTop: 50 }}>
          <ShipmentRestrictionAgreement
            value={agreeShippingRestrictions}
            onChange={() => setAgreeShippingRestrictions(!agreeShippingRestrictions)}
            />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 50
          }}
        >
          <button
            type="button"
            className="btn"
            style={{ 
              color: "#050593", 
              border: "1px solid #050593",
              paddingLeft: 30,
              paddingRight: 30

            }}
            onClick={context.handleBackButton}
          >
            Back
          </button>
          <button
            type="submit"
            style={{ paddingLeft: 30, paddingRight: 30, background: "#050593"}}
            className="btn btn-primary"
            disabled={
              isSubmitting
              || !isValid
              || !orderValueDeclaration
              || !shipmentReweighsPolicy
              || !agreeShippingRestrictions
            }
          >
            {isSubmitting ? "Submiting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

const mapState = ({ order, pickupPoint, shipperDetails }) => ({
  services: order.service,
  pickupPoints: pickupPoint.pickupPoints,
  shipperDetails: shipperDetails.shipperDetails
});

export default withTranslation("common")(connect(mapState)(ServiceDefinitionSelection));
