import React, { useEffect } from "react";
import { Table, Button, } from 'antd'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { useSecretKey } from '../../auth/useSecretKey'
import { fetchLaneConfigs } from './store'

const COLUMNS = [
  {
    title: 'Origin Country',
    dataIndex: 'origin_country',
  },
  {
    title: 'Destination Country',
    dataIndex: 'destination_country',
  },
  {
    title: 'Service type',
    dataIndex: 'service_type',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      return (
        <Link to={"/channels/lane-configs/"+record.id}>
          <Button type="primary">Edit</Button>
        </Link>
      );
    }
  }
];

export const ManageLaneConfigs = () => {
  const dispatch = useDispatch()
  const [secretKey] = useSecretKey()

  const laneConfigsStore = useSelector((state) => {
    return state.laneConfigs.entries
  });

  useEffect(() => {
    if(secretKey && !laneConfigsStore.loaded){
      dispatch(fetchLaneConfigs(secretKey));
    }
  }, [dispatch, laneConfigsStore.loaded, secretKey]);

  return (
    <div>
      <div className="link-container">
        <Link to={"/channels/lane-configs/new/"}>
          <Button type="primary">Add Lane Config</Button>
        </Link>
      </div>
      <Table
        rowKey="id"
        dataSource={laneConfigsStore.data}
        columns={COLUMNS}
        loading={laneConfigsStore.loading} />
    </div>
  );
}
