import React from "react";
import { format, addBusinessDays } from "date-fns";
import { parseISO } from "date-fns/esm";
import add from "date-fns/add";
import Cookies from "universal-cookie";

let user_id;
let shipper_email;
export let allowedFeaturesAgentApp;
if (process.env.REACT_APP_IS_PRODUCTION) {
  user_id = 5;
  shipper_email = "junkai.ng@janio.asia";

  allowedFeaturesAgentApp = {
    returnOrder: [
      2, // Janio Shipper
      1134, // LOVET (S) PTE  LTD
      274, // Playdress
      479, // Thesocialdataco
      1358, // Wish Paris
      1359, // PARIS & ME PTE LTD
      945, // Pangaea Holdings Inc
      1263, // Arden Teal LLP,
      1279, // F J Benjamin (Singapore)   - Pretty Ballerina>
      743, // My Scent
      623, // Developer's Client Account
    ],
    shopee: [
      2, // Janio Shipper
      2348, // Alcosm Singapore
      4533, // MP Warehouse
      851, // Hoomie
      8277, // BioFinest
      623, // Developer's Client Account
    ],
    lazada: [
      2348, // Alcosm Singapore
      623, // Developer's Client Account
    ],
  };
} else if (process.env.REACT_APP_IS_STAGING) {
  user_id = 422;
  shipper_email = "user@email.com";

  allowedFeaturesAgentApp = {
    returnOrder: [
      266, // Janio Shipper
    ],
    shopee: [
      266, // Janio Shipper
      2348, // Alcosm Singapore
      4533, // MP Warehouse
      851, // Hoomie
    ],
    lazada: [
      266, // Janio Shipper
    ],
  };
} else {
  allowedFeaturesAgentApp = {
    returnOrder: [],
    shopee: [],
    lazada: [],
  };
}

export const checkIsJanioShipper = (shipperDetails) => {
  return !!shipperDetails
    ? shipperDetails.user_id === user_id &&
        shipperDetails.shipper_email === shipper_email
    : false;
};

export const checkAllowedFeaturesAgentApp = (agentAppDetails, featureName) => {
  const { agent_application_id } = agentAppDetails;
  const feature = allowedFeaturesAgentApp[featureName];
  return feature.includes(parseInt(agent_application_id));
};

const skipDashboardComponentUrls = [
  "/signin",
  "/signin-shopify",
  "/register/success",
  "/register",
  "/password-sent/",
  "/reset-password/success",
  "/reset-password",
  "/consignee-customs-document",
  "/dropoff-points",
  "/neto/connect",
  "/neto/uninstall",
  "/rts-reschedule",
  "/select-dropoff-returns",
  "/select-returns-service",
  "/metro-order/",
];
export const shouldSkipDashboardComponent = () => {
  const pathname = window.location.pathname;
  return !!skipDashboardComponentUrls.find((url) => pathname.startsWith(url));
};

export const titleize = (str) => {
  if (typeof str !== "string") {
    throw new TypeError("Expected a string");
  }

  return str.toLowerCase().replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
};

export const capitalizeUnderscore = (string) => {
  const removeUndercore = string.split("_").join(" ");
  return titleize(removeUndercore);
};

export const capitalizeWord = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const formatDateTime = (dateStr) => {
  try {
    const dateInstance = new Date(parseISO(dateStr));
    const formattedDate = format(dateInstance, "MMM d, yyyy");
    const formattedTime = format(dateInstance, "hh:mm a");
    return [formattedDate, formattedTime];
  } catch {
    console.log("invalid date string");
    return ["", ""];
  }
};

export function getTrackerUrl(trackingNo) {
  return process.env.REACT_APP_IS_PRODUCTION
    ? `https://tracker.janio.asia/${trackingNo}`
    : `http://tracker.staging.janio.asia/${trackingNo}`;
}

export const isAgentApp = (agentAppNames = [], agentAppDetails) => {
  if (!agentAppNames.length || !Object.entries(agentAppDetails || {}).length) {
    return true;
  }
  return agentAppNames.some((agentApp) =>
    agentAppDetails?.agent_application_name?.toLowerCase().includes(agentApp)
  );
};

export const getRootDomain = () => {
  const splittedHost = window.location.host.split(".").reverse();
  const domain = `.${splittedHost[1]}.${splittedHost[0]}`;
  return domain;
};

export const getSelfServeUrl = () => {
  const path = "/auth/migration/";
  return process.env.REACT_APP_IS_PRODUCTION
    ? `https://ss.janio.asia${path}`
    : `https://ss.staging.janio.asia${path}`;
};

export const handleRedirectToSelfServe = (source, token) => {
  if (!!source && !!token && source.toLowerCase() === "selfserve") {
    const cookies = new Cookies();
    const cookieExpiredOn = add(new Date(), { minutes: 1 });
    const domain = getRootDomain();
    cookies.set("_tkn.merchant.janio.asia", token, {
      expires: cookieExpiredOn,
      path: "/",
      domain,
    });
    const selfServeUrl = getSelfServeUrl();
    window.location.replace(selfServeUrl);
    return true;
  }
  return false;
};

export const getWorkingDays = (startDate, days = 2) => {
  // startDate format: yyyy-mm-dd
  if (!startDate) {
    return {};
  }

  const dateObj = new Date(startDate);
  const endDateOjb = addBusinessDays(dateObj, days);
  const endDate = format(endDateOjb, "dd/MM/yyyy");
  return {
    startDate: format(dateObj, "dd/MM/yyyy"),
    endDate,
  };
};

export const ChannelContainer = ({ children }) => {
  return (
    <div style={{ padding: "8px", boxShadow: "4px 4px 4px 1px #eee" }}>
      {children}
    </div>
  );
};
