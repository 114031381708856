export const domesticServices = {
  FIRSTMILE_ONLY: 'First Mile Only (pickup/dropoff)',
  FIRSTMILE_DELIVERY: 'First Mile (pickup/dropoff) + Delivery'
}

export const crossBorderServices = {
  FIRSTMILE_AIRFREIGHT: 'First Mile (pickup/dropoff) + Airfreight',
  FIRSTMILE_CUSTOMS_LASTMILE: 'First Mile (pickup/dropoff) + Airfreight + Customs + Lastmile'
}

export const serviceTypes = {
  PICKUP_FROM_CUSTOMER: "Pickup from customer's address",
  DROPOFF: 'Dropoff',
  LETS_CUSTOMER_DECIDE: 'Let customer decide (pickup/dropoff)'
}

export const domesticServiceEnums = {
  PICKUP: 'PICKUP',
  PICKUP_NO_CONSO: 'PICKUP_NO_CONSO',
  DROPOFF: 'DROPOFF',
  DROPOFF_NO_CONSO: 'DROPOFF_NO_CONSO',
  SHOPPERCHOICE: 'SHOPPERCHOICE',
  SHOPPERCHOICE_NO_CONSO: 'SHOPPERCHOICE_NO_CONSO',
  PICKUP_DELIVERY: 'PICKUP_LM',
  PICKUP_DELIVERY_NO_CONSO: 'PICKUP_LM_NO_CONSO',
  DROPOFF_DELIVERY: 'DROPOFF_LM',
  DROPOFF_DELIVERY_NO_CONSO: 'DROPOFF_LM_NO_CONSO',
  SHOPPERCHOICE_DELIVERY: 'SHOPPERCHOICE_LM',
  SHOPPERCHOICE_DELIVERY_NO_CONSO: 'SHOPPERCHOICE_LM_NO_CONSO'
}
const crossBorderServiceEnums =  {
  PICKUP_AIRFREIGHT: 'PICKUP_AF',
  PICKUP_AIRFREIGHT_NO_CONSO: 'PICKUP_AF_NO_CONSO',
  DROPOFF_AIRFREIGHT: 'DROPOFF_AF',
  DROPOFF_AIRFREIGHT_NO_CONSO: 'DROPOFF_AF_NO_CONSO',
  SHOPPERCHOICE_AIRFREIGHT: 'SHOPPERCHOICE_AF',
  SHOPPERCHOICE_AIRFREIGHT_NO_CONSO: 'SHOPPERCHOICE_AF_NO_CONSO',
  PICKUP_AIRFREIGHT_CUSTOMS_DELIVERY: 'PICKUP_AF_CC_LM',
  PICKUP_AIRFREIGHT_CUSTOMS_DELIVERY_NO_CONSO: 'PICKUP_AF_CC_LM_NO_CONSO',
  DROPOFF_AIRFREIGHT_CUSTOMS_DELIVERY: 'DROPOFF_AF_CC_LM',
  DROPOFF_AIRFREIGHT_CUSTOMS_DELIVERY_NO_CONSO: 'DROPOFF_AF_CC_LM_NO_CONSO',
  SHOPPERCHOICE_AIRFREIGHT_CUSTOMS_DELIVERY: 'SHOPPERCHOICE_AF_CC_LM',
  SHOPPERCHOICE_AIRFREIGHT_CUSTOMS_DELIVERY_NO_CONSO: 'SHOPPERCHOICE_AF_CC_LM_NO_CONSO'
}

export const serviceDefinitions = {
  [domesticServiceEnums.PICKUP]: {
    service: domesticServices.FIRSTMILE_ONLY,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER
  },
  [domesticServiceEnums.PICKUP_NO_CONSO]: {
    service: domesticServices.FIRSTMILE_ONLY,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER
  },
  [domesticServiceEnums.DROPOFF]: {
    service: domesticServices.FIRSTMILE_ONLY,
    serviceType: serviceTypes.DROPOFF
  },
  [domesticServiceEnums.DROPOFF_NO_CONSO]: {
    service: domesticServices.FIRSTMILE_ONLY,
    serviceType: serviceTypes.DROPOFF
  },
  [domesticServiceEnums.SHOPPERCHOICE]: {
    service: domesticServices.FIRSTMILE_ONLY,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE
  },
  [domesticServiceEnums.SHOPPERCHOICE_NO_CONSO]: {
    service: domesticServices.FIRSTMILE_ONLY,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE
  },
  // ===
  [domesticServiceEnums.PICKUP_DELIVERY]: {
    service: domesticServices.FIRSTMILE_DELIVERY,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER
  },
  [domesticServiceEnums.PICKUP_DELIVERY_NO_CONSO]: {
    service: domesticServices.FIRSTMILE_DELIVERY,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER
  },
  [domesticServiceEnums.DROPOFF_DELIVERY]: {
    service: domesticServices.FIRSTMILE_DELIVERY,
    serviceType: serviceTypes.DROPOFF
  },
  [domesticServiceEnums.DROPOFF_DELIVERY_NO_CONSO]: {
    service: domesticServices.FIRSTMILE_DELIVERY,
    serviceType: serviceTypes.DROPOFF
  },
  [domesticServiceEnums.SHOPPERCHOICE_DELIVERY]: {
    service: domesticServices.FIRSTMILE_DELIVERY,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE
  },
  [domesticServiceEnums.SHOPPERCHOICE_DELIVERY_NO_CONSO]: {
    service: domesticServices.FIRSTMILE_DELIVERY,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE
  },
  // ===
  [crossBorderServiceEnums.PICKUP_AIRFREIGHT]: {
    service: crossBorderServices.FIRSTMILE_AIRFREIGHT,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER,
  },
  [crossBorderServiceEnums.PICKUP_AIRFREIGHT_NO_CONSO]: {
    service: crossBorderServices.FIRSTMILE_AIRFREIGHT,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER,
  },
  [crossBorderServiceEnums.DROPOFF_AIRFREIGHT]: {
    service: crossBorderServices.FIRSTMILE_AIRFREIGHT,
    serviceType: serviceTypes.DROPOFF,
  },
  [crossBorderServiceEnums.DROPOFF_AIRFREIGHT_NO_CONSO]: {
    service: crossBorderServices.FIRSTMILE_AIRFREIGHT,
    serviceType: serviceTypes.DROPOFF,
  },
  [crossBorderServiceEnums.SHOPPERCHOICE_AIRFREIGHT]: {
    service: crossBorderServices.FIRSTMILE_AIRFREIGHT,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE,
  },
  [crossBorderServiceEnums.SHOPPERCHOICE_AIRFREIGHT_NO_CONSO]: {
    service: crossBorderServices.FIRSTMILE_AIRFREIGHT,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE,
  },
  // ===
  [crossBorderServiceEnums.PICKUP_AIRFREIGHT_CUSTOMS_DELIVERY]: {
    service: crossBorderServices.FIRSTMILE_CUSTOMS_LASTMILE,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER,
  },
  [crossBorderServiceEnums.PICKUP_AIRFREIGHT_CUSTOMS_DELIVERY_NO_CONSO]: {
    service: crossBorderServices.FIRSTMILE_CUSTOMS_LASTMILE,
    serviceType: serviceTypes.PICKUP_FROM_CUSTOMER,
  },
  [crossBorderServiceEnums.DROPOFF_AIRFREIGHT_CUSTOMS_DELIVERY]: {
    service: crossBorderServices.FIRSTMILE_CUSTOMS_LASTMILE,
    serviceType: serviceTypes.DROPOFF,
  },
  [crossBorderServiceEnums.DROPOFF_AIRFREIGHT_CUSTOMS_DELIVERY_NO_CONSO]: {
    service: crossBorderServices.FIRSTMILE_CUSTOMS_LASTMILE,
    serviceType: serviceTypes.DROPOFF,
  },
  [crossBorderServiceEnums.SHOPPERCHOICE_AIRFREIGHT_CUSTOMS_DELIVERY]: {
    service: crossBorderServices.FIRSTMILE_CUSTOMS_LASTMILE,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE,
  },
  [crossBorderServiceEnums.SHOPPERCHOICE_AIRFREIGHT_CUSTOMS_DELIVERY_NO_CONSO]: {
    service: crossBorderServices.FIRSTMILE_CUSTOMS_LASTMILE,
    serviceType: serviceTypes.LETS_CUSTOMER_DECIDE,
  },
}

export const availablePickupDateCountries = ["Singapore", "Indonesia"]