import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Jumbotron } from 'react-bootstrap'
import { Radio } from 'antd'
import { Trans } from 'react-i18next'
import { updateAgentAppLabelPreference } from '../../actions/agentAppInfoActions';
import { updateReturnConsolidationPeriodApi } from './changeLabelApi'

const ReturnConsolidation = ({ agentAppDetails, updateAgentAppLabelPreference }) => {
  const [consolidationValue, setConsolidationValue] = useState()
  const [, setshowSuccess] = useState(false)
  const [, setshowError] = useState(false)
  const [loading, setloading] = useState(false)

  const handleChange = e => {
    let { value } = e.target
    setConsolidationValue(value)
  }

  const handleSave = async () => {
    setshowSuccess(false)
    setshowError(false)
    setloading(true)
    const data = await updateReturnConsolidationPeriodApi(
      agentAppDetails.agent_application_id,
      agentAppDetails.agent_application_secret_key,
      consolidationValue
    )
    if (!data.isError) {
      updateAgentAppLabelPreference({
        default_returns_consolidation_period: consolidationValue
      })
      setshowSuccess(true)
    } else {
      setshowError(true)
    }
    setloading(false)
  }

  useEffect(() => {
    if (
        !!agentAppDetails.default_returns_consolidation_period
        && agentAppDetails.default_returns_consolidation_period !== consolidationValue) {
      setConsolidationValue(agentAppDetails.default_returns_consolidation_period)
    }
  }, [agentAppDetails.default_returns_consolidation_period])  

  return (
    <Jumbotron className="p-4 border border-secondary">
      <div className="h5 font-weight-bold capitalize mb-1">Return Consolidation</div>
      <p>Only applies to cross-border shipping</p>
      <div>
        <Radio.Group onChange={handleChange} value={consolidationValue}>
          <Radio value="weekly">Weekly</Radio>
          <Radio value="bi-weekly">Bi-Weekly</Radio>
          <Radio value="monthly">Monthly</Radio>
          <Radio value="no consolidation">No Consolidation</Radio>
        </Radio.Group>
      </div>

      <button
        type="button"
        className="mt-4 w-100 btn btn-lg btn-success"
        onClick={handleSave}
        disabled={(agentAppDetails.default_returns_consolidation_period === consolidationValue) || loading}
        >
          {loading ? 'Saving...':<Trans i18nKey="common:common.save" />}
      </button>
    </Jumbotron>
  )
}

const mapState = ({ agentAppDetails }) => ({
  agentAppDetails
})

export default connect(
  mapState, {
    updateAgentAppLabelPreference: updateAgentAppLabelPreference
  }
)(ReturnConsolidation)