import React, { useContext } from 'react'
import Select from 'react-select'
import useServiceLevel from '../hooks/useServiceLevel'
import { ShopifyOrderContext } from '../ShopifyOrderContext'

const SelectDeliveryOptions = ({
  pickupCountry, consigneeCountry, isDisabled, error, onChange, ...rest
}) => {
  const context = useContext(ShopifyOrderContext)

  const [serviceLevels, serviceLevelsLoading] = useServiceLevel(
    pickupCountry, consigneeCountry, context.secretKey
  )
  console.log('serviceLevels', serviceLevels)
  const getDeliveryOptions = !!serviceLevels.length?
    serviceLevels[0].service_levels.map(level => ({
      value: level,
      label: level
    }))
    :
    []

  return (
    <div className="form-group">
      <label className="submit-title">Delivery Service Level</label>
      <div>
        <Select
          options={getDeliveryOptions}
          isDisabled={isDisabled || serviceLevelsLoading}
          isLoading={serviceLevelsLoading}
          onChange={onChange}
          placeholder="Select delivery option"
          {...rest}
        />
        {error &&
        <span className="invalid-text">{error}</span>
        }
      </div>
    </div>
  )
}

export default SelectDeliveryOptions