import React, { useEffect, useState, useContext } from 'react'
import { connect } from 'react-redux'
import { fetchOrderDefaultValues } from '../../../actions/orderDefaultValueActions'
import { ShopifyOrderContext } from '../ShopifyOrderContext'
import { withTranslation, Trans } from 'react-i18next'
import { compose } from 'recompose'
import Select from 'react-select'
import _ from 'lodash'

const OrderDefaultValueSelection = ({  fetchOrderDefaultValues, ...props }) => {
  const context = useContext(ShopifyOrderContext)

  const [secretKey, setSecretKey] = useState()
  if (!secretKey && props.shipperDetails && props.shipperDetails.agent_application_secret_key) {
    setSecretKey(props.shipperDetails.agent_application_secret_key)
  }
  useEffect(() => {
    if (secretKey) {
      fetchOrderDefaultValues(secretKey)
    }
  }, [fetchOrderDefaultValues, secretKey])

  const getOptions = () => {
    let options = []
    if (props.orderDefaultValue && props.orderDefaultValue.data) {
      options = props.orderDefaultValue.data
    }
    if (context.shopifyOrders.isShopify && context.selectedOrder) {
      options = options.filter(o => o.service.service_destination_country.includes(
        context.selectedOrder.consignee_country
      ))
    }
    
  options = options.map(o => ({value:o.id, label:o.title}))
  options = _.sortBy(options, ['label']);
  return options;
}
  
  const handleChange = e => {
    const id = e.value
    const orderValue = props.orderDefaultValue.data.filter(o => o.id === parseInt(id))[0]
    context.setOrderDefaultValue(orderValue)
  }
  
  return (
    <div style={{ width: 200 }}>
    <div className="form-group">
      <label><Trans i18nKey="common:submitOrder.selectDefaultValues" /></label>
      <Select
        placeholder={props.t("submitOrder.selectDefaultValuesPlaceholder")}
        options={getOptions()}
        name="pickup_country"
        isDisabled={(!props.orderDefaultValue)}
        onChange={handleChange}
        defaultValue=''
      >
      </Select>
    </div>
    </div>
  )
}

const mapState = state => ({
  orderDefaultValue: state.orderDefaultValue,
  shipperDetails: state.shipperDetails.shipperDetails
})

const mapDispatch = {
  fetchOrderDefaultValues,
}

export default compose(
  connect(mapState, mapDispatch),
  withTranslation('common')
)(OrderDefaultValueSelection)