import axios from "axios";
import { ROOT_URL } from "../../actions/index";

export function getDraftOrders(secretKey, params) {
  const token = localStorage.getItem("token");
  return axios.get(
    `${ROOT_URL}/channel/draft-orders/`,
    {
      params: { secret_key: secretKey, ...params },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function applySyncRule(secretKey, params) {
  const token = localStorage.getItem("token");
  return axios.get(
    `${ROOT_URL}/api/channel/draft-order-rules/sync_all_rules?secret_key=${secretKey}`,
    {
      params: { secret_key: secretKey, ...params },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function getDraftOrderDetail(secretKey, { orderId }) {
  const token = localStorage.getItem("token");
  return axios.get(
    `${ROOT_URL}/channel/draft-orders/${orderId}/`,
    {
      params: { secret_key: secretKey },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function validateDraftOrder(secretKey, { orderId, params }) {
  const token = localStorage.getItem("token");

  return axios.post(
    `${ROOT_URL}/channel/draft-orders/${orderId}/validate/`,
    { ...params, secret_key: secretKey },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function createShipment(secretKey, { orderId, params }) {
  const token = localStorage.getItem("token");

  return axios.post(
    `${ROOT_URL}/channel/draft-orders/${orderId}/create_order/`,
    { ...params, secret_key: secretKey },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function deleteDraftOrders(secretKey, params) {
  const token = localStorage.getItem("token");
  return axios.post(
    `${ROOT_URL}/channel/draft-orders/delete-selected/`,
    { ...params, secret_key: secretKey },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function createShipments(secretKey, { params }) {
  const token = localStorage.getItem("token");

  return axios.post(
    `${ROOT_URL}/channel/draft-orders/create-shipments/`,
    { ...params, secret_key: secretKey },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function getChannels(secretKey, params) {
  const token = localStorage.getItem("token");
  return axios.get(
    `${ROOT_URL}/channel/channels/`,
    {
      params: { secret_key: secretKey, ...params },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function getFilterData(secretKey, params) {
  const token = localStorage.getItem("token");
  return axios.get(
    `${ROOT_URL}/channel/data/filters/`,
    {
      params: { secret_key: secretKey, ...params },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function getLaneConfigs(secretKey, params) {
  const token = localStorage.getItem("token");
  return axios.get(
    `${ROOT_URL}/channel/lane-configs/`,
    {
      params: { secret_key: secretKey, ...params },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function createLaneConfigs(secretKey, params) {
  const token = localStorage.getItem("token");
  return axios.post(
    `${ROOT_URL}/channel/lane-configs/`,
    { secret_key: secretKey, ...params },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function getLaneConfigDetail(secretKey, id, params) {
  const token = localStorage.getItem("token");
  return axios.get(
    `${ROOT_URL}/channel/lane-configs/${id}/`,
    {
      params: { secret_key: secretKey, ...params },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function updateLaneConfig(secretKey, id, params) {
  const token = localStorage.getItem("token");
  return axios.put(
    `${ROOT_URL}/channel/lane-configs/${id}/`,
    { secret_key: secretKey, ...params },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function getShipmentRules(secretKey, params) {
  const token = localStorage.getItem("token");
  return axios.get(
    `${ROOT_URL}/channel/draft-order-rules/`,
    {
      params: { secret_key: secretKey, ...params },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function getShipmentRuleById(secretKey, id, params) {
  const token = localStorage.getItem("token");
  return axios.get(
    `${ROOT_URL}/channel/draft-order-rules/${id}`,
    {
      params: { secret_key: secretKey, ...params },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function createShipmentRules(secretKey, params) {
  const token = localStorage.getItem("token");
  return axios.post(
    `${ROOT_URL}/channel/draft-order-rules/?secret_key=${secretKey}`,
    { ...params, secret_key: secretKey },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function updateShipmentRules(secretKey, id, params) {
  const token = localStorage.getItem("token");
  return axios.put(
    `${ROOT_URL}/channel/draft-order-rules/${id}/?secret_key=${secretKey}`,
    { ...params, secret_key: secretKey },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function updateShipmentRulesOrder(secretKey, idTop, idBottom, params) {
  const token = localStorage.getItem("token");
  return axios.put(
    `${ROOT_URL}/channel/draft-order-rules/${idTop}/swap-sequence/${idBottom}/?secret_key=${secretKey}`,
    { ...params, secret_key: secretKey },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function deleteShipmentRules(secretKey, id) {
  const token = localStorage.getItem("token");
  return axios.delete(
    `${ROOT_URL}/channel/draft-order-rules/${id}/?secret_key=${secretKey}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function checkRuleConflict(secretKey, params) {
  const token = localStorage.getItem("token");
  return axios.post(
    `${ROOT_URL}/channel/draft-order-rules/check_rule_conflict/?secret_key=${secretKey}`,
    { ...params, secret_key: secretKey },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function fetchShopeeAuthUrl() {
  const token = localStorage.getItem("token");
  return axios.get("/api/channel/shopee/auth/", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  });
}

export function onboardShopee(code, shop_id, shop_name, shop_country) {
  const token = localStorage.getItem("token");
  return axios.get(
    `/api/channel/shopee/onboard/?code=${code}&shop_id=${shop_id}&shop_name=${shop_name}&shop_country=${shop_country}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}

export function fetchLazadaAuthUrl() {
  const token = localStorage.getItem("token");
  return axios.get("/api/channel/lazada/auth/", {
    Headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  });
}

export function onboardLazada(code, shop_id, shop_name, shop_country) {
  const token = localStorage.getItem("token");
  return axios.post(
    `/api/channel/lazada/onboard/`,
    {
      code,
      shop_id,
      shop_name,
      shop_country,
    },
    {
      Headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  );
}
