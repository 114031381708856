export const serviceTypeToServiceValue = {
  airfreight: "AF",
  customs: "CC",
  delivery: "LM",
};

export const serviceValuesOrdering = Object.values(serviceTypeToServiceValue);

export const excelTemplateLink =
  "https://janio-b2c-documents.s3.ap-southeast-1.amazonaws.com/janio_bulk_order_submission_template.xlsx";

export const csvCovidTemplateLink =
  "https://janio-b2c-documents.s3.ap-southeast-1.amazonaws.com/janio-shipper-submit-order-covid-CSV.csv";
export const csvMpsTemplateLink = "";

export const csvTemplateLinkStaging =
  "https://janio-b2c-documents.s3.ap-southeast-1.amazonaws.com/janio-shipper-submit-order-CSV.staging.csv";
export const csvTemplateLink =
  "https://janio-b2c-documents.s3.ap-southeast-1.amazonaws.com/janio-shipper-submit-order-CSV.csv";

export const csvTemplateMpsLinkStaging =
  "https://janio-b2c-documents.s3.ap-southeast-1.amazonaws.com/janio-shipper-submit-mps-order-CSV.staging.csv";
export const csvTemplateMpsLink =
  "https://janio-b2c-documents.s3.ap-southeast-1.amazonaws.com/janio-shipper-submit-mps-order-CSV.csv";

export const DOCUMENT_NAME_MAPPING = {
  NPWP: "LOCALTAXID",
  "KTP/NIK": "KTP",
  "Passport number": "PASSPORT",
  "Indonesia Driver's License": "LICENSE",
};
