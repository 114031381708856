import axios from 'axios'

async function getData(url, params={}) {
  try {
    const response = await axios.get(url, {...params})
    return response.data
  } catch (err) {
    return {
      errorData: (err.response && err.response.data) || {},
      isError: true
    }
  }
}

export const TrackingUpdateAPI = (secretKey) => {
  return {
    getTrackingUpdates: async (orderId) => getData(`/api/v2/tracker/update/${orderId}/`, {
      params: {
        secret_key: secretKey
      }
    })
  }
}