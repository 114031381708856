import axios from 'axios';
import {
    OPEN_TERMS_AND_CONDITIONS_MODAL,
    CLOSE_TERMS_AND_CONDITIONS_MODAL,
    CONFIRM_TERMS_SUCCESS,
    CONFIRM_TERMS_FAILURE,
    SUBMIT_TERMS_AND_CONDITIONS
} from './types';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const defaultToken = localStorage.getItem('token');
if (defaultToken) {
  axios.defaults.headers.common['Authorization'] = 'Token ' + defaultToken;
}

export const confirmTerms = (secret_key, version=0) => {
    console.log('confirming terms and conditions...')
    return function(dispatch) {
        dispatch({
            type: SUBMIT_TERMS_AND_CONDITIONS
        })

        axios.post(`/api/shipper/agree-tnc/`, { secret_key, version })
        .then(() => {
            dispatch({
                type: CONFIRM_TERMS_SUCCESS
            })
            setTimeout(() => {
                dispatch({
                    type: CLOSE_TERMS_AND_CONDITIONS_MODAL
                });
            }, 1600);
        })
        .catch(error => {
            dispatch({
                type: CONFIRM_TERMS_FAILURE
            });
        });
    };   
}

export function openTermsModal() {
    console.log('opening terms and conditons modal...')
    return {
        type: OPEN_TERMS_AND_CONDITIONS_MODAL
    }
}

export function closeTermsModal() {
    console.log('closing terms and conditons modal...')
    return {
        type: CLOSE_TERMS_AND_CONDITIONS_MODAL
    }
}