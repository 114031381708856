import {
  getPickupDataFromPickupPoint,
  getPickupDataFromService,
} from "../../utils/createOrder";
import { domesticServiceEnums } from "../orderReturn/constants";
import {
  csvTemplateLink,
  csvTemplateLinkStaging,
  csvTemplateMpsLink,
  csvTemplateMpsLinkStaging,
  DOCUMENT_NAME_MAPPING,
  serviceTypeToServiceValue,
  serviceValuesOrdering,
} from "./constants";

const DEFAULT_SERIVICE_LEVEL = [
  {
    pickupCountry: "Indonesia",
    consigneeCountry: "Indonesia",
    serviceLevel: "Regular",
  },
  {
    pickupCountry: "Singapore",
    consigneeCountry: "Singapore",
    serviceLevel: "1 - 3 Days",
  },
];

export const isNotClean = (k) => k.includes("(") && /[\s]+/g.test(k);
export const cleanKey = (k) => {
  let cleanedKey = k;
  if (k.includes("(")) {
    cleanedKey = k.substring(0, k.indexOf("(")).trim();
  }
  if (/[\s]+/g.test(k)) {
    cleanedKey = k.substring(0, k.indexOf(" ")).trim();
  }
  return cleanedKey;
};

const cleanValue = (value) => {
  if (typeof value === "string") {
    return value.trim();
  }
  return value;
};

export const getCleanedRawObjects = (rawObjects, options = {}) => {
  const {
    renameFields = {},
    isReturnOrder = false,
  } = options;

  const cleanedObjects = [];
  for (let object of rawObjects) {
    const cleanedObject = {};
    if (isReturnOrder) {
      cleanedObject.serviceValues = [];
    }

    for (let key in object) {
      let cleanedKey = cleanKey(key);
      if (renameFields[cleanedKey]) {
        cleanedKey = renameFields[cleanedKey];
      }
      let cleanedValue = cleanValue(object[key]);
      if (["tracking_no", "shipper_order_id"].includes(cleanedKey)) {
        cleanedValue = String(cleanedValue);
      }

      if (isReturnOrder) {
        // service_type
        if (cleanedKey === "firstmile_type") {
          // column name on the csv
          if (cleanedValue.toLowerCase().includes("shopper")) {
            cleanedValue = domesticServiceEnums.SHOPPERCHOICE;
          } else {
            cleanedValue = cleanedValue.toUpperCase();
          }
          cleanedKey = "service_type"; // rename it so other functions don't break
        }
        if (
          cleanedKey.includes("require_") &&
          cleanedValue.toLowerCase() === "yes"
        ) {
          const [, requireService] = cleanedKey.split("_");
          const serviceValue = serviceTypeToServiceValue[requireService];
          cleanedObject.serviceValues.push(serviceValue);
        }
      }

      cleanedObject[cleanedKey] = cleanedValue;
    }

    if (isReturnOrder) {
      cleanedObject.serviceValues = [cleanedObject.service_type].concat(
        cleanedObject.serviceValues
      );
      cleanedObject.serviceValue = getServiceValue(
        cleanedObject.service_type,
        cleanedObject.serviceValues
      );
    }

    cleanedObjects.push(cleanedObject);
  }
  return cleanedObjects;
};

const getOrderItem = (rawObject) => {
  const {
    item_desc,
    item_quantity,
    item_product_id,
    item_sku,
    item_category,
    item_price_value,
    item_price_currency,
    shipper_order_id,
    special_instruction,
    gst_ovr,
    gst_collected,
  } = rawObject;

  const specialInstruction = special_instruction || "";
  return {
    item_desc,
    item_quantity,
    item_product_id,
    item_sku,
    item_category,
    item_price_value,
    item_price_currency,
    shipper_order_id,
    special_instruction: specialInstruction,
    gst_ovr: gst_ovr,
    gst_collected: gst_collected,
  };
};

export const getServiceDefinition = (
  agentApp,
  serviceDefinitions,
  selectedService,
  serviceType,
  dropoffPoint
) => {
  console.log("get service def");
  let exclusive = serviceDefinitions
    .filter(
      (serviceDef) =>
        serviceDef.origin_country === selectedService.origin &&
        serviceDef.destination_country === selectedService.destination &&
        serviceDef.service_type === serviceType &&
        serviceDef.exclusive_agents.includes(agentApp) &&
        (serviceType === "Dropoff" || serviceType === "Dropoff Express"
          ? serviceDef.dropoff_id === dropoffPoint.serviceDefinition.dropoff_id
          : true)
    )
    .map((serviceDefinition) => {
      if (serviceType === "Pickup" || serviceType === "Pickup Express") {
        console.log(serviceType);
        return {
          service: {
            service_id: serviceDefinition.service_id,
            service_category:
              serviceType === "Pickup" ? "pickup" : "pickup express",
          },
        };
      } else {
        console.log(serviceType);
        return {
          service: {
            service_id: serviceDefinition.service_id,
            service_category:
              serviceType === "Dropoff" ? "ldropoff" : "dropoff express",
            pickup_contact_name: serviceDefinition.dropoff_point_contact_person,
            pickup_contact_number: serviceDefinition.dropoff_point_number,
            pickup_state: serviceDefinition.dropoff_point_state,
            pickup_city: serviceDefinition.dropoff_point_city,
            pickup_province: serviceDefinition.dropoff_point_province,
            pickup_postal: serviceDefinition.dropoff_postal,
            pickup_address: serviceDefinition.dropoff_address,
            pickup_country: serviceDefinition.dropoff_point_country,
            serviceDefinition,
          },
        };
      }
    });

  if (exclusive.length > 0) {
    return exclusive;
  } else {
    return serviceDefinitions
      .filter(
        (serviceDef) =>
          serviceDef.origin_country === selectedService.origin &&
          serviceDef.destination_country === selectedService.destination &&
          serviceDef.service_type === serviceType &&
          !serviceDef.exclusive_agents?.length &&
          (serviceType === "Dropoff"
            ? serviceDef.dropoff_id ===
              dropoffPoint.serviceDefinition.dropoff_id
            : true)
      )
      .map((serviceDefinition) => {
        if (serviceType === "Pickup" || serviceType === "Pickup Express") {
          return {
            service: {
              service_id: serviceDefinition.service_id,
              service_category:
                serviceType === "Pickup" ? "pickup" : "pickup express",
            },
          };
        } else {
          return {
            service: {
              service_id: serviceDefinition.service_id,
              service_category:
                serviceType === "Dropoff" ? "ldropoff" : "dropoff express",
              pickup_contact_name:
                serviceDefinition.dropoff_point_contact_person,
              pickup_contact_number: serviceDefinition.dropoff_point_number,
              pickup_state: serviceDefinition.dropoff_point_state,
              pickup_city: serviceDefinition.dropoff_point_city,
              pickup_province: serviceDefinition.dropoff_point_province,
              pickup_postal: serviceDefinition.dropoff_postal,
              pickup_address: serviceDefinition.dropoff_address,
              pickup_country: serviceDefinition.dropoff_point_country,
              serviceDefinition,
            },
          };
        }
      });
  }
};

const titleCase = (str) => {
  if (["UK", "US"].includes(str)) {
    return str;
  }
  return str
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(" ");
};

const titleCaseCountries = (obj) => {
  return [titleCase(obj.pickup_country), titleCase(obj.consignee_country)];
};

export const getServiceLevel = (obj) => {
  const [pickupCountry, consigneeCountry] = titleCaseCountries(obj);
  const serviceLevel = DEFAULT_SERIVICE_LEVEL.find(
    (s) =>
      s.pickupCountry === pickupCountry &&
      s.consigneeCountry === consigneeCountry
  );
  if (!obj.service_level && !!serviceLevel) {
    return serviceLevel.serviceLevel;
  }
  return obj.service_level || "";
};

export const getPickupData = (serviceValues, rawOrder) => {
  const [pickupCountry, consigneeCountry] = titleCaseCountries(rawOrder);
  const serviceLevel = getServiceLevel(rawOrder);
  const filedName = `${pickupCountry}-${consigneeCountry}-${serviceLevel}`;

  const serviceValue = serviceValues[filedName];
  const service = serviceValue.service.service;
  let pickupData = {
    service_id: service.service_id,
  };
  console.log(
    "filedName",
    filedName,
    "serviceValues",
    serviceValues,
    "serviceValue",
    serviceValue
  );
  if (service.service_category.includes("pickup")) {
    const pickupPoint =
      serviceValue.pickupPoint && serviceValue.pickupPoint.pickupPoint;
    pickupData = {
      ...pickupData,
      ...getPickupDataFromPickupPoint(pickupPoint),
      pickup_date: serviceValue.pickupDate
        ? serviceValue.pickupDate.format("YYYY-MM-DD")
        : null,
      pickup_notes: pickupPoint ? pickupPoint.pickup_point_notes : null,
    };
  } else {
    const serviceDef = serviceValue.dropoffPoint.serviceDefinition;
    pickupData = { ...pickupData, ...getPickupDataFromService(service) };
    // add the parker id if the dropoff point is from parknparcel
    if (serviceDef.source === "parknparcel") {
      pickupData.additional_data = { ParknParcel: serviceDef.additional_data };
    }
  }

  return pickupData;
};

const removeIfEmpty = [
  "cod_amt_to_collect",
  "pickup_point_id",
  "incoterm",
  "additional_data",
];

export const getOrderObjects = (
  cleanedRawObjects,
  serviceValues,
  allowHiphenInTn
) => {
  const finalResult = [];

  for (let obj of cleanedRawObjects) {
    const item = getOrderItem(obj);

    if (
      !!obj.shipper_order_id &&
      finalResult.some((o) => o.shipper_order_id === obj.shipper_order_id)
    ) {
      // combine item if order already exists in finalResult
      const i = finalResult.findIndex(
        (o) => o.shipper_order_id === obj.shipper_order_id
      );
      const orderObject = finalResult[i];
      if (allowHiphenInTn) {
        orderObject.allow_hyphen_in_tracking_number = true;
      }
      orderObject.items.push(item);
    } else {
      const pickupData = getPickupData(serviceValues, obj);
      const orderObject = { ...obj, ...pickupData };
      orderObject.items = [item];
      removeIfEmpty.forEach((i) => {
        if (!orderObject[i]) {
          delete orderObject[i];
        }
      });

      if (!!orderObject.identification_document_name) {
        orderObject.identification_document_name =
          DOCUMENT_NAME_MAPPING[orderObject.identification_document_name] ||
          orderObject.identification_document_name;
      }
      if (allowHiphenInTn) {
        orderObject.allow_hyphen_in_tracking_number = true;
      }
      finalResult.push(orderObject);
    }
  }
  return finalResult;
};

export const formatErrorText = (inputText) => {
  let replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  );

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  );

  // change field name to bold
  replacedText = replacedText.replace(
    /(\s)([a-z]+_[_a-z]+)([\s,.])/g,
    "$1<b>$2</b>$3"
  );

  return replacedText;
};

function getServiceValue(serviceType, serviceValues) {
  const result = [serviceType];
  serviceValuesOrdering.forEach((v) => {
    if (serviceValues.includes(v)) {
      result.push(v);
    }
  });
  return result.join("_");
}

export function getMpsOrderObjects(rawOrdersData, serviceValues) {
  const result = [];
  console.log("mps bulk", rawOrdersData);
  rawOrdersData.forEach((row, rowIndex) => {
    const itemObj = {
      item_desc: row.item_desc,
      item_category: row.item_category,
      item_price_currency: row.item_price_currency,
      item_product_id: row.item_product_id,
      item_quantity: row.item_quantity,
      item_price_value: row.item_price_value,
      item_sku: row.item_sku,
      shipper_order_id: row.shipper_order_id,
      special_instruction: row.special_instruction || "",
      gst_ovr: row.gst_ovr,
      gst_collected: row.gst_collected,
    };
    const packageObj = {
      child_tracking_no: row.child_tracking_no,
      package_no: row.package_order_id,
      length: row.order_length,
      weight: row.order_weight,
      height: row.order_height,
      width: row.order_width,
      items: [itemObj],
      // shipper_order_id: row.shipper_order_id
    };

    const existingParentOrderIndex = result.findIndex(
      (r) => r.mps_group_id === row.mps_group_id
    );
    if (existingParentOrderIndex === -1 && !!row.mps_group_id) {
      // add new parent order
      const pickupData = getPickupData(serviceValues, row);
      const newOrderObj = {
        ...row,
        ...pickupData,
        packages: [packageObj],
      };
      removeIfEmpty.forEach((i) => {
        if (!newOrderObj[i]) {
          delete newOrderObj[i];
        }
      });
      newOrderObj.tracking_no = row.mps_parent_tracking_no;
      if (!!newOrderObj.identification_document_name) {
        newOrderObj.identification_document_name =
          DOCUMENT_NAME_MAPPING[newOrderObj.identification_document_name];
      }
      result.push(newOrderObj);
    } else {
      // existing order
      const order = result[existingParentOrderIndex];
      const existingPackageIndex = order.packages.findIndex(
        (p) => p.package_no === row.package_order_id
      );
      if (existingPackageIndex === -1 || !row.package_order_id) {
        // add new package object
        if (!row.package_order_id) {
          const generatedPackageNo = `${row.mps_group_id}_package_${rowIndex}`;
          packageObj.package_no = generatedPackageNo;
        }
        order.packages.push(packageObj);
      } else {
        // add items to existing package
        const orderPackage = order.packages[existingPackageIndex];
        orderPackage.items.push(itemObj);
      }
    }
  });
  return result;
}

export const getCSVTemplateUrl = () =>
  process.env.REACT_APP_IS_PRODUCTION
    ? csvTemplateLink
    : csvTemplateLinkStaging;

export const getMpsCSVTemplateUrl = () =>
  process.env.REACT_APP_IS_PRODUCTION
    ? csvTemplateMpsLink
    : csvTemplateMpsLinkStaging;
