import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { Router, Route, Switch } from "react-router-dom";
import reduxThunk from "redux-thunk";
import { history } from "./utils/historyUtils";
import Favicon from "react-favicon";
import ReactGA from "react-ga";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import commonEng from "./translations/eng/common.json";
import commonChi from "./translations/chi/common.json";
import commonCht from "./translations/cht/common.json";
import commonInd from "./translations/ind/common.json";
import { QueryParamProvider } from "use-query-params";

import App from "./components/app";
import RequireAuth from "./components/auth/RequireAuth";
import Signin from "./components/auth/Signin";
import NetoConnect from "./components/auth/NetoConnect";
import SigninShopify from "./components/auth/SigninShopify";
import RegisterShopify from "./components/registerShopify/RegisterShopify";
import RegisterSuccess from "./components/register/RegisterSuccess";
import Register from "./components/register/Register";
import ResetPasswordSuccess from "./components/resetPassword/ResetPasswordSuccess";
import ResetPassword from "./components/resetPassword/ResetPassword";
import PasswordSent from "./components/resetPassword/PasswordSent";
import SubmitOrderSuccess from "./components/order/SubmitOrderSuccess";
import ViewOrders from "./components/order/ViewOrders";
import ViewOrderDetails from "./components/order/ViewOrderDetails";
import ViewItemDetails from "./components/itemDetails/ViewItemDetails";
import ViewItemDetailsById from "./components/itemDetails/ViewItemDetailsById";
import AddItemDetails from "./components/itemDetails/AddItemDetails";
import Reports from "./components/reports/Reports";
import Invoices from "./components/invoices/Invoices";
import User from "./components/user/User";
import ChangePassword from "./components/changePassword/ChangePassword";
import ViewSubProfile from "./components/subProfiles/ViewSubProfile";
import AddSubProfileSuccess from "./components/subProfiles/AddSubProfileSuccess";
import DeleteSubProfileSuccess from "./components/subProfiles/DeleteSubProfileSuccess";
import AddSubProfile from "./components/subProfiles/AddSubProfile";
import SubProfiles from "./components/subProfiles/SubProfiles";
import ShipperDetails from "./components/shipperDetails/ShipperDetails";
import ConnectAStore from "./components/connectAStore/ConnectAStore";
import AddPickupPointSuccess from "./components/pickupPoint/AddPickupPointSuccess";
import DeletePickupPointSuccess from "./components/pickupPoint/DeletePickupPointSuccess";
import AddPickupPoint from "./components/pickupPoint/AddPickupPoint";
import AddConsigneeAddress from "./components/pickupPoint/AddConsigneeAddress";
import EditPickupPoint from "./components/pickupPoint/EditPickupPoint";
// import PickupPoints from "./components/pickupPoint/PickupPoints";
import SearchPostalCode from "./components/searchPostalCode/SearchPostalCode";
import Stats from "./components/stats/Stats";
import Terms from "./components/terms/Terms";
import reducers from "./reducers";
import { AUTH_USER } from "./actions/types";
import { ViewDraftOrders } from "./components/draftOrder/viewDraftOrders";
import { ViewDraftOrderDetails } from "./components/draftOrder/viewDraftOrderDetails";
import { CreateShipments } from "./components/draftOrder/createShipments";
import { ManageChannels } from "./components/channels/ManageChannels.page";
import { NewChannel } from "./components/channels/NewChannel.page";
import { ManageLaneConfigs } from "./components/channels/laneConfigs/ManageConfigs.page";
import { NewLaneConfig } from "./components/channels/laneConfigs/NewConfig.page";
import { EditLaneConfig } from "./components/channels/laneConfigs/EditConfig.page";
import { ShipmentRuleDashboard } from "./components/channels/shipmentRules/ShipmentRuleDashboard";
import { ShipmentRuleItemCategoryForm } from "./components/channels/shipmentRules/ItemCategoryForm";
import { ShipmentRuleParcelDimensionForm } from "./components/channels/shipmentRules/ParcelDimensionForm";
import { ServiceTypeForm } from "./components/channels/shipmentRules/ServiceTypeForm";
import { Callback } from "./components/channels/Callback.page";

import img from "./images/janio-favicon-new.png";
import ShopifyOrderContainer from "./components/shopifyOrders/ShopifyOrderContainer";
import ConfigureDefaultValues from "./components/configureDefaultValues/ConfigureDefaultValues";
import ConfigureDefaultValuesAdd from "./components/configureDefaultValues/ConfigureDefaultValuesAdd";
import ConfigureDefaultValuesEdit from "./components/configureDefaultValues/ConfigureDefaultValuesEdit";
import { fetchShipperDetails } from "./actions/shipperDetailsActions";

import ServiceDefSubmissionPage from "./components/orderSubmitBulk/serviceDefSubmission";
import CustomsDocumentPage from "./components/customsDocument/CustomsDocument.page";
import NetoUninstall from "./components/auth/NetoUninstall";
import AddConsigneeAddressSuccess from "./components/pickupPoint/AddConsigneeAddressSuccess";
import EditConsigneeAddress from "./components/pickupPoint/EditConsigneeAddress";
import MetroBlackFridayForm from "./publicPages/MetroBlackFriday/MetroBlackFridayForm";

const SubmitReturnOrderPage = React.lazy(() => import("./components/orderReturn/SubmitReturnOrder.page"));
const SubmitReturnOrderBulkPage = React.lazy(() => import("./components/orderReturnBulk/SubmitReturnOrderBulk.page"));
const ManageAddresses = React.lazy(() => import("./components/pickupPoint/ManageAddresses.page"));
const DropoffPoints = React.lazy(() => import("./components/dropoffPoints/DropoffPoints.page"));
const RTSReschedulingPage = React.lazy(() => import("./publicPages/RTSRescheduling/RTSRescheduling.page"));
const RTSSelectDropoffPage = React.lazy(() => import("./publicPages/RTSSelectDropoff/RTSSelectDropoff.page"));
const ReturnsServicePage = React.lazy(() => import("./publicPages/ReturnsServicePage/ReturnsServicePage.page"));
const ViewOrderDetailsPage = React.lazy(() => import("./components/order/viewOrderDetails/ViewOrderDetails.page"));

const ConfigureDefaultReturnPointsPage = React.lazy(() => import("./components/addressManagement/configureDefaultReturnPoints/ConfigureDefaultReturnPoints.page"));
const ReturnPointsCreateRulePage = React.lazy(() => import("./components/addressManagement/configureDefaultReturnPoints/ReturnPointsCreateRulePage.page"));

// import Performance from './components/stats/Performance.page.js'

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const token = localStorage.getItem("token");
const userId = localStorage.getItem("_id");
if (token && userId) {
  store.dispatch(fetchShipperDetails());
  store.dispatch({ type: AUTH_USER });
}

ReactGA.initialize("UA-122396409-1");
history.listen((location, action) => {
  ReactGA.pageview(window.location.pathname);
});

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("lang") || "eng",
  fallbackLng: "eng",
  resources: {
    eng: {
      common: commonEng,
    },
    chi: {
      common: commonChi,
    },
    cht: {
      common: commonCht,
    },
    ind: {
      common: commonInd,
    },
  },
});

const Loading = (props) => <p>Loading...</p>;

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      {/* <DoRedirect> */}
      <Favicon url={img} />
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <App>
            <Suspense fallback={<Loading />}>
              <Switch>
                <Route path="/signin" component={Signin} />
                <Route path="/neto/connect" component={NetoConnect} />
                <Route path="/neto/uninstall" component={NetoUninstall} />
                <Route path="/signin-shopify" component={SigninShopify} />
                <Route path="/register-shopify" component={RegisterShopify} />
                <Route path="/register/success" component={RegisterSuccess} />
                <Route path="/register" component={Register} />
                <Route path="/password-sent/:resetToken" component={PasswordSent} />
                <Route path="/reset-password/success" component={ResetPasswordSuccess} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/metro-order/:id" component={MetroBlackFridayForm} />

                <Route path="/submit-order/success" component={RequireAuth(SubmitOrderSuccess)} />
                <Route path="/submit-order" component={RequireAuth(ShopifyOrderContainer)} />
                <Route path="/submit-order-csv" component={RequireAuth(ServiceDefSubmissionPage)} />
                <Route path="/submit-return-order/" component={RequireAuth(SubmitReturnOrderPage)} />
                <Route path="/submit-return-csv/" component={RequireAuth(SubmitReturnOrderBulkPage)} />
                <Route path="/view-order-details/:id" component={RequireAuth(ViewOrderDetails)} />
                <Route path="/view-orders/:id" component={RequireAuth(ViewOrderDetailsPage)} />
                <Route path="/view-orders" component={RequireAuth(ViewOrders)} />
                <Route path="/view-draft-orders" component={RequireAuth(ViewDraftOrders)} />
                <Route path="/view-draft-order-details/:id" component={RequireAuth(ViewDraftOrderDetails)} />
                <Route path="/view-draft-orders" component={RequireAuth(ViewDraftOrders)} />
                <Route path="/create-shipments/:ids" component={RequireAuth(CreateShipments)} />
                <Route path="/view-item-details/:id" component={RequireAuth(ViewItemDetailsById)} />
                <Route path="/view-item-details" component={RequireAuth(ViewItemDetails)} />
                <Route path="/add-item-details" component={RequireAuth(AddItemDetails)} />
                {/* <Route path="/add-web-store/success" component={RequireAuth(AddStoreSuccess)} /> */}
                {/* <Route path="/delete-web-store/success" component={RequireAuth(DeleteStoreSuccess)} /> */}
                {/* <Route path="/add-web-store" component={RequireAuth(AddStore)} /> */}
                {/* <Route path="/web-stores/:id" component={RequireAuth(EditStore)} /> */}
                {/* <Route path="/web-stores" component={RequireAuth(Stores)} /> */}
                {/* <Route path="/submit-store-order/success" component={RequireAuth(SubmitStoreOrderSuccess)} /> */}
                {/* <Route path="/view-web-store-order-details/:orderId/:storeId" component={RequireAuth(ViewStoreOrderDetails)} /> */}
                {/* <Route path="/view-web-store-orders" component={RequireAuth(ViewStoreOrders)} /> */}
                {/* <Route path="/performance" component={RequireAuth(Performance)} /> */}
                <Route path="/reports" component={RequireAuth(Reports)} />
                <Route path="/invoices" component={RequireAuth(Invoices)} />
                <Route path="/user" component={RequireAuth(User)} />
                <Route path="/change-password" component={RequireAuth(ChangePassword)} />
                <Route path="/view-sub-profile/:agentApplicationid/" component={RequireAuth(ViewSubProfile)} />
                <Route path="/add-sub-profile/success" component={RequireAuth(AddSubProfileSuccess)} />
                <Route path="/delete-sub-profile/success" component={RequireAuth(DeleteSubProfileSuccess)} />
                <Route path="/add-sub-profile" component={RequireAuth(AddSubProfile)} />
                <Route path="/sub-profiles" component={RequireAuth(SubProfiles)} />
                <Route path="/merchant-details" component={RequireAuth(ShipperDetails)} />
                <Route path="/connect-a-store" component={RequireAuth(ConnectAStore)} />
                <Route path="/channels/lane-configs/new" component={RequireAuth(NewLaneConfig)} />
                <Route path="/channels/lane-configs/:id" component={RequireAuth(EditLaneConfig)} />
                <Route path="/channels/lane-configs" component={RequireAuth(ManageLaneConfigs)} />
                <Route path="/channels" component={RequireAuth(ManageChannels)} />
                <Route path="/add-channel" component={RequireAuth(NewChannel)} />
                <Route path="/add-address/success" component={RequireAuth(AddPickupPointSuccess)} />
                <Route path="/delete-address/success" component={RequireAuth(DeletePickupPointSuccess)} />
                <Route path="/add-consignee-point/success" component={RequireAuth(AddConsigneeAddressSuccess)} />
                <Route path="/add-pickup-point" component={RequireAuth(AddPickupPoint)} />
                <Route path="/pickup-points/:id" component={RequireAuth(EditPickupPoint)} />
                <Route path="/add-return-point" component={RequireAuth(AddPickupPoint)} />
                <Route path="/return-points/:id" component={RequireAuth(EditPickupPoint)} />
                <Route path="/add-consignee-point" component={RequireAuth(AddConsigneeAddress)} />
                <Route path="/consignee-address/:id" component={RequireAuth(EditConsigneeAddress)} />
                <Route path="/manage-address" component={RequireAuth(ManageAddresses)} />
                <Route path="/search-postal-code" component={RequireAuth(SearchPostalCode)} />
                <Route path="/shopify-order" component={RequireAuth(ShopifyOrderContainer)} />
                <Route path="/configure-default-values/:id/edit" component={RequireAuth(ConfigureDefaultValuesEdit)} />
                <Route path="/configure-default-values/add" component={RequireAuth(ConfigureDefaultValuesAdd)} />
                <Route path="/configure-default-values" component={RequireAuth(ConfigureDefaultValues)} />
                <Route path="/consignee-customs-document" component={CustomsDocumentPage} />
                <Route path="/terms-of-service" component={Terms} />

                {/* public page routes */}
                <Route path="/rts-reschedule" component={RTSReschedulingPage} />
                <Route path="/dropoff-points" component={DropoffPoints} />
                <Route path="/select-dropoff-returns" component={RTSSelectDropoffPage} />
                <Route path="/select-returns-service" component={ReturnsServicePage} />
                {/* end public page routes */}

                <Route path="/shipment-rules/item-category/:id" component={RequireAuth(ShipmentRuleItemCategoryForm)} />
                <Route path="/shipment-rules/item-category" component={RequireAuth(ShipmentRuleItemCategoryForm)} />
                <Route path="/shipment-rules/parcel-dimension/:id" component={RequireAuth(ShipmentRuleParcelDimensionForm)} />
                <Route path="/shipment-rules/parcel-dimension" component={RequireAuth(ShipmentRuleParcelDimensionForm)} />
                <Route path="/shipment-rules/service-type/:id" component={RequireAuth(ServiceTypeForm)} />
                <Route path="/shipment-rules/service-type" component={RequireAuth(ServiceTypeForm)} />
                <Route path="/shipment-rules" component={RequireAuth(ShipmentRuleDashboard)} />

                <Route path="/configure-default-return-points" exact component={RequireAuth(ConfigureDefaultReturnPointsPage)} />
                <Route path="/configure-default-return-points/create-rule" exact component={RequireAuth(ReturnPointsCreateRulePage)} />

                <Route path="/callback/:channel/" component={RequireAuth(Callback)} />

                <Route path="/" component={RequireAuth(Stats)} />
              </Switch>
            </Suspense>
          </App>
        </QueryParamProvider>
      </Router>
      {/* </DoRedirect> */}
    </I18nextProvider>
  </Provider>,
  document.querySelector("#root")
);
// registerServiceWorker();
