import React, { useEffect, useRef } from "react"
import { useSelector, useDispatch } from 'react-redux';

import { Formik, Form } from 'formik';
import {
  Alert,
  Select,
  Spin,
  Button,
  Form as F ,
  Row, Col,
} from 'antd'
import { fetchAllCountries } from '../../../actions/orderActions'
import useServiceDef from '../../orderSubmitBulk/hooks/useServiceDef'
import useStoreAddresses from '../../shopifyOrders/hooks/useStoreAddresses'
import { getDropoffOptions, getFilteredServiceDefinitions } from '../../draftOrder/components/ServiceSelector'


const ServiceSelector = ({serviceDefinitions, countries, agentApplicationName, addresses, formikProps}) => {
  const serviceTypes = serviceDefinitions.filter(
    def => formikProps.values['origin_country'] === def.origin_country &&
    formikProps.values['destination_country'] === def.destination_country
  )
  .map(serviceDef => serviceDef.service_type)
  .filter(
    (value, index, self) => self.indexOf(value) === index
  )

  return (
    <Row gutter={12} type="flex" align="middle">
      <Col span={12}>
        <F.Item label="Origin Country" >
          <Select style={{width: '100%'}} showSearch
            defaultValue={formikProps.values['origin_country']}
            onChange={(val) => formikProps.setFieldValue('origin_country', val)} >
            {
              (countries || []).map((country) => (
                <Select.Option key={country} value={country} >
                  {country}
                </Select.Option>)
              )
            }
          </Select>
        </F.Item>
      </Col>
      <Col span={12}>
        <F.Item label="Destination Country" >
          <Select style={{width: '100%'}} showSearch
            defaultValue={formikProps.values['destination_country']}
            onChange={(val) => formikProps.setFieldValue('destination_country', val)} >
            {
              (countries || []).map((country) => (
                <Select.Option key={country} value={country} >
                  {country}
                </Select.Option>)
              )
            }
          </Select>
        </F.Item>
      </Col>
      { formikProps.values['origin_country'] && formikProps.values['destination_country'] &&
      <Col span={12}>
        <F.Item label="Service Type" >
          <Select style={{width: '100%'}}
            defaultValue={formikProps.values['service_type']}
            onChange={(val) => formikProps.setFieldValue('service_type', val)}>
          {
            serviceTypes.map((serviceType, i) => (
              <Select.Option key={i} value={serviceType}>{serviceType}</Select.Option>
            ))
          }
          </Select>
        </F.Item>
      </Col>
      }
      { formikProps.values['service_type'] === 'Dropoff' &&
        <Col span={12}>
          <F.Item label="Dropoff Point" >
            <Select style={{width: '100%'}} showSearch
              defaultValue={formikProps.values['dropoff_point_id']}
              onChange={(val) => formikProps.setFieldValue('dropoff_point_id', val)} >
              {
                (getDropoffOptions(serviceDefinitions, formikProps.values['origin_country'], formikProps.values['destination_country'], agentApplicationName)).map((def, i) => (
                  <Select.Option key={i} value={def.dropoff_id}>
                    {def.dropoff_address}
                  </Select.Option>
                ))
              }
            </Select>
          </F.Item>
        </Col>
      }
      { formikProps.values['service_type'] === 'Pickup' &&
        <Col span={12}>
          <F.Item label="Pickup Location" >
            <Select style={{width: '100%'}} showSearch
              defaultValue={formikProps.values['pickup_point_id']}
              onChange={(val) => formikProps.setFieldValue('pickup_point_id', val)} >
              {
                addresses
                  .filter(a => a.pickup_point_country === formikProps.values['origin_country'])
                  .map((a, i) => (
                    <Select.Option key={i} value={a.pickup_point_id}>
                      {a.pickup_point_name} - {a.pickup_point_address}
                    </Select.Option>
                    )
                  )
              }
            </Select>
          </F.Item>
        </Col>
      }
    </Row>
  )
}

const ErrorAlert = ({errors}) => {
  const messages = []
  Object.values(errors).forEach( error => {
    error.forEach((e) => messages.push(e))
  })
  return (
    <Alert type="error" style={{marginBottom: '2em'}} message={<ul style={{marginBottom: 0}}>
      {messages.map((m, i) => <li key={i}>{m}</li>)}
    </ul>} />
  )
}

export const LaneConfigForm = ({laneConfig, onSubmit, isSubmitting, isLoading, errors}) => {
  const formRef = useRef();
  const dispatch = useDispatch()
  const state = useSelector( state => {
    return {
      secretKey: state.shipperDetails?.shipperDetails?.agent_application_secret_key,
      agentApplicationName: state.shipperDetails?.shipperDetails?.shipper_name,
      countries: state.order?.countries
    }
  })

  useEffect(() => {
    dispatch(fetchAllCountries())
  }, [dispatch])
  const [serviceDefinitions, isServicedefinitionLoading] = useServiceDef(state.secretKey)
  const [addresses] = useStoreAddresses()

  const submit = (val) => {
    let filteredServiceDef = getFilteredServiceDefinitions(
      serviceDefinitions,
      state.agentApplicationName,
      val['origin_country'],
      val['destination_country'],
      val['service_type'],
      val['dropoff_postal_id']
    )

    if (filteredServiceDef.length === 1){
      const serviceDef = filteredServiceDef[0]
      const params = {
        ...val,
        service_id: serviceDef.service_id,
      }
      onSubmit(params)
    }
    else{
     console.log('NOT')
    }
  }

  if (isLoading || isServicedefinitionLoading || !state.countries){
    return (
      <div className="center-screen">
        <Spin/>
      </div>
    )
  }

  return (
    <Formik
      ref={formRef}
      onSubmit={submit}
      initialValues={laneConfig}>
      {(formikProps) => (
      <Form>
        {errors && <ErrorAlert errors={errors}/>}
        <ServiceSelector
          formikProps={formikProps}
          serviceDefinitions={serviceDefinitions}
          countries={state.countries}
          agentApplicationName={state.agentApplicationName}
          addresses={addresses}
        />
        <Button type="primary" htmlType="submit" loading={isSubmitting}>
          Save
        </Button>
      </Form>
      )}
    </Formik>
  )
}
