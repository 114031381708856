import axios from "axios";

let url = "https://api.staging.janio.asia/api/order/metro-order/";

if (process.env.REACT_APP_IS_PRODUCTION) {
  url = "https://api.janio.asia/api/order/metro-order/";
}

export default async function createStoreOrder(payload) {
  try {
    console.log("payload", payload);
    const response = await axios.post(url, payload);
    return response.data;
  } catch (err) {
    let errors = [];
    if (err.data) {
      console.log(err.data);
      Object.keys(err.data).forEach((key) => {
        if (key === "items") {
          err.data[key].forEach((val) => {
            Object.keys(val).forEach((k1) => {
              errors.push(`${keyMap(k1)}: ${val[k1]}`);
            });
          });
        } else {
          err.data[key].forEach((val) => {
            errors.push(`${keyMap(key)}: ${val}`);
          });
        }
      });
    }
    console.log("errors", errors);
    return {
      errorData: errors || [],
      isError: true,
    };
  }
}

// This is a disposable code.
const keyMap = (key) => {
  switch (key) {
    case "shipper_order_id":
      return "Metro Receipt Number";
    case "consignee_name":
      return "Full Name";
    case "consignee_number":
      return "Telephone Number";
    case "consignee_address":
      return "Full Address";
    case "consignee_postal":
      return "Postal Code";
    case "consignee_email":
      return "Email Address";
    case "item_quantity":
      return "No. of Items";
    case "item_price_value":
      return "Receipt Value (SGD)";
    default:
      return key;
  }
};
