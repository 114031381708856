import React from 'react'
import { withFormik, Form } from 'formik'
import classNames from 'classnames'
import { ClipLoader } from 'react-spinners'
import * as Yup from 'yup'
import { Trans, withTranslation } from 'react-i18next'
import Select from 'react-select';
import { DatePicker } from 'antd';
import PickupInformation from '../../common/PickupInformation'
import { getPickupDataFromPickupPoint, getPickupDataFromServiceDefinition } from '../../../utils/createOrder'
import SelectDeliveryOptions from '../components/SelectDeliveryOptions'


const SenderDetailsForm = props => {
  let {
    ctx, values, errors, touched,
    setValues, setTouched, isSubmitting
  } = props
  const {
    selectedOrder, addresses, shopifyOrders,
    serviceDefinitions, isServicedefinitionLoading,
    agentApplicationId
  } = ctx
  const getAddress = pickupPointId => addresses.filter(
    a => a.pickup_point_id === parseInt(pickupPointId, 10)
  )[0]

  const getServicesByConsignee = () => ctx.data.services.filter(
    s => s.consignee_country === values.consignee_country
  )
  const servicesByConsignee = getServicesByConsignee()

  const loadingDiv = (
    <div className="col-md-6">
      <ClipLoader color={"#000028"} loading={true} size={24} />
    </div>
  )

  const getFilteredServices = () => ctx.data.services.filter(
    s => s.pickup_country === values.pickup_country &&
         s.consignee_country === values.consignee_country
  )

  const filteredServices = getFilteredServices()

  const getServiceTypeOptions = (origin, destination) => {
    return serviceDefinitions
    .filter(
      def => {
        const serviceType = def.service_type?.toLowerCase() || ''
        return (
          origin === def.origin_country
          && destination === def.destination_country
          && !serviceType.includes('return')
        )
      }
    )
    .map(serviceDef => serviceDef.service_type)
    .filter(
      (value, index, self) => self.indexOf(value) === index
    )
    .map(
      serviceType => ({
        value: serviceType,
        label:serviceType,
        serviceType
      })
    )
  }

  const getDropoffOptions = (origin, destination) =>{
    let exclusive = serviceDefinitions.filter(serviceDef=>(
      serviceDef.origin_country === origin &&
      serviceDef.destination_country === destination &&
      serviceDef.service_type === props.values.serviceType &&
      serviceDef.exclusive_agents_ids.includes(agentApplicationId)
    ))
    if (exclusive.length > 0){
      return exclusive.map(serviceDefinition =>({
        label: serviceDefinition.dropoff_address,
        value: serviceDefinition.dropoff_id,
        serviceDefinition
      }))
    }else{
    return serviceDefinitions.filter(def => (origin === def.origin_country && destination === def.destination_country && def.service_type === props.values.serviceType )).map(serviceDefinition =>({
        label: serviceDefinition.dropoff_address,
        value: serviceDefinition.dropoff_id,
        serviceDefinition
      }))
    }
  }

  const getFilteredServiceDefinitions = (origin, destination, serviceType, dropoffId) => {
    let exclusive = serviceDefinitions.filter(serviceDef=>(
      serviceDef.origin_country === origin &&
      serviceDef.destination_country === destination &&
      serviceDef.service_type === serviceType &&
      serviceDef.exclusive_agents_ids.includes(agentApplicationId)&&
      (dropoffId ? serviceDef.dropoff_id === dropoffId :true)
    ))
    if (exclusive.length > 0){
      return exclusive
    }else{
      return serviceDefinitions.filter(serviceDef => (
        serviceDef.origin_country === origin &&
        serviceDef.destination_country === destination &&
        serviceDef.service_type === serviceType &&
        (dropoffId ? serviceDef.dropoff_id === dropoffId :true)
      ))
    }
  }

  let pickupAddress
  if (values.serviceType) {
    if (values.serviceType.toLowerCase().includes("pickup") && values.pickup_point) {
      pickupAddress = getPickupDataFromPickupPoint(values.pickup_point)
    }
    else if (values.serviceDefinition){
      pickupAddress = getPickupDataFromServiceDefinition(values.serviceDefinition)
    }
  }

  const displayServiceErrors = (values, touched) => {
    let showErrors = false
    // if service type is selected and is not dropoff, service definition would have been chosen & errors can be shown
    if (touched.service_id && values.serviceType !== 'Dropoff' && values.serviceType !== "Dropoff Express") {
      showErrors = true
    // if service type is dropoff can only show errors after dropoff pt is chosen
    } else if (touched.service_id && (values.serviceType === 'Dropoff' || values.serviceType === "Dropoff Express") && touched.dropoffPointId) {
      showErrors = true
    }

    // if no service def means no unique service def found
    if (showErrors && !values.serviceDefinition) {
      return <div className="text-danger">Unable to find a unique service</div>
    }
    // if service def but no service, means the service is not allowed for merchant
    else if (showErrors && !values.service) {
      return <div className="text-danger">Service not allowed</div>
    } else {
      return null
    }
  }

  const getDisabledDate = (currentDate) => {
    const startDate = new Date()

    if (values.pickup_country === 'Indonesia') {
      if (values.pickup_state === 'DKI Jakarta') {
        if (startDate.getHours() >= 12) {
          startDate.setDate(startDate.getDate() + 1)
        }
      } else {
        if (startDate.getHours() <= 17) {
          startDate.setDate(startDate.getDate() + 1)
        } else {
          startDate.setDate(startDate.getDate() + 2)
        }
      }
    }
    else {
      if (startDate.getHours() >= 13) {
        startDate.setDate(startDate.getDate() + 1)
      }
    }
    return currentDate.diff(new Date(), 'days') >= 14 || currentDate.diff(startDate, 'days') < 0
  }

  const showPickupAddress = values.service && (values.serviceType === "Pickup" || values.serviceType === "Pickup Express")
  const showDropoffAddress = values.serviceType === "Dropoff" || values.serviceType === "Dropoff Express"
  const showDeliveryOptions = (
    values.pickup_country === "Indonesia"
    && values.consignee_country === 'Indonesia'
    && !!values.serviceType
    // && values.serviceType === 'Pickup'
  )

  const showPickupDate =
    (values.serviceType === "Pickup" || values.serviceType === "Pickup Express") &&
    ["Singapore", "Indonesia"].includes(values.pickup_country) &&
    !!values.pickup_address
  const showDeliveryInformation = pickupAddress && !!Object.keys(pickupAddress).length

  const getPickupAddressOptions = () => addresses.filter(a => a.pickup_point_country === values.pickup_country)
  .map((a, i) => ({
    label: `${a.pickup_point_name} - ${a.pickup_point_address}`,
    value: a.pickup_point_id
  }))

  let pickupAddressError
  if (touched.pickup_state && errors.pickup_state) {
    pickupAddressError = errors.pickup_state
  } else if (touched.pickup_city && errors.pickup_city) {
    pickupAddressError = errors.pickup_city
  }

  let renderContent
  const content = !ctx.isServiceLoading && !isServicedefinitionLoading && !ctx.isCurrencyMappingsLoading ?
    <>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="submit-title"><Trans i18nKey='common:submitOrder.selectPickupCountry' /></label>
              <Select name="pickup_country"
                classNamePrefix='react-select'
                className={classNames(
                  'basic-single',
                  {'is-invalid': !!(errors.pickup_country && touched.pickup_country)}
              )} style={{width: 150}}
                isSearchable = {true}
                onChange={e => {
                  setValues({
                    pickup_country: e.value,
                    consignee_country: values.consignee_country,
                    service_id: '',
                    pickup_point_id: '',
                    pickup_address: '',
                    service: null,
                    service_level: '',
                  })
                  setTouched({
                    pickup_country: true
                  }, false)

                }}
                value={values.pickup_country ? {
                  value: values.pickup_country, label: values.pickup_country
                }: ''}
                isDisabled={selectedOrder.submitted}
                options = {
                  ctx.data.countries.map((country, i) => (
                    {'value': country, 'label': country}
                  ))
                }
                >
              </Select>
              {touched.pickup_country && errors.pickup_country &&
              <div className="text-danger">{errors.pickup_country}</div>}
            </div>

          </div>
          <div className="col-md-6">
            {(!values.consignee_country || !shopifyOrders.isShopify) &&
            <div className="form-group">
              <label className="submit-title"><Trans i18nKey='common:submitOrder.selectConsigneeCountry' /></label>
              <Select disabled={(!values.pickup_country || selectedOrder.submitted)} name="consignee_country"
                classNamePrefix='react-select'
                className={classNames(
                  'basic-single',
                  {'is-invalid': !!(errors.consignee_country && touched.consignee_country)}
                )}
                style={{width: 150}} onChange={e => {
                  setValues({
                    pickup_country: values.pickup_country,
                    consignee_country: e.value,
                    service_id: '',
                    pickup_point_id: '',
                    pickup_address: '',
                    service_level: ''
                  })
                  setTouched({
                    pickup_country: true,
                    consignee_country: true
                  }, false)
                }}
                value={values.consignee_country ? {
                  value: values.consignee_country, label: values.consignee_country
                }: ''}
                options = {
                    ctx.data.countries.map((country, i) => (
                      {'value': country, 'label': country}
                    ))
                  } >
              </Select>
              {touched.consignee_country && errors.consignee_country &&
              <div className="text-danger">{errors.consignee_country}</div>}
            </div>
            }
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
          {values.pickup_country && values.consignee_country &&
            <React.Fragment>
              {filteredServices.length ?
                <div className="form-group">
                  <label className="submit-title"><Trans i18nKey='common:submitOrder.selectServicePlaceholder' /></label>
                  <Select disabled={(!values.pickup_country || selectedOrder.submitted)} name="service_type"
                    className={classNames(
                      'basic-single',
                    )}
                    style={{width: 150}} onChange={e => {
                      const filteredServiceDef = getFilteredServiceDefinitions(values.pickup_country, values.consignee_country, e.value)
                      if (filteredServiceDef.length === 1){
                        const serviceDef = filteredServiceDef[0]
                        values.service_id = serviceDef.service_id
                        values.service = ctx.getServiceById(serviceDef.service_id)
                        values.serviceDefinition = serviceDef
                        if (serviceDef.source === 'parknparcel'){
                          values.additional_data = {'ParknParcel': serviceDef.additional_data}
                        }
                        if (!!serviceDef.dropoff_id) {
                          values.pickup_state = serviceDef.dropoff_point_state
                          values.pickup_city = serviceDef.dropoff_point_city
                          values.pickup_address = serviceDef.dropoff_address
                          values.pickup_postal = serviceDef.dropoff_postal
                        }
                      } else {
                        values.service_id = null
                        values.service = null
                        values.serviceDefinition = null
                      }

                      if (e.value === "Pickup" || e.value === "Pickup Express") {
                        values.pickup_state = ""
                        values.pickup_city = ""
                        values.pickup_address = ""
                        values.pickup_postal = ""
                      }
                      values.pickup_point_id = ""
                      values.pickup_point = null

                      values.serviceType = e.value
                      setValues(values)
                      setTouched({
                        ...touched,
                        pickup_country: true,
                        service_id: true
                      }, false)
                    }}
                    value={values.serviceType ? {
                      value: values.serviceType, label: values.serviceType
                    }: ''}
                    options={getServiceTypeOptions(values.pickup_country, values.consignee_country)} >
                  </Select>
                  {displayServiceErrors(values, touched)}
                </div>
                :
                <p><Trans i18nKey='common:submitOrder.noServiceAvailable' /></p>
                }
            </React.Fragment>
          }
          </div>

          {showPickupAddress &&
          <div className="col-md-6">
            <div className='form-group'>
              <label className="form-label submit-title"><Trans i18nKey='common:submitOrder.selectPickupAddressPlaceholder' /></label>
              <Select name="pickup_point_id"
                onChange={e => {
                  const pickup_point = getAddress(e.value)
                  setValues({
                    ...values,
                    pickup_point_id: e.value,
                    pickup_address: (pickup_point && pickup_point.pickup_point_address) || null,
                    pickup_notes: (pickup_point && pickup_point.pickup_point_notes) || null,
                    pickup_state: (pickup_point && pickup_point.pickup_point_state) || null,
                    pickup_city: (pickup_point && pickup_point.pickup_point_city) || null,
                    pickup_postal: (pickup_point && pickup_point.pickup_point_postal) || null,
                    pickup_date: null,
                    pickup_point
                  })
                }}
                value={values.pickup_point && !!Object.entries(values.pickup_point).length ?
                  {
                    value: values.pickup_point.pickup_point_id,
                    label: `${values.pickup_point.pickup_point_name} - ${values.pickup_point.pickup_point_address}`,
                  }
                  :
                  ''
                }
                className={classNames(
                  'basic-single',
                  // {'is-invalid': !!(errors.pickup_point_id && touched.pickup_point_id)})}
                )}
                disabled={selectedOrder.submitted}
                options={getPickupAddressOptions()}>
              </Select>
              <p className='mb-0'>
                <a href="/add-pickup-point" target='_blank'>{props.t('submitOrder.addPickupPoint')}</a>
              </p>
              {touched.pickup_point_id && errors.pickup_point_id &&
              <div className="text-danger">{errors.pickup_point_id}</div>}
              {!!pickupAddressError &&
              <div className="text-danger">{pickupAddressError}</div>}
            </div>
          </div>
          }

          {showDropoffAddress &&
          <div className="col-md-6">
              <div className="form-group">
                <label className="submit-title"><Trans i18nKey='common:submitOrder.selectDropoffAddressPlaceholder' /></label>
                <Select
                  name="dropoff_point"
                  disabled={(!values.pickup_country || selectedOrder.submitted)}
                  placeholder="Select dropoff point"
                  className={classNames(
                    'basic-single',
                  )}
                  style={{width: 150}} onChange={e => {
                    const filteredServiceDef = getFilteredServiceDefinitions(
                      values.pickup_country,
                      values.consignee_country,
                      values.serviceType, 
                      e.value)

                    if (filteredServiceDef.length === 1){
                      const serviceDef = filteredServiceDef[0]
                      values.service_id = serviceDef.service_id
                      values.service = ctx.getServiceById(serviceDef.service_id)
                      values.serviceDefinition = serviceDef
                      if (serviceDef.source === 'parknparcel'){
                        values.additional_data = {'ParknParcel': serviceDef.additional_data}
                      }

                      if (!!serviceDef.dropoff_id) {
                        values.pickup_state = serviceDef.dropoff_point_state
                        values.pickup_city = serviceDef.dropoff_point_city
                        values.pickup_address = serviceDef.dropoff_address
                        values.pickup_postal = serviceDef.dropoff_postal
                      }
                    }
                    setValues(values)
                    setTouched({
                      ...touched,
                      dropoffPointId: true
                    }, false)
                  }}
                  value={values.serviceDefinition ? {
                    value: values.serviceDefinition.dropoff_id, label: values.serviceDefinition.dropoff_address
                  }: ''}
                  options = {getDropoffOptions(values.pickup_country, values.consignee_country)} >
                </Select>
              </div>
          </div>
          }
        </div>

        <div className="row">
          {showDeliveryOptions &&
          <div className="col-md-6">
            <SelectDeliveryOptions
              pickupCountry={values.pickup_country}
              consigneeCountry={values.consignee_country}
              error={(touched.service_level && errors.service_level) ? errors.service_level: ''}
              onChange={e => {
                setValues({
                  ...values,
                  service_level: e.value,
                })
                setTouched({
                  ...touched,
                  service_level: true,
                }, false)
              }}
              value={values.service_level ? {
                label: values.service_level,
                value: values.service_level
              }: ''}
            />
          </div>
          }

          {showPickupDate &&
          <div className="col-md-6">
            <div className="form-group">
              <label className="submit-title">Pickup Date (Optional)</label>
              <div>
                <DatePicker
                  size="large"
                  format="DD/MM/YY"
                  disabledDate={getDisabledDate}
                  onChange={(val) => setValues({ ...values, pickup_date: val })}
                  style={{ width: "100%"}}
                  value={values.pickup_date}
                />
                {touched.pickup_date && errors.pickup_date && (
                  <span className="invalid-text">{errors.pickup_date}</span>
                )}
              </div>
            </div>
          </div>
          }

          {showDeliveryInformation &&
          <div className="col-md-6">
            <PickupInformation
              pickup={pickupAddress}
              pickupDate={values.pickup_date}
              type={values.serviceType}
              deliveryOption={values.service_level}
            />
          </div>
          }
        </div>

        <div className="row">
          {/* { values.serviceType === "Pickup" && values.pickup_country === "Singapore" && values.pickup_address &&
          <div className="col-md-6">
            <div className="form-group">
              <label className="submit-title">Pickup Notes (Optional)</label>
              <TextArea
                placeholder="Please let us know if you have any special pickup requests..."
                autosize={{ minRows: 6, maxRows: 6 }}
                onChange={(event) => setValues({ ...values, pickup_notes: event.target.value })}
                value={values.pickup_notes}
                style={{ height: "178px" }}
              />
            </div>
          </div>
          } */}
        </div>
        </div>

    </>
    :
    loadingDiv

  if (ctx.isServiceLoading || ctx.isCurrencyMappingsLoading || isServicedefinitionLoading) {
    renderContent = loadingDiv
  }
  if (shopifyOrders.isShopify) {
    if (selectedOrder.shopify_shipping_address_exists) {
      if (servicesByConsignee.length && !ctx.isServiceLoading && !ctx.isCurrencyMappingsLoading) {
       renderContent = content
     } else {
       renderContent = !ctx.isServiceLoading && !ctx.isCurrencyMappingsLoading ?
         <div className="col-md-6">
           <p>No service available to {values.consignee_country}</p>
         </div>
         :
         loadingDiv
     }
   } else {
     renderContent = (
       <div className="col-md-6">
         <p>Shipping address missing, please input shipping address in Shopify.</p>
       </div>
     )
   }
  } else {
    renderContent = content
  }
  return (
    <Form>
      <div className="row">
      {renderContent}
      </div>

      {errors.nonFieldErrors &&
        <div className="mt-3">
          <p className="mb-0">{JSON.stringify(errors.nonFieldErrors)}</p>
        </div>
      }

      <div className="d-flex justify-content-between mt-4">
        <div></div>
        <button className='btn btn-primary' type='submit'
          disabled={
            selectedOrder.submitted || !filteredServices.length || !values.service ||
            (values.serviceDefinition && !values.serviceDefinition.dropoff_id && !values.pickup_address) ||
            isSubmitting
          }>
            {isSubmitting ?
            <>
            <ClipLoader
              color={'#fff'}
              loading={true}
              size={16}
            /> <span className='ml-2'><Trans i18nKey='common:submitOrder.loading' /></span>
            </>:
            props.t('submitOrder.saveAndContinue')
            }
          </button>
      </div>
    </Form>
  )
}

export default withTranslation('common')(
  withFormik({
    async handleSubmit(values, {props, setErrors, setSubmitting}) {
      values.service_id = values.serviceDefinition.service_id
      if (values.service && values.serviceType !== "Pickup" && values.serviceType !== "Pickup Express") {
        values.pickup_address = values.serviceDefinition.dropoff_address
      }

      const validationData = {
        consignee_country: values.consignee_country,
        pickup_country: values.pickup_country,
        pickup_date: values.pickup_date,
        pickup_state: values.pickup_state,
        pickup_city: values.pickup_city,
        pickup_postal: values.pickup_postal,
        service_id: values.service_id,
        service_level: values.service_level
      }
      setSubmitting(true)
      const response = await props.ctx.validateToBackend(validationData)
      if (response.error) {
        const {
          pickup_country,
          consignee_country,
          service_id,
          pickup_point_id,
          service_level,
          ...rest
        } = response

        setErrors({
          pickup_country,
          consignee_country,
          service_id,
          pickup_point_id,
          service_level,
          nonFieldErrors: rest
        })
        setSubmitting(false)
        return
      }

      setSubmitting(false)

      props.ctx.updateOrderData(values)
      props.ctx.nextPage()
    },
    mapPropsToValues: props => {
      const { selectedOrder } = props.ctx
      return {
        ...selectedOrder,
        service_level: selectedOrder.service_level || ''
      }
    },
    validationSchema: props => Yup.lazy(values => {
      // const service = props.ctx.getServiceById(values.service_id)
      const required = props.t('common.required')
      return Yup.object().shape({
        pickup_country: Yup.string().required(required),
        consignee_country: Yup.string().required(required),
        service_id: Yup.string().required(required),
        pickup_point_id: values.service_id && (values.serviceType === "Pickup" || values.serviceType === "Pickup Express") ?
          Yup.string().required(required) : Yup.string(),
        service_level: (
          values.pickup_country === 'Indonesia'
          && values.consignee_country === 'Indonesia'
          // && values.serviceType === "Pickup"
        ) ?
          Yup.string().required(required)
          :
          Yup.string()
        // address_id: values.service_category === 'pickup' ? Yup.string().required('Required'):Yup.string(),
        // dropoff_area: values.service_category === 'dropoff' ? Yup.string().required('Required'):Yup.string()
      })
    }),
    enableReinitialize: true
  })(SenderDetailsForm)
)
