import React, { Component } from "react";
import { shouldSkipDashboardComponent } from "../utils";
import { history } from "../utils/historyUtils";

import Dashboard from "./Dashboard";

class App extends Component {
  renderDashboard = () => {
    let dashboard = (
      <div style={{height: "100%"}}>
        {this.props.children}
      </div>
    )

    const checkShouldSkipDashboard = shouldSkipDashboardComponent()
    if (!checkShouldSkipDashboard) {
      dashboard = (
        <Dashboard children={this.props.children} history={history} />
      );
    }

    return dashboard;
  };

  render() {
    return this.renderDashboard();
  }
}
export default App;
