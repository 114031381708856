import React from 'react'
import OrderDefaultValueForm from './OrderDefaultValueForm';

const ConfigureDefaultValuesAdd = () => {
  return (
    <div className="col-md-4 offset-md-4 mt-5">
    <OrderDefaultValueForm />
    </div>
  )
}

export default ConfigureDefaultValuesAdd