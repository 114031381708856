import React from "react"
import { useQuery } from 'react-query'
import { capitalizeUnderscore, formatDateTime } from "../../../utils"
import { TrackingUpdateAPI } from "./trackingUpdates.api"
import "./trackingUpdates.scss"
import classnames from 'classnames'

const TrackingUpdates = ({ orderId, secretKey }) => {
  const { data, isLoading } = useQuery(
    ['tracking-updates', orderId],
    async () => await TrackingUpdateAPI(secretKey).getTrackingUpdates(orderId),
    {
      refetchInterval: false,
      staleTime: Infinity
    }
  )
  if (isLoading) {
    return <p>Loading tracking updates...</p>
  }

  return data && data.updates && data.updates.length
    ? data.updates.map((update, index, arr) => {
      const { status, updated_on, country, address } = update
      const formattedStatus = capitalizeUnderscore(status)
      const [date, time] = formatDateTime(updated_on)

      const previousIndex = index - 1
      const previousUpdate = arr[previousIndex]
      const isNewSection = index === 0 || (!!previousUpdate && previousUpdate.status !== status)
      return (
        <div key={`update-${status}-${index}`}
          className={classnames('status-update-item', {'status-update-item-section': isNewSection})}>
          {
          isNewSection &&
          <p className="text-large font-weight-bold" style={{ fontSize: 16 }}>{formattedStatus}</p>
          }
          <div className="d-flex status-update">
            <div style={{ flex: 2 }}>
              <div className="d-none d-sm-block">
                <p className="mb-0">{date}, {time}</p>
              </div>
              <div className="d-block d-sm-none">
                <p className="mb-0">{date}</p>
                <p>{time}</p>
              </div>
            </div>
            <div style={{ flex: 3, marginLeft: 24 }}>
              <p>{update.description}</p>
              <div>
                <p className="mb-0">{country}</p>
                <p className="mb-0">{address}</p>
              </div>
            </div>
          </div>
        </div>
      )
    })
    : null
}

export default TrackingUpdates