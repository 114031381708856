import React, { Component } from "react";
import { Jumbotron } from 'react-bootstrap';
import { connect } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import momenttz from 'moment-timezone';
import _ from 'lodash';
import compose from 'recompose/compose';
// import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next';

import { fetchShipperDetails } from '../../actions/shipperDetailsActions';
import {
  fetchAllCountries
} from '../../actions/orderActions';
import {
  fetchOrdersInCSV,
  fetchTrackerUpdatesInCSV,
  clearReportErrors
} from '../../actions/reportsActions';
import {
  fetchService,
  fetchStatuses,
} from '../../actions/orderActions';

import SelectDropdown from "../common/SelectDropdown";
import { capitalizeUnderscore } from "../../utils";
import { ROOT_URL } from '../../actions/index';
import axios from 'axios';

const styles = (theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
  },
});

class Reports extends Component {
  constructor() {
    super();
    this.state = {
      error: true,
      fetched: false,
      anchorEl: null,

      secretKey: '',
      serviceId: null,
      pickupCountry: '',
      consigneeCountry: '',
      uploadBatchNo: '',
      dateFrom: moment().subtract(2, 'months').startOf('days'),
      dateTo: moment(),
      trackingNo: '',
      emails: '',

      ordersInCSV: [],
      trackerUpdatesInCSV: [],

      ordersForDownloadInCSV: [],
      trackerUpdatesForDownloadInCSV: [],
      downloadOrdersCompleted: "",
      downloadTrackerUpdatesCompleted: "",

      emailsMessage: null,

      lastUpdatedFetchOrdersDetails: moment(),
      lastUpdatedFetchTrackerUpdates: moment(),
      loadingFetchOrdersDetails: false,
      enableFetchOrdersDetails: true,
      loadingFetchTrackerUpdates: false,
      enableFetchTrackerUpdates: true,
      showFetchOrdersDetails: true,
      showFetchTrackerUpdates: true,
      selectedService: null,
      selected_consigneeCountry: null,
      selected_pickupCountry: null,
      selected_statusCode: null,

      public_status_v2_shipper: "",
      public_status: "",
    };
  }

  downloadOrdersCsvLink = React.createRef();
  downloadTrackerUpdatesCsvLink = React.createRef();

  componentDidMount() {
    const datePickerWrapperList = document.querySelectorAll('.react-datepicker-wrapper');
    datePickerWrapperList.forEach((item) => {
      item.setAttribute('class', 'w-100');
      item.parentNode.setAttribute('class', 'w-100');
    });

    const datePickerList = document.querySelectorAll('.react-datepicker__input-container');
    datePickerList.forEach((item) => {
      item.setAttribute('class', 'w-100');
    });

    this.props.fetchShipperDetails();
    if (this.props.countries === undefined) {
      this.props.fetchAllCountries();
    }
    if (this.props.publicStatuses === undefined) {
      this.props.fetchStatuses();
    }
  }

  componentWillUnmount() {
    this.props.clearReportErrors();
  }

  componentDidUpdate() {
    if (this.props.service === undefined && this.state.secretKey.length > 0 && !this.state.fetched) {
      this.props.fetchService(this.state.secretKey);
      this.setState({
        fetched: true
      });
    }

    if (this.props.error !== this.state.error) {
      this.setState({
        error: this.props.error
      });
    }

    if (this.props.ordersInCSV !== this.state.ordersInCSV && this.props.ordersInCSV) {
      this.setState({
        ordersInCSV: this.props.ordersInCSV
      });
    }

    if (this.props.trackerUpdatesInCSV !== this.state.trackerUpdatesInCSV) {
      this.setState({
        trackerUpdatesInCSV: this.props.trackerUpdatesInCSV
      });   
    }

    if (this.props.emailsMessage !== this.state.emailsMessage) {
      this.setState({
        emailsMessage: this.props.emailsMessage
      });
    }

    if (this.props.lastUpdatedFetchOrdersDetails && this.state.lastUpdatedFetchOrdersDetails) {
      if ((this.props.lastUpdatedFetchOrdersDetails.valueOf() > this.state.lastUpdatedFetchOrdersDetails.valueOf()) && this.state.loadingFetchOrdersDetails) {
        this.setState({
          loadingFetchOrdersDetails: false
        });
      }
    }

    if (this.props.lastUpdatedFetchOrdersDetails > this.state.lastUpdatedFetchOrdersDetails) {
      this.setState({
        lastUpdatedFetchOrdersDetails: this.props.lastUpdatedFetchOrdersDetails
      });
    }

    if (this.props.lastUpdatedFetchTrackerUpdates && this.state.lastUpdatedFetchTrackerUpdates) {
      if ((this.props.lastUpdatedFetchTrackerUpdates.valueOf() > this.state.lastUpdatedFetchTrackerUpdates.valueOf()) && this.state.loadingFetchTrackerUpdates) {
        this.setState({
          loadingFetchTrackerUpdates: false
        });
      }
    }

    if (this.props.lastUpdatedFetchTrackerUpdates > this.state.lastUpdatedFetchTrackerUpdates) {
      this.setState({
        lastUpdatedFetchTrackerUpdates: this.props.lastUpdatedFetchTrackerUpdates
      });
    }

    const { shipperDetails } = this.props
    if (shipperDetails && shipperDetails.hasOwnProperty('agent_application_secret_key') && this.state.secretKey.length === 0) {
      this.setState({
        secretKey: this.props.shipperDetails.agent_application_secret_key
      });
    }
  }

  handlePopoverOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleDateChange = (e, fieldName) => {
    this.props.clearReportErrors();
    this.setState({
      enableFetchOrdersDetails: true,
      enableFetchTrackerUpdates: true,
      showFetchOrdersDetails: true,
      showFetchTrackerUpdates: true,
    });

    this.setState({ [fieldName]: e });
  }

  handleDropdownChange = (e, type) => {
    this.props.clearReportErrors();
    this.setState({
      enableFetchOrdersDetails: true,
      enableFetchTrackerUpdates: true,
      showFetchOrdersDetails: true,
      showFetchTrackerUpdates: true,
    });

    if (type === "statusCode") {
      const publicStatusJoined = e.filter(item => item.value.includes("v2")).map(item => item.value.split("-")[0]).join(",")
      const publicStatusOldJoined = e.filter(item => item.value.includes("v1")).map(item => item.value.split("-")[0]).join(",")
      console.log({publicStatusJoined, publicStatusOldJoined})
      const updateState = {
        [`selected_${type}`]: e,
        public_status_v2_shipper:  publicStatusJoined || "",
        public_status: publicStatusOldJoined || "",
      }
      
      this.setState(updateState);
      return
    }

    if (e.length === 1) {
      if (type === 'service') {
        const serviceId = e[0].serviceId;

        if (serviceId !== undefined) {
          this.setState({
            serviceId: serviceId,
            selectedService: e
          });
        } else {
          this.setState({
            serviceId: null,
            selectedService: null
          });
        }
      } else {
        this.setState({ [type]: e[0].value, [`selected_${type}`]: e });
      }
    } else {
      if (type === 'service') {
        const serviceIdList = _.map(e, (item, i) => {
          return item.serviceId;
        });
        const serviceId = serviceIdList.join(',');

        if (serviceId !== undefined) {
          this.setState({
            serviceId: serviceId,
            selectedService: e
          });
        } else {
          this.setState({
            serviceId: null,
            selectedService: null
          });
        }
      } else {
        const valueList = _.map(e, (item, i) => {
          return item.value;
        });
        const value = valueList.join(',');

        this.setState({
          [type]: value,
          [`selected_${type}`]: e
        });
      }
    }
  }

  handleOnChange(e) {
    this.props.clearReportErrors();
    this.setState({
      enableFetchOrdersDetails: true,
      enableFetchTrackerUpdates: true,
      showFetchOrdersDetails: true,
      showFetchTrackerUpdates: true,
    });

    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleFetchOrdersDetails = (obj) => {
    if(!obj) {
      obj = {};
    }

    this.props.clearReportErrors();

    if(!obj.downloadOnly) {
      this.setState({
        loadingFetchOrdersDetails: true,
        enableFetchOrdersDetails: false,
        showFetchOrdersDetails: true,
        showFetchTrackerUpdates: false,
        ordersInCSV: []
      });
    } else {
      this.setState({
        downloadOrdersCompleted: false
      })
    }
  if (this.state.secretKey.length > 0 && this.state.dateFrom !== null && this.state.dateTo !== null) {
      const userTimezone = momenttz.tz.guess();
      const dateFrom = moment(this.state.dateFrom).tz(userTimezone).format();
      const dateTo = moment(this.state.dateTo).tz(userTimezone).format();

      var {
        secretKey,
        serviceId,
        pickupCountry,
        consigneeCountry,
        uploadBatchNo,
        publicStatusCode,
        trackingNo,
        emails,
        public_status_v2_shipper, public_status
      } = this.state

      if(obj.downloadOnly) {
        emails = "";
      }

      const filters = {
        dateFrom, dateTo,
        secretKey,
        serviceId,
        pickupCountry,
        consigneeCountry,
        uploadBatchNo,
        publicStatusCode,
        trackingNo,
        emails,
        public_status_v2_shipper, public_status
      }
      if(obj.downloadOnly) {
        this.getOrdersForDownload(filters);
      } else {
        this.props.fetchOrdersInCSV(filters);
      }
    }
  }

  handleFetchTrackerUpdates = (obj) => {
    if(!obj) {
      obj = {};
    }

    this.props.clearReportErrors();

    if(!obj.downloadOnly) {
      this.props.clearReportErrors();
      this.setState({
        loadingFetchTrackerUpdates: true,
        enableFetchTrackerUpdates: false,
        showFetchOrdersDetails: false,
        showFetchTrackerUpdates: true,
        trackerUpdatesInCSV: []
      });
    } else {
      this.setState({
        downloadTrackerUpdatesCompleted: false
      })      
    }

    if (this.state.secretKey.length > 0 && this.state.dateFrom !== null && this.state.dateTo !== null) {
      const userTimezone = momenttz.tz.guess();
      const dateFrom = moment(this.state.dateFrom).tz(userTimezone).format();
      const dateTo = moment(this.state.dateTo).tz(userTimezone).format();

      var {
        secretKey,
        serviceId,
        pickupCountry,
        consigneeCountry,
        uploadBatchNo,
        publicStatusCode,
        trackingNo,
        emails,
        public_status_v2_shipper, public_status
      } = this.state

      if(obj.downloadOnly) {
        emails = "";
      }

      const filters = {
        dateFrom, dateTo,
        secretKey,
        serviceId,
        pickupCountry,
        consigneeCountry,
        uploadBatchNo,
        publicStatusCode,
        trackingNo,
        emails,
        public_status_v2_shipper, public_status
      }

      if(obj.downloadOnly) {
        this.getTrackerUpdatesForDownloadInCSV(filters);
      } else {
        this.props.fetchTrackerUpdatesInCSV(filters);
      }      
    }
  }

  renderDownloadOrdersAlert = () => {
    if(this.state.downloadOrdersCompleted === false) {
      return <div className="alert alert-warning text-center w-100 my-2" role="alert">
        Please wait while we fetch the data.....
      </div>
    } 
    return "";
  }

  renderDownloadTrackerUpdatesAlert = () => {
    if(this.state.downloadTrackerUpdatesCompleted === false) {
      return <div className="alert alert-warning text-center w-100 my-2" role="alert">
        Please wait while we fetch the data.....
      </div>
    } 
    return "";
  }  

  renderPleaseEnterYourEmailAlert = () => {
    let pleaseEnterYourEmailAlert = null;

    if (this.state.emails.length === 0) {
      pleaseEnterYourEmailAlert = <div className="alert alert-warning text-center w-100 my-2" role="alert">
        <Trans i18nKey="common:reports.pleaseEnterYourEmail" />
      </div>;
    } else {
      const emailValid = this.validateStrIsEmail(this.state.emails);
      if (!emailValid) {
        pleaseEnterYourEmailAlert = <div className="alert alert-danger text-center w-100 my-2" role="alert">
          <Trans i18nKey="common:reports.emailIsNotValid" />
        </div>;
      }
    }

    return pleaseEnterYourEmailAlert;
  }

  validateStrIsEmail = (email) => {
    let result = null;

    if (!email.includes(',')) {
      const regex = /(\S+@\S+\.\S+)/g;
      result = regex.test(email);
    } else {
      let str = "(\\S+@\\S+\\.\\S+)";
      const commaCount = email.match(/,/g).length;
      for (let i = 0; i < commaCount; i++) {
        str += ",(\\S+@\\S+\\.\\S+)";
      }
      const regex = new RegExp(str);
      result = regex.test(email);
    }

    return result;
  }

  renderFetchOrdersDetailsButton = () => {
    let button = null;

    const emailValid = this.validateStrIsEmail(this.state.emails);

    if (this.state.secretKey.length > 0 && !this.state.loadingFetchOrdersDetails && this.state.enableFetchOrdersDetails &&
      this.state.emails.length > 0 && emailValid) {
      if (this.state.showFetchOrdersDetails) {
        button = <div onClick={this.handleFetchOrdersDetails}>
          <button
            type="button"
            className="w-100 btn btn-lg btn-success">
            <Trans i18nKey="common:reports.fetchOrdersDetails" />
          </button>
        </div>;
      }
    } else {
      button = <div onClick={this.handleFetchOrdersDetails}>
        <button
          type="button"
          className="w-100 btn btn-lg btn-secondary"
          disabled={true}>
          <Trans i18nKey="common:reports.fetchOrdersDetails" />
        </button>
      </div>;
    }

    return button;
  }

  isValidCsv = (data) => {
    if(Object.prototype.toString.call(data) === "[object Array]") {
      return true;
    }    
    return false;
  }

  getOrdersForDownload = (filters) => {
    const token = localStorage.getItem('token');
    const {
      secretKey, serviceId, pickupCountry,
      consigneeCountry, uploadBatchNo,
      dateFrom, dateTo, trackingNo,
      emails, public_status_v2_shipper, public_status
    } = filters;

    axios.post(`${ROOT_URL}/order/fetch-orders-in-csv/`,
      {
        secret_key: secretKey,
        service_id: serviceId,
        pickup_country: pickupCountry,
        consignee_country: consigneeCountry,
        upload_batch_no: uploadBatchNo,
        date_from: dateFrom,
        date_to: dateTo,
        tracking_nos: trackingNo,
        emails: emails,
        public_status_v2_shipper, public_status
      },
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } }
    )
      .then((response) => {
        console.log(response);
        this.setState({
          ordersForDownloadInCSV: response.data,
          downloadOrdersCompleted: true
        })
        var that = this;
        setTimeout(() => {
          if(that.downloadOrdersCsvLink.current) {
            that.downloadOrdersCsvLink.current.link.click();
          }
        }, 500);        
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          downloadOrdersCompleted: true
        }) 
      });    
  }

  getTrackerUpdatesForDownloadInCSV = (filters) => {
    const token = localStorage.getItem('token');
    const {
      secretKey, serviceId, pickupCountry,
      consigneeCountry, uploadBatchNo,
      dateFrom, dateTo, trackingNo,
      emails, public_status_v2_shipper, public_status
    } = filters
  
  
    axios.post(`${ROOT_URL}/order/fetch-tracker-updates-in-csv/`,
      {
        secret_key: secretKey,
        service_id: serviceId,
        pickup_country: pickupCountry,
        consignee_country: consigneeCountry,
        upload_batch_no: uploadBatchNo,
        date_from: dateFrom,
        date_to: dateTo,
        tracking_nos: trackingNo,
        emails: emails,
        public_status_v2_shipper, public_status
      },
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } }
    )
    .then((response) => {
      console.log(response);
      this.setState({
        trackerUpdatesForDownloadInCSV: response.data,
        downloadTrackerUpdatesCompleted: true
      })
      var that = this;
      setTimeout(() => {
        if(that.downloadTrackerUpdatesCsvLink.current) {
          that.downloadTrackerUpdatesCsvLink.current.link.click();
        }
      }, 500);        
    })
    .catch((error) => {
      console.log(error);
      this.setState({
        downloadTrackerUpdatesCompleted: true
      })      
    });    
  }

  renderDownloadsOrdersDetailsButton = () => {
    return <button
    type="button"
    className="w-100 btn btn-lg btn-success" onClick={() => {
      this.handleFetchOrdersDetails({downloadOnly: true});
    }}>
    <Trans i18nKey="common:reports.downloadOrdersInCSV" />
  </button>;
  }

  renderDownloadsTrackerUpdatesButton = () => {
    return <button
    type="button"
    className="w-100 mt-1 btn btn-lg btn-success" onClick={() => {
      this.handleFetchTrackerUpdates({downloadOnly: true});
    }}>
    <Trans i18nKey="common:reports.downloadTrackerUpdatesInCSV" />
  </button>;
  }

  renderFetchTrackerUpdatesButton = () => {
    let button = null;

    const emailValid = this.validateStrIsEmail(this.state.emails);

    if (this.state.secretKey.length > 0 && !this.state.loadingFetchTrackerUpdates && this.state.enableFetchTrackerUpdates &&
      this.state.emails.length > 0 && emailValid) {
      if (this.state.showFetchTrackerUpdates) {
        button = <div onClick={this.handleFetchTrackerUpdates}>
          <button
            type="button"
            className="w-100 mt-1 btn btn-lg btn-success">
            <Trans i18nKey="common:reports.fetchTrackerUpdates" />
          </button>
        </div>;
      }
    } else {
      button = <div onClick={this.handleFetchTrackerUpdates}>
        <button
          type="button"
          className="w-100 mt-1 btn btn-lg btn-secondary"
          disabled={true}>
          <Trans i18nKey="common:reports.fetchTrackerUpdates" />
        </button>
      </div>;
    }

    return button;
  }

  getDateFrom = () => {
    const userTimezone = momenttz.tz.guess();
    const dateFrom = moment(this.state.dateFrom).tz(userTimezone).format('YYYYMMDDHHmm');
    return dateFrom;
  }

  getDateTo = () => {
    const userTimezone = momenttz.tz.guess();
    const dateTo = moment(this.state.dateTo).tz(userTimezone).format('YYYYMMDDHHmm');
    return dateTo;
  }

  getPublicStatusOptions = () => {
    if (!this.props.publicStatuses) return []

    const { public_statuses, public_statuses_v2 } = this.props.publicStatuses
    const groupedOptions = []
    if (public_statuses_v2 && !!public_statuses_v2.length) {
      groupedOptions.push({
        label: "Public Statuses",
        options: public_statuses_v2.map(s => ({
          label: capitalizeUnderscore(s),
          value: `${s}-v2`,
        }))
      })
    }
    if (public_statuses && !!public_statuses.length) {
      groupedOptions.push({
        label: "Old Public Statuses",
        options: public_statuses.map(s => ({
          label: s,
          value: `${s}-v1`,
        }))
      })
    }
    return groupedOptions
  }

  render() {
    return (
      <div>
        <div className="mt-4 container max-width-40">
          <Jumbotron className="p-4 border border-secondary">
            <div>
              <h5>
                <Trans i18nKey="common:reports.service" />
                <i onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose} className="far fa-sm fa-question-circle ml-2"></i>
              </h5>
              <SelectDropdown
                placeholder={this.props.t('common.allServices')}
                value={this.state.selectedService}
                onChange={(e) => this.handleDropdownChange(e, 'service')}
                isMulti={true}
                options={this.props.service}
                optionObject={item => ({
                  serviceId: item.service_id,
                  value: item.service_name,
                  label: item.service_name
                })} />
            </div>

            <div className="mt-2">
              <h5>
                <Trans i18nKey="common:reports.pickupCountry" />
                <i onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose} className="far fa-sm fa-question-circle ml-2"></i>
              </h5>
              <SelectDropdown
                isMulti={true}
                placeholder={this.props.t('common.allCountries')}
                onChange={(e) => this.handleDropdownChange(e, 'pickupCountry')}
                value={this.state.selected_pickupCountry}
                options={this.props.countries}/>
            </div>

            <div className="mt-2">
              <h5>
                <Trans i18nKey="common:reports.consigneeCountry" />
                <i onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose} className="far fa-sm fa-question-circle ml-2"></i>
              </h5>
              <SelectDropdown
                isMulti={true}
                placeholder={this.props.t('common.allCountries')}
                onChange={(e) => this.handleDropdownChange(e, 'consigneeCountry')}
                value={this.state.selected_consigneeCountry}
                options={this.props.countries}
              />
            </div>

            <div className="mt-2">
              <h5>
                <Trans i18nKey="common:reports.uploadBatchNo" />
                <i onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose} className="far fa-sm fa-question-circle ml-2"></i>
              </h5>
              <input
                className="form-control"
                name="uploadBatchNo"
                placeholder={this.props.t("reports.uploadBatchNoPlaceholder")}
                value={this.state.uploadBatchNo}
                onChange={this.handleOnChange.bind(this)}
              />
            </div>

            <div className="mt-2">
              <h5>
                <Trans i18nKey="common:reports.statusCode" />
                <i onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose} className="far fa-sm fa-question-circle ml-2"></i>
              </h5>

              <SelectDropdown
                isMulti={true}
                placeholder={this.props.t('common.allStatuses')}
                onChange={(e) => this.handleDropdownChange(e, 'statusCode')}
                value={this.state.selected_statusCode}
                groupedOptions={this.getPublicStatusOptions()}
                formatGroupLabel={data => (
                  <strong style={{ color: "rgba(0, 0, 0, 0.65)", textTransform: "capitalize" }}>{data.label}</strong>
                )}
              />
            </div>

            <div className="mt-2">
              <h5>
                <Trans i18nKey="common:reports.dateFrom" />
                <i onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose} className="far fa-sm fa-question-circle ml-2"></i>
              </h5>
              <DatePicker
                className="form-control"
                name="dateFrom"
                selected={this.state.dateFrom}
                onChange={(e) => this.handleDateChange(e, 'dateFrom')}
                showTimeSelect
                dateFormat="DD/MM/YY HH:mm"
                timeFormat="HH:mm"
                timeIntervals={30}
                timeCaption="time"
                locale="en-gb"
                todayButton={"Today"}
                // readOnly={true}
              />
            </div>

            <div className="mt-2">
              <h5>
                <Trans i18nKey="common:reports.dateTo" />
                <i onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose} className="far fa-sm fa-question-circle ml-2"></i>
              </h5>
              <DatePicker
                className="form-control"
                name="dateTo"
                selected={this.state.dateTo}
                onChange={(e) => this.handleDateChange(e, 'dateTo')}
                showTimeSelect
                dateFormat="DD/MM/YY HH:mm"
                timeFormat="HH:mm"
                timeIntervals={30}
                timeCaption="time"
                locale="en-gb"
                todayButton={"Today"}
                // readOnly={true}
              />
            </div>

            <div className="mt-2">
              <h5>
                <Trans i18nKey="common:reports.trackingNumbers" />
                <i onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose} className="far fa-sm fa-question-circle ml-2"></i>
              </h5>
              <div className="form-group m-0">
                <textarea
                  name="trackingNo"
                  className="form-control"
                  rows="5"
                  placeholder="e.g. ACB1234567, DEF1234567, ..."
                  onChange={this.handleOnChange.bind(this)} />
              </div>
            </div>

            <div className="mt-2">
              <h5>
                <Trans i18nKey="common:reports.emailsToSentReportTo" />
                <i onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose} className="far fa-sm fa-question-circle ml-2"></i>
              </h5>
              <input
                className="mb-2 form-control"
                name="emails"
                placeholder={this.props.t("reports.emailsPlaceholder")}
                value={this.state.emails}
                onChange={this.handleOnChange.bind(this)}
              />
            </div>

            {
              this.state.loadingFetchOrdersDetails || this.state.loadingFetchTrackerUpdates ?
                <div className="alert alert-warning text-center w-100 m-0" role="alert">
                  <Trans i18nKey="common:reports.loadingThisMayTakeLonger" />
                </div>
                :
                null
            }

            {
              this.state.emailsMessage ?
                <div className="alert alert-success text-center w-100 m-0" role="alert">
                  {this.state.emailsMessage}
                </div>
                :
                null
            }

            {this.renderPleaseEnterYourEmailAlert()}

            {this.renderFetchOrdersDetailsButton()}

            {this.renderFetchTrackerUpdatesButton()}

            <div style={{ padding: 15, textAlign: "center"}}>-------------- OR --------------</div>

            {this.renderDownloadOrdersAlert()}
            {this.renderDownloadsOrdersDetailsButton()}

            {this.renderDownloadTrackerUpdatesAlert()}
            {this.renderDownloadsTrackerUpdatesButton()}

            {this.isValidCsv(this.state.ordersForDownloadInCSV)?<CSVLink
                data={this.state.ordersForDownloadInCSV}
                filename="downloadOrdersCsv.csv"
                className="hidden"
                ref={this.downloadOrdersCsvLink}
                target="_blank" 
            />:""}

            {this.isValidCsv(this.state.trackerUpdatesForDownloadInCSV)?<CSVLink
                data={this.state.trackerUpdatesForDownloadInCSV}
                filename="downloadTrackerUpdatesCsv.csv"
                className="hidden"
                ref={this.downloadTrackerUpdatesCsvLink}
                target="_blank" 
            />:""}            

            {/* {this.renderDownloadTrackerUpdatesButton()} */}

            {/*
              this.state.ordersInCSV !== undefined && this.state.ordersInCSV.length > 0 && this.state.emails.length === 0 ?
                <CSVLink
                  className="w-100 mt-1 btn btn-lg btn-info"
                  data={this.state.ordersInCSV}
                  filename={"Janio Orders " + this.getDateFrom() + "-" + this.getDateTo() + ".csv"}
                  target="_blank"
                >
                  <Trans i18nKey="common:reports.downloadOrdersInCSV" />
                </CSVLink>
                :
                null
            */}

            {/*
              this.state.trackerUpdatesInCSV !== undefined && this.state.trackerUpdatesInCSV.length > 0 && this.state.emails.length === 0 ?
                <CSVLink
                  className="w-100 mt-1 btn btn-lg btn-info"
                  data={this.state.trackerUpdatesInCSV}
                  filename={"Janio Tracker Updates " + this.getDateFrom() + "-" + this.getDateTo() + ".csv"}
                  target="_blank"
                >
                  <Trans i18nKey="common:reports.downloadTrackerUpdatesInCSV" />
                </CSVLink>
                :
                null
            */}
          </Jumbotron>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ shipperDetails, order, reports }) {
  return ({
    error: reports.error,
    shipperDetails: shipperDetails.shipperDetails,
    countries: order.countries,
    service: order.service,
    publicStatuses: order.publicStatuses,
    ordersInCSV: reports.ordersInCSV,
    trackerUpdatesInCSV: reports.trackerUpdatesInCSV,
    emailsMessage: reports.emailsMessage,
    lastUpdatedFetchOrdersDetails: reports.lastUpdatedFetchOrdersDetails,
    lastUpdatedFetchTrackerUpdates: reports.lastUpdatedFetchTrackerUpdates,
  });
}

export default compose(
  connect(mapStateToProps, {
    fetchShipperDetails,
    fetchAllCountries,
    fetchService,
    fetchStatuses,
    fetchOrdersInCSV,
    fetchTrackerUpdatesInCSV,
    clearReportErrors
  }),
  withStyles(styles),
  withTranslation('common')
)(Reports);
