export const REQUIREMENT_DOCUMENT_FOR_COUNTRIES = {
  'Indonesia': {
    LOCALTAXID: 'NPWP',
    KTP: 'KTP/NIK',
    PASSPORT: 'Passport Number',
    LICENSE: "Indonesia Driver's License"
  },
  'South Korea': {
    PCCC: "PCCC"
  },
}
