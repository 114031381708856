import React, { useEffect } from "react";
import { Spin } from "antd";

import { useDispatch, useSelector } from "react-redux";

import { history } from "../../utils/historyUtils";

import { handleShopeeOnboard, handleLazadaOnboard } from "../draftOrder/channelStore";
import CallbackForm from "./CallbackForm";

export const Callback = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const shop_id = params.get("shop_id");
  const dispatch = useDispatch();

  const shopeeOnboard = useSelector((state) => {
    return state.channels.shopeeOnboard;
  });

  const lazadaOnboard = useSelector((state) => {
    return state.channels.lazadaOnboard;
  });

  useEffect(() => {
    if (shopeeOnboard.loaded || lazadaOnboard.loaded) {
      history.push("/channels/");
    }
  }, [shopeeOnboard, lazadaOnboard]);

  // TODO: refactor and use commong code for different channels.

  return (
    <>
      <CallbackForm
        shop_id={shop_id}
        code={code}
        errors={shopeeOnboard.validationErrors || lazadaOnboard.validationErrors}
        handleShopeeOnboard={(code, shop_id, shop_name, shop_country) => {
          dispatch(handleShopeeOnboard(code, shop_id, shop_name, shop_country));
        }}
        handleLazadaOnboard={(code, shop_id, shop_name, shop_country) => {
          dispatch(handleLazadaOnboard(code, shop_id, shop_name, shop_country));
        }}
      />
      {(shopeeOnboard.loading || lazadaOnboard.loading) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            margin: 0,
          }}
        >
          <Spin />
        </div>
      )}
    </>
  );
};
