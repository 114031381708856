export const COUNTRY_TO_CURRENCY = {
  'Afghanistan': 'AFN',
  'Albania': 'ALL',
  'Andorra': 'EUR',
  'Argentina': 'ARS',
  'Armenia': 'AMD',
  'Australia': 'AUD',
  'Azerbaijan': 'AZN',
  'Bahrain': 'BHD',
  'Belgium': 'EUR',
  'Bolivia': 'BOB',
  'Bosnia & Herzegovina': 'BAM',
  'Brazil': 'BRL',
  'Brunei': 'BND',
  'Bulgaria': 'BGN',
  'Canada': 'CAD',
  'Canary Islands': 'EUR',
  'Chile': 'CLP',
  'China': 'CNY',
  'Colombia': 'COP',
  'Croatia': 'HRK',
  'Cyprus': 'CYP',
  'Czech Republic': 'CZK',
  'Denmark': 'DKK',
  'Ecuador': 'ECS',
  'Egypt': 'EGP',
  'Estonia': 'EEK',
  'Falkland Islands': 'FKP',
  'Faroe Islands': 'FAE',
  'Finland': 'EUR',
  'France': 'EUR',
  'French Guiana': 'GNF',
  'Georgia': 'GEL',
  'Germany': 'EUR',
  'Gibraltar': 'GIP',
  'Greece': 'EUR',
  'Greenland': 'DKK',
  'Guernsey': 'GGP',
  'Guyana': 'GYD',
  'Hong Kong': 'HKD',
  'Hungary': 'HUF',
  'Iceland': 'ISK',
  'India': 'INR',
  'Indonesia': 'IDR',
  'Ireland': 'EUR',
  'Israel': 'ILS',
  'Italy': 'EUR',
  'Japan': 'JPY',
  'Jersey': 'JMD',
  'Jordan': 'JOD',
  'Kazakhstan': 'KZT',
  'Kosovo': 'EUR',
  'Kuwait': 'KWD',
  'Latvia': 'EUR',
  'Libya': 'LYD',
  'Liechtenstein': 'CHF',
  'Lithuania': 'LVL',
  'Luxembourg': 'EUR',
  'Macedonia': 'MKD',
  'Malaysia': 'MYR',
  'Malta': 'EUR',
  'Moldova': 'MDL',
  'Monaco': 'EUR',
  'Montenegro': 'EUR',
  'Netherlands': 'EUR',
  'New Zealand': 'NZD',
  'Norway': 'NOK',
  'Oman': 'OMR',
  'Paraguay': 'PYG',
  'Peru': 'PEN',
  'Philippines': 'PHP',
  'Poland': 'PLN',
  'Portugal': 'EUR',
  'Qatar': 'QAR',
  'Romania': 'ROL',
  'Saudi Arabia': 'SAR',
  'Serbia': 'SRB',
  'Singapore': 'SGD',
  'Slovakia': 'EUR',
  'Slovenia': 'EUR',
  'South Georgia and the South Sandwich Islands': 'GBP',
  'South Korea': 'KRW',
  'Spain': 'EUR',
  'Suriname': 'SRD',
  'Sweden': 'SEK',
  'Switzerland': 'CHF',
  'Taiwan': 'TWD',
  'Thailand': 'THB',
  'Turkey': 'TRY',
  'Turkmenistan': 'TMM',
  'UAE': 'AED',
  'UK': 'GBP',
  'Uruguay': 'UYU',
  'US': 'USD',
  'Vatican City': 'EUR',
  'Venezuela': 'VEB',
  'Vietnam': 'VND'
}