import React from "react";
import { useFormik } from "formik";
import { Form, Button, Alert, Input } from "antd";
import * as yup from "yup";
import SelectCountry from "../common/SelectCountry";
import { history } from "../../utils/historyUtils";
import { isEmpty } from "lodash";

const r = "This field is required.";
const validationSchema = yup.object().shape({
  shop_name: yup.string().required(r),
  shop_country: yup.string().required("Please select country"),
});

const CallbackForm = ({ code, shop_id, handleShopeeOnboard, errors, handleLazadaOnboard }) => {
  const onSubmit = async (values, { setSubmitting, setStatus }) => {
    const url = window.location.href.split("?")[0];
    const channel = url.substr(url.lastIndexOf("/") + 1);
    if (channel === "shopee") {
      handleShopeeOnboard(code, shop_id, values.shop_name, values.shop_country);
    }
    if (channel === "lazada") {
      handleLazadaOnboard(code, shop_id, values.shop_name, values.shop_country);
    }
  };

  const initialValues = {
    shop_name: shop_id,
    shop_country: "",
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  const getErrorsProps = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return {
        validateStatus: "error",
        help: formik.errors[fieldName],
        hasFeedback: true,
      };
    }
    return {};
  };

  // TODO: Refactor while refactoring the shopee code.
  let message = "";
  if (errors && errors.message) {
    message = errors.message;
  } else if (!isEmpty(errors)) {
    console.log(errors);
    Object.keys(errors).forEach((key) => {
      message = `${message} \n ${key}: ${errors[key].join(", ")}`;
    });
  }

  return (
    <div>
      {message && <Alert message={message} type="error" showIcon />}

      <form className="mt-2" onSubmit={formik.handleSubmit}>
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <p className="mb-4 mr-3">Shop Name</p>
            <Form.Item colon={false} {...getErrorsProps("shop_name")}>
              <Input
                onChange={(v) => {
                  formik.setFieldValue("shop_name", v.target.value);
                }}
                value={formik.values.shop_name}
                style={{ width: 300 }}
                disabled={formik.isSubmitting}
              />
            </Form.Item>
          </div>

          <div className="ml-4">
            <div className="d-flex align-items-center">
              <p className="mb-4 mr-3">Shop Country</p>
              <SelectCountry
                formItemProps={{
                  ...getErrorsProps("shop_country"),
                }}
                onChange={(v) => {
                  formik.setFieldValue("shop_country", v);
                }}
                value={formik.values.shop_country}
                disabled={formik.isSubmitting}
                placeholderText="Please select country"
              />
            </div>
          </div>
        </div>

        <hr />
        <div className="d-flex">
          <div>
            <Button type="primary" size="large" htmlType="submit" disabled={formik.isSubmitting}>
              {formik.isSubmitting ? "Submitting" : "Save"}
            </Button>
          </div>
          <div className="ml-3">
            <Button size="large" disabled={formik.isSubmitting} onClick={() => history.push("/channels/")}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CallbackForm;
